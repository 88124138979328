import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import {
  GetPlans,
  PendingPlanResponse,
} from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/get-plan.model';
import {
  PreviousSolicitor,
} from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/money-manager-data';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { Subscription } from 'rxjs';
import { actionText, alertMessages, modalMessage, statesList, validationPatterns } from 'src/app/helpers/common-constants';
import { MoneyManagerIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'lib-solicitor-old-workflow',
  templateUrl: './solicitor-old-workflow.component.html',
  styleUrls: ['./solicitor-old-workflow.component.css'],
})
export class SolicitorOldWorkflowComponent implements OnInit, OnDestroy {
  @Input() planDetails: GetPlans;
  selectedAction: string;
  submitted = false;
  loading = false;
  oldWorkflowForm: UntypedFormGroup;
  statesList = statesList;
  pattern = /[0-9]/g;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  infoMessage: NfrpAlertMessage;
  solicitorRequestObj: any;
  planId: number;
  userEmail: string;
  solicitorList: PreviousSolicitor[];
  formSubscription: Subscription;
  selectedSolicitor:string;
  modalOptions: any = {
    header: modalMessage.CONFIRMATION,
    message: modalMessage.REMOVE_TRUSTEE_MSG,
    showModal: false,
    noButtonValue: modalMessage.NO_TEXT,
    yesButtonValue: modalMessage.YES_TEXT,
  };

  buttonText: any = {
    save: actionText.SAVE,
    remove: actionText.REMOVE,
    add: actionText.ADD,
  };
  private subscriptions = [];
  isPending: boolean;
  showOldWorkflow = false;
  hideAddNewSolicitor = false;
  tpaNo: any;
  userId: number;  
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private route: ActivatedRoute,
    private navigationStore: NavigationStore,
    private router: Router,
    private communicationService: CommunicationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    this.loading = true;
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe((pending) => {
        this.isPending = pending;
      })
    );
    
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.selectedAction = params.action;
      this.tpaNo = this.planDetails?.tpAdminNbr;  
      if (this.planDetails?.moneyManagerId === MoneyManagerIDEnum.MGO_INVESTMENT) {
        this.hideAddNewSolicitor = true;
      }                 
      this.getPreviousSolicitor();
      this.loading = false;
    });
    this.oldWorkflowForm = this.fb.group({
      solicitorId: [null],
      solicitorName: [null, [Validators.required]],
      addressLineOne: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      addressLineTwo: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      city: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumericPattern)]],
      state: [null],
      zip: [null, [Validators.maxLength(10)]],
      phone: [null, [Validators.maxLength(30)]],
      fax: [null, [Validators.maxLength(30)]],
      email:[null, [Validators.maxLength(255), Validators.pattern(validationPatterns.emailPattern)]]
    });
   
    this.formSubscription = this.oldWorkflowForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }
  
  get oldWorkflowFormControl() {
    return this.oldWorkflowForm.controls;
  }

  getPreviousSolicitor(): void {
    this.subscriptions.push(
      this.planDetailsService
        .getPreviousSolicitorList(this.tpaNo)
        .subscribe((data: PreviousSolicitor[]) => {          
          if (data.length > 0) {
            this.solicitorList = data;
          }
          else {
            this.showOldWorkflow = true;
          }  
        },
        (error) => {
          this.errorMessage = { message: error, details: [] };
        })
    );
  }
 
  noClicked(): void {
    this.modalOptions.showModal = false;
  }

  yesClicked(): void {
    this.modalOptions.showModal = false;
  }

  onCancel(): void {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.router.navigate(['/plan-summary', this.planId]);
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.oldWorkflowForm.valid) {     
      this.solicitorRequestObj = {
        solicitorId:this.oldWorkflowForm.value.solicitorId,
        solicitorFirmName: this.oldWorkflowForm.value.solicitorName,
        solAddressA: this.oldWorkflowForm.value.addressLineOne,
        solAddressB: this.oldWorkflowForm.value.addressLineTwo,
        solCity: this.oldWorkflowForm.value.city,
        solState: this.oldWorkflowForm.value.state,
        solZip: this.oldWorkflowForm.value.zip,
        solPhone: this.oldWorkflowForm.value.phone,
        solFax: this.oldWorkflowForm.value.fax,
        solEmail: this.oldWorkflowForm.value.email,
        userId:this.userId,
        solStatus: 'A',
        active: 1,
        advisory: 0
      };
      this.subscriptions.push(
        this.planDetailsService.saveNewSolicitor(this.planId,this.isPending, this.solicitorRequestObj).subscribe(
          (solicitorResponse: PendingPlanResponse) => {
            window.scroll(0, 0);
            this.loading = false;            
            this.successMessage = { message: solicitorResponse.message, details: [] };
            this.isPending = solicitorResponse.newPendingPlanCreated;
            this.communicationService.setIsPendingForNewPendingPlan(solicitorResponse);
            this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
            this.errorMessage = { message: '', details: [] };
            this.infoMessage = { message: '', details: [] };
          },
          (error) => {
            this.loading = false;
            window.scroll(0, 0);
            this.successMessage = { message: '', details: [] };
            this.infoMessage = { message: '', details: [] };
            this.errorMessage =  { message: error, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  chooseSolicitor(): void {
    this.subscriptions.push(
      this.planDetailsService
        .chooseSolicitor(this.planId,+this.selectedSolicitor,this.isPending, this.userId)
        .subscribe((response: PendingPlanResponse) => {          
         this.successMessage =   { message:response.message , details: [] };
         this.errorMessage = { message: '', details: [] };
         this.isPending = response.newPendingPlanCreated;
         this.communicationService.setIsPendingForNewPendingPlan(response);
         this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
        },
        (error) => {
          this.errorMessage = { message: error, details: [] };
        })
    );
  }

  showNewSolicitor(): void {
  this.showOldWorkflow = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
