<div class="padding-top">
  <ul id="plan-head" summary="employee dates" class="ul-li-view-edit border-common">
    <!-- <li *ngIf="showEditOption && !archivePlanStatus && planFees != null && isPlanFeeVerified && !isMnyRiaFeeVerified">
      <div class="padding">
        <nfrp-alert
          *ngIf="editPlanFeeMessge?.message?.length > 0"
          alertId="editplan-display-message"
          [message]="editPlanFeeMessge"
          type="error"
          [page]="false"
        >
        </nfrp-alert>
      </div>
    </li> -->
    <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
      <nfrp-alert alertId="plan-fee-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
    </div>
    <li  [ngClass]="{'pending-status-bg':pendingStatus}">
      <div class="col-sm-9 padding-left">
        <b>Plan Fee Schedule</b>
        <div class="ml-10" *ngIf="showEditOption && !archivePlanStatus && planFees !== null && isPlanFeeVerified">
          <span class="text-red">Fee Verification Required</span>
        </div>
      </div>
      <div
        class="col-sm-3 padding-right"
        *ngIf="showEditOption && !archivePlanStatus && planFees !== null && status"
      >
        <button class="button button-tertiary" [ngClass]="{'pending-status-bg':pendingStatus}" routerLink="plan-fee-schedule/edit">Edit</button>
      </div>
      <div class="col-sm-3 padding-right" *ngIf="showEditOption && !archivePlanStatus && planFees === null && status">
        <button class="button button-tertiary" [ngClass]="{'pending-status-bg':pendingStatus}" routerLink="plan-fee-schedule/add">Add</button>
      </div>
    </li>
  </ul>
  <ul class="ul-li-view-edit table stripes no-margin border-common">
    <li>
      <div class="col-sm-6 padding-left">
        <span id="firm-label">Program</span>
      </div>
      <div class="col-sm-6 padding-left">
        <span id="firm-value">{{ planFees?.firm }}</span>
      </div>
    </li>
    <li *ngIf="planFees?.isPlanNwAdmin === 1">
      <div class="col-sm-6 padding-left">
        <span id="nrp-bundled-label">NRP&nbsp; </span>
        <span padding-left>Bundled</span>
      </div>
      <div class="col-sm-6 padding-left">
        <span id="nrp-bundled-value">{{ planFees?.planFeeTpa }}</span>
      </div>
    </li>
    <li>
      <div class="col-sm-6 padding-left">
        <span id="nrp-tpa-label" *ngIf="planFees?.isPlanNwAdmin === 1">NRP&nbsp;</span>
        <span padding-left>TPA</span>
      </div>
      <div class="col-sm-6 padding-left">
        <span id="nrp-tpa-value">{{ planFees?.isPlanNwAdmin === 1 ? planFees?.planFeeTpaTeam : planFees?.planFeeTpa }}</span>
      </div>
    </li>
    <li>
      <div class="col-sm-6 padding-left">
        <span id="ria-services-label">RIA Services</span>
      </div>
      <div class="col-sm-6 padding-left">
        <span id="ria-services-value">{{ planFees?.planFeeRias }}</span>
      </div>
    </li>
    <li>
      <div class="col-sm-6 padding-left">
        <span id="plan-fee-solicitor-label">Solicitor</span>
      </div>
      <div class="col-sm-6 padding-left">
        <span id="plan-fee-solicitor-value">{{ planFees?.planFeeSolicitor }}</span>
      </div>
    </li>
    <li>
      <div class="col-sm-6 padding-left">
        <span id="plan-fee-manager-label">Manager</span>
      </div>
      <div class="col-sm-6 padding-left">
        <span id="plan-fee-manager-value">{{ planFees?.planFeeManager }}</span>
      </div>
    </li>
    <li>
      <div class="col-sm-6 padding-left">
        <span id="plan-fee-total-label">Total</span>
      </div>
      <div class="col-sm-6 padding-left">
        <span id="plan-fee-total-value">{{ planFees?.planFeeTotal }}</span>
      </div>
    </li>
  </ul>
</div>
