<div class="base-footer-min-height">
  <!-- Footer tiles -->
  <div class="base-footer-min-height">
    <div id="footer-tiles" role="contentinfo" *ngIf="login">
      <section class="dashboard-push-back container-fluid no-padding-sides no-padding-bottom home">
        <div class="tile" *ngIf="processCategory?.length > 0">
          <h4>
            <i class="icon-document"></i>
            <span class="subject">Processing</span>
          </h4>

          <nav class="pagenav">
            <ul class="nav flex-column">
              <li class="nav-item" *ngFor="let i of processCategory">
                <a id="{{i.featureTarget}}-footer-link"
                  routerLink="/{{ i.featureTarget }}"
                  routerLinkActive="{{ i.featureActive === 1 ? 'Active' : 'Inactive' }}"
                  >{{ i.featureLabel }}</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <div class="tile" *ngIf="commCategory?.length > 0">
          <h4>
            <i class="icon-notification"></i>
            <span class="subject">Communication</span>
          </h4>
          <nav class="pagenav">
            <ul class="nav flex-column">
              <li class="nav-item" *ngFor="let i of commCategory">
                <a id="{{i.featureTarget}}-footer-link"
                  routerLink="/{{ i.featureTarget }}"
                  routerLinkActive="{{ i.featureActive === 1 ? 'Active' : 'Inactive' }}"
                  >{{ i.featureLabel }}</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <div class="tile" *ngIf="adminCategory?.length > 0">
          <h4>
            <i class="icon-world"></i>
            <span class="subject">Application Administration</span>
          </h4>
          <nav class="pagenav">
            <ul class="nav flex-column">
              <li class="nav-item" *ngFor="let i of adminCategory">
                <a id="{{i.featureTarget}}-footer-link"
                  routerLink="/{{ i.featureTarget }}"
                  (click)="onClick(i.featureID)"
                  routerLinkActive="{{ i.featureActive === 1 ? 'Active' : 'Inactive' }}"
                >
                  {{ i.featureLabel }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="tile" *ngIf="clientCategory?.length > 0">
          <h4>
            <i class="nw-icon-settings"></i>
            <span class="subject">Client Setup</span>
          </h4>
          <nav class="pagenav">
            <ul class="nav flex-column">
              <li class="nav-item" *ngFor="let i of clientCategory">
                <a id="{{i.featureTarget}}-footer-link"
                  routerLink="/{{ i.featureTarget }}"
                  (click)="onClick(i.featureID)"
                  routerLinkActive="{{ i.featureActive === 1 ? 'Active' : 'Inactive' }}"
                  >{{ i.featureLabel }}</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </div>
    <footer class="nfrp-footer pb-6" id="footer" role="contentinfo">
      <hr />
      <p>
        Life and annuity products are issued by Nationwide Life Insurance Company or Nationwide Life and Annuity
        Company, Columbus, Ohio. The general distributor for variable products is Nationwide Investment Services
        Corporation, member
        <a href="http://www.finra.org" target="_blank">FINRA</a> The Nationwide Retirement Institute is a division of
        NISC. Nationwide Funds distributed by Nationwide Fund Distributors, LLC, Member
        <a href="http://www.finra.org" target="_blank">FINRA</a>, King of Prussia, PA. Nationwide Life Insurance
        Company, Nationwide Life and Annuity Company, Nationwide Investment Services Corporation, and Nationwide Fund
        Distributors are separate but affiliated companies.
      </p>
      <p>
        The Nationwide Group Retirement Series includes unregistered group fixed and variable annuities issued by
        Nationwide Life Insurance Company. It also includes trust programs and trust services offered by Nationwide
        Trust Company, FSB a division of Nationwide Bank
        <sup>&reg;</sup>.
      </p>
      <p>
        Nationwide, the Nationwide N and Eagle, The Nationwide Retirement Institute, Nationwide is On Your Side and
        Nationwide Funds Group are service marks of Nationwide Mutual Insurance Company. Let's Face it Together is a
        service mark of Nationwide Life Insurance Company.
      </p>
      <p>
        &copy;2015 Nationwide Mutual Insurance Company and affiliated companies
      </p>
    </footer>
  </div>
</div>
