import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NfrpAlertMessage, NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { FeatureTypeIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { GetPlanNotes, GetPlans } from '../../../model/get-plan.model';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { PlanDetailsService } from '../../../services/plan-details.service';

@Component({
  selector: 'lib-plan-notes',
  templateUrl: './plan-notes.component.html',
  styleUrls: ['./plan-notes.component.css'],
})
export class PlanNotesComponent extends NfrpDefaultGridComponent<GetPlanNotes> implements OnInit, OnChanges, OnDestroy {
  @Input() status: boolean;
  @Input() pendingStatus: boolean;
  planId: number;
  isViewPlanMode = true;
  isEditPlanMode = false;
  dataSource: GetPlanNotes[];
  numberOfItems: number;
  archivePlanStatus = false;
  planDetails: GetPlans;
  errorMessage: NfrpAlertMessage;
  private userEmail: string;
  private subscriptions = [];

  constructor(
    private route: ActivatedRoute,
    private planDetailsService: PlanDetailsService,
    private footerFeaturesService: FooterFeaturesService,
    private communicationService: CommunicationService,
    nfrpGridService: NfrpGridService<GetPlanNotes>
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.VIEW_PLANS, this.userEmail).subscribe()
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.getPlanNotes();
    });
    //Archive plan event
    this.subscriptions.push(
      this.communicationService.archivePlanStatusEvent.subscribe((archivePlanStatus: boolean) => {
        this.archivePlanStatus = archivePlanStatus;
      })
    );
    this.getPlanDetails();
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.pendingStatus).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
        this.archivePlanStatus = this.planDetails?.planArchived === BinaryResponseEnum.NO ? true : false;
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
  getPlanNotes() {
    this.subscriptions.push(
      this.planDetailsService
        .getPlanNotes(this.planId)
        .pipe(
          tap((data) => {
            this.dataSource = data?.planNotesResponses;
            this.numberOfItems = this.dataSource?.length;
            if (this.dataSource) {
              this.setupGrid();
            }
          })
        )
        .subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.planId && changes.pendingStatus && (changes.pendingStatus.currentValue || changes.pendingStatus.previousValue)) {
      this.getPlanNotes();      
      this.getPlanDetails();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
