<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
     
    <div class="col-sm-10">
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-tpa-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-tpa-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div> 
      <ng-container>
        <h1 id="main-title" class="page-title ng-binding">Third Party Administrators</h1>
       
        <div class="search-horizontal margin-bottom">
          <div class="search-horizontal">
            <input
            id="internal-tpa-input"
            type="text"
            size="40"
            name="searchQuery"
            title="TPA search"
            maxlength="100"
            [(ngModel)]="searchQuery"
            placeholder="Search TPA"
            ng-reflect-maxlength="100"
            ng-reflect-name="searchQuery"
            class="ng-pristine ng-valid ng-touched"
          />
          </div>          
          <div class="search-horizontal">
            <input id="search-button" class="button button-primary" type="submit" value="Search" (click)="searchSubmit()"/>
          </div>
        </div>
        
       
        
        <ng-container *ngIf="dataSource; else loading">
          <ng-template #headerTemplate>
            <div nfrpColumn [width]="15" widthType="%" [id]="'tpadmin-number-label'" defaultDirection="ASCENDING">
              Number
              <nfrp-grid-sorter [column]="'tpAdminNbr'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
            <div nfrpColumn [width]="35" widthType="%" [id]="'tpadmin-admin-label'">
              Administrator
              <nfrp-grid-sorter [column]="'tpAdminLabel'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
            <div nfrpColumn [width]="15" widthType="%" [id]="'tpadmin-active-label'">
              Active
              <nfrp-grid-sorter [column]="'tpAdminActive'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>

            <div nfrpColumn [width]="15" widthType="%" [id]="'tpadmin-nwadmin-label'">
              NW Admin
              <nfrp-grid-sorter [column]="'tpAdminNWAdmin'" dataType="number" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>

            <div nfrpColumn [width]="20" widthType="%" [id]="'tpadmin-filecabinet-label'">
              File Cabinet
              <nfrp-grid-sorter (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
          </ng-template>
          <ng-template #rowTemplate let-tpadminSearchResult="item" let-index="index">
            <div>
              <div [id]="'tpadmin-number-' + index">
                <a class="text-decor-underline"  (click)="tpAdminRowClicked(tpadminSearchResult)">{{ tpadminSearchResult.tpAdminNbr | nfrpNormalizeValue: '-' }}</a>
              </div>
              <div [id]="'tpadmin-admin-' + index">
                <a class="text-decor-underline"  (click)="tpAdminRowClicked(tpadminSearchResult)">{{ tpadminSearchResult.tpAdminLabel | nfrpNormalizeValue: '-' }}</a>
              </div>
              <div [id]="'tpadmin-active-' + index">
                {{ tpadminSearchResult.tpAdminActive === 1 ? 'Yes' : ('No' | nfrpNormalizeValue: '-') }}
              </div>
              <div [id]="'tpadmin-nwadmin-' + index">
                {{ tpadminSearchResult.tpAdminNWAdmin === 1 ? 'Yes' : ('No' | nfrpNormalizeValue: '-') }}
              </div>
              <div [id]="'tpadmin-filecabinet-' + index">
                <a class="text-decor-underline">Link</a>
              </div>
            </div>
          </ng-template>

          <nfrp-grid
            [headerTemplate]="headerTemplate"
            [rowTemplate]="rowTemplate"
            [iterable]="data"
            [width]="100"
            rowIdPrefix="tpadmin-details"
          ></nfrp-grid>
          <nfrp-grid-paginator
            (paginate)="paginate($event)"
            [numberOfItems]="numberOfItems"
            [itemsPerPageOptions]="[20, 50, 100]"
            [defaultItemsPerPage]="20"
          ></nfrp-grid-paginator>
        </ng-container>
      </ng-container>
      <ng-template #loading>
        <nfrp-spinner></nfrp-spinner>
      </ng-template>
    </div>
  </div>
</section>
