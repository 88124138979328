<section class="container-fluid temp-color">
  <div class="box compartment summary animation-fade-in">
    <div class="section__header" role="button">
      <h2 class="content-title"><b>Print booklet documents for plan: </b></h2>
      <i id="ul-li-view-header-chevron"></i>
      {{ ekpResponse?.planNumber }} : {{ ekpResponse?.planLabel }}
    </div>
    <div class="content">
      <div class="section__body">
        <ng-container *ngIf="loading">
          <nfrp-spinner></nfrp-spinner>
        </ng-container>
        <ul>
          <div class="margin-bottom" *ngIf="infoMessage?.message?.length > 0">
            <div class="col-sm-12 no-padding">
              <nfrp-alert alertId="info-ekp" [message]="infoMessage" type="info" [page]="false"></nfrp-alert>
            </div>
          </div>
          <li *ngFor="let printDoc of trackInfo; let i = index">
            <a>{{ printDoc.documentLabel }}</a
            >&nbsp;<span>| {{ printDoc.numberOfDays }} Day(s) Old - Created On: {{ printDoc.createdDate }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
