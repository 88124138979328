import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NfrpDefaultGridComponent, NfrpAlertMessage, NfrpGridService } from 'nfrp-shared-angular';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, UserTypeIDEnum } from 'src/app/helpers/enum';
import { AssignedRoles } from '../../model/assigned-roles';
import { MoneyManager, MoneyManagerData } from '../../model/money-manager-data';
import { SearchUser } from '../../model/search-user';
import { TpAdminData } from '../../model/tpadmin-data';
import { FooterFeaturesService } from '../../services/footer-features.service';
import { SearchUsersService } from '../../services/search-users.service';

@Component({
  selector: 'retail-users',
  templateUrl: './retail-users.component.html',
  styleUrls: ['./retail-users.component.css'],
})
export class RetailUsersComponent extends NfrpDefaultGridComponent<SearchUser> implements OnInit, OnDestroy {
  @Output() loadEditUser: EventEmitter<string> = new EventEmitter<string>();
  numberOfItems: number;
  isRecordsList: boolean;
  dataSource: SearchUser[];
  loading: boolean;
  retailUserForm: UntypedFormGroup = this.fb.group({
    searchQuery: [null],
    userTypeId: [null]
  });
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  userSearchResult$: Observable<SearchUser[]> = this.searchUsersService.searchUserResult$;
  searchQuery: string;
  selectedUserRoleCode: number;
  assignedRoleCollection: any;
  clonedDataSource: SearchUser[];
  userEmail: string;
  selectedFirmId: string;
  moneyManagerFirmData: MoneyManager[];
  tpAdminFirmData: TpAdminData[];
  private subscriptions = [];
  isInternal = false;
  loginDetails : any;
  userId: number;
  tpAdminNbr: string;
  submitted = false;
  get retailFormControl() {
    return this.retailUserForm.controls;
  }
  constructor(
    private fb: UntypedFormBuilder,
    private searchUsersService: SearchUsersService,
    nfrpGridService: NfrpGridService<SearchUser>,
    private footerFeaturesService: FooterFeaturesService,
    private router: Router
  ) {
    super(nfrpGridService);
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : '';
    this.tpAdminNbr = loginUser ? JSON.parse(loginUser).tpAdminNbr : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.USER_MGMT, this.userEmail).subscribe()
    );
    this.isRecordsList = false;
    this.isInternal = JSON.parse(loginUser)?.isInternal === 1 ? true : false;
    this.loginDetails = JSON.parse(loginUser) ? JSON.parse(loginUser) : {};
    this.getUserListDetails();
  }

  rowDataClicked(data: SearchUser) {
    this.router.navigate(['userMgmt/edit-user-access', data.userId]);
  }

  searchSubmit(): void {
    this.searchQuery = this.retailUserForm.get('searchQuery').value;
    if (this.searchQuery) {
      this.isRecordsList = true;
      if (!this.isInternal && (this.searchQuery === this.loginDetails.nwId || this.searchQuery === this.loginDetails.nwEmail)){
        this.resetTable();
        this.errorMessage = { message: alertMessages.USER_NOT_ALLOWED_TO_SEARCH_WITH_NW_CREDENTIALS, details : []}
      }
      else {        
        this.getUsersListDetails(this.searchQuery);
      }
      
    }
  }

  selectedRole():  void {
    this.selectedUserRoleCode = +this.retailUserForm.get('userTypeId').value;
    if (this.selectedUserRoleCode === UserTypeIDEnum.MONEY_MANAGER) {  
      this.retailUserForm.addControl('selectedUserFirm', this.fb.control(null, [Validators.required]));    
      this.tpAdminFirmData = [];
      this.getMoneyManagerData();
    } else if (this.selectedUserRoleCode === UserTypeIDEnum.TPA) {
      this.retailUserForm.addControl('selectedUserFirm', this.fb.control(null, [Validators.required]));
      this.moneyManagerFirmData = [];
      this.getTpAdminFirmData();
    }
    else {
      this.tpAdminFirmData = [];
      this.moneyManagerFirmData = [];
      this.retailUserForm.removeControl('selectedUserFirm');      
    }
  }

  getUsersListDetails(searchQuery: string): void {
    this.loading = true;
    this.dataSource = null;
    this.clonedDataSource = null;
    this.retailUserForm.patchValue({
      userTypeId: null,
    });
    this.subscriptions.push(
      this.searchUsersService.getUserDetails(this.userId, searchQuery).subscribe(
        (response: any) => {
          window.scroll(0, 0);
          this.loading = false;
          this.dataSource = response;
          this.errorMessage = { message: '', details: [] };
          this.successMessage = { message: '', details: [] };
          this.clonedDataSource = Object.assign([], response);
          this.numberOfItems = this.dataSource?.length;
          this.setupGrid();
          this.paginate({
            limit: 20,
            offset: 1,
          });
        },
        (error) => {
          window.scroll(0, 0);
          this.resetTable();
          this.errorMessage = { message: error, details: [] };
        }
      )
    );
  }

  getUserListDetails() {
    this.subscriptions.push(
      this.searchUsersService.getAssignedRoleByUserId(this.userEmail).subscribe((assignedRole: AssignedRoles) => {
        this.assignedRoleCollection = assignedRole?.roles;
      })
    );
  }

  getMoneyManagerData(): void {
    this.subscriptions.push(
      this.searchUsersService.getMoneyManagerForUser(this.userEmail).subscribe((firmData: MoneyManager[]) => {
        this.moneyManagerFirmData = firmData;
      },
      (error) => {
        this.errorMessage = { message: error, details : [] };
      })
    );
  }

  getTpAdminFirmData(): void {
    this.subscriptions.push(
      this.searchUsersService.getTpAdmin(this.tpAdminNbr).subscribe(
        (firmData: TpAdminData[]) => {
          this.tpAdminFirmData = firmData;
        },
        (error) => {
          this.errorMessage = { message: error, details: [] };
        }
      )
    );
  }

  changeUserSearchView(userId: string) {
    this.loadEditUser.emit(userId);
    this.searchQuery = '';
  }
  
  resetTable(): void {
    this.loading = false;
    this.dataSource = [];
    this.numberOfItems = this.dataSource?.length;
    this.setupGrid();
    this.paginate({
      limit: 20,
      offset: 1,
    });
    this.successMessage = { message: '', details: [] };
  }

  searchUserByUserType(): void {
    const searchQuery = this.retailUserForm.get('userTypeId').value;
    const selectedUserFirm = this.retailUserForm.get('selectedUserFirm')?.value;
    this.submitted = true;
    if (this.retailUserForm.valid) {
      this.subscriptions.push(
        this.searchUsersService.getUserByUserType(searchQuery, selectedUserFirm).subscribe(
          (response: any) => {
            window.scroll(0, 0);
            this.isRecordsList = true;
            this.loading = false;
            this.dataSource = response;
            this.errorMessage = { message: '', details: [] };
            this.successMessage = { message: '', details: [] };
            this.clonedDataSource = Object.assign([], response);
            this.numberOfItems = this.dataSource?.length;
            this.setupGrid();
            this.paginate({
              limit: 20,
              offset: 1,
            });
          },
          (error) => {
            window.scroll(0, 0);
            this.resetTable();
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    }
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }

  ngOnDestroy() {
    window.scroll(0, 0);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
