import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import { Configuration } from '../models/configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config?: Configuration;
  config$: Observable<Configuration>;
  constructor(private readonly http: HttpClient) {
    this.config$ = this.http.get<Configuration>('../../../config/app-config.json').pipe(
      tap((config) => {
        this.config = config;
      })
    );
  }
}
