<section class="container-fluid temp-color">
  <div ui-view="" class="ng-scope">
    <div id="section-level-error-msg-section" class="row">
      <div class="col-sm-12">
        <div class="compartment box summary animation-fade-in">
          <div class="section__header "> <div class="col-sm-offset-5"><h2 class="content-title">Marketing Materials</h2>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
