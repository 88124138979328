/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum BinaryResponseEnum {
  YES = 1,
  NO = 0,
}
export enum UserRoleEnum {
  MONEY_MANAGER = 'Money Manager',
  THIRD_PARTY_ADMINISTRATOR = 'Third Party Administrator',
  RIA_SERVICES_EMPLOYEE = 'RIA Services Employee',
}
export enum BooleanEnum {
  YES = 'Y',
  NO = 'N',
}
export enum UserTypeIDEnum {
  RIA = 1,
  TPA = 3,
  TPA_TEAM = 9,
  NB_TEAM = 49,
  MONEY_MANAGER = 4,
  ENROLLMENT_KIT_PROCESSOR = 29,
  NO_ACCESS_USER = 89,
  CALL_CENTER = 69,
}

export enum PlanTypeIDEnum {
  PLAN_401 = 1,
  PROFIT_SHARING = 2,
  MONEY_PURCHASE = 3,
  DEFINED_BENEFIT = 4,
  CASE_BALANCE = 5,
  PLAN_457 = 6,
  PLAN_403 = 7,
}
export enum MoneyManagerIDEnum {
  CLS_INVESTMENTS = 16039,
  NW_INVESTMENT_ADVISOR_LLC = 51031,
  MEEDER_ADVISORY_SERVICES = 21871,
  PORTFOLIO_STRATEGIES = 29161,
  STADION_MONEY_MGMT = 62695,
  MGO_INVESTMENT = 48115,
  FLEXIBLE_INVESTMENT = 18955,
  HANLON = 20413,
}
export enum PlanStatusEnum {
  PENDING = 'Pending',
  UNDER_MGMT_TRACK = 'Under Mgmt. (Trac)',
  UNDER_MGMT_IK = 'Under Mgmt. (IK)',
}
export enum RecordSystemEnum {
  TRACK = 'Track',
  IK = 'Ik',
  ACTIVATE_IN_TRAC = 'Activate in Track',
  ACTIVATE_IN_IK = 'Activate in IK',
  TRACK_SYSTEM = 'TRAC',
  IK_SYSTEM = 'IK',
}

export enum ActionTypeEnum {
  EDIT = 'edit',
  ADD = 'add',
  REMOVE = 'remove',
}

export enum PlanSetupCheckListModalActionEnum {
  COMMIT_WARNING = 'COMMIT_WARNING',
  COMMIT_CONFIRM = 'COMMIT_CONFIRM',
  DELETE_CONFIRM = 'DELETE_CONFIRM',
}
export enum ProductTypeEnum {
  ADVISOR = 1,
  RESOURCE = 2,
  INNOVATOR = 3,
  CLEAR_ADVANTAGE = 4,
  FLEXIBLE_ADVANTAGE = 5,
  INNOVATOR_ADVANTAGE = 6,
}
export enum PlanInfoEnum {
  ALREADY_EXIST = 0,
  NEW_STARTUP = 1,
  NEW_TAKEOVER = 2,
}
export enum MatrixDocumentTypeName {
  PLAN_SPONSOR_AGREEMENT = 'Plan Sponsor Agreement',
  ENROLLMENT_BOOKLET_DOCS = 'Enrollment Booklet Docs',
  ENROLLMENT_BOOKLET_DOCS_NO = 1,
  PLAN_SPONSOR_AGREEMENT_NO = 2,
}
export enum FeatureTypeIDEnum {
  VIEW_PLANS = 1,
  CREATE_PLANS = 2,
  IK_PARTICIPANT = 3,
  SYSTEM_MESSAGES = 4,
  REPORTS = 5,
  PROPOSAL_REPORT = 6,
  MATRIX = 7,
  APPLICATION_SOLICITOR_MAINT = 8,
  SITE_MAINTENANCE = 9,
  USER_MGMT = 10,
  MNY_MGR_MGMT = 11,
  TPA_MGMT = 12,
  QDIA_REPORTS = 13,
  FEE_ACK_REPORT = 14,
  FILE_CABINET = 15,
  BOOK_CASE = 16,
  MARKETING_MATERIALS = 17,
  PRIVACY_POLICY = 18,
  TIE_FEE_MAINTENANCE = 19,
  UPDATE_FEES = 20,
  EMAIL_TOOLS = 21,
  CLIENT_SETUP_SOLICITOR_MAINT = 22,
  TRACK_PARTICIPANT = 24,
}
