import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage, NavigationStore, NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { GetPlanNotes, GetPlans } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/get-plan.model';
import { FooterFeaturesService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/footer-features.service';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';


@Component({
  selector: 'lib-add-view-notes',
  templateUrl: './add-view-notes.component.html',
  styleUrls: ['./add-view-notes.component.css'],
})
export class AddViewNotesComponent extends NfrpDefaultGridComponent<GetPlanNotes> implements OnInit, OnDestroy {
  selectedAction: string;
  buttonString: string;
  submitted = false;
  loading = false;
  companyId: string;
  addNotesForm: UntypedFormGroup;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  dataSource: GetPlanNotes[];
  planId: number;
  userEmail: string;
  planDetails: GetPlans;
  numberOfItems: number;
  formSubscription: Subscription;
  userId: number;
  planNoteRequest: GetPlanNotes;
  query: string;
  private subscriptions = [];
  isPending: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationStore: NavigationStore,
    private footerFeaturesService: FooterFeaturesService,
    nfrpGridService: NfrpGridService<GetPlanNotes>,
    private communicationService: CommunicationService
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe(pending => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.getPlanDetails();
    });
    this.getPlanNotes();
    this.addNotesForm = this.fb.group({
      addNoteText: [null, [Validators.required, Validators.maxLength(1000)]],
    });
    this.formSubscription = this.addNotesForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
  getPlanNotes() {
    this.subscriptions.push(
      this.planDetailsService
        .getPlanNotes(this.planId)
        .pipe(
          tap((data) => {
            this.dataSource = data?.planNotesResponses;
            // this.numberOfItems = this.dataSource?.length;
            if (this.dataSource) {
              this.setupGrid();
              /*this.paginate({
                limit: 5,
                offset: 1,
              });*/
            }
          })
        )
        .subscribe()
    );
  }
  get addNotesControl() {
    return this.addNotesForm.controls;
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.addNotesForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  onCancel(): void {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.router.navigate(['..'], { relativeTo: this.route });
  }
  savePlanNotes() {
    this.submitted = true;
    this.loading = true;
    if (this.addNotesForm.valid) {
      this.planNoteRequest = {
        planNotes: this.addNotesForm.value.addNoteText,
        userId: this.userId,
      };
      this.planDetailsService.savePlanNotes(this.planId, this.planNoteRequest).subscribe(
        (planNotesResponses) => {
          this.loading = false;
          this.errorMessage = { message: '', details: [] };
          this.successMessage = { message: alertMessages.PLAN_NOTES_ADDED, details: [] };
          this.getPlanNotes();
          this.addNotesForm.controls.addNoteText.setValue('');
          this.addNotesForm.controls.addNoteText.setErrors(null);
          this.navigationStore.set('formChanged', false);
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
