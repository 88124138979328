import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { statesList, validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, BinaryResponseEnum, UserTypeIDEnum } from 'src/app/helpers/enum';
import { UserResponse } from '../../../model/edit-user';
import { MoneyManagerMgmt } from '../../../model/mny-mgr-mgmt';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { SearchUsersService } from '../../../services/search-users.service';
import { BoxService } from '../../../services/box.service';

@Component({
  selector: 'add-money-manager',
  templateUrl: './add-money-manager.component.html',
  styleUrls: ['./add-money-manager.component.css'],
})
export class AddMoneyManagerComponent implements OnInit, OnDestroy {
  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();
  moneyManagerMode: string;
  mnyMgrStates = statesList;
  moneyManagerData: any;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  submitted = false;
  moneyManagerRequestObj: MoneyManagerMgmt;
  mnyMgrForm: UntypedFormGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  userEmail: string;
  formSubscription: Subscription;
  private subscriptions = [];
  loading = false;

  constructor(
    private searchUsersService: SearchUsersService,
    private footerFeaturesService: FooterFeaturesService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private navigationStore: NavigationStore,
    private boxService: BoxService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.MNY_MGR_MGMT, this.userEmail).subscribe()
    );
    this.mnyMgrForm = this.fb.group({
      moneyManagerNumber: [
        null,
        [
          Validators.required,
          Validators.maxLength(5),
          Validators.minLength(5),
          Validators.pattern(validationPatterns.numberPattern),
        ],
      ],
      nwId: [
        null,
        [Validators.required, Validators.maxLength(11), Validators.pattern(validationPatterns.numberPattern)],
      ],
      moneyManagerName: [
        null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern),
        ],
      ],
      firstName: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern)]],
      lastName: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern)]],
      address1: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      address2: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      city: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumericPattern)]],
      state: [''],
      zip: [null, [Validators.maxLength(10)]],
      phone: [null, [Validators.maxLength(30)]],
      fax: [null, [Validators.maxLength(30)]],
      email: [null, [Validators.maxLength(255), Validators.pattern(validationPatterns.emailPattern)]],
      contact: [null, [Validators.maxLength(255)]],
      active: [true],
      walledOff: [false],
      feeInvoicePrefix: [null, [Validators.maxLength(10), Validators.pattern(validationPatterns.alphaNumericPattern)]],
      feeInvoiceStart: [null, [Validators.maxLength(11), Validators.pattern(validationPatterns.numberPattern)]],
      feeInvoiceMessage: [
        null,
        [Validators.maxLength(2000), Validators.pattern(validationPatterns.alphaNumericPattern)],
      ],
      feeReportAccess: [false],
      feeMessageAccess: [false],
      allowToPrintDocuments: [false],
      enrollmentBookletAccess: [true],
      overridePlanLevelPrintAccess: [false],
      showConversion: [null, [Validators.required]],
      showQDIA: [null, [Validators.required]],
      merrillAutoMgmt: [null, [Validators.required]],
      merrillParticipantMap: [null, [Validators.required]],
      provideMapping: [null, [Validators.required]],
    });
    this.formSubscription = this.mnyMgrForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.mnyMgrForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  get moneyManagerControl() {
    return this.mnyMgrForm.controls;
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }
  cancel() {
    this.router.navigate(['/mnyMgrMgmt']);
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }

  onSubmit(): void {
    this.submitted = true;
    this.resetAlertMessages();
    if (this.mnyMgrForm.valid) {
      const moneyManagerRequestObj = {
        mnyMgrNbr: 'RIA' + this.mnyMgrForm.value.moneyManagerNumber,
        mnyMgrId: this.mnyMgrForm.value.nwId,
        mnyMgrLbl: this.mnyMgrForm.value.moneyManagerName,
        mnyMgrNameFirst: this.mnyMgrForm.value.firstName,
        mnyMgrNameLast: this.mnyMgrForm.value.lastName,
        mnyMgrAddrA: this.mnyMgrForm.value.address1,
        mnyMgrAddrB: this.mnyMgrForm.value.address2,
        mnyMgrCity: this.mnyMgrForm.value.city,
        mnyMgrState: this.mnyMgrForm.value.state,
        mnyMgrZip: this.mnyMgrForm.value.zip,
        mnyMgrPhone: this.mnyMgrForm.value.phone,
        mnyMgrFax: this.mnyMgrForm.value.fax,
        mnyMgrEmail: this.mnyMgrForm.value.email,
        mnyMgrContact: this.mnyMgrForm.value.contact,
        mnyMgrAct: this.checkMoneyManagerObj(this.mnyMgrForm.value.active),
        mnyMgrWF: this.checkMoneyManagerObj(this.mnyMgrForm.value.walledOff),
        mnyMgrFIP: this.mnyMgrForm.value.feeInvoicePrefix ? this.mnyMgrForm.value.feeInvoicePrefix : '0',
        mnyMgrFISN: this.mnyMgrForm.value.feeInvoiceStart ? +this.mnyMgrForm.value.feeInvoiceStart : 0,
        mnyMgrFIM: this.mnyMgrForm.value.feeInvoiceMessage ? this.mnyMgrForm.value.feeInvoiceMessage : '0',
        mnyMgrFRA: this.checkMoneyManagerObj(this.mnyMgrForm.value.feeReportAccess),
        mnyMgrFMA: this.checkMoneyManagerObj(this.mnyMgrForm.value.feeMessageAccess),
        mnyMgrPA: this.checkMoneyManagerObj(this.mnyMgrForm.value.allowToPrintDocuments),
        mnyMgrPO: this.checkMoneyManagerObj(this.mnyMgrForm.value.enrollmentBookletAccess), // need to confirm
        mnyMgrBDY: this.checkMoneyManagerObj(this.mnyMgrForm.value.overridePlanLevelPrintAccess),
        mnyMgrSC: this.checkMoneyManagerObj(this.mnyMgrForm.value.showConversion),
        mnyMgrSQ: this.checkMoneyManagerObj(this.mnyMgrForm.value.showQDIA),
        mnyMgrMAD: this.checkMoneyManagerObj(this.mnyMgrForm.value.merrillAutoMgmt),
        mnyMgrMPD: this.checkMoneyManagerObj(this.mnyMgrForm.value.merrillParticipantMap),
        mnyMgrPMD: this.checkMoneyManagerObj(this.mnyMgrForm.value.provideMapping),
        nwieVisibility: "Hidden"
      };
      this.subscriptions.push(
        this.searchUsersService.saveAddMoneyManagerData(moneyManagerRequestObj).subscribe(
          (moneyManagerResponse: UserResponse) => {
            window.scroll(0, 0);
            if (moneyManagerResponse.status && moneyManagerResponse.status === 'MM user created') { 
              if (this.mnyMgrForm.value.active) { 
                this.loading = true;                            
                this.showSuccessMessage();
                this.createDirectory(moneyManagerRequestObj.mnyMgrId);
              } else if (!this.mnyMgrForm.value.active) {
                this.successMessage = { message: alertMessages.NEW_INACTIVE_MONEY_MANAGER_MESSAGE, details: [] };
              }
            } else if (moneyManagerResponse.status && moneyManagerResponse.status === 'MM User already exists') {
              this.errorMessage = { message: alertMessages.MONEY_MANAGER_ALREADY_EXISTS, details: [] };
            }
          },
          (error) => {
            window.scroll(0, 0);
            this.successMessage = { message: '', details: [] };
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    } else {
      this.successMessage = { message: '', details: [] };
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  checkMoneyManagerObj(moneyManagerData) {
    return moneyManagerData ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
  }
  showSuccessMessage(): void {
    this.successMessage = { message: alertMessages.NEW_ACTIVE_MONEY_MANAGER_MESSAGE, details: [] };
  }

  resetAlertMessages(): void {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }

  createDirectory(mnyMgrNbr: string): void {
    this.boxService.createFoldersForEntity('money manager', mnyMgrNbr).subscribe((res) => {
      this.loading = false;
      this.successMessage = { message: 'Box directory created', details: [] };                
    },
    (error) => {
      this.loading = false;
      this.errorMessage = { message: error, details: [] };
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
