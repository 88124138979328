import { Injectable } from '@angular/core';
import { Store } from './store';

export class BaseState {
  authenticated = true;
  loggedIn = true;
}

@Injectable({
  providedIn: 'root'
})
export class BaseStore extends Store {
  constructor() {
    super(new BaseState());
  }
}
