import { NgModule } from '@angular/core';
import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SecurityNavigationComponent } from './components/security-navigation/security-navigation.component';
import {
  NfrpWidgetsModule,
  NfrpGridUtilsModule,
  NfrpPipesModule,
  NfrpNavigationModule,
  NfrpDateModule,
} from 'nfrp-shared-angular';
import { RouterModule } from '@angular/router';
import { OrderByPipe } from './pipes/order-by-pipe';
import { TpaMgmtComponent } from './components/tpa-mgmt/tpa-mgmt.component';
import { MnymgrMgmtComponent } from './components/mnymgr-mgmt/mnymgr-mgmt.component';
import { AddMoneyManagerComponent } from './components/mnymgr-mgmt/add-money-manager/add-money-manager.component';
import { EditMoneyManagerComponent } from './components/mnymgr-mgmt/edit-money-manager/edit-money-manager.component';
import { AddTpaComponent } from './components/tpa-mgmt/add-tpa/add-tpa.component';
import { EditTpaComponent } from './components/tpa-mgmt/edit-tpa/edit-tpa.component';
import { PlanSummaryComponent } from './components/plan-summary/plan-summary.component';
import { HttpClient } from '@angular/common/http';
import { PlanSponsorDocsComponent } from './components/plan-summary/plan-sponsor-docs/plan-sponsor-docs.component';
import { CorePlanComponent } from './components/plan-summary/core-plan/core-plan.component';
import { PlanNotesComponent } from './components/plan-summary/plan-notes/plan-notes.component';
import { PlanFeeComponent } from './components/plan-summary/plan-fee/plan-fee.component';
import { PlanDatesComponent } from './components/plan-summary/plan-dates/plan-dates.component';
import { PhoneMaskDirective } from './directive/phone-mask.directive';
import { EditAddRepComponent } from './components/plan-summary/plan-rep/edit/edit-add-rep.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PlanSummaryModule } from './components/plan-summary/plan-summary.module';
import { PlanRepComponent } from './components/plan-summary/plan-rep/view/plan-rep.component';
import { EditEmployeeSponsorComponent } from './components/plan-summary/employee-sponsor/edit/edit-employee-sponsor.component';
import { ViewEmployeeSponsorComponent } from './components/plan-summary/employee-sponsor/view/view-employee-sponsor.component';
import { EditTrusteeComponent } from './components/plan-summary/trustee/edit/edit-trustee.component';
import { ViewTrusteeComponent } from './components/plan-summary/trustee/view/view-trustee.component';
import { PlanFeeScheduleComponent } from './components/plan-summary/plan-fee-schedule/plan-fee-schedule.component';
import { PlanSetupChecklistComponent } from './components/plan-setup-checklist/plan-setup-checklist.component';
import { CheckListPipe } from './pipes/checklist-label-pipe';
import { CreatePlanDocsComponent } from './components/create-plan-docs/create-plan-docs.component';
import { PlanSolicitorComponent } from './components/plan-summary/plan-solicitor/plan-solicitor.component';
import { OrderReversedByPipe } from './pipes/order-reversed-by-pipe';
import { SolicitorMgmtComponent } from './components/solicitor-mgmt/solicitor-mgmt.component';
import { AssignSolicitorComponent } from './components/solicitor-mgmt/assign-solicitor/assign-solicitor.component';
import { AddViewNotesComponent } from './components/plan-summary/plan-notes/add-view-notes/add-view-notes.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AdvancedSearchComponent } from './components/advanced-search/advanced-search.component';
import { EnrollDocComponent } from './components/enroll-doc/enroll-doc.component';
import { PrintEnrollDocComponent } from './components/enroll-doc/print-enroll-doc/print-enroll-doc.component';
import { ParticipantIkComponent } from './components/participant-ik/participant-ik.component';
import { ParticActivationComponent } from './components/participant-ik/partic-activation/partic-activation.component';
import { PrintSponsorDocComponent } from './components/plan-summary/plan-docs/print-sponsor-doc/print-sponsor-doc.component';
import { PlanDocsComponent } from './components/plan-summary/plan-docs/plan-docs.component';
import { ToNumberPipe } from './pipes/to-number';
import { RetailUsersComponent } from './components/retail-users/retail-users.component';
import { EditAddUserAccessComponent } from './components//retail-users/user-access/edit-add-user-access.component';
import { ParticipantTrackComponent } from './components/participant-track/participant-track.component';
import { ParticTrackActivationComponent } from './components/participant-track/partic-track-activation/partic-track-activation.component';
import { PrintDocsComponent } from './components/plan-summary/print-docs/print-docs.component';
import { SystemMessagesComponent } from './components/system-messages/system-messages.component';
import { AddViewSysMsgsComponent } from './components/system-messages/add-view-sys-msgs/add-view-sys-msgs.component';
import { MarketingMaterialComponent } from './components/marketing-material/marketing-material.component';
import { BookCaseComponent } from './components/book-case/book-case.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { SolicitorOldWorkflowComponent } from './components/plan-summary/plan-solicitor/solicitor-old-workflow/solicitor-old-workflow.component';
import { FileCabinetComponent } from './components/file-cabinet/file-cabinet.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    RetailUsersComponent,
    SecurityNavigationComponent,
    EditAddUserAccessComponent,
    OrderByPipe,
    TpaMgmtComponent,
    MnymgrMgmtComponent,
    AddMoneyManagerComponent,
    EditMoneyManagerComponent,
    AddTpaComponent,
    EditTpaComponent,
    PlanSummaryComponent,
    PlanSponsorDocsComponent,
    CorePlanComponent,
    PlanNotesComponent,
    PlanFeeComponent,
    PlanDatesComponent,
    PhoneMaskDirective,
    EditAddRepComponent,
    PlanRepComponent,
    EditEmployeeSponsorComponent,
    ViewEmployeeSponsorComponent,
    EditTrusteeComponent,
    ViewTrusteeComponent,
    PlanFeeScheduleComponent,
    PlanSetupChecklistComponent,
    CheckListPipe,
    CreatePlanDocsComponent,
    PlanSetupChecklistComponent,
    PlanSolicitorComponent,
    PrintDocsComponent,
    OrderReversedByPipe,
    SolicitorMgmtComponent,
    AssignSolicitorComponent,
    AddViewNotesComponent,
    PrivacyPolicyComponent,
    AdvancedSearchComponent,
    EnrollDocComponent,
    PrintSponsorDocComponent,
    PlanDocsComponent,
    PrintEnrollDocComponent,
    ParticipantIkComponent,
    ParticActivationComponent,
    ToNumberPipe,
    ParticipantTrackComponent,
    ParticTrackActivationComponent,
    SystemMessagesComponent,
    AddViewSysMsgsComponent,
    MarketingMaterialComponent,
    BookCaseComponent,
    SolicitorOldWorkflowComponent,
    FileCabinetComponent,
    ContactUsComponent
  ],
  imports: [
    CommonModule,
    PlanSummaryModule,
    ReactiveFormsModule,
    AgGridModule,
    NfrpWidgetsModule,
    NfrpGridUtilsModule,
    NfrpPipesModule,
    RouterModule,
    NfrpNavigationModule,
    FormsModule,
    NgxPaginationModule,
    NfrpDateModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [SecurityNavigationComponent, RetailUsersComponent, PrintSponsorDocComponent],
  providers: [HttpClient, AuthGuardService],
})
export class NfrpMasRetailPrivateAngularModule {}

export { SecurityNavigationComponent } from './components/security-navigation/security-navigation.component';
export { CreatePlanDocsComponent } from './components/create-plan-docs/create-plan-docs.component';
