import { Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'nfrp-shared-angular';
import { Observable } from 'rxjs';
import { CommunicationService } from '../services/communication.service';
@Injectable()
export class AuthGuardService {
  isUserLoggedIn = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private communicationService: CommunicationService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkAuth(state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkAuth(state);
  }

  checkAuth(state: RouterStateSnapshot) {
    this.isUserLoggedIn = sessionStorage.getItem('loginUser') ? true : false;
    if (!this.isUserLoggedIn) {
      this.router.navigate(['/login']);
      console.log('Authentication Failed >>>>>');
      return false;
    }
    return this.isUserLoggedIn;
  }
}
