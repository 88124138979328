<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="
            (planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1
          "
          ><i class="nw-icon-check f-green"></i
        ></span>
        {{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }})
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <form [formGroup]="anotherRepForm" (ngSubmit)="onRepSubmit($event)">
          <ul id="another_rep" summary="another_rep" class="ul-li-view-edit table stripes no-margin no-padding">
            <li class="head" [ngClass]="{'pending-status-bg':isPending}">
              <div class="col-sm-12">
                <b>{{ headerString }} Representative Information </b>
              </div>
            </li>
          </ul>
          <div class="repSelect col-sm-12 no-padding">
            <ul *ngIf="selectedAction === 'add'" class="accounting-finance-box-data no-padding-bottom">
              <li class="ul-li-view-edit" *ngIf="selectedProduct?.length > 0 || repList?.length > 0">
                <b>Current Representative(s) on this plan</b> &nbsp;<span *ngIf="isIncompleteRep">(Plan sponsor documents will not be generated until representative information is complete. Contact (866) 213-1883 to add or update required fields.)</span>
              </li>

              <li *ngFor="let rep of repList">
                <span [ngClass]="{'has-error': !rep.complete}"
                  >&emsp;•&emsp;{{ rep.repFirstName }} {{ rep.repLastName }} &emsp;<span *ngIf="!rep.complete"> - Information Incomplete</span>&nbsp;
                  <a *ngIf="repList" class="subLink" routerLink="../../representative/edit/{{ rep.repId }}">Edit</a
                  >&nbsp;-&nbsp;
                  <a
                    href="javascript:void(0);"
                    *ngIf="repList"
                    class="subLink"
                    routerLink="../../representative/remove/{{ rep.repId }}"
                  >
                    Remove</a
                  ></span
                >
              </li>

              <li *ngFor="let sel of selectedProduct">
                <div *ngIf="repDisplay">
                  <span
                    >&emsp;•&emsp;{{ sel.repFirstName }} {{ sel.repLastName }} &emsp;
                    <a *ngIf="anotherRep" class="subLink" routerLink="../../representative/edit/{{ sel.repId }}">Edit</a
                    >&nbsp;-&nbsp;
                    <a
                      href="javascript:void(0);"
                      *ngIf="anotherRep"
                      class="subLink"
                      routerLink="../../representative/remove/{{ sel.repId }}"
                    >
                      Remove</a
                    ></span
                  >
                </div>
              </li>
              <li>
                <b>Search for a representative</b><br /><br />
                <div class="row">
                  <div class="col-sm-2">Last Name:*</div>
                  <div class="col-sm-4">
                    <input type="text" name="repLastNameSearch" formControlName="repLastName" size="38" />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (repAnotherFormControl.repLastName.touched || anotherFormSubmitted) &&
                          repAnotherFormControl.repLastName.errors?.required
                        "
                        errorId="last-name-rep-required"
                        [message]="getRequiredErrorMessage('Last Name')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-2">First Name</div>
                  <div class="col-sm-4">
                    <input type="text" name="repFirstName" formControlName="repFirstName" size="38" />
                  </div>
                  <div class="col-sm-1">
                    <button class="button button-primary" (click)="onRepSearch(); $event.preventDefault()">
                      Search
                    </button>
                  </div>
                  <div class="col-sm-2">
                    <button class="button button-secondary" (click)="onClearResults(); $event.preventDefault()">
                      Clear Results
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ng-container class="no-padding" *ngIf="repNameList && repSearchDisplay">
              <ng-template #headerTemplate>
                <div nfrpColumn [width]="25" widthType="%" [id]="'rep-name-label'">
                  Representative Name
                  <nfrp-grid-sorter [column]="'repFirstName' + 'repLastName'" dataType="string"> </nfrp-grid-sorter>
                </div>
                <div nfrpColumn [width]="60" widthType="%" [id]="'rep-address-label'">
                  Address
                  <nfrp-grid-sorter [column]="'repAddrA' + 'repAddrB' + 'repCity' + 'repState'" dataType="string">
                  </nfrp-grid-sorter>
                </div>
                <div nfrpColumn [width]="15" widthType="%" [id]="'rep-phone-label'">
                  Plan Number
                  <nfrp-grid-sorter [column]="'repPhone'" dataType="string"> </nfrp-grid-sorter>
                </div>
              </ng-template>

              <ng-template #rowTemplate let-repSearchResult="item" let-index="index">
                <div (click)="anotherRepSubmit(repSearchResult.repId)">
                  <div [id]="'rep-name-' + index">
                    <a class="text-decor-underline">
                      {{
                        repSearchResult.repLastName + ', ' + repSearchResult.repFirstName | nfrpNormalizeValue: '-'
                      }}</a
                    >
                  </div>
                  <div [id]="'rep-address-' + index">
                    {{
                      repSearchResult.repAddrA !== null
                        ? repSearchResult.repAddrA
                        : '' + ' ' + repSearchResult.repAddrB !== null
                        ? repSearchResult.repAddrB
                        : '' + ' ' + repSearchResult.repCity !== null
                        ? repSearchResult.repCity
                        : '' + ' ' + repSearchResult.repState !== null
                        ? repSearchResult.repState
                        : '' + ' ' + repSearchResult.repZip !== null
                        ? repSearchResult.repZip
                        : ('' | nfrpNormalizeValue: '-')
                    }}
                    7
                  </div>
                  <div [id]="'rep-phone-' + index">
                    {{ repSearchResult.repPhone | nfrpNormalizeValue: '-' }}
                  </div>
                </div>
              </ng-template>
              <nfrp-grid
                [headerTemplate]="headerTemplate"
                [rowTemplate]="rowTemplate"
                [iterable]="repNameList"
                [width]="100"
                rowIdPrefix="rep-search-details"
              ></nfrp-grid>
            </ng-container>
            <ng-template *ngIf="loading">
              <nfrp-spinner></nfrp-spinner>
            </ng-template>
            <!-- <li> -->
            <div [class.disabled]="!anotherFormSubmitted" class="padding-top">
              <b>If Representative is not found, please <a (click)="onClickNewRep()">Add New Rep</a></b><b *ngIf="!hideNotApplicableLink"> - Or set to <a (click)="onNotLicensed()">Not Applicable</a></b>
            </div>
            <!-- </li>
              </ul> -->
          </div>
          <!-- </ul> -->
        </form>
      </ng-container>
      <ng-container>
        <form [formGroup]="repForm" (ngSubmit)="onSubmit()">
          <ul
            id="representative-params"
            *ngIf="(newRep && selectedAction === 'add') || selectedAction === 'edit' || selectedAction === 'remove'"
            summary="representative"
            class="ul-li-view-edit table stripes no-margin"
          >
            <li *ngIf="showBDFirmName">
              <div class="col-sm-2 padding-left">BD Firm Name*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="firmName"
                  formControlName="firmName"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('firmName')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (repFormControl.firmName.touched || submitted) &&
                      repFormControl.firmName.errors?.required &&
                      newRep
                    "
                    errorId="bdFirmName-required"
                    [message]="getRequiredErrorMessage('BD Firm Name')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.firmName.touched || submitted) && repFormControl.firmName.errors?.pattern"
                    errorId="bd-name-valid-error"
                    [message]="getValidErrorMessage('BD Firm Name')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">First Name*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repFirstName"
                  formControlName="repFirstName"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('repFirstName')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (repFormControl.repFirstName.touched || submitted) &&
                      repFormControl.repFirstName.errors?.required &&
                      newRep
                    "
                    errorId="repFirstName-required"
                    [message]="getRequiredErrorMessage('First Name')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (repFormControl.repFirstName.touched || submitted) && repFormControl.repFirstName.errors?.pattern
                    "
                    errorId="first-name-valid-error"
                    [message]="getValidErrorMessage('First Name')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Last Name*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repLastName"
                  formControlName="repLastName"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('repLastName')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (repFormControl.repLastName.touched || submitted) && repFormControl.repLastName.errors?.required
                    "
                    errorId="last-name-required"
                    [message]="getRequiredErrorMessage('Last Name')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (repFormControl.repLastName.touched || submitted) && repFormControl.repLastName.errors?.pattern
                    "
                    errorId="company-name-valid-error"
                    [message]="getValidErrorMessage('Last Name')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Address Line One*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repAddrA"
                  formControlName="repAddrA"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('repAddrA')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repAddrA.touched || submitted) && repFormControl.repAddrA.errors?.required"
                    errorId="address-line-one-required"
                    [message]="getRequiredErrorMessage('Address Line One')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repAddrA.touched || submitted) && repFormControl.repAddrA.errors?.pattern"
                    errorId="address-line-one-valid-error"
                    [message]="getValidErrorMessage('Address Line One')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Address Line Two</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repAddrB"
                  formControlName="repAddrB"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('repAddrB')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repAddrB.touched || submitted) && repFormControl.repAddrB.errors?.pattern"
                    errorId="address-line-two-valid-error"
                    [message]="getValidErrorMessage('Address Line Two')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">City*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repCity"
                  formControlName="repCity"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('repCity')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repCity.touched || submitted) && repFormControl.repCity.errors?.required"
                    errorId="repCity-required"
                    [message]="getRequiredErrorMessage('City')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repCity.touched || submitted) && repFormControl.repCity.errors?.pattern"
                    errorId="repCity-valid-error"
                    [message]="getValidErrorMessage('City')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">State*</div>
              <div class="col-sm-8 padding-left">
                <select
                  name="repState"
                  id="repState"
                  formControlName="repState"
                  [ngClass]="{
                    'has-error margin-right-20': isControlInvalid('repState')
                  }"
                >
                  <option [ngValue]="null">-- Choose from List --</option>
                  <option *ngFor="let state of statesList" [value]="state.value">{{ state.label }}</option>
                </select>
                <div>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repState.touched || submitted) && repFormControl.repState.errors?.required"
                    errorId="repState-required"
                    [message]="getRequiredErrorMessage('State')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Zip*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repZip"
                  formControlName="repZip"
                  size="20"
                  mask="00000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('repZip')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repZip.touched || submitted) && repFormControl.repZip.errors?.required"
                    errorId="repZip-required"
                    [message]="getRequiredErrorMessage('Zip')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      ((repFormControl.repZip.touched || submitted) && repFormControl.repZip.errors?.maxLength) ||
                      repFormControl.repZip.errors?.pattern
                    "
                    errorId="companyZip-valid-error"
                    [message]="getValidErrorMessage('Zip')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Phone*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repPhone"
                  formControlName="repPhone"
                  size="25"
                  mask="000-000-0000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('repPhone')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repPhone.touched || submitted) && repFormControl.repPhone.errors?.required"
                    errorId="repPhone-required"
                    [message]="getRequiredErrorMessage('Phone')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      ((repFormControl.repPhone.touched || submitted) && repFormControl.repPhone.errors?.maxLength) ||
                      repFormControl.repPhone.errors?.pattern
                    "
                    errorId="companyPhone-valid-error"
                    [message]="getValidErrorMessage('Phone')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Fax</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repFax"
                  formControlName="repFax"
                  size="25"
                  mask="000-000-0000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('repFax')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      ((repFormControl.repFax.touched || submitted) && repFormControl.repFax.errors?.maxLength) ||
                      repFormControl.repFax.errors?.pattern
                    "
                    errorId="repFax-valid-error"
                    [message]="getValidErrorMessage('Fax')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">E-mail</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="repEmail"
                  formControlName="repEmail"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('repEmail')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="(repFormControl.repEmail.touched || submitted) && repFormControl.repEmail.errors?.pattern"
                    errorId="repEmail-valid-error"
                    [message]="getValidErrorMessage('Email')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
          </ul>
          <div class="row margin-top">
            <div class="col-sm-1 no-padding">
              <input
                id="search-button"
                class="button-primary button hover-left margin-left"
                type="submit"
                size="40"
                [disabled]="!newRep && selectedAction === 'add'"
                value="{{ buttonString }}"
              />
            </div>
            <div class="col-sm-10 no-padding"></div>
            <div class="col-sm-1 no-padding">
              <input
                type="button"
                class="button button-secondary hover-right margin-right"
                (click)="onCancel()"
                value="Cancel"
              />
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="loading">
        <nfrp-spinner></nfrp-spinner>
      </ng-container>
    </div>
  </div>
</section>

<nfrp-navigate-away-modal></nfrp-navigate-away-modal>

<nfrp-default-modal
  [header]="modalOptions.header"
  [message]="modalOptions.message"
  [noButtonValue]="modalOptions.noButtonValue"
  [yesButtonValue]="modalOptions.yesButtonValue"
  [showModal]="modalOptions.showModal"
  (noClicked)="noClicked()"
  (yesClicked)="yesClicked()"
>
</nfrp-default-modal>
