import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationStore } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'create-plan-docs',
  templateUrl: './create-plan-docs.component.html',
  styleUrls: ['./create-plan-docs.component.css'],
})
export class CreatePlanDocsComponent implements OnInit {
  planId: number;
  userEmail: string;
  subscriptions = [];
  formSubscription: Subscription;
  planSponsorForm: UntypedFormGroup;
  selectedAction: string;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private router: Router,
    private navigationStore: NavigationStore
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.userEmail = this.communicationService.loginUserId;
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.planId = params.planId;
        this.selectedAction = params.action;
      })
    );
    this.planSponsorForm = this.fb.group({
      planSponsorDoc: this.fb.array([]),
    });
    this.formSubscription = this.planSponsorForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }

  onSubmit() {
    this.router.navigate(['/cc-home']);
  }
}
