import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkListName',
})
export class CheckListPipe implements PipeTransform {
  transform(value: any): any {
    let returnValue ='';
    if(value === true || value === false || value === 'true' || value === 'false'){
      returnValue ='';
    } else{
      returnValue = value;
    }
    return returnValue;
  }
}
