import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ErrorUtilsService } from './error-utils.service';
import { UserResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/edit-user';
import { LoginResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-response';
import { LoginUser } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-user';
import { LoginUsersStore } from '../login-users.store';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  loginUserResponse$: Observable<LoginResponse[]> = this.loginUsersStore.select('loginUserResponse$');
  userResponse$: Observable<UserResponse[]> = this.loginUsersStore.select('userResponse$');
  private headers: HttpHeaders;
  baseUrl = this.config.config?.masRetailPrivateProcessing;
  constructor(private http: HttpClient, 
    private loginUsersStore: LoginUsersStore, 
    private errorUtilsService: ErrorUtilsService,
    private config: ConfigService) {
    this.headers = new HttpHeaders({
      accessControlAllowOrigin: '*',
      print2Image: 'true',
      proxyAlternateURI: 'http://localhost:8080/',
    });
  }
  saveLoginUserData(loginUserData: LoginUser): Observable<LoginResponse> {
    return this.http
      .post(this.baseUrl + `/validate-user`, loginUserData)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  changePasswordData(loginUserData: LoginUser): Observable<LoginUser> {
    return this.http
      .post(this.baseUrl + `/change-password`, loginUserData)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  forgetPasswordData(loginUserData: LoginUser): Observable<LoginUser> {
    return this.http
      .post(this.baseUrl + `/forgot-password`, loginUserData)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
}
