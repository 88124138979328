<section class="container-fluid temp-color">
  <div ui-view="" class="ng-scope">
    <div id="section-level-error-msg-section" class="row">
      <div class="col-sm-12">
        <div class="compartment box summary animation-fade-in">          
          <div class="content padding-left">
            <div class="section__body"><p class="lead">If you have any questions regarding our application or support, please contact us at <a href="mailto:riaops@nationwide.com">riaops&#64;nationwide.com</a>
            </p>
            </div>          
          </div>
        </div>
      </div>
    </div>
</div>
</section>
