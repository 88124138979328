import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { UserTypeIDEnum } from 'src/app/helpers/enum';
import { LoginUsersStore } from 'src/app/login-users.store';
import { UserResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-response';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'mas-retail-header',
  templateUrl: './mas-retail-header.component.html',
})
export class MasRetailHeaderComponent implements OnInit {
  planId: string;
  userResponse$: Observable<UserResponse[]> = this.loginUsersStore.userResponse$;

  searchPlanForm: UntypedFormGroup = this.fb.group({
    searchQuery: [null],
  });
  searchQuery: string;
  isUserLoggedIn = false;
  userTypeId: number;
  login = false;
  isHideSearchBox = false;
  isPrivacyPolicy = false;

  constructor(
    private fb: UntypedFormBuilder,
    private loginUsersStore: LoginUsersStore,
    private communicationService: CommunicationService,
    private router: Router
  ) {
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (
          event.url === '/login' ||
          event.url === '/' ||
          event.url === '/reset-password' ||
          event.url === '/forgot-password' ||
          (event.url === '/privacy-policy' && this.userTypeId === 0)
        ) {
          this.login = false;
        } else {
          this.login = true;
        }
      }
    });
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.communicationService.userLoggedInStatusEvent.subscribe((loggedInStatus: boolean) => {
      this.isUserLoggedIn = loggedInStatus;
      const loginUser = sessionStorage.getItem('loginUser');
      this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
      if (
        this.userTypeId === UserTypeIDEnum.CALL_CENTER ||
        this.userTypeId === UserTypeIDEnum.ENROLLMENT_KIT_PROCESSOR
      ) {
        this.isHideSearchBox = true;
      } else {
        this.isHideSearchBox = false;
      }
    });
  }
  
  onSearchPlanSubmit(): void {
    this.searchQuery = this.searchPlanForm.get('searchQuery').value ? this.searchPlanForm.get('searchQuery').value: '';
    this.changePlanSearchView(this.searchQuery);
  }

  changePlanSearchView(searchStr: string) {
    this.router.navigate(['/home', searchStr]);
    this.searchPlanForm.reset();
  }
  navigateToHome() {
    this.searchPlanForm.reset();
    this.communicationService.userLoggedInStatus(false);
    this.userTypeId = 0;
    sessionStorage.removeItem('loginUser');
    this.router.navigate(['/login']);
  }
}
