import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { GetPlans, SearchPrintSponsorDoc } from '../../../model/get-plan.model';
import { alertMessages } from 'src/app/helpers/common-constants';
import { PlanDetailsService } from '../../../services/plan-details.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { FormBuilder, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'print-enroll-doc',
  templateUrl: './print-enroll-doc.component.html',
  styleUrls: ['./print-enroll-doc.component.css'],
})
export class PrintEnrollDocComponent implements OnInit {
  planNumber: string;
  searchEnrollmentDocs: SearchPrintSponsorDoc[];
  loading = false;
  createDoc = false;
  errorMessage: NfrpAlertMessage;
  infoMessage: NfrpAlertMessage;
  planId: number;
  planLabel: string;
  showNav = true;
  userEmail: string;
  planDetails: GetPlans;
  isPending: boolean;
  private subscriptions = [];
  private planNo: number;
  private userTypeId: any;
  printEnrollDocForm: UntypedFormGroup;
  userId: number;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private planDetailsService: PlanDetailsService,
    private communicationService: CommunicationService,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : null;
    this.planNumber = sessionStorage.getItem('planNumber');
    this.planLabel = sessionStorage.getItem('planLabel');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe((pending) => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.getPlanDetails();
    });
    this.searchPrintEnrollmentDocument();
    this.showLeftNav();
    this.printEnrollDocForm = this.fb.group({
      enrollDoc: this.fb.array([]),
    });
  }
  getPlanDetails() {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
      })
    );
  }
  searchPrintEnrollmentDocument() {
    this.loading = true;
    this.subscriptions.push(
      this.planDetailsService.searchPrintEnrollmentDocument(this.planNumber).subscribe(
        (res) => {
          this.searchEnrollmentDocs = res.trackFormDetails;
          this.createDoc = true;
          this.loading = false;
          if (this.searchEnrollmentDocs == null) {
            this.infoMessage = { message: 'There are no documents to generate for this plan', details: [] };
          }
        },
        (error: HttpErrorResponse) => {
          console.log('error');
          this.createDoc = false;
          window.scroll(0, 0);
          this.loading = false;
          if (error?.error?.status) {
            this.errorMessage = { message: error?.error?.status, details: [] };
          } else {
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        }
      )
    );
  }
  onClickCancel() {
    this.loading = false;
    if (this.userTypeId === 29) {
      this.router.navigate(['ekp-home']);
    } else if (this.userTypeId === 69) {
      this.router.navigate(['/cc-home']);
    } else {
      this.router.navigate(['/plan-summary', this.planId]);
    }
  }

  showLeftNav() {
    if (this.userTypeId === 69 || this.userTypeId === 29) {
      this.showNav = false;
    }
  }

  selectedDocument(event: any): void {
    const enrollDoc: UntypedFormArray = this.printEnrollDocForm.get('enrollDoc') as UntypedFormArray;
    this.planDetailsService.checkboxFormChange(event, enrollDoc);
  }

  downloadSelectedDoc(): void {
    const selectedDocRequest = {
      planId : +this.planId,
      trackFormIds: this.printEnrollDocForm.get('enrollDoc').value,
    };
    if(this.printEnrollDocForm.valid) {
      this.subscriptions.push(
        this.planDetailsService.downloadPlanDocument(this.userId,selectedDocRequest).subscribe(
          (res) => {

            const blob = new Blob([res], { type: 'application/zip' });
            const file = window.URL.createObjectURL(blob);
            window.open(file, '_blank');
            
            window.scroll(0, 0);
            this.loading = false;
          },
          (error) => {
            window.scroll(0, 0);
            this.loading = false;
            if (error) {
              this.errorMessage = { message: error, details: [] };            
            } else {
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          }
        )
      );
    }
    else {
      window.scroll(0, 0);
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
   
  }
}
