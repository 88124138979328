import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CCAutoCompleteView, CcViewResponse } from '../model/cc-landing.model';
import { CCLandingStore } from '../cc-landing-store';
import { EkpResponse } from '../model/ekp-detail';
import { SysMsgResponse } from '../model/system-msg';
import { ErrorUtilsService } from 'src/app/services/error-utils.service';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class CcLandingService {
  ccLandingResult$: Observable<CcViewResponse[]> = this.ccLandingStore.select('ccLandingResult');
  planDesc$: Observable<CCAutoCompleteView[]> = this.ccLandingStore.select('ccLandingResult');
  private headers: HttpHeaders;
  baseUrl = this.config.config?.masRetailPrivateProcessing;
  constructor(private http: HttpClient,
    private ccLandingStore: CCLandingStore,
    private errorUtilsService: ErrorUtilsService,
    private config: ConfigService) {
    this.headers = new HttpHeaders({
      accessControlAllowOrigin: '*',
      print2Image: 'true',
    });
  }
  /** HTTP GET to load user search information from the database table */
  getCcViewResponse(caseNumber: string, ssn: string): Observable<CcViewResponse> {
    const params = new HttpParams().set('case-number', caseNumber ? caseNumber : '').set('ssn', ssn ? ssn : '');
    return this.http.get<CcViewResponse>(this.baseUrl + `/plan/get-cc-plan-number-view`, { params }).pipe(
      // eslint-disable-next-line no-shadow
      tap((ccViewResponse: CcViewResponse) => {
        this.ccLandingStore.set('ccLandingResult', ccViewResponse);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  getCCAutoCompleteView(planDesc: string): Observable<CCAutoCompleteView> {
    const params = new HttpParams().set('plan-desc', planDesc ? planDesc : '');
    return this.http.get<CCAutoCompleteView>(this.baseUrl + `/plan/get-cc-auto-complete-view`, { params }).pipe(
      tap((ccAutoCompleteView: CCAutoCompleteView) => {
        this.ccLandingStore.set('ccLandingResult', ccAutoCompleteView);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  //EKP service
  getEkpPlanDetails(caseNumber: string): Observable<EkpResponse> {
    const params = new HttpParams().set('plan-number', caseNumber ? caseNumber : '');
    const serviceURL = this.baseUrl +'/ekp/retrieve-plan-details';
    return this.http.get<EkpResponse>(serviceURL, { params }).pipe(
      tap((ekpResponse: EkpResponse) => {
        this.ccLandingStore.set('ekpResult', ekpResponse);
      })
    );
  }
  getActiveSystemMsgs(): Observable<SysMsgResponse> {
    const serviceURL = this.baseUrl +'/message/active';
    return this.http.get<SysMsgResponse>(serviceURL).pipe(
      tap((msgResponse: SysMsgResponse) => {
        this.ccLandingStore.set('sysMsgResult', msgResponse);
      })
    );
  }
  getSystemMsgDetails(): Observable<SysMsgResponse> {
    const serviceURL = this.baseUrl +'/message';
    return this.http.get<SysMsgResponse>(serviceURL).pipe(
      tap((msgResponse: SysMsgResponse) => {
        this.ccLandingStore.set('sysMsgResult', msgResponse);
      })
    );
  }
  getSystemMsgDetailsById(msgId?: number): Observable<SysMsgResponse> {
    const serviceURL = this.baseUrl + `/message/${msgId}`;
    return this.http.get<SysMsgResponse>(serviceURL).pipe(
      tap((msgResponse: SysMsgResponse) => {
        this.ccLandingStore.set('sysMsgResult', msgResponse);
      })
    );
  }
  saveSystemMessages(sysMsgInfo: SysMsgResponse): Observable<any> {
    return this.http.post(this.baseUrl +'/message', sysMsgInfo);
  }
  updateSystemMessages(sysMsgInfo: SysMsgResponse, msgId: number): Observable<any> {
    return this.http.put(this.baseUrl + `/message/${msgId}`, sysMsgInfo);
  }
}
