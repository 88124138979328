import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NfrpBaseModule, NfrpWidgetsModule } from 'nfrp-shared-angular';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MasRetailBaseModule } from './nfrp-mas-retail/modules/mas-retail-base/mas-retail-base.module';
import { AppRoutingModule } from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { NfrpMasRetailModule } from './nfrp-mas-retail/container/nfrp-mas-retail.module';
import { EkpHomeComponent } from './ekp-home/ekp-home.component';
import { EkpPrintDocComponent } from './ekp-home/ekp-print-doc/ekp-print-doc.component';
import { ConfigService } from './services/config.service';
import { EndpointInterceptor } from './apigee.interceptor';
import { TrackSsncComponent } from './track-ssnc/track-ssnc.component';

@NgModule({
    declarations: [AppComponent, PageNotFoundComponent, EkpHomeComponent, EkpPrintDocComponent,TrackSsncComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        NfrpBaseModule,
        NfrpMasRetailModule,
        MasRetailBaseModule,
        ReactiveFormsModule,
        FormsModule,
        NfrpWidgetsModule,
    ],
    providers: [HttpClient, 
      {
        provide: HTTP_INTERCEPTORS,
        useClass: EndpointInterceptor,
        multi: true
      },
      {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.config$.toPromise(),

      deps: [ConfigService],
      multi: true,
    }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
  exports: [  ]
})
export class AppModule {}
