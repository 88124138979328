import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { actionText, alertMessages, modalMessage, statesList } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, ActionTypeEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { GetPlanSolicitor, GetPlans, PendingPlanResponse } from '../../../model/get-plan.model';
import { UpdateSolicitorRequest, SolicitorInfo, ApprovedSolicitorsMnyMgr } from '../../../model/money-manager-data';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { PlanDetailsService } from '../../../services/plan-details.service';
import { SuccessResponse } from '../../../model/representative.model';


@Component({
  selector: 'lib-plan-solicitor',
  templateUrl: './plan-solicitor.component.html',
  styleUrls: ['./plan-solicitor.component.css'],
})
export class PlanSolicitorComponent implements OnInit, OnDestroy {
  selectedAction: string;
  buttonString: string;
  submitted = false;
  loading = false;
  trusteeId: string;
  solicitorForm: UntypedFormGroup;
  statesList = statesList;
  pattern = /[0-9]/g;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  infoMessage: NfrpAlertMessage;
  itemPerPage = 45;
  solicitorRequestObj: UpdateSolicitorRequest;
  planId: number;
  userEmail: string;
  solicitorObj: GetPlanSolicitor;
  filteredSolicitor: SolicitorInfo[];
  planDetails: GetPlans;
  activeSolicitorLetter: string;
  filterLetter: string;
  solicitorList: SolicitorInfo[];
  formSubscription: Subscription;
  modalOptions: any = {
    header: modalMessage.CONFIRMATION,
    message: modalMessage.REMOVE_TRUSTEE_MSG,
    showModal: false,
    noButtonValue: modalMessage.NO_TEXT,
    yesButtonValue: modalMessage.YES_TEXT,
  };

  buttonText: any = {
    save: actionText.SAVE,
    remove: actionText.REMOVE,
    add: actionText.ADD,
  };
  private subscriptions = [];
  isPending:  boolean;
  showOldWorkflow = false;
  userId: number;
  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private route: ActivatedRoute,
    private navigationStore: NavigationStore,
    private router: Router,
    private communicationService: CommunicationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe(pending => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.selectedAction = params.action;
      this.getPlanDetails();
    });
    this.solicitorForm = this.fb.group({ solicitorFirm: [null, [Validators.required]]});
    this.formSubscription = this.solicitorForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }
  get solicitorFormControl() {
    return this.solicitorForm.controls;
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
        if (this.planDetails?.newFirmWorkFlow) {          
          this.getApprovedSolicitor();
        }
        else {
          this.showOldWorkflow = true;
        }
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
  getApprovedSolicitor() {
    this.loading = true;
    this.subscriptions.push(
      this.planDetailsService
        .getApprovedSolicitorList(this.planId, this.planDetails?.moneyManagerId)
        .subscribe((data: ApprovedSolicitorsMnyMgr) => {
          this.solicitorList = data.approvedSolicitorResponseList;
          if (this.selectedAction === ActionTypeEnum.ADD) {
            this.getSolicitorInfo('A');
          } else {
            this.getSelectedSolicitor();
          }
          if (this.solicitorList?.length === BinaryResponseEnum.NO) {
            this.infoMessage = { message: alertMessages.NO_APPROVED_SOLICITOR, details: [] };
          }
          this.loading = false;
        })
    );
  }
  getSolicitorInfo(filterLetter: string) {
    this.activeSolicitorLetter = filterLetter;
    if (filterLetter === '0-9') {
      this.filteredSolicitor = this.solicitorList?.filter((solicitor) =>
        solicitor.appSolicitorName.match(this.pattern)
      );
    } else {
      this.filteredSolicitor = this.solicitorList?.filter((solicitor) =>
        solicitor.appSolicitorName.startsWith(filterLetter)
      );
      if (this.filteredSolicitor?.length === 0) {
        this.filterLetter = filterLetter;
      }
    }
    return this.filteredSolicitor;
  }

  getSelectedSolicitor(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanSolicitor(this.planId, this.isPending).subscribe(
        (data: GetPlanSolicitor) => {
          this.solicitorObj = data.approvedPlanSolicitorResponse;
          if (this.solicitorObj) {
            this.solicitorForm.patchValue({ solicitorFirm: this.solicitorObj?.appSolicitorId });
            this.navigationStore.set('formChanged', false);
            if (this.solicitorObj?.appSolicitorId === BinaryResponseEnum.NO) {
              this.getSolicitorInfo('A');
            } else {
              const firstSolicitorLetter = this.solicitorObj?.appSolicitorName?.charAt(0);
              this.getSolicitorInfo(firstSolicitorLetter);
            }
          }
        },
        (error) => {
          this.loading = false;
          this.infoMessage = { message: '', details: [] };
          this.errorMessage = { message: error, details: [] };
        })
    );
  }
  noClicked() {
    this.modalOptions.showModal = false;
  }
  yesClicked() {
    this.modalOptions.showModal = false;
  }
  onCancel() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.router.navigate(['/plan-summary', this.planId]);
  }
  onSubmit(): void{
    this.submitted = true;
    if (this.solicitorForm.valid) {
      this.solicitorRequestObj = {
        planId: +this.planId,
        mnyMgrId: this.planDetails?.moneyManagerId,
        appSolicitorId: this.solicitorForm.value.solicitorFirm,
        userId: this.userId
      };
      this.subscriptions.push(
        this.planDetailsService.updateApprovedSolicitor(this.planId, this.solicitorRequestObj, this.isPending).subscribe(
          (solicitorResponse:PendingPlanResponse) => {                   
            this.successMessage = { message: solicitorResponse.message, details: [] };
            window.scroll(0, 0);
            this.loading = false;
            this.isPending = solicitorResponse.newPendingPlanCreated;
            this.communicationService.setIsPendingForNewPendingPlan(solicitorResponse);  
            this.errorMessage = { message: '', details: [] };
            this.infoMessage = { message: '', details: [] };
            this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
          },
          (error) => {
            this.loading = false;
            console.log(error);
            window.scroll(0, 0);
            this.successMessage = { message: '', details: [] };
            this.infoMessage = { message: '', details: [] };
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
