<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>

      <form [formGroup]="retailUserForm">
        <div class="form-group advanced-search margin-top">
          <h1 id="main-title" class="page-title ng-binding">User Search</h1>
          <div class="row">
            <div class="col-sm-4">
              <div class="box">
                <div class="content">
                  <input
                    id="internal-user-input"
                    class="no-margin-right"
                    type="text"
                    size="40"
                    name="searchQuery"
                    title="user search"
                    maxlength="100"
                    formControlName="searchQuery"
                    placeholder="User ID, First Name or Last Name"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="box">
                <div class="content">
                  <input
                    id="search-button"
                    class="button-primary button no-margin-top"
                    type="submit"
                    size="40"
                    value="Search"
                    (click)="searchSubmit()"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-1">&nbsp;</div>
            <div class="col-sm-3">
              <div class="box" *ngIf="assignedRoleCollection">
                <div class="content margin-bottom">
                  <select name="roleDropdown" id="roleDropdown" formControlName="userTypeId" class="width-100"  (change)="selectedRole()">
                    <option [ngValue]="null">--- Select Role ---</option>
                    <option
                      *ngFor="let assignedRole of assignedRoleCollection | orderBy: 'userTypeLabel'"
                      [value]="assignedRole.userTypeId"
                    >
                      {{ assignedRole.userTypeLabel }}
                    </option>
                  </select>
                </div>
                <div *ngIf="moneyManagerFirmData?.length > 0">
                  <select
                    class="form-input"
                    name="selectedUserFirm"
                    formControlName="selectedUserFirm"
                    class="width-100"
                  >
                    <option *ngFor="let firm of moneyManagerFirmData | orderBy: 'moneyManagerLabel'" [value]="firm?.moneyManagerId">
                      {{ firm?.moneyManagerLabel }}
                    </option>
                  </select>
                  <span class="inline-error">
                    <nfrp-inline-error class="margin-bottom"
                      *ngIf="
                        (retailFormControl.selectedUserFirm.touched || submitted) &&
                        retailFormControl.selectedUserFirm.errors?.required
                      "
                      errorId="selectedFirm-required-error"
                      [message]="getRequiredErrorMessage('Money Manager')"
                    >
                    </nfrp-inline-error>
                  </span>
                </div>
                <div *ngIf="tpAdminFirmData?.length > 0">
                  <select
                  class="form-input"
                  name="selectedUserFirm"
                  formControlName="selectedUserFirm"
                  class="width-100"
                  >
                    <option *ngFor="let firm of tpAdminFirmData | orderBy: 'tpAdminLabel'" [value]="firm?.tpAdminNbr">
                      {{ firm?.tpAdminLabel }}
                    </option>
                  </select>
                  <span class="inline-error">
                    <nfrp-inline-error class="margin-bottom"
                      *ngIf="
                        (retailFormControl.selectedUserFirm.touched || submitted) &&
                        retailFormControl.selectedUserFirm.errors?.required
                      "
                      errorId="selectedFirm-required-error"
                      [message]="getRequiredErrorMessage('TP Admin')"
                    >
                    </nfrp-inline-error>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="box" *ngIf="assignedRoleCollection">
                <div class="content">
                  <input
                    id="search-button"
                    class="button-primary button no-margin-top"
                    type="submit"
                    size="40"
                    (click)="searchUserByUserType()"
                    value="Get Users"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="col-sm-12" *ngIf="isRecordsList">
        <ul
          id="retail-user-grid-params"
          summary="retail-user-grid"
          class="ul-li-view-edit table stripes width-100 margin-top"
        >
          <li class="retail_align_list">
            <ng-container *ngIf="dataSource">
              <div class="row">
                <div class="col-sm-8">
                  <h3 class="no-padding no-margin-top">Search Results for {{ searchQuery }}:</h3>
                </div>
              </div>
              <ng-template #headerTemplate>
                <div nfrpColumn [width]="30" widthType="%" [id]="'userEmail-label'">
                  Username
                  <nfrp-grid-sorter [column]="'userEmail'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
                </div>
                <div nfrpColumn [width]="17" widthType="%" [id]="'userNameFirst-label'">
                  First Name
                  <nfrp-grid-sorter [column]="'userNameFirst'" dataType="string" (sort)="sort($event)">
                  </nfrp-grid-sorter>
                </div>
                <div nfrpColumn [width]="17" widthType="%" [id]="'userNameLast-label'">
                  Last Name
                  <nfrp-grid-sorter [column]="'userNameLast'" dataType="string" (sort)="sort($event)">
                  </nfrp-grid-sorter>
                </div>
                <div nfrpColumn [width]="11" widthType="%" [id]="'userActive-label'">
                  Status
                  <nfrp-grid-sorter [column]="'userActive'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
                </div>
                <div nfrpColumn [width]="25" widthType="%" [id]="'userTypeLabel-label'">
                  Role Assigned
                  <nfrp-grid-sorter [column]="'userTypeLabel'" dataType="string" (sort)="sort($event)">
                  </nfrp-grid-sorter>
                </div>
              </ng-template>

              <ng-template #rowTemplate let-userListData="item" let-index="index">
                <div>
                  <div [id]="'userEmail' + index">
                    <a class="text-decor-underline" (click)="rowDataClicked(userListData)">
                      {{ userListData.userEmail | nfrpNormalizeValue: '-' }}</a
                    >
                  </div>
                  <div [id]="'userNameFirst-' + index">
                    {{ userListData.userNameFirst | nfrpNormalizeValue: '-' }}
                  </div>
                  <div [id]="'userNameLast-' + index">
                    {{ userListData.userNameLast | nfrpNormalizeValue: '-' }}
                  </div>
                  <div [id]="'userActive-' + index">
                    {{ userListData.userActive === 1 ? 'Active' : ('No' | nfrpNormalizeValue: '-') }}
                  </div>
                  <div [id]="'userTypeLabel-' + index">
                    {{ userListData.userTypeLabel | nfrpNormalizeValue: '-' }}
                  </div>
                </div>
              </ng-template>

              <nfrp-grid
                [headerTemplate]="headerTemplate"
                [rowTemplate]="rowTemplate"
                [iterable]="data"
                [width]="100"
                [expandOnRowClick]="true"
              ></nfrp-grid>
              <nfrp-grid-paginator
                (paginate)="paginate($event)"
                [numberOfItems]="numberOfItems"
                [itemsPerPageOptions]="[20, 50, 100]"
                [defaultItemsPerPage]="20"
              ></nfrp-grid-paginator>
            </ng-container>
            <ng-container *ngIf="loading">
              <nfrp-spinner></nfrp-spinner>
            </ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
