import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NfrpAlertMessage, NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { MoneyManager } from '../../model/money-manager-data';
import { FooterFeaturesService } from '../../services/footer-features.service';
import { SearchUsersService } from '../../services/search-users.service';
@Component({
  selector: 'mnymgrMgmt',
  templateUrl: './mnymgr-mgmt.component.html',
  styleUrls: ['./mnymgr-mgmt.component.css'],
})
export class MnymgrMgmtComponent extends NfrpDefaultGridComponent<MoneyManager> implements OnInit, OnDestroy {
  @Output() loadEditMMUser: EventEmitter<string> = new EventEmitter<string>();
  isUserLoggedIn = false;
  moneyMgrResult$: Observable<MoneyManager[]> = this.searchUserService.moneyMgrResult$;
  numberOfItems: number;
  dataSource: MoneyManager[];
  userEmail: string;
  mnyManagerListView = true;
  moneyManagerId: number;
  isMMAddMode = false;
  isMMEditMode = false;
  private subscriptions = [];
  errorMessage: NfrpAlertMessage;
  constructor(
    nfrpGridService: NfrpGridService<MoneyManager>,
    private searchUserService: SearchUsersService,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private footerFeaturesService: FooterFeaturesService,
    private router: Router
  ) {
    super(nfrpGridService);
  }

  ngOnInit() {
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.getMoneyManagerDetails();
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.MNY_MGR_MGMT, this.userEmail).subscribe()
    );
  }

  getMoneyManagerDetails(): void {
    this.subscriptions.push(
      this.searchUserService.getMoneyManagerMaintenance().subscribe((response: any) => {
        this.dataSource = response;
        this.numberOfItems = this.dataSource?.length;
        this.setupGrid();
        this.paginate({
          limit: 20,
          offset: 1,
        });
      },
      (error) => {
        this.errorMessage = { message: error, details : [] };
      })
    );
  }

  mnyManagerClicked(moneyManagerData: MoneyManager): void {
    this.moneyManagerId = moneyManagerData.moneyManagerId;
    this.router.navigate(['mnyMgrMgmt/edit-money-manager', this.moneyManagerId]);
  }
  ngOnDestroy(): void {
    for (const subscriptions$ of this.subscriptions) {
      subscriptions$.unsubscribe();
    }
  }
}
