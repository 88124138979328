import { Component, OnInit, OnDestroy } from '@angular/core';
import { NfrpAlertMessage, NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { Observable } from 'rxjs';
import { PaginateParameters, PlanSearch, PlanSearchResponse } from '../models/plan-search';
import { ActivatedRoute, Router } from '@angular/router';
import { SysMsgResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/system-msg';
import { CcLandingService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/cc-landing.service';
import { SearchUsersService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/search-users.service';
import { UserTypeIDEnum, BinaryResponseEnum } from '../helpers/enum';
import { CommunicationService } from '../services/communication.service';

@Component({
  selector: 'mas-retail-home',
  templateUrl: './mas-retail-home.component.html',
  styleUrls: ['./mas-retail-home.component.scss'],
})
export class MasRetailHomeComponent extends NfrpDefaultGridComponent<PlanSearchResponse> implements OnInit, OnDestroy {
  planSearchData: string;
  planSearchResults$: Observable<PlanSearch> = this.searchUserService.planSearchResult$;
  numberOfItems: number;
  dataSource: PlanSearchResponse[];
  userId: number;
  userTypeID: number;
  private subscriptions = [];
  showSystemMsg = false;
  normalImportanceSysMsgs: SysMsgResponse[];
  highImportanceSysMsgs: SysMsgResponse[];
  currentPageNumber = 0;
  selectedItemsPerPage: number;
  size = 20;
  pages = 0;
  pendingStatus = false;
  errorMessage: NfrpAlertMessage;
  constructor(
    nfrpGridService: NfrpGridService<PlanSearchResponse>,
    private searchUserService: SearchUsersService,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private ccLandingService: CcLandingService,
    private router: Router
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    window.scroll(0, 0);    
    const loginUser = sessionStorage.getItem('loginUser');
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.userTypeID = loginUser ? JSON.parse(loginUser).userTypeId : 0;
    sessionStorage.removeItem('isPending');
    this.communicationService.setPendingStatus(false);
    this.route.params.subscribe((params) => {
      if(params.id) {
        this.planSearchData = params.id;
        this.pendingStatus = false;
      }
      this.getPlanSearchDetails();
      this.getActiveSystemMsgDetails();
    });
  }

  getPlanSearchDetails(): void {
    this.pages = this.planSearchData ? 0 : this.pages;
    this.searchUserService
      .getPlanSearchDetails(this.planSearchData, this.userId, this.pendingStatus, this.size, this.pages)
      .subscribe(
        (response: any) => {
          this.errorMessage = { message: '', details: [] };
          if (this.userTypeID === UserTypeIDEnum.RIA) {
            this.dataSource = response?.plans ? response.plans : [];
          } else {
            this.dataSource = response?.plans.filter((planResult) => planResult?.planArchived === 1);
          }
          this.numberOfItems = response?.totalElements;
          this.setupGrid();
          this.planSearchData = '';
        },
        (error) => {
          this.dataSource = [];
          this.setupGrid();
          this.errorMessage = { message: error, details: [] };
        }
      );
  }

  getActiveSystemMsgDetails(): void {
    this.ccLandingService.getActiveSystemMsgs().subscribe((response) => {
      if (response?.messageResponseList?.length) {
        this.showSystemMsg = true;
        this.normalImportanceSysMsgs = response.messageResponseList.filter(
          (msgResponse) => msgResponse.importance === BinaryResponseEnum.YES
        );
        this.highImportanceSysMsgs = response.messageResponseList.filter(
          (msgResponse) => msgResponse.importance === BinaryResponseEnum.NO
        );
      } else {
        this.showSystemMsg = false;
      }
    });
  }

  paginate(event: PaginateParameters): void {
    const prevPageNumber = this.pages;
    const prevItemsPerPage = this.size;
    this.size = event?.limit;
    //feigeb: reduce page number by 1 because backend pages start at page 0 not 1
    this.pages = Math.floor((event?.offset + event?.limit - 1) / event?.limit) - 1;
    if (prevItemsPerPage != this.size || prevPageNumber != this.pages) {
      this.getPlanSearchDetails();
    }
  }

  ngOnDestroy(): void {
    for (const subscriptions$ of this.subscriptions) {
      subscriptions$.unsubscribe();
    }
  }

  planSearchClicked(planSearchResult: PlanSearchResponse): void {
    this.router.navigate(['/plan-summary', planSearchResult.planId]);
  }
}
