<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
      <!-- <ul>
        <li>
          <a routerLink="sysMessages" routerLinkActive="active">System Messages</a>
        </li>
        <li><a routerLink="add-view-system-messages" routerLinkActive="active">Add System Messages</a></li>
      </ul> -->
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">
        System Messages
      </h1>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <form>
          <ul
            id="add-notes-list-view"
            summary="Add Notes View"
            class="ul-li-view-edit table stripes no-margin border-common"
          >
            <ng-template #headerTemplate>
              <div nfrpColumn [width]="10" widthType="%" [id]="'id-label'">
                ID
                <nfrp-grid-sorter [column]="'id'" dataType="number" (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
              <div nfrpColumn [width]="20" widthType="%" [id]="'title-label'">
                Title
                <nfrp-grid-sorter [column]="'title'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'active-label'">
                Active
                <nfrp-grid-sorter [column]="'active'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'updated-label'">
                Updated
                <nfrp-grid-sorter [column]="'updatedDate'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'created-label'">
                Created
                <nfrp-grid-sorter [column]="'createdDate'" dataType="string" defaultDirection="ASCENDING"  (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'start-label'">
                Start
                <nfrp-grid-sorter [column]="'startDate'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'end-label'">
                End
                <nfrp-grid-sorter [column]="'endDate'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'type-label'">
                Type
                <nfrp-grid-sorter [column]="'type'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'important-label'">
                Importance
                <nfrp-grid-sorter [column]="'importance'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
              </div>
            </ng-template>
            <ng-template #rowTemplate let-msgDetails="item" let-index="index">
              <div>
                <div [id]="'msg-id' + index">
                  <a class="text-decor-underline" (click)="rowDataClicked(msgDetails)">
                    {{ msgDetails.id | nfrpNormalizeValue: '-' }}</a
                  >
                </div>
                <div [id]="'msg-title-' + index">
                  <a class="text-decor-underline" (click)="rowDataClicked(msgDetails)">
                    {{ msgDetails.title | nfrpNormalizeValue: '-' }}</a
                  >
                </div>
                <div [id]="'msg-active-' + index">
                  {{ msgDetails.active === 1 ? 'Yes' : ('No' | nfrpNormalizeValue: '-') }}
                </div>
                <div [id]="'msg-updated-' + index">
                  {{ msgDetails.updatedDate | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'msg-created-' + index">
                  {{ msgDetails.createdDate | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'msg-start-' + index">
                  {{ msgDetails.startDate | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'msg-end-' + index">
                  {{ msgDetails.endDate | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'msg-type-' + index">
                  {{ msgDetails.type === 1 ? 'Outage' : ('Normal' | nfrpNormalizeValue: '-') }}
                </div>
                <div [id]="'msg-important-' + index">
                  {{ msgDetails.importance === 1 ? 'Normal' : ('High' | nfrpNormalizeValue: '-') }}
                </div>
              </div>
            </ng-template>
            <nfrp-grid
              [headerTemplate]="headerTemplate"
              [rowTemplate]="rowTemplate"
              [iterable]="data"
              [width]="100"
              rowIdPrefix="sys-msg-details"
            ></nfrp-grid>
          </ul>
          <nfrp-grid-paginator
            (paginate)="paginate($event)"
            [numberOfItems]="numberOfItems"
            [itemsPerPageOptions]="[5, 10, 20]"
            [defaultItemsPerPage]="5"
          ></nfrp-grid-paginator>
        </form>
      </ng-container>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
