<section class="container-fluid temp-color">

  <h1 id="main-title" class="page-title ng-binding">Call Center Home</h1>
  <div class="box compartment summary animation-fade-in">
  <div class="section__header" role="button" ><h2
    class="content-title"><b>Create Participant Enrollment Documents:</b>
  </h2><i id="ul-li-view-header-chevron"></i></div>
  <div class="content" >
    <div class="section__body padding-bottom"><p><b>Plan:&ensp;</b>
      <a>779-80012 : American Hospitality Services, Inc 401(k) Profit Sharting Plan</a></p></div>
    <div class="content">
      <div class="section__body">
        <div class="row participant-overview">
          <div class="col-sm-12 padding-left">
                <div class="col-sm-12 padding-left"><b>Available Participant Enrollment Documents</b></div>
              <div class="padding-left"><input type="checkbox"  id="checkbox-label-1-id">ProAcct_Part_Enr_NS_1019</div>
          </div>
        </div>
      </div>
    </div><div class="row padding-bottom"></div>
  </div>
  </div>
  <div class="row ">
  <div class="col-sm-2">
    <input type="button" value="Create Documents" class="button button-primary">
  </div>
    <div class="col-sm-9"></div><div class="col-sm-1 padding-right">
    <input (click)="onSubmit()" id="search-button" type="submit" size="40" value="Cancel" class="button-secondary button  padding-right">
  </div>
  </div></section>
