import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationStore, NfrpAlertMessage, NfrpTypeaheadService } from 'nfrp-shared-angular';
import { Observable, Subscription } from 'rxjs';
import { modalMessage, validationPatterns } from '../helpers/common-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, switchMap } from 'rxjs/operators';
import * as Fuse from 'fuse.js';
import { CCAutoCompleteView, CcViewResponse, Participant } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/cc-landing.model';
import { CcLandingService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/cc-landing.service';
import { CommunicationService } from '../services/communication.service';
@Component({
  selector: 'cc-home',
  templateUrl: './cc-home.component.html',
  styleUrls: ['./cc-home.component.scss'],
})
export class CcHomeComponent implements OnInit, OnDestroy {
  planDesc$: Observable<CCAutoCompleteView[]> = this.ccLandingService.planDesc$;
  planNoValue: string;
  planProfileNo = false;
  planNoRecord = false;
  selectedPlan = [];
  planDetails: any;
  planNoExist = false;
  proAccountNotExist = false;
  participantNotExist = false;
  planOnlySSN = false;
  selectedAction: string;
  submitted = false;
  loading = false;
  showLoading = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  planId: number;
  userEmail: string;
  planNumberView: CcViewResponse;
  autoCompleteView: CCAutoCompleteView;
  planWithSSN = false;
  planWithOutSSN = false;
  subscriptions = [];
  formSubscription: Subscription;
  participantDetails: Participant
  modalOptions: any = {
    header: modalMessage.CONFIRMATION,
    message: modalMessage.REMOVE_EMPLOYEE_SPONSOR_MSG,
    showModal: false,
    noButtonValue: modalMessage.NO_TEXT,
    yesButtonValue: modalMessage.YES_TEXT,
  };
  cchomeForm: UntypedFormGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  private planNumber: string;
  private planLabel: string;
  private userTypeId: any;
  constructor(
    private fb: UntypedFormBuilder,
    private ccLandingService: CcLandingService,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private router: Router,
    private nfrpTypeaheadService: NfrpTypeaheadService,
    private navigationStore: NavigationStore
  ) {}
  get cchomeFormControl() {
    return this.cchomeForm.controls;
  }
  ngOnInit() {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.userEmail = this.communicationService.loginUserId;
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.planId = params.planId;
        this.selectedAction = params.action;
      })
    );
    this.cchomeForm = this.fb.group({
      planNo: ['', [Validators.maxLength(100), Validators.pattern(validationPatterns.planNoPattern)]],
      planDesc: ['', [Validators.maxLength(255)]],
      ssn: ['', [Validators.maxLength(100), Validators.pattern(validationPatterns.ssnPattern)]],
    });
    this.formSubscription = this.cchomeForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }
  searchplanDescOptions = (searchParameter: string): Observable<Fuse.FuseResult<CCAutoCompleteView>[]> => {
    this.getCCAutoCompleteView(searchParameter);
    return this.planDesc$?.pipe(
      filter((planDesc: CCAutoCompleteView[]) => !!planDesc),
      first(),
      switchMap((planDesc: any) =>
        this.nfrpTypeaheadService.getFuzzySearch(planDesc?.planList, 'planNo', 'planDesc')(searchParameter)
      )
    );
  };

  getCCAutoCompleteView(searchParameter: string): void {
    this.showLoading = true;
    this.subscriptions.push(
      this.ccLandingService.getCCAutoCompleteView(searchParameter).subscribe((data) => {
        this.autoCompleteView = data;
        this.showLoading = false;
      })
    );
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.cchomeForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }

  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  noClicked() {
    this.modalOptions.showModal = false;
  }

  yesClicked() {
    this.modalOptions.showModal = false;
  }

  onSubmit(): void {
    if (this.cchomeForm.valid) {
      this.loading = true;
      this.submitted = true;
      this.subscriptions.push(
        this.ccLandingService.getCcViewResponse(this.cchomeForm.value.planNo, this.cchomeForm.value.ssn).subscribe(
          (data) => {
            this.planNumberView = data.planDetails;
            this.participantDetails = data.participant;
            this.planNumber = this.cchomeForm.value.planNo;
            this.planLabel = this.planNumberView.planDesc;
            sessionStorage.setItem('planNumber', this.planNumber);
            sessionStorage.setItem('planLabel', this.planLabel);
            window.scroll(0, 0);
            if (this.cchomeForm.valid) {
              if (
                this.planNumberView.planNo === this.cchomeForm.value.planNo &&
                this.participantDetails?.ssn === this.cchomeForm.value.ssn &&
                this.participantDetails?.participantId !== null &&
                this.planNumberView.mrgId === 51031
              ) {
                this.errorMessage = { message: '', details: [] };
                this.planWithSSN = true;
                this.planWithOutSSN = false;
                this.loading = false;
              } else if (
                this.planNumberView.planNo === this.cchomeForm.value.planNo &&
                this.participantDetails?.ssn !== this.cchomeForm.value.ssn &&
                this.planNumberView.mrgId === 51031
              ) {
                this.errorMessage = { message: '', details: [] };
                this.planWithOutSSN = true;
                this.planWithSSN = false;
                this.loading = false;
              }
            }
          },
          (error) => {
            window.scroll(0, 0);
            this.planWithOutSSN = false;
            this.planWithSSN = false;
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    }
  }
  get planDesc(): UntypedFormControl {
    return this.cchomeForm.get('planDesc') as UntypedFormControl;
  }
  get planNo(): UntypedFormControl {
    return this.cchomeForm.get('planNo') as UntypedFormControl;
  }

  getplanDescInput(planDetails): void {
    return planDetails?.item.planDesc;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }

  onPlanChange(pName: any): void {
    if (pName?.item) {
      this.cchomeForm.get('planNo').setValue(pName.item.planNo);
      this.cchomeForm.get('planNo').updateValueAndValidity();
    }
  }

  inputTextValue(abc) {
    console.log('inputTextValue:', abc);
  }
  inputValue(a) {
    console.log('inputValue:', a);
  }
  onClickParticipant() {
    this.router.navigate(['/enroll-doc', this.planNumberView.planId]);
  }
}
