import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseStore } from '../../base.store';

@Component({
  selector: 'mas-retail-shell',
  templateUrl: './mas-retail-shell.component.html',
})
export class MasRetailShellComponent implements OnInit{
  authenticated$: Observable<boolean> = this.baseStore.select('authenticated');

  constructor(private baseStore: BaseStore) {}
  ngOnInit() {
    window.scroll(0, 0);
  }
}
