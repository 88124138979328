import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NfrpDirectivesModule, NfrpSecurityModule, UnsavedChangesGuard, NfrpWidgetsModule } from 'nfrp-shared-angular';
import { AdvancedSearchComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/advanced-search/advanced-search.component';
import { BookCaseComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/book-case/book-case.component';
import { MarketingMaterialComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/marketing-material/marketing-material.component';
import { ParticActivationComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/participant-ik/partic-activation/partic-activation.component';
import { ParticipantIkComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/participant-ik/participant-ik.component';
import { ParticTrackActivationComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/participant-track/partic-track-activation/partic-track-activation.component';
import { ParticipantTrackComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/participant-track/participant-track.component';
import { PlanSetupChecklistComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/plan-setup-checklist/plan-setup-checklist.component';
import { CorePlanComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/plan-summary/core-plan/core-plan.component';
import { PrivacyPolicyComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/privacy-policy/privacy-policy.component';
import { CreatePlanDocsComponent, NfrpMasRetailPrivateAngularModule } from 'projects/nfrp-mas-retail-private-angular/src/public-api';
import { CcHomeComponent } from 'src/app/cc-home/cc-home.component';
import { EkpHomeComponent } from 'src/app/ekp-home/ekp-home.component';
import { EkpPrintDocComponent } from 'src/app/ekp-home/ekp-print-doc/ekp-print-doc.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { MasRetailHomeComponent } from 'src/app/mas-retail-home/mas-retail-home.component';
import { ForgotPasswordLinkComponent } from 'src/app/mas-retail-login/forgot-password-link/forgot-password-link.component';
import { ForgotPasswordComponent } from 'src/app/mas-retail-login/forgot-password/forgot-password.component';
import { MasRetailLoginComponent } from 'src/app/mas-retail-login/mas-retail-login.component';
import { ResetPasswordInfoComponent } from 'src/app/mas-retail-login/reset-password-info/reset-password-info.component';
import { ResetPasswordComponent } from 'src/app/mas-retail-login/reset-password/reset-password.component';
import { NfrpMasRetailComponent } from './nfrp-mas-retail.component';
import { FileCabinetComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/file-cabinet/file-cabinet.component';
import { ContactUsComponent } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/contact-us/contact-us.component';
const routes: Routes = [
  {
    path: '',
    component: MasRetailLoginComponent,
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'login',
    component: MasRetailLoginComponent,
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    component: MasRetailHomeComponent,
  },
  {
    path: 'home/:id',
    canActivate: [AuthGuardService],
    component: MasRetailHomeComponent,
  },
  {
    path: 'cc-home',
    canActivate: [AuthGuardService],
    component: CcHomeComponent,
  },
  {
    path: 'ekp-home',
    canActivate: [AuthGuardService],
    component: EkpHomeComponent,
  },
  {
    path: 'ekp-print-doc',
    canActivate: [AuthGuardService],
    component: EkpPrintDocComponent,
  },
  {
    path: 'particUpload',
    component: ParticipantIkComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'particActivate',
    component: ParticActivationComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'TracparticUpload',
    component: ParticipantTrackComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'trackActivation',
    canActivate: [AuthGuardService],
    component: ParticTrackActivationComponent,
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'forgot-password-link',
    component: ForgotPasswordLinkComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'reset-password-info',
    component: ResetPasswordInfoComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumb: 'Privacy Policy',
    },
  },
  {
    path: 'marketing-material',
    component: MarketingMaterialComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumb: 'Marketing Material',
    },
  },
  {
    path: 'book-case',
    component: BookCaseComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumb: 'Book Case',
    },
  },
  {
    path: 'fileCab',
    component: FileCabinetComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumb: 'File Cabinet',
    },
  },
  {
    path: 'advanced-search',
    canActivate: [AuthGuardService],
    component: AdvancedSearchComponent,
  },
  {
    path: 'plan-summary/:planId',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuardService],
        loadChildren: () =>
          import(
            '../../../../projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/plan-summary/plan-summary.module'
          ).then((m) => m.PlanSummaryModule),
      },
    ],
    data: {
      breadcrumb: 'Plan Summary',
    },
  },
  {
    path: 'solMain',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            '../../../../projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/solicitor-mgmt/solicitor-mgmt.module'
          ).then((m) => m.SolicitorMgmtModule),
      },
    ],
    data: {
      breadcrumb: 'Solicitor Maintenance',
    },
  },
  {
    path: 'mnyMgrMgmt',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            '../../../../projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/mnymgr-mgmt/mnymgr-mgmt.module'
          ).then((m) => m.MnymgrMgmtModule),
      },
    ],
    data: {
      breadcrumb: 'Money Manager Management',
    },
  },
  {
    path: 'tpaMgmt',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            '../../../../projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/tpa-mgmt/tpa-mgmt.module'
          ).then((m) => m.TpaMgmtModule),
      },
    ],
    data: {
      breadcrumb: 'TPA Management',
    },
  },
  {
    path: 'userMgmt',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            '../../../../projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/retail-users/user-mgmt.module'
          ).then((m) => m.UserMgmtModule),
      },
    ],
    data: {
      breadcrumb: 'User Management',
    },
  },
  {
    path: 'matrix',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../../../../projects/nfrp-mas-retail-private-angular/src/matrix/matrix.module').then(
            (m) => m.MatrixModule
          ),
      },
    ],
    data: {
      breadcrumb: 'Matrix',
    },
  },
  {
    path: 'sysMessages',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            '../../../../projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/components/system-messages/system-messages.module'
          ).then((m) => m.SystemMessageModule),
      },
    ],
    data: {
      breadcrumb: 'System Messages',
    },
  },
  {
    path: 'core-plan',
    canActivate: [AuthGuardService],
    component: CorePlanComponent,
    data: {
      breadcrumb: 'Create Plan',
    },
  },
  {
    path: 'create-plan-docs',
    canActivate: [AuthGuardService],
    component: CreatePlanDocsComponent,
    data: {
      breadcrumb: 'Create Plan Documents',
    },
  },
  {
    path: 'ekp-print-doc',
    component: EkpPrintDocComponent,
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'EKP Print Documents',
    },
  },
  {
    path: 'plan-setup-checklist/:planId',
    component: PlanSetupChecklistComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Plan Setup Checklist',
    },
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumb: 'Contact Us',
    },
  },
];

@NgModule({
  declarations: [NfrpMasRetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NfrpMasRetailPrivateAngularModule,
    NfrpWidgetsModule,
    NfrpDirectivesModule,
    NfrpSecurityModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NfrpMasRetailModule {}
