import { Observable, BehaviorSubject } from 'rxjs';
import { pluck, distinctUntilChanged } from 'rxjs/operators';

export interface State {
  [key: string]: any;
}

export abstract class Store {
  private subject: BehaviorSubject<State>;
  private store: Observable<State>;

  constructor(initialState: State) {
    this.subject = new BehaviorSubject<State>(initialState);
    this.store = this.subject.asObservable().pipe(distinctUntilChanged());
  }

  get value(): State {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(
      pluck(name),
      distinctUntilChanged<T>()
    );
  }

  set(name: string, state: any) {
    this.subject.next({ ...this.value, [name]: state });
  }
}
