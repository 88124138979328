<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">{{ headerString }} System Messages</h1>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <form [formGroup]="addSystemMsgForm" (ngSubmit)="onSubmit()">
          <ul id="sysmsgs-params" summary="add system messages" class="ul-li-view-edit table stripes no-margin">
            <li>
              <div class="col-sm-2 padding-left">Title*</div>
              <div class="col-sm-8 padding-left">
                <input
                  id="add-system-message-title"
                  type="text"
                  name="title"
                  formControlName="sysMsgTitle"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('sysMsgTitle')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (sysMsgFormControl.sysMsgTitle.touched || submitted) &&
                      sysMsgFormControl.sysMsgTitle.errors?.required
                    "
                    errorId="title-required"
                    [message]="getRequiredErrorMessage('Title')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Body*</div>
              <div class="col-sm-8 padding-left">
                <textarea
                  name="sysMsgBody"
                  formControlName="sysMsgBody"
                  id="note_text"
                  rows="3"
                  cols="70"
                  [ngClass]="{
                    'has-error': isControlInvalid('sysMsgBody')
                  }"
                ></textarea>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (sysMsgFormControl.sysMsgBody.touched || submitted) &&
                      sysMsgFormControl.sysMsgBody.errors?.required
                    "
                    errorId="sys-msg-body-required"
                    [message]="getRequiredErrorMessage('Body')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Type*</div>
              <div class="col-sm-8 padding-left">
                <select
                  name="sysMsgType"
                  id="sysMsgType"
                  formControlName="sysMsgType"
                  [ngClass]="{
                    'has-error margin-right-20': isControlInvalid('sysMsgType')
                  }"
                >
                  <option [value]="1">Outage</option>
                  <option [value]="0">Notice</option>
                </select>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (sysMsgFormControl.sysMsgType.touched || submitted) &&
                      sysMsgFormControl.sysMsgType.errors?.required
                    "
                    errorId="sys-msg-type-required"
                    [message]="getRequiredErrorMessage('Type')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="input-group flex-settings-datepicker-input">
                <div class="col-sm-6 padding-left">
                  Start Date*
                </div>
                <div class="col-sm-6 padding-left-20">
                  <div class="form-group">
                    <nfrp-datepicker
                      [uniqueId]="'sys-msg-start-date'"
                      [parentForm]="addSystemMsgForm"
                      [controlName]="'startDate'"
                      [formSubmitted]="submitted"
                      [minDate]="'minStartDate'"
                    >
                    </nfrp-datepicker>
                  </div>
                  <div>
                    <nfrp-inline-error
                      *ngIf="
                        (sysMsgFormControl.startDate.touched || submitted) &&
                        sysMsgFormControl.startDate.errors?.required
                      "
                      errorId="start-date-error"
                      [message]="getRequiredErrorMessage('Start Date')"
                    >
                    </nfrp-inline-error>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="input-group flex-settings-datepicker-input">
                <div class="col-sm-6 padding-left">
                  End Date*
                </div>
                <div class="col-sm-6 padding-left-20">
                  <div class="form-group">
                    <nfrp-datepicker
                      [uniqueId]="'sys-msg-end-date'"
                      [parentForm]="addSystemMsgForm"
                      [controlName]="'endDate'"
                      [formSubmitted]="submitted"
                      [minDate]="'minStartDate'"
                    >
                    </nfrp-datepicker>
                  </div>
                  <div>
                    <nfrp-inline-error
                      *ngIf="
                        (sysMsgFormControl.endDate.touched || submitted) && sysMsgFormControl.endDate.errors?.required
                      "
                      errorId="end-date-error"
                      [message]="getRequiredErrorMessage('End Date')"
                    >
                    </nfrp-inline-error>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Start Time*</div>
              <div class="col-sm-8 padding-left">
                <input
                  id="add-system-start-time"
                  type="time"
                  name="startTime"
                  formControlName="startTime"
                  size="25"
                  [ngClass]="{
                    'has-error': isControlInvalid('startTime')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (sysMsgFormControl.startTime.touched || submitted) && sysMsgFormControl.startTime.errors?.required
                    "
                    errorId="startTime-required"
                    [message]="getRequiredErrorMessage('Start Time')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">End Time*</div>
              <div class="col-sm-8 padding-left">
                <input
                  id="add-system-end-time"
                  type="time"
                  name="endTime"
                  formControlName="endTime"
                  size="25"
                  [ngClass]="{
                    'has-error': isControlInvalid('endTime')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (sysMsgFormControl.endTime.touched || submitted) && sysMsgFormControl.endTime.errors?.required
                    "
                    errorId="endTime-required"
                    [message]="getRequiredErrorMessage('End Time')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Importance*</div>
              <div class="col-sm-8 padding-left">
                <select
                  name="importance"
                  id="importance"
                  formControlName="importance"
                  [ngClass]="{
                    'has-error margin-right-20': isControlInvalid('importance')
                  }"
                >
                  <option [value]="1">Normal</option>
                  <option [value]="0">High</option>
                </select>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (sysMsgFormControl.importance.touched || submitted) &&
                      sysMsgFormControl.importance.errors?.required
                    "
                    errorId="sys-msg-importance-required"
                    [message]="getRequiredErrorMessage('Importance')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Active*</div>
              <div class="col-sm-8 padding-left">
                <select
                  name="sysMsgActive"
                  id="sysMsgActive"
                  formControlName="sysMsgActive"
                  [ngClass]="{
                    'has-error margin-right-20': isControlInvalid('sysMsgActive')
                  }"
                >
                  <option [value]="1">Yes</option>
                  <option [value]="0">No</option>
                </select>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (sysMsgFormControl.sysMsgActive.touched || submitted) &&
                      sysMsgFormControl.sysMsgActive.errors?.required
                    "
                    errorId="sys-msg-active-required"
                    [message]="getRequiredErrorMessage('Active')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
          </ul>
          <div class="row margin-top">
            <div class="col-sm-1 no-padding">
              <input
                id="save-button"
                class="button-primary button hover-left margin-left"
                type="submit"
                size="40"
                value="Save"
              />
            </div>
            <div class="col-sm-10 no-padding"></div>
            <div class="col-sm-1 no-padding">
              <input
                id="cancel-button"
                type="button"
                class="button button-secondary hover-right margin-right"
                (click)="onCancel()"
                value="Cancel"
              />
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="loading">
        <nfrp-spinner></nfrp-spinner>
      </ng-container>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
