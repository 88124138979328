<section class="container-fluid temp-color">
  <div ui-view="" class="ng-scope">
    <div id="section-level-error-msg-section" class="row">
      <div class="col-sm-12">
        <div class="compartment box summary animation-fade-in">
          <div class="section__header "> <div class="col-sm-offset-5"><h2 class="content-title">INTERNET PRIVACY STATEMENT</h2></div></div>
          <div class="content padding-left">
            <div class="section__body"><p class="lead">We value you as a customer and respect your right to privacy. We know that you purchase our products and services because you trust that we will stand behind our promises. We pledge our commitment to treat your information responsibly. We created this privacy policy to show you that we are working hard to protect your privacy.
            </p>
              <h3>Confidentiality and security</h3>
              <div class="padding-left-2"> <p>We use physical and technical safeguards to protect your information. We restrict access to your information to those who need it to perform their jobs. Third-party business partners are bound by law to use the information only for our purposes. They may not disclose it or use it in any other way. We comply with all data security laws.</p></div>
              <h3>Collecting your personal information</h3>
              <div class="padding-left-2"> <p>We may collect information about you when you visit our Web site.</p></div>
              <h3>Sharing your personal information</h3>
              <div class="padding-left-2"> <p>We do not sell your information to anyone. We may disclose this information to a business that carries out services and marketing for us. We may disclose your information as required or permitted by law.</p></div>
              <h3>Internet Policies</h3>
              <div class="padding-left-2"> <p class="no-padding-bottom">We may collect the information you provide when you:</p></div>
              <ul><div class="padding-left">
                <li>request information from one of our Web sites</li>
                <li>use online tools and calculators</li>
                <li>apply online for products</li>
                <li>subscribe to online services</li>
                <li>complete an online form</li>
                <li>conduct transactions online</li>
              </div>
              </ul>
              <div class="padding-left-2"> <p>We may contract with business partners who help us deliver online products and services. They may keep the information you provide. Other online activities are listed below.</p></div>
              <h3>Visitor Data</h3>
              <div class="padding-left-2"> <p>We collect site aggregate and customer information about the pages you view on our site. We log IP addresses, browser and platform types, domain names, access times, and referral data. We also collect information you volunteer on electronic forms.
                <BR><br>We gather data on our Web site. This is a standard industry practice and is used to find out how visitors use our site. We collect aggregate information on the public areas of our Web site. We also collect information on an individual basis on areas of our Web site that require visitors to logon using an ID and password. We may collect the following data:
              </p></div>
              <ul><div class="padding-left">
                <li>how many people visit our Web site</li>
                <li>the pages they visit and the features they use</li>
                <li>how long they stay on our Web site</li>
                <li>the Web site people come from before reaching our Web site</li>
              </div>
              </ul>
              <h3>Use of Information</h3>
              <div class="padding-left-2"> <p>Our business partners or we use the information from our Web sites for many purposes:
              <br><br>edits and feedback marketing and promotions analysis of user behavior product development content improvement informing advertisers how many visitors have seen or clicked on their advertisements customize Web site content and layout</p></div>
              <h3>Use of "Cookies"</h3>
              <div class="padding-left-2"> <p>We may store a cookie on your computer when you visit our Web site. A cookie is a tiny piece of information that is required to create and maintain a secure session. Cookies let you request information and do business online. The cookies do not have confidential or personal information. They do not track you after leaving our Web site.</p></div>
              <h3>Other Online Security</h3>
              <div class="padding-left-2"> <p>We also use a Secure Sockets Layer (SSL). This keeps all information confidential when it is sent between our Web site and your computer.</p></div>
              <h3>E-mail</h3>
              <div class="padding-left-2"> <p>We keep the e-mail you send to us. If you agree to receive e-mail messages from us, we will keep the information you give us to send e-mail of specific interest to you. If you choose to send us e-mail, we keep your e-mail, your e-mail address, and our reply. Please be careful about the information you give us in e-mail. As with any public Web site, this communication may not be secure.</p></div>
              <h3>Web Links</h3>
              <div class="padding-left-2"> <p>It is important to note that other companies or organizations may have links on our Web sites. We are not responsible for the collection, use, or security of information by these companies or organizations. Please read the privacy policy of Web sites reached through the use of links from our Web sites.</p></div>
              <h3>Questions</h3>
              <div class="padding-left-2"> <p>For questions about our Web site, please contact support&#64;RIAServices.net
              <br><br>Updated: May 11, 2005</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</section>
