<section class="container-fluid temp-color">
  <h1 id="main-title" class="page-title ng-binding">Call Center Home</h1>
  <div class="row">
    <div class="col-sm-12">
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"></nfrp-alert>
      </div>
      <ng-container>
        <div class="col-sm-12 padding-left">
          <div role="alert" aria-live="assertive" class="alert-container alert-container--info group">
            <span class="nw-icon-info-new"> </span>
            <div class="message">
              <div>.&emsp;Enter Plan Number or Plan Name to view plan fee details.</div>
              <div>.&emsp;Enter SSN with Plan Name or Number to view participant details.</div>
            </div>
          </div>
          <div class="box compartment summary animation-fade-in">
            <form [formGroup]="cchomeForm" (ngSubmit)="onSubmit()">
              <div class="section__header">
                <h2 class="content-title">Enter plan information:</h2>
              </div>
              <ul class="ul-li-view-edit no-margin">
                <li>
                  <div class="col-sm-2 padding-left">Plan Number</div>
                  <div class="col-sm-3">
                    <input
                      class="form-input form-input--large"
                      type="text"
                      name="planNo"
                      formControlName="planNo"
                      [ngClass]="{
                        'has-error': isControlInvalid('planNo')
                      }"
                    />
                  </div>
                  <div>
                    <nfrp-inline-error
                      *ngIf="
                        (cchomeFormControl.planNo.touched || submitted) && cchomeFormControl.planNo.errors?.pattern
                      "
                      errorId="planNo-valid-error"
                      [message]="getValidErrorMessage('planNo')"
                    >
                    </nfrp-inline-error>
                  </div>
                </li>
                <li style="background-color: #ffffff;">
                  <div class="col-sm-2 padding-left">Plan Name</div>
                  <div class="col-sm-3">
                    <nfrp-typeahead
                      class="hybrid-typeahead"
                      [id]="'planDesc'"
                      [size]="'large'"
                      [minLength]="3"
                      formControlName="planDesc"
                      [headerTemplate]="headerTemplate"
                      [resultTemplate]="resultTemplate"
                      (ngModelChange)="onPlanChange($event)"
                      [placeholder]="''"
                      [search]="searchplanDescOptions"
                      [getInputText]="getplanDescInput"
                      (enterFromInput)="(inputTextValue)"
                      [verticalScroll]="autoCompleteView"
                      (input)="(inputValue)"
                    ></nfrp-typeahead>
                    <ng-template #headerTemplate>
                      <div class="col-sm-4">Plan Number</div>
                      <div class="col-sm-8">Plan Desc</div>
                    </ng-template>
                    <ng-template #resultTemplate let-result="result" let-maxResults="maxResults" let-index="index">
                   
                      <div class="col-sm-4">{{ result.item.planNo }}</div>
                      <div class="col-sm-8">{{ result.item.planDesc }}</div>
                    </ng-template>
                  </div>
                  <nfrp-inline-error
                    *ngIf="
                      (cchomeFormControl.planDesc.touched || submitted) && cchomeFormControl.planDesc.errors?.pattern
                    "
                    errorId="plan-desc-valid-error"
                    [message]="getValidErrorMessage('planDesc')"
                  >
                  </nfrp-inline-error>
                  <div
                    class="nw-icon-info-new"
                    placement="top-left"
                    ngbTooltip="Please type at least 3 letters of Plan Description."
                  ></div>
                  <div class="col-sm-1" *ngIf="showLoading">
                    <nfrp-spinner size="small"></nfrp-spinner>
                  </div>
                </li>
                <li>
                  <div class="col-sm-2 padding-left">SSN</div>
                  <div class="col-sm-3 padding-left">
                    <input
                      class="form-input form-input--large"
                      type="text"
                      name="ssn"
                      formControlName="ssn"
                      [ngClass]="{
                        'has-error': isControlInvalid('ssn')
                      }"
                    />
                  </div>
                  <div>
                    <nfrp-inline-error
                      *ngIf="(cchomeFormControl.ssn.touched || submitted) && cchomeFormControl.ssn.errors?.pattern"
                      errorId="company-name-valid-error"
                      [message]="getValidErrorMessage('ssn')"
                    >
                    </nfrp-inline-error>
                  </div>
                </li>
              </ul>
              <div class="row padding-bottom-2">
                <div class="col-sm-offset-3 padding-left-4">
                  <input
                    id="search-button"
                    class="button-primary button hover-right margin-right"
                    type="submit"
                    size="35"
                    value="Search Plans"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="margin-bottom alert-container--error" *ngIf="errorMessage?.message?.length > 0">
            <div class="col-sm-12 no-padding">
              <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"></nfrp-alert>
            </div>
          </div>
          <ng-container *ngIf="planNumberView">
            <div class="box compartment summary animation-fade-in" *ngIf="planWithSSN || planWithOutSSN">
              <div class="content">
                <div class="section__body">
                  <div class="row participant-overview">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ul class="ul-li-view-edit with-head no-padding" role="table">
                        <li class="head" role="row">
                          <div class="col-sm-12 padding-left" role="columnheader">
                            <span id="header-cellA">Fee Details</span>
                            <span>&nbsp;and Profile</span>
                          </div>
                        </li>
                        <li role="row" *ngIf="planWithSSN || planWithOutSSN">
                          <div class="col-sm-2" role="cell">
                            <span id="dlu"><b>Number:</b></span>
                          </div>
                          <div class="col-sm-6 padding-left" role="cell">
                            <span class="padding-right">{{ planNumberView?.planNo }} &emsp;</span>
                            <a (click)="onClickParticipant()">Create Participant Agreement</a>
                          </div>
                        </li>
                        <li role="row" *ngIf="planWithSSN || planWithOutSSN">
                          <div class="col-sm-2" role="cell">
                            <span id="mindl"><b>Plan Name:</b></span>
                          </div>
                          <div class="col-sm-6 padding-left" role="cell">
                            {{ planNumberView?.planDesc }}
                          </div>
                        </li>
                        <li role="row" *ngIf="planWithSSN">
                          <div class="col-sm-2 padding-left" role="cell">
                            <span id="maxdl"><b>Participant Name:</b></span>
                          </div>
                          <div class="col-sm-6 padding-left" role="cell">
                            {{ participantDetails?.firstName }}{{ participantDetails?.lastName }}
                          </div>
                        </li>
                        <li role="row" *ngIf="planWithSSN">
                          <div class="col-sm-2 padding-left" role="cell">
                            <span id="df"><b>Participant SSN:</b> </span>
                          </div>
                          <div class="col-sm-6 padding-left" role="cell">
                            {{ participantDetails?.ssn }}
                          </div>
                        </li>
                        <li role="row" *ngIf="planWithSSN || planWithOutSSN">
                          <div class="col-sm-2 padding-left" role="cell">
                            <span id="tf"><b>Total Fee Percentage:</b></span>
                          </div>
                          <div class="col-sm-6 padding-left" role="cell">{{ planNumberView?.totMgmtFeePct }}</div>
                        </li>
                        <li role="row" *ngIf="planWithSSN">
                          <div class="col-sm-2 padding-left" role="cell">
                            <span id="pn"><b>Profile Number:</b></span>
                          </div>
                          <div
                            class="padding-left"
                            role="cell"
                            *ngIf="planNumberView?.profileNo !== null || planNumberView?.profileNo !== ''"
                          >
                            {{ participantDetails?.profileNo }}
                          </div>
                          <div
                            class="alert"
                            role="cell"
                            style="color: red;"
                            *ngIf="planNumberView?.profileNo === null || planNumberView?.profileNo === ''"
                          >
                            <b>Profile not assigned.</b>
                          </div>
                        </li>
                        <li role="row" *ngIf="planWithSSN">
                          <div class="col-sm-2 padding-left" role="cell">
                            <span id="tp"><b>Profile Description:</b></span>
                          </div>
                          <div class="col-sm-6 padding-left" role="cell">{{ participantDetails?.profileDesc }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
<nfrp-default-modal
  [header]="modalOptions.header"
  [message]="modalOptions.message"
  [noButtonValue]="modalOptions.noButtonValue"
  [yesButtonValue]="modalOptions.yesButtonValue"
  [showModal]="modalOptions.showModal"
  (noClicked)="noClicked()"
  (yesClicked)="yesClicked()"
>
</nfrp-default-modal>
