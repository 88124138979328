import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber',
})
export class ToNumberPipe implements PipeTransform {
  transform(value: string): any {
    const retNumber = parseFloat(parseFloat(value.replace(/,/g, '')).toFixed(2));
    return isNaN(retNumber) ? 0 : retNumber;
  }
}
