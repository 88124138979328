import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements OnInit {
  constructor() {
    //Do nothing
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
