<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-12">
      <div class="content-title" id="sysmsg-title">System Messages:</div>
      <div class="content no-padding-sides no-padding-bottom">
        <div
          role="alert"
          aria-live="assertive"
          id="system-alert"
          class="no-margin-bottom system-message-container message-container--warning group"
        >
          <div class="message" id="-alert-message" *ngIf="!showSystemMsg">
            <span class="nw-icon-info"></span> <b>There are no notifications at this time.</b>
          </div>
          <ul
            id="sysmsgs-params"
            summary="system messages"
            *ngIf="showSystemMsg"
            class="ul-li-view-edit table stripes no-margin border-common width-100"
          >
            <ng-container *ngFor="let sysMsgs of normalImportanceSysMsgs">
              <li class="head">
                <div class="col-sm-7 padding-left">
                  <b>{{ sysMsgs.title }}</b>
                </div>
                <div class="col-sm-5 padding-left">
                  <b>Posted On:&nbsp;{{ sysMsgs.startDate }}</b>
                </div>
              </li>
              <li>
                <div class="padding-left">{{ sysMsgs.body }}</div>
              </li>
            </ng-container>
            <hr *ngIf="highImportanceSysMsgs?.length" />
            <ng-container class="" *ngFor="let sysMsgs of highImportanceSysMsgs">
              <li class="head">
                <div class="col-sm-7 padding-left">
                  <b class="text-red"> <span class="nw-icon-error"></span> {{ sysMsgs.title }}</b>
                </div>
                <div class="col-sm-5 padding-left">
                  <b>Posted On:&nbsp;{{ sysMsgs.startDate }}</b>
                </div>
              </li>
              <li>
                <div class="padding-left">{{ sysMsgs.body }}</div>
              </li>
            </ng-container>           
          </ul>
        </div>
      </div>
      <div class="margin-bottom padding-top" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="plan-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <div class="padding-top"><div class="content-title" id="plan-list">Plan List:</div></div>     
    </div>
  </div>
  <ng-container *ngIf="dataSource; else loading">   
    <ng-template #headerTemplate>
      <div nfrpColumn [width]="35" widthType="%" [id]="'plan-name-label'">
        Plan Name
        <nfrp-grid-sorter [column]="'planLabel'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
      </div>
      <div nfrpColumn [width]="25" widthType="%" [id]="'money-manager-label'">
        Money Manager
        <nfrp-grid-sorter [column]="'mnyMgrLbl'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
      </div>
      <div nfrpColumn [width]="25" widthType="%" [id]="'plan-number-label'">
        Plan Number
        <nfrp-grid-sorter [column]="'planNum'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
      </div>
      <div nfrpColumn [width]="15" widthType="%" [id]="'created-label'">
        Created
        <nfrp-grid-sorter [column]="'planCreated'" dataType="date" defaultDirection="ASCENDING" (sort)="sort($event)">
        </nfrp-grid-sorter>
      </div>
    </ng-template>

    <ng-template #rowTemplate let-planSearchResult="item" let-index="index">
      <div>
        <div [id]="'plan-name-' + index">
          <span
            *ngIf="
              planSearchResult?.planIsTMSActive === 1 || planSearchResult?.isTracActive === 1;
              else noSearchResult
            "
            >&ensp;<i class="nw-icon-check f-green"></i
          ></span>
          <ng-template #noSearchResult><span class="ml-25"></span></ng-template>
          <a class="text-decor-underline" (click)="planSearchClicked(planSearchResult)">
            {{ planSearchResult.planLabel | nfrpNormalizeValue: '-' }}</a
          >
          <span *ngIf="planSearchResult?.planArchived === 0">&ensp;<i class="icon-a-symbol f-red"></i></span>
        </div>
        <div [id]="'money-manager-' + index">
          {{ planSearchResult.mnyMgrLbl | nfrpNormalizeValue: '-' }}
        </div>
        <div [id]="'plan-number-' + index">
          {{ planSearchResult.planNum | nfrpNormalizeValue: '-' }}
        </div>
        <div [id]="'created-' + index">
          {{ planSearchResult.planCreated | nfrpNormalizeValue: '-' }}
        </div>
      </div>
    </ng-template>

    <nfrp-grid
      [headerTemplate]="headerTemplate"
      [rowTemplate]="rowTemplate"
      [iterable]="data"
      [width]="100"
      rowIdPrefix="seach-plan-details"
    ></nfrp-grid>
    <nfrp-grid-paginator
      (paginate)="paginate($event)"
      [numberOfItems]="numberOfItems"
      [itemsPerPageOptions]="[20, 50, 100]"
      [defaultItemsPerPage]="20"
    ></nfrp-grid-paginator>
  </ng-container>
  <ng-template #loading>
    <nfrp-spinner></nfrp-spinner>
  </ng-template>
</section>
