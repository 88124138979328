import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FooterFeatureStore } from '../footer-features.store';
import { FooterFeatures } from '../model/footer-features.model';
import { SubFooterFeatures } from '../model/subfooter-features.model';
import { ErrorUtilsService } from 'src/app/services/error-utils.service';
import { BinaryResponseEnum, UserTypeIDEnum } from 'src/app/helpers/enum';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class FooterFeaturesService {
  footerFeatureResult$: Observable<FooterFeatures[]> = this.footerFeatureStore.select('footerFeatureResult');
  subFooterFeatureResult: Observable<SubFooterFeatures[]>;
  private headers: HttpHeaders;
  private featureID: number;
  private subFooterFeatureList: SubFooterFeatures;
  isAdmin : number;
  userTypeId : number;
  baseUrl = this.config.config?.masRetailPrivateProcessing;
  hideEnrollDoc = false;
  
  private featureLeftMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private http: HttpClient,
    private footerFeatureStore: FooterFeatureStore,
    private errorUtilsService: ErrorUtilsService,
    private config: ConfigService) {
    this.headers = new HttpHeaders({
      accessControlAllowOrigin: '*',
      print2Image: 'true',
    });
    const loginUser = sessionStorage.getItem('loginUser');
    this.isAdmin = loginUser ? JSON.parse(loginUser)?.isAdmin : 0; 
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
  }
  /** HTTP GET to load user search information from the database table */
  getFooterDetails(userId): Observable<FooterFeatures> {
    const params = new HttpParams().set('id', userId);
    const serviceURL = this.baseUrl+'/get-features';
    return this.http
      .get<FooterFeatures>(serviceURL, { params })
      .pipe(
        tap((footerFeatureList: FooterFeatures) => {
          this.footerFeatureStore.set('footerFeatureResult', footerFeatureList);
        })
      );
  }
  getSubFooterDetails(featureID: number, userId: string, hideTab?: boolean): Observable<SubFooterFeatures> {
    this.featureID = featureID;   
    const params = new HttpParams().set('id', String(this.featureID)).set('email-id', userId);
    return this.http
      .get<SubFooterFeatures>(this.baseUrl + `/get-sub-features`, { params })
      .pipe(
        tap((subFooterFeatureList: SubFooterFeatures) => { 
          if(hideTab)  {
            subFooterFeatureList = this.sortSubFooter(subFooterFeatureList,'createPSd');
          } 
          if(this.hideEnrollDoc) {
            subFooterFeatureList = this.sortSubFooter(subFooterFeatureList,'creEnrFor');
          }   
          if(this.userTypeId === (UserTypeIDEnum.TPA || UserTypeIDEnum.TPA_TEAM) && this.isAdmin === BinaryResponseEnum.NO) {
            subFooterFeatureList = this.sortSubFooter(subFooterFeatureList,'printDoc');            
            subFooterFeatureList = this.sortSubFooter(subFooterFeatureList,'createPSd');
          }
          if(this.userTypeId != UserTypeIDEnum.RIA ) {
            subFooterFeatureList = this.sortSubFooter(subFooterFeatureList,'planChkL');
          }
          this.footerFeatureStore.set('subFooterFeatureResult', subFooterFeatureList);
        }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
      );
  }
  set footerFeatureID(featureID: number) {
    this.featureID = featureID;
    console.log('Feature ID ?>>>>>', this.featureID);
  }

  get footerFeatureID() {
    return this.featureID;
  }

  setFeatureListForLeftNav(hasPendinChanges: boolean, changeState: boolean): void{
    let hideSponsorDocMenu = true;
    this.hideEnrollDoc = false;
    if(hasPendinChanges && changeState) {
      hideSponsorDocMenu = false;
      this.hideEnrollDoc = true;
    } else if (!hasPendinChanges && !changeState) {
      hideSponsorDocMenu = false;
    }
    else if (!hasPendinChanges && changeState) {
      hideSponsorDocMenu = true;
    }
    else {
      hideSponsorDocMenu = true;
    }
    this.featureLeftMenu$.next(hideSponsorDocMenu);
  }

  getFeatureListForLeftNav(): Observable<boolean> {
    return this.featureLeftMenu$.asObservable();
  }

  sortSubFooter(subFooterFeatureList:SubFooterFeatures, searchCode: string): SubFooterFeatures {  
    const index = subFooterFeatureList.subFeatureResponseList.findIndex((feature) => feature.subFeatureCode === searchCode);
    if(index > -1) {
      subFooterFeatureList.subFeatureResponseList.splice(index,1);
    }
    return  subFooterFeatureList;
  }

}
