import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NavigationStore,
  NfrpAlertMessage,
  NfrpDefaultGridComponent,
  NfrpGridService,
  NfrpDateService,
} from 'nfrp-shared-angular';
import { CcViewResponse } from '../../model/cc-landing.model';
import { Subscription } from 'rxjs';
import { SearchUsersService } from '../../services/search-users.service';
import { TpadminSearch } from '../../model/tpadmin-data';
import { PlanDetailsService } from '../../services/plan-details.service';
import { GetPlanRepresentatives, GetPlans } from '../../model/get-plan.model';
import { MoneyManager, MoneyManagerData } from '../../model/money-manager-data';
import { AdvancedSearchData } from '../../model/advanced-search';
import { validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { UserTypeIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.css'],
})
export class AdvancedSearchComponent extends NfrpDefaultGridComponent<AdvancedSearchData> implements OnInit, OnDestroy {
  planNoValue: string;
  createDoc = false;
  ssn: string;
  advSearchView = false;
  selectedProduct: number;
  planDesc: string;
  dataSource: AdvancedSearchData[];
  numberOfItems: number;
  planRep: GetPlanRepresentatives[];
  productsList: GetPlans;
  planTypeList: GetPlans;
  moneyMgrInfo: MoneyManager[];
  mmNonWalledOffList: MoneyManager[];
  selectedPlanType: number;
  selectedTPA: string;
  selectedMoneyManager: string;
  selectedRep: string;
  tpaResult: TpadminSearch[];
  userTypeId: number;
  advDisable: boolean;
  showWFMM = false;
  planNoRecord = false;
  planDetails: any;
  planNoExist = false;
  selectedAction: string;
  submitted = false;
  loading = false;
  isMoneyManger = false;
  isTpa = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  planId: number;
  userEmail: string;
  planNumberView: CcViewResponse;
  subscriptions = [];
  formSubscription: Subscription;
  advancedSearchForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private searchUsersService: SearchUsersService,
    private planDetailsService: PlanDetailsService,
    nfrpGridService: NfrpGridService<AdvancedSearchData>,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private navigationStore: NavigationStore,
    private searchUserService: SearchUsersService,
    private nfrpDateService: NfrpDateService,
    private router: Router
  ) {
    super(nfrpGridService);
  }

  get advancedSearchFormControl() {
    return this.advancedSearchForm.controls;
  }

  ngOnInit() {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.setLoginUserEmailAndType(loginUser)
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.planId = params.planId;
        this.selectedAction = params.action;
      })
    );
    this.advancedSearchForm = this.fb.group({
      planCaseNumber: ['', [ Validators.maxLength(9),
        Validators.minLength(5)]],
      startDate: [this.nfrpDateService.addDays(this.nfrpDateService.today, -30)],
      endDate: [this.nfrpDateService.today],
      providerProductId: [''],
      planTypeId: [''],
      tpAdminNbr: [''],
      mnyMgrId: [''],
      repId: [''],
      planStatus: [2],
      isArchived: [2],
      isAutoEnrolled: [2],
      isPpaTpa: [2],
    });
    this.getTpAdmin(loginUser);
    this.formSubscription = this.advancedSearchForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    this.getMoneyManager();
    this.getAllProducts();
    this.getAllPlanType();
    this.getPlanRepresentatives();
  }

  setLoginUserEmailAndType(loginUser: string){
    this.userTypeId = loginUser ? JSON.parse(loginUser).userTypeId : 0;
    this.userEmail = this.communicationService.loginUserId ? 
      this.communicationService.loginUserId : JSON.parse(loginUser)?.userEmail;
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.advancedSearchForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }

  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  get startDate(): string {
    return this.advancedSearchForm.get('startDate').value;
  }

  get endDate(): string {
    return this.advancedSearchForm.get('endDate').value;
  }

  get minDate(): string {
    const date = new Date("0000-1-1");
    return this.nfrpDateService.convertNativeDateToString(date);
  }

  get maxDate(): string {
    return this.nfrpDateService.today;
  }

  onSubmit() {
    if (this.advancedSearchForm.valid) {
      this.submitted = true;
      this.ssn = '';
      this.planDesc = '';
      this.advDisable = false;
      this.subscriptions.push(
        this.searchUserService.advanceSearch(this.advancedSearchForm.value).subscribe(
          (data: any) => {
            this.dataSource = data.advanceSearchResponseList;
            this.advSearchView = true;
            this.errorMessage = { message: '', details: [] };
            if (this.dataSource) {
              this.numberOfItems = this.dataSource?.length;
              this.setupGrid();
              this.paginate({
                limit: 20,
                offset: 1,
              });
            }
            this.loading = false;
          },
          (error) => {
            window.scroll(0, 0);
            if (error) {
              this.errorMessage = { message: error, details: [] };
              this.advDisable = true;
              this.advSearchView = false;
            } else {
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
              this.advDisable = true;
              this.advSearchView = false;
            }
          }
        )
      );
    }
    this.navigationStore.set('formChanged', false);
  }

  navigateToPlanSummary(planId: number) {
    this.router.navigate(['/plan-summary', planId]);
  }
  get planCaseNumber(): UntypedFormControl {
    return this.advancedSearchForm.get('planCaseNumber') as UntypedFormControl;
  }

  getPlanRepresentatives() {
    this.subscriptions.push(
      this.planDetailsService.getPlanRepresentatives(this.planId).subscribe((data) => {
        this.planRep = data.planRepresentativeResponseList;
      })
    );
  }

  getAllProducts() {
    this.subscriptions.push(
      this.planDetailsService.getAllProducts().subscribe((productData: GetPlans) => {
        this.productsList = productData;
      })
    );
  }

  getMoneyManager(): void {
    this.subscriptions.push(
      this.searchUsersService.getMoneyManagerAdvancedSearch(this.userEmail).subscribe((moneyManger: MoneyManager[]) => {
        this.moneyMgrInfo = moneyManger;
        if (this.userTypeId !== UserTypeIDEnum.RIA) {
          this.showWFMM = true;
          this.mmNonWalledOffList = this.moneyMgrInfo?.filter(
            (mnyMgr) => mnyMgr.wallOff === BinaryResponseEnum.NO && mnyMgr.active === BinaryResponseEnum.YES
          );
        } else {
          this.showWFMM = false;
          this.moneyMgrInfo = this.moneyMgrInfo?.filter((mnyMgr) => mnyMgr.active === BinaryResponseEnum.YES);
        }
        this.limitSearchForMoneyManager(this.moneyMgrInfo[0]);
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  limitSearchForMoneyManager(moneyManager: MoneyManager) {
    if (this.userTypeId === UserTypeIDEnum.MONEY_MANAGER) {
      this.isMoneyManger = true;
      const moneyManagerId = moneyManager ? moneyManager.moneyManagerId.toString() : ''
      this.advancedSearchForm.get('mnyMgrId').setValue(moneyManagerId)
    }
    this.onSubmit();
  }

  getAllPlanType() {
    this.subscriptions.push(
      this.planDetailsService.getAllPlanType().subscribe((planTypeData: GetPlans) => {
        this.planTypeList = planTypeData;
      })
    );
  }

  getTpAdmin(loginUser: string) {
    const tpAdminNbr = this.limitSearchForTpa(loginUser);
    this.subscriptions.push(
      this.searchUserService.getTpAdmin(tpAdminNbr).subscribe((response: any) => {
        this.tpaResult = response;
        window.scroll(0, 0);
      })
    );
  }

  limitSearchForTpa(loginUser: string): string {
    let tpAdminNbr;
    if (this.userTypeId === UserTypeIDEnum.TPA) {
      this.isTpa = true;
      this.selectedTPA = loginUser ? JSON.parse(loginUser).tpAdminNbr : null;
      tpAdminNbr = this.selectedTPA
      this.advancedSearchForm.get('tpAdminNbr').setValue(this.selectedTPA)
    }
    return tpAdminNbr;
  }

  downloadFile(data: any) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const header1 = [
      'Plan Id',
      'Plan Number',
      'Plan Name',
      'TPA',
      'Money Manager',
      'Activated Date',
      'Trac Or Ik',
      'Created Date',
      'Archived?',
    ];
    const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header1.join(','));
    const csvArray = csv.join('\r\n');
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  getSelectedPlanType(planTypeId: number) {
    this.selectedPlanType = planTypeId;
  }

  getSelectedProduct(productId: number) {
    this.selectedProduct = productId;
  }

  getRequiredInputErrorMessage(preText: string): string {
    return `Please enter the ${preText}`;
  }

  getRequiredDropdownErrorMessage(preText: string): string {
    return `Please choose a ${preText}`;
  }

  getSelectedTPA(tpaNo: string) {
    this.selectedTPA = tpaNo;
  }

  getSelectedMoneyManager(mnyMgr: string) {
    this.selectedMoneyManager = mnyMgr;
  }

  onRepIdChange(rep: string) {
    this.selectedRep = rep;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
