<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding" *ngIf="!showOldWorkflow">
        <span
          *ngIf="(planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1"
          ><i class="nw-icon-check f-green"></i
        ></span>
        {{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }})
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="infoMessage?.message?.length > 0">
        <nfrp-alert alertId="mm-error" [message]="infoMessage" type="info" [page]="false"> </nfrp-alert>
      </div>
      <ng-container *ngIf="!showOldWorkflow; else oldWorkFlow">
        <form [formGroup]="solicitorForm" (ngSubmit)="onSubmit()">
          <ul
            id="representative-params"
            summary="employee sponsor"
            class="ul-li-view-edit table stripes no-margin border-common"
          >
            <li class="head" [ngClass]="{'pending-status-bg':isPending}">
              <div class="col-sm-12 padding-left">
                <b>Select an Approved Solicitor.</b>
              </div>
            </li>
            <li>
              <div class="solicitor-margin">
                <div class="solicitor-filter" [class.disabled]="solicitorList?.length === 0">
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === '0-9' }"
                    (click)="getSolicitorInfo('0-9')"
                  >
                    0-9</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'A' }"
                    (click)="getSolicitorInfo('A')"
                    >A</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'B' }"
                    (click)="getSolicitorInfo('B')"
                    >B</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'C' }"
                    (click)="getSolicitorInfo('C')"
                    >C</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'D' }"
                    (click)="getSolicitorInfo('D')"
                    >D</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'E' }"
                    (click)="getSolicitorInfo('E')"
                    >E</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'F' }"
                    (click)="getSolicitorInfo('F')"
                    >F</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'G' }"
                    (click)="getSolicitorInfo('G')"
                    >G</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'H' }"
                    (click)="getSolicitorInfo('H')"
                    >H</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'I' }"
                    (click)="getSolicitorInfo('I')"
                    >I</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'J' }"
                    (click)="getSolicitorInfo('J')"
                    >J</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'K' }"
                    (click)="getSolicitorInfo('K')"
                    >K</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'L' }"
                    (click)="getSolicitorInfo('L')"
                    >L</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'M' }"
                    (click)="getSolicitorInfo('M')"
                    >M</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'N' }"
                    (click)="getSolicitorInfo('N')"
                    >N</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'O' }"
                    (click)="getSolicitorInfo('O')"
                    >O</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'P' }"
                    (click)="getSolicitorInfo('P')"
                    >P</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'Q' }"
                    (click)="getSolicitorInfo('Q')"
                    >Q</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'R' }"
                    (click)="getSolicitorInfo('R')"
                    >R</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'S' }"
                    (click)="getSolicitorInfo('S')"
                    >S</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'T' }"
                    (click)="getSolicitorInfo('T')"
                    >T</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'U' }"
                    (click)="getSolicitorInfo('U')"
                    >U</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'V' }"
                    (click)="getSolicitorInfo('V')"
                    >V</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'W' }"
                    (click)="getSolicitorInfo('W')"
                    >W</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'X' }"
                    (click)="getSolicitorInfo('X')"
                    >X</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'Y' }"
                    (click)="getSolicitorInfo('Y')"
                    >Y</a
                  >
                  |
                  <a
                    href="javascript:void(0);"
                    [ngClass]="{ 'solicitor-letter': activeSolicitorLetter === 'Z' }"
                    (click)="getSolicitorInfo('Z')"
                    >Z</a
                  >
                </div>
                <div *ngIf="loading">
                  <nfrp-spinner></nfrp-spinner>
                </div>
                <div *ngIf="!loading" [ngClass]="{ 'solicitor-column': filteredSolicitor?.length > 10 }">
                  <div
                    class="mnyMgrOptions solicitor-list padding-left mt-10"
                    *ngFor="let solicitor of filteredSolicitor; let i = index"
                  >
                    <input
                      type="radio"
                      id="radio-{{ i }}"
                      name="solicitorFirm"
                      [value]="solicitor?.appSolicitorId"
                      formControlName="solicitorFirm"
                    />{{ solicitor?.appSolicitorName }}
                  </div>
                  <div class="padding-left mt-10" *ngIf="filteredSolicitor?.length === 0">
                    There are no solicitors that begin with {{ filterLetter }}
                  </div>
                  <div class="padding-left mt-10 ml-5">
                    <input
                      type="radio"
                      id="smmNotListed"
                      formControlName="solicitorFirm"
                      name="solicitorFirm"
                      [value]="0"
                    />
                    Not Listed
                  </div>
                </div>
                <nfrp-inline-error
                  *ngIf="submitted && solicitorFormControl.solicitorFirm.errors?.required"
                  errorId="plan-cls-option-code-valid-error"
                  [message]="'Please Select a Solicitor Name'"
                >
                </nfrp-inline-error>
              </div>
            </li>
          </ul>
          <div class="row margin-top">
            <div class="col-sm-1 no-padding">
              <input
                id="search-button"
                class="button-primary button hover-left margin-left"
                type="submit"
                size="40"
                value="Save"
              />
            </div>
            <div class="col-sm-10 no-padding"></div>
            <div class="col-sm-1 no-padding">
              <input
                type="button"
                class="button button-secondary hover-right margin-right"
                (click)="onCancel()"
                value="Cancel"
              />
            </div>
          </div>
        </form>
      </ng-container>
      <ng-template #oldWorkFlow>
        <lib-solicitor-old-workflow [planDetails]="planDetails"></lib-solicitor-old-workflow>
      </ng-template>   
    </div>
  </div>
</section>

<nfrp-navigate-away-modal></nfrp-navigate-away-modal>

<nfrp-default-modal
  [header]="modalOptions.header"
  [message]="modalOptions.message"
  [noButtonValue]="modalOptions.noButtonValue"
  [yesButtonValue]="modalOptions.yesButtonValue"
  [showModal]="modalOptions.showModal"
  (noClicked)="noClicked()"
  (yesClicked)="yesClicked()"
>
</nfrp-default-modal>
