<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <ng-container *ngIf="editUser$ | async as data">
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
          <div class="title-actions-inline" *ngIf="editUser">
            <h1 id="edit-user-label" class="page-title" aria-live="assertive" tabindex="0">
              {{ editForm.value.userNameFirst }} {{ editForm.value.userNameLast }} Details:
            </h1>
          </div>
          <div class="box compartment summary animation-fade- no-margin">
            <div class="">
              <div class="section-header border-bottom">
                <h2 class="content-title no-margin-bottom">User Information</h2>
              </div>
              <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
                <nfrp-alert alertId="edit-user-success" type="success" [message]="successMessage" [page]="false">
                </nfrp-alert>
              </div>
              <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
                <nfrp-alert alertId="edit-user-error" [message]="errorMessage" type="error" [page]="false">
                </nfrp-alert>
              </div>
              <div class="row">
                <div class="col-sm-12 col-lg-5">
                  <div class="content">
                    <div class="row">
                      <p id="user-info-user-email" class="col-sm-3 no-margin-bottom">User Email</p>
                      <p id="user-info-user-email-data" class="col-sm-7 no-margin-bottom">
                        <input type="text" name="useremail" formControlName="userEmail" />
                      </p>
                      <span class="inline-error col-sm-offset-3 col-sm-7">
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="
                            (editFormControl.userEmail.touched || submitted) &&
                            editFormControl.userEmail.errors?.required
                          "
                          errorId="username-required-error"
                          [message]="getRequiredErrorMessage('User Email')"
                        >
                        </nfrp-inline-error>
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="editFormControl.userEmail.touched && editFormControl.userEmail.errors?.pattern"
                          errorId="username-valid-error"
                          [message]="getValidErrorMessage('User Email')"
                        >
                        </nfrp-inline-error>
                      </span>
                    </div>
                    <div class="row">
                      <p id="user-info-first-name" class="col-sm-3 no-margin-bottom">First Name</p>
                      <p id="user-info-first-name-data" class="col-sm-7 no-margin-bottom">
                        <input type="text" name="userfirstname" formControlName="userNameFirst" />
                      </p>
                      <span class="inline-error col-sm-offset-3 col-sm-7">
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="
                            (editFormControl.userNameFirst.touched || submitted) &&
                            editFormControl.userNameFirst.errors?.required
                          "
                          errorId="firstname-required-error"
                          [message]="getRequiredErrorMessage('First Name')"
                        >
                        </nfrp-inline-error>
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="editFormControl.userNameLast.touched && editFormControl.userNameFirst.invalid"
                          errorId="firstname-valid-error"
                          [message]="getValidErrorMessage('First Name')"
                        >
                        </nfrp-inline-error>
                      </span>
                    </div>
                    <div class="row">
                      <p id="user-info-last-name" class="col-sm-3 no-margin-bottom">Last Name</p>
                      <p id="user-info-last-name-data" class="col-sm-7 no-margin-bottom">
                        <input type="text" name="userlastname" formControlName="userNameLast" />
                      </p>
                      <span class="inline-error col-sm-offset-3 col-sm-7">
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="
                            (editFormControl.userNameLast.touched || submitted) &&
                            editFormControl.userNameLast.errors?.required
                          "
                          errorId="lastname-required-error"
                          [message]="getRequiredErrorMessage('Last Name')"
                        >
                        </nfrp-inline-error>
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="editFormControl.userNameLast.touched && editFormControl.userNameLast.invalid"
                          errorId="lastname-valid-error"
                          [message]="getValidErrorMessage('Last Name')"
                        >
                        </nfrp-inline-error>
                      </span>
                    </div>
                    <div class="row" *ngIf="isInternal">
                      <p id="nwid-nwid" class="col-sm-3 no-margin-bottom">NWID</p>
                      <p id="nwid-data" class="col-sm-7 no-margin-bottom">
                        <input type="text" name="nwid" formControlName="nwId" />
                      </p>
                      <span class="inline-error col-sm-offset-3 col-sm-7">
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="
                            (editFormControl.nwId.touched || submitted) &&
                            editFormControl.nwId.errors?.required
                          "
                          errorId="nwId-required-error"
                          [message]="getRequiredErrorMessage('NWID')"
                        >
                        </nfrp-inline-error>
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="editFormControl.nwId.touched && editFormControl.nwId.errors"
                          errorId="nwId-valid-error"
                          [message]="getValidErrorMessage('NWID')"
                        >
                        </nfrp-inline-error>
                      </span>
                    </div>
                    <div class="row" *ngIf="isInternal">
                      <p id="nw-email" class="col-sm-3 no-margin-bottom">NW Email</p>
                      <p id="nw-email-data" class="col-sm-7 no-margin-bottom">
                        <input type="text" name="nw-email" formControlName="nwEmail" />
                      </p>
                      <span class="inline-error col-sm-offset-3 col-sm-7">
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="
                            (editFormControl.nwEmail.touched || submitted) &&
                            editFormControl.nwEmail.errors?.required
                          "
                          errorId="username-required-error"
                          [message]="getRequiredErrorMessage('NW Email')"
                        >
                        </nfrp-inline-error>
                        <nfrp-inline-error class="margin-bottom"
                          *ngIf="editFormControl.nwEmail.touched && editFormControl.nwEmail.errors?.pattern"
                          errorId="username-valid-error"
                          [message]="getValidErrorMessage('NW Email')"
                        >
                        </nfrp-inline-error>
                      </span>
                    </div> 
                  </div>
                </div>
                <div class="col-sm-12 col-lg-1">
                  <div class="row padding-top">
                    <p id="user-info-active" class="col-sm-2 no-margin-bottom"></p>
                    <div class="padding-bottom">
                      <label>
                        <input
                          type="radio"
                          formControlName="userActive"
                          id="active"
                          name="userActive"
                          [value]="true"
                        /><small>Active</small>
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          formControlName="userActive"
                          id="inactive"
                          name="userActive"
                          [value]="false"
                        /><small>Inactive</small>
                      </label>
                    </div>
                  </div>
                </div>
                <div  class="col-sm-12 col-lg-offset-1 col-lg-5" *ngIf="showPasswordOptions">
                  <div id="new-password" class="row padding-top">
                    <p class="detailTitle col-sm-4 no-margin-bottom">New Password</p>
                    <p class="col-sm-7 no-margin-bottom">
                    <input formControlName="newPassword" type="password" class="userDetailInput"/>
                    </p>
                    <div class="inline-error col-sm-offset-4 col-sm-7">
                      <nfrp-inline-error class="margin-bottom"
                        *ngIf="editFormControl.newPassword.touched && editFormControl.newPassword.errors?.pattern"
                        errorId="username-required-error"
                        [message]="'Enter a valid password'"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                  <div id="confirm-password" class="row">
                    <p class="col-sm-4 no-margin-bottom">Confirm Password</p>
                    <p class="col-sm-7 no-margin-bottom">
                    <input formControlName="confirmPassword" type="password" class="userDetailInput" /></p>
                    <div class="inline-error col-sm-offset-4 col-sm-7">
                      <nfrp-inline-error class="margin-bottom"
                        *ngIf="editFormControl.confirmPassword.valid && editForm.errors?.passwordsShouldMatch && editForm.invalid"
                        errorId="confirm-pwd-valid-error"
                        [message]="'Passwords do not match.'"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>                 
                  <div class="search-button" style="padding-left: 160px;">
                    <input class="button-primary button" type="button" (click)="changePassword()" value="Save Password" />
                  </div>
                  <ul style="padding-top: 1rem;">
                    <li>Password Must be at least 12 characters</li>
                    <li>A mixture of both uppercase and lowercase letters</li>
                    <li>A mixture of letters and numbers</li>
                    <li>Inclusion of at least one special character, e.g., ! &#64; # ?</li>
                  </ul>
                </div> 
              </div>
            </div>
          </div>
          <div class="box compartment summary animation-fade-in margin-top">
            <div class="section-header border-bottom">
              <h2 class="content-title no-margin-bottom">User Information</h2>
            </div>
            <div class="content padding-2">             
              <div class="row">
                <div class="col-sm-6 no-padding">
                  <div class="position-relative">
                    <div class="form-check user-list">
                      <ul class="ul-li-view-edit with-head no-padding">
                        <li class="row">
                          <div class="col-sm-12">Available Roles</div>
                        </li>
                      </ul>
                      <div class="border">
                        <ul class="ul-li-view-edit">
                          <li
                            formArrayName="roles"
                            class="list-group-item padding-left"
                            *ngFor="let assignedRole of availableResultsFormArray.controls; let i = index"
                          >
                            <label class="form-check-label width-100" [formGroup]="assignedRole" id="radio-{{ i }}">
                              {{ assignedRole.value.roleName }}
                              <input
                                id="input-radio-{{ i }}"
                                type="radio"
                                class="form-check-input float-right"
                                name="selected"
                                [value]="assignedRole.value.roleName"
                                [formControl]="assignedRole.get('selected')"
                                (change)="onAssignedRoleChange(assignedRole)"
                              />
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-1 no-padding"></div>
                <div class="col-sm-5 no-padding">
                  <div class="">
                    <ul class="ul-li-view-edit with-head no-padding">
                      <li class="row">
                        <div class="col-sm-12">User Roles</div>
                      </li>
                    </ul>
                    <div class="padding-bottom no-padding-top">
                      <nfrp-alert
                        *ngIf="isAdminMessage?.message?.length > 0"
                        alertId="make-admin-display-message"
                        [message]="isAdminMessage"
                        type="info"
                        [page]="false"
                      >
                      </nfrp-alert>
                      <nfrp-alert
                        *ngIf="noMakeAdminMessage?.message?.length > 0"
                        alertId="no-make-admin-display-message"
                        [message]="noMakeAdminMessage"
                        type="info"
                        [page]="false"
                      >
                      </nfrp-alert>
                    </div>
                    <div class="border padding">
                      <h3>
                        {{ selectedUserRole }}
                        <span class="float-right" *ngIf="isUserAdminEnabled()">
                          Make Administrator
                          <input
                            #makeAdminCheckbox
                            id="makeAdminCheckbox"
                            type="checkbox"
                            name="isAdmin"
                            formcontrolname="isAdmin"
                            [checked]="editForm.value.isAdmin"
                            class="padding-left"
                            (change)="userAdminStatus(makeAdminCheckbox.checked)"
                          />
                        </span>
                      </h3>
                      <select
                        class="form-input form-input--xlarge"
                        name="selectedUserFirm"
                        formControlName="selectedUserFirm"
                        *ngIf="selectedUserRole === 'Money Manager' && !!moneyManagerFirmData"
                        (change)="selectedFirmData($event.target.value)"
                      >
                        <option
                          *ngFor="let firm of moneyManagerFirmData | orderBy: 'moneyManagerLabel'"
                          [ngValue]="firm?.moneyManagerId"
                        >
                          {{ firm?.moneyManagerLabel }} {{ firm?.wallOff === 1 ? '(W)' : '' }}
                        </option>
                      </select>
                      <select
                        class="form-input form-input--xlarge"
                        name="selectedUserFirm"
                        formControlName="selectedUserFirm"
                        *ngIf="selectedUserRole === 'Third Party Administrator' && !!tpAdminFirmData"
                        (change)="selectedFirmData($event.target.value)"
                      >
                        <option
                          *ngFor="let firm of tpAdminFirmData | orderBy: 'tpAdminLabel'"
                          [ngValue]="firm?.tpAdminNbr"
                        >
                        {{ firm?.tpAdminNbr }} - {{ firm?.tpAdminLabel }}  
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-1 no-padding">
              <input
              type="button"
              class="button button-secondary hover-left margin-left"
              (click)="cancel()"
              value="Cancel"
            />
            </div>
            <div class="col-sm-8 no-padding"></div>
            <div class="col-sm-offset-1 col-sm-1 no-padding">
              <input
              id="search-button"
              class="button-primary button hover-right margin-right"
              type="submit"
              size="40"
              value="Submit"
            />
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
