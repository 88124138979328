import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { modalMessage, validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { MoneyManagerIDEnum, UserTypeIDEnum, FeatureTypeIDEnum, BinaryResponseEnum, PlanInfoEnum, PlanTypeIDEnum, RecordSystemEnum, PlanStatusEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { GetPlans, MoneyMgrProgram, CoAdvisoryFirm, PlanSummaryResponse, PendingPlanResponse } from '../../../model/get-plan.model';
import { MoneyManager } from '../../../model/money-manager-data';
import { TpAdminData } from '../../../model/tpadmin-data';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { PlanDetailsService } from '../../../services/plan-details.service';
import { SearchUsersService } from '../../../services/search-users.service';

@Component({
  selector: 'core-plan',
  templateUrl: './core-plan.component.html',
  styleUrls: ['./core-plan.component.css'],
})
export class CorePlanComponent implements OnInit, OnDestroy {
  planDetailsData: GetPlans;
  userEmail: string;
  userTypeID: number;
  selectedMoneyMgrId: number;
  moneyManagerList: MoneyManager[];
  tpaList: TpAdminData[];
  nwTpaList: TpAdminData[];
  showWFMM = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  productsList: GetPlans;
  planTypeList: GetPlans;
  mmNonWalledOffList: MoneyManager[];
  corePlanRequestObject: GetPlans;
  submitted = false;
  planId: number;
  saveResponsePlanId: number;
  planDetails: GetPlans;
  moneyMgrPgmDetails: MoneyMgrProgram;
  corePlanForm: UntypedFormGroup;
  selectedProduct: number;
  showTpaActive = false;
  selectedPlanType: number;
  isTpaUser = false;
  tpaFirmName: string;
  staditionPlanMessage: NfrpAlertMessage;
  selectedTPA = '';
  selectedCoreFundStrategy = false;
  showModal = false;
  message: string;
  showConversion = false;
  stadionMnyMgrId = MoneyManagerIDEnum.STADION_MONEY_MGMT;
  nwInvestmentAdvisorLlc = MoneyManagerIDEnum.NW_INVESTMENT_ADVISOR_LLC;
  clsInvestments = MoneyManagerIDEnum.CLS_INVESTMENTS;
  portFolioStrategies = MoneyManagerIDEnum.PORTFOLIO_STRATEGIES;
  meederAdvisoryServices = MoneyManagerIDEnum.MEEDER_ADVISORY_SERVICES;
  riaUserId = UserTypeIDEnum.RIA;
  tpaUserId = UserTypeIDEnum.TPA;
  tpaTeam = UserTypeIDEnum.TPA_TEAM;
  nbtTeam = UserTypeIDEnum.NB_TEAM;
  showManagerMapping = false;
  coAdvisoryList: CoAdvisoryFirm[];
  isPlanActive = false;
  showTpAdminNwList = false;
  isAddMode = false;
  corePlanSubmitValue: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  formSubscription: Subscription;
  loading = false;
  isCoreFundSelected = false;
  selectedMMData: MoneyManager[];
  showQdia = false;
  showProviderMapping = false;
  showAutoMgmt = false;
  planIsTmsActive = 0;
  loggedInUserId: number;
  activateTrackIkButton = 'Activate Plan';
  loggedInUserTpa: string;
  editPlanInfo: NfrpAlertMessage;
  iam: string;
  cpm: string;
  adv1core: string;
  showOldMM = false;
  private subscriptions = [];
  showAdvisorOnly = false;
  isPending: boolean;
  hideCustodianORTrustee = false;
  showPendingButtons: boolean;
  hideActivatePendingButton: boolean;
  modalOptions: any = {
    header: modalMessage.CONFIRMATION,
    message: modalMessage.CORE_PLAN_MSG,
    showClsModal: false,
    noButtonValue: modalMessage.NO_TEXT,
    yesButtonValue: modalMessage.YES_TEXT,
  };
  pendingChangesModel: any = {
    header: modalMessage.CONFIRMATION,
    message: '',
    showClsModal: false,
    noButtonValue: modalMessage.NO_TEXT,
    yesButtonValue: modalMessage.YES_TEXT,
  };
  constructor(
    private planDetailsService: PlanDetailsService,
    private fb: UntypedFormBuilder,
    private searchUserService: SearchUsersService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationStore: NavigationStore,
    private footerFeaturesService: FooterFeaturesService,
    private communicationService: CommunicationService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.loggedInUserId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.userTypeID = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.loggedInUserTpa = loginUser ? JSON.parse(loginUser)?.tpAdminNbr : '';
    if(this.userTypeID === UserTypeIDEnum.RIA) {
      this.hideActivatePendingButton = false;
    } else {
      this.hideActivatePendingButton = true;
    }
    this.route.params.subscribe((params) => {
      this.planId = params?.planId;
      this.isAddMode = this.planId ? false : true;
      if (this.isAddMode) {
        this.subscriptions.push(
          this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.CREATE_PLANS, this.userEmail).subscribe()
        );
      } else {
        this.subscriptions.push(
          this.communicationService.getPendingStatus().subscribe(pending => {
            this.isPending = pending;
          })
        );
        this.getPlanDetails();
      }
    });

    this.corePlanForm = this.fb.group({
      planInfo: [null, [Validators.required]],
      providerProductId: [null, [Validators.required]],
      planType: [null, [Validators.required]],
      tpAdminNbr: [null, [Validators.required]],
      mnyMgrId: [null, [Validators.required]],
      isCoreFundStrategy: [null],
      stadionPlanOption: [null],
      stadionPartipantCode: [null],
      mnyMgrProgramCode: [null],
      solicitorFirm: [null],
      recordSystem: [null],
      planAssets: [null, [Validators.required, Validators.maxLength(22)]],
      isPrintAccess: [1, [Validators.required]],
      isPrintEnrollment: [1, [Validators.required]],
      isMerillAccess: [1, [Validators.required]],
      planName: [null, [Validators.required, Validators.maxLength(200)]],
      caseNumber: [
        null,
        [
          Validators.required,
          Validators.maxLength(5),
          Validators.minLength(5),
          Validators.pattern(validationPatterns.numberPattern),
        ],
      ],
      ein: [null],
      trustTaxId: [null, [Validators.maxLength(20)]],
      planNumber: [null],
      conversion: [null],
      managerMapping: [null],
      providerMapping: [null],
      autoManagement: [0],
      qdia: [0],
      custodianOrTrustee: [null],
    });
    this.formSubscription = this.corePlanForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    this.checkCoreFormValueChanges();
    this.getMoneyManager();
    this.getCoAdvisoryFirm();
    this.getTpAdminDetails();
    this.getAllPlanType();
    this.corePlanSubmitValue = this.isAddMode ? 'Create Plan' : 'Edit';
  }
  checkCoreFormValueChanges() {
    this.corePlanForm.get('mnyMgrId').valueChanges.subscribe((mnyMgr) => {
      this.activateTrackIkButton = 'Activate Plan';
      this.selectedMoneyMgrId = +mnyMgr;
      this.updateMMValidators(this.selectedMoneyMgrId);
      this.corePlanForm.controls.mnyMgrProgramCode.setValue(null);
      this.corePlanForm.controls.recordSystem.setValue(null);
      this.checkStaditionPlanToMM();
      this.checkFlexibleInvestment(this.selectedMoneyMgrId);
      this.getMoneyMgrPgmOptions(this.selectedMoneyMgrId);
      this.getAllProducts();
      this.checkProductAvailable();
    });
    // Record system validation
    this.corePlanForm.get('recordSystem').valueChanges.subscribe((recordSystem) => {
      if (+recordSystem === BinaryResponseEnum.YES) {
        this.planIsTmsActive = BinaryResponseEnum.YES;
      }
    });
  }
  checkProductAvailable(){
    if(this.selectedMoneyMgrId && this.selectedProduct && this.productsList){
      let isProdAvailable = false;
      for(const prod in this.productsList){
        isProdAvailable = this.productsList[prod].providerProductId === this.selectedProduct ? true : isProdAvailable;
      }
      if(!isProdAvailable){
        this.corePlanForm.controls.providerProductId.setValue(null);
      }
    }
  }
  checkFlexibleInvestment(mnyMgr: number): void {
    this.resetAlertMessages();
    if (
      mnyMgr === MoneyManagerIDEnum.FLEXIBLE_INVESTMENT &&
      this.corePlanForm.value.planInfo === PlanInfoEnum.ALREADY_EXIST
    ) {
      this.showModal = true;
      this.message = alertMessages.FLEXIBLE_INVEST_INFO;
    } else {
      this.showModal = false;
    }
  }

  mnyMgrProgramCodeChangeEvent(programCodeEvent: any, mnyMgrCode: string): void {
    if (mnyMgrCode === 'adv1core') {
      this.modalOptions.showClsModal = true;
    }
  }
  noClicked(): void {
    this.modalOptions.showClsModal = false;
  }
  yesClicked(): void {
    this.modalOptions.showClsModal = false;
    this.showAdvisorOnly = true;
  }

  getSelectedProduct(productId: string): void {
    this.selectedProduct = +productId;
    this.hideCustodianORTrustee = this.selectedProduct === BinaryResponseEnum.YES ? true : false;
    this.setCustodianValidation();
  }

  get stadtionPlanOption(): UntypedFormControl {
    return this.corePlanForm.get('stadionPlanOption') as UntypedFormControl;
  }
  getSelectedPlanType(planTypeId: number) {
    this.selectedPlanType = planTypeId;
    this.checkStaditionPlanToMM();
  }

  checkStaditionPlanToMM(): void {
    this.resetAlertMessages();
    if (
      (+this.selectedPlanType === PlanTypeIDEnum.PLAN_403 || +this.selectedPlanType === PlanTypeIDEnum.PLAN_457) &&
      this.selectedMoneyMgrId === MoneyManagerIDEnum.STADION_MONEY_MGMT
    ) {
      this.showModal = true;
      this.message = alertMessages.STADION_PLAN_MSG_403B_457;
    } else {
      this.showModal = false;
    }
  }
  getSelectedTPA(tpaNo: string) {
    this.selectedTPA = tpaNo;
  }
  onCoreFundStrategyChange(coreFundStrategy: boolean) {
    this.isCoreFundSelected = true;
    this.selectedCoreFundStrategy = coreFundStrategy;
    if (coreFundStrategy) {
      this.showModal = true;
      this.message = alertMessages.CORE_FUND_STRATEGY_STADION_MM;
    } else {
      this.showModal = false;
    }
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetailsData = data?.planOverviews;
        if(this.planDetailsData?.pending && this.planDetailsData?.hasPendingChanges) {
          this.showPendingButtons = true;
        }
        else {
          this.showPendingButtons =false;
        }
        this.setEditCoreFormData(this.planDetailsData);
        this.editPlanInfo = { message: alertMessages.EDIT_CORE_PLAN_INFO, details: [] };
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
  setEditCoreFormData(planDetailsData: GetPlans): void {
    if (planDetailsData) {
      const caseNo = planDetailsData.planCaseNumber.split('-')[1];
      if (this.planDetailsData.userTypeID === UserTypeIDEnum.TPA) {
        this.isTpaUser = true;
        this.tpaFirmName = this.planDetailsData.tpAdminLabel;
      }
      this.isPlanActive = this.planDetailsData.planIsTMSActive ? true : false;
      this.selectedTPA = this.planDetailsData.tpAdminNbr.toString();
      this.selectedMoneyMgrId = this.planDetailsData.moneyManagerId;
      this.selectedCoreFundStrategy = this.planDetailsData.stadionCoreFund ? true : false;
      this.updateMMValidators(this.selectedMoneyMgrId);
      this.getMoneyMgrPgmOptions(this.selectedMoneyMgrId);
      this.selectedProduct = this.planDetailsData.providerProductId;
      this.hideCustodianORTrustee = this.selectedProduct === BinaryResponseEnum.YES ? true : false;
      this.setCustodianValidation();
      if (
        this.planDetailsData.moneyManagerProgramCode === 'cpm' ||
        this.planDetailsData.moneyManagerProgramCode === 'iam'
      ) {
        this.showOldMM = true;
      }
      //Setting coreplan form values
      this.corePlanForm.patchValue({
        planInfo: planDetailsData.planNewToProviderNo,
        providerProductId: planDetailsData.providerProductId,
        planType: planDetailsData.planTypeId,
        tpAdminNbr: planDetailsData.tpAdminNbr,
        mnyMgrId: planDetailsData.moneyManagerId,
        isCoreFundStrategy: planDetailsData.stadionCoreFund ? true : false,
        stadionPlanOption: (planDetailsData.storyLineOption || planDetailsData.planAutoEnroll) == 1 ? true : (planDetailsData.storyLineOption || planDetailsData.planAutoEnroll)== 0 ? false : null,
        stadionPartipantCode: planDetailsData.stadionParticCode,
        mnyMgrProgramCode: planDetailsData.moneyManagerProgramCode,
        solicitorFirm: planDetailsData.coadvisoryId,
        recordSystem:
          planDetailsData?.trackOrIk === RecordSystemEnum.IK_SYSTEM ? BinaryResponseEnum.YES : BinaryResponseEnum.NO,
        planAssets: planDetailsData.totalAssets,
        isPrintAccess: planDetailsData.planPrintAccess,
        isPrintEnrollment: planDetailsData.mgrPrintAccess,
        isMerillAccess: planDetailsData.merrillAccess,
        planName: planDetailsData.planLabel,
        caseNumber: caseNo,
        ein: planDetailsData.planEin,
        trustTaxId: planDetailsData.planTTId,
        planNumber: planDetailsData.planNbr ? planDetailsData.planNbr : null,
        conversion: planDetailsData.conversionYn,
        managerMapping: planDetailsData.planParticMap,
        providerMapping: planDetailsData.providerMapping,
        autoManagement: planDetailsData.planAutoEnroll,
        qdia: planDetailsData.qdiaYn,
        custodianOrTrustee: planDetailsData.custodianOrTrustee
      });
    }
    this.navigationStore.set('formChanged', false);
  }

  updateMMValidators(mnyMgr: number): void {
    if (mnyMgr === MoneyManagerIDEnum.STADION_MONEY_MGMT) {
      this.corePlanForm.controls.isCoreFundStrategy.setValidators([Validators.required]);
      this.corePlanForm.controls.isCoreFundStrategy.updateValueAndValidity();
      this.corePlanForm.controls.stadionPlanOption.setValidators([Validators.required]);
      this.corePlanForm.controls.stadionPlanOption.updateValueAndValidity();
      this.corePlanForm.controls.stadionPartipantCode.setValidators([
        Validators.pattern(validationPatterns.alphaNumericPattern),
      ]);
      this.corePlanForm.controls.stadionPartipantCode.updateValueAndValidity();
      this.corePlanForm.controls.planNumber.setValidators([Validators.required]);
      this.corePlanForm.controls.planNumber.updateValueAndValidity();
      this.corePlanForm.controls.ein.setValidators([Validators.required]);
      this.corePlanForm.controls.ein.updateValueAndValidity();
      this.corePlanForm.controls.mnyMgrProgramCode.clearValidators();
    } else {
      this.corePlanForm.controls.isCoreFundStrategy.clearValidators();
      this.corePlanForm.controls.isCoreFundStrategy.updateValueAndValidity();
      this.corePlanForm.controls.stadionPlanOption.clearValidators();
      this.corePlanForm.controls.stadionPlanOption.updateValueAndValidity();
      this.corePlanForm.controls.stadionPartipantCode.clearValidators();
      this.corePlanForm.controls.stadionPartipantCode.updateValueAndValidity();
      this.corePlanForm.controls.planNumber.clearValidators();
      this.corePlanForm.controls.planNumber.updateValueAndValidity();
      this.corePlanForm.controls.ein.clearValidators();
      this.corePlanForm.controls.ein.updateValueAndValidity();
    }
    if (
      mnyMgr === MoneyManagerIDEnum.CLS_INVESTMENTS ||
      mnyMgr === MoneyManagerIDEnum.PORTFOLIO_STRATEGIES ||
      mnyMgr === MoneyManagerIDEnum.MEEDER_ADVISORY_SERVICES
    ) {
      this.corePlanForm.controls.mnyMgrProgramCode.setValidators([Validators.required]);
      this.corePlanForm.controls.mnyMgrProgramCode.updateValueAndValidity();
      if (mnyMgr === MoneyManagerIDEnum.CLS_INVESTMENTS) {
        this.corePlanForm.controls.solicitorFirm.setValidators([Validators.required]);
        this.corePlanForm.controls.solicitorFirm.updateValueAndValidity();
      } else {
        this.corePlanForm.controls.solicitorFirm.clearValidators();
        this.corePlanForm.controls.solicitorFirm.updateValueAndValidity();
      }
    } else {
      this.corePlanForm.controls.solicitorFirm.clearValidators();
      this.corePlanForm.controls.solicitorFirm.updateValueAndValidity();
      this.corePlanForm.controls.mnyMgrProgramCode.clearValidators();
      this.corePlanForm.controls.mnyMgrProgramCode.updateValueAndValidity();
    }
  }

  getMoneyManager(): void {
    this.subscriptions.push(
      this.searchUserService.getMoneyManagerForPlans(this.userEmail,this.planId).subscribe((firmData: MoneyManager[]) => {
        this.moneyManagerList = firmData;
        if (this.userTypeID !== UserTypeIDEnum.RIA) {
          this.showWFMM = true;
          this.mmNonWalledOffList = this.moneyManagerList?.filter(
            (mnyMgr) => mnyMgr.wallOff === BinaryResponseEnum.NO && mnyMgr.active === BinaryResponseEnum.YES
          );
        } else {
          this.moneyManagerList = this.moneyManagerList?.filter(
            (mnyMgr) => mnyMgr.active === BinaryResponseEnum.YES
          );
        }
        this.getMoneyMgrPgmOptions(this.selectedMoneyMgrId)
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
  sortMoneyManager(mnyMgr1, mnyMgr2) {
    return mnyMgr1.mnyMgrWF - mnyMgr2.mnyMgrWF;
  }
  getTpAdminDetails() {
    if (this.userTypeID === UserTypeIDEnum.RIA || this.userTypeID === UserTypeIDEnum.NB_TEAM) {
      this.showTpaActive = true;
      this.getTpAdminActive();
    }
    if (this.userTypeID === UserTypeIDEnum.TPA_TEAM || (this.userTypeID === UserTypeIDEnum.TPA && this.isAddMode)) {
      this.subscriptions.push(
        this.searchUserService.getTpAdmin().subscribe((firmData: TpAdminData[]) => {
          if (this.userTypeID === UserTypeIDEnum.TPA_TEAM) {
            this.tpaList = firmData;
            this.nwTpaList = this.tpaList.filter((tpa) => tpa.tpAdminNWAdmin === BinaryResponseEnum.YES);
            this.showTpAdminNwList = true;
          } else {
            let userTpaList = [];
            userTpaList = firmData.filter((tpa) => tpa.tpAdminNbr === this.loggedInUserTpa);
            this.tpaFirmName = userTpaList[0]?.tpAdminLabel;
            this.isTpaUser = true;
            this.selectedTPA = this.loggedInUserTpa;
            this.corePlanForm.controls.tpAdminNbr.setValue(this.loggedInUserTpa);
          }
        })
      );
    }
  }
  getTpAdminActive() {
    this.subscriptions.push(
      this.searchUserService.getTpAdminActive(BinaryResponseEnum.YES).subscribe((firmData: TpAdminData[]) => {
        this.tpaList = firmData;
      })
    );
  }
  getCoAdvisoryFirm() {
    this.subscriptions.push(
      this.planDetailsService.getCoAdvisoryFirm().subscribe((coAdvisoryData: CoAdvisoryFirm[]) => {
        this.coAdvisoryList = coAdvisoryData;
      })
    );
  }
  getAllProducts() {
    this.subscriptions.push(
      this.planDetailsService.getAllProducts(this.selectedMoneyMgrId).subscribe((productData: GetPlans) => {
        this.productsList = productData;
      })
    );
  }
  getAllPlanType() {
    this.subscriptions.push(
      this.planDetailsService.getAllPlanType().subscribe((planTypeData: GetPlans) => {
        this.planTypeList = planTypeData;
      })
    );
  }
  getMoneyMgrPgmOptions(mnyMgr) {
    if (mnyMgr != null) {
      this.subscriptions.push(
        this.planDetailsService
          .getMoneyMgrPgmOptions(this.selectedMoneyMgrId)
          .subscribe((mnyMgrData: MoneyMgrProgram) => {
            this.moneyMgrPgmDetails = mnyMgrData;
          })
      );
      this.selectedMMData = this.moneyManagerList?.filter(
        (mnyManager) => mnyManager.moneyManagerId.toString() === mnyMgr.toString()
      );
      this.showQdia = this.selectedMMData
        ? this.selectedMMData[0]?.showQdia === BinaryResponseEnum.YES
          ? true
          : false
        : false;
      if (this.isAddMode) {
        this.showProviderMapping =
          this.corePlanForm.value.planInfo === PlanInfoEnum.NEW_TAKEOVER &&
          this.selectedMMData[0]?.provMapDisplay === BinaryResponseEnum.YES
            ? true
            : false;
      } else {
        this.showProviderMapping = this.selectedMMData
          ? this.selectedMMData[0]?.provMapDisplay === BinaryResponseEnum.YES
            ? true
            : false
          : false;
      }

      this.showAutoMgmt = this.selectedMMData
        ? this.selectedMMData[0]?.merrAmDisplay === BinaryResponseEnum.YES
          ? true
          : false
        : false;
      this.showConversion =
        (this.selectedMMData ? this.selectedMMData[0]?.showConversion === BinaryResponseEnum.YES : false) &&
        (this.planDetailsData?.status === PlanStatusEnum.UNDER_MGMT_TRACK ||
          this.planDetailsData?.status === PlanStatusEnum.UNDER_MGMT_IK)
          ? true
          : false;
      this.showManagerMapping =
        (this.selectedMMData ? this.selectedMMData[0]?.merrPmDisplay === BinaryResponseEnum.YES : false) &&
        (this.planDetailsData?.status === PlanStatusEnum.UNDER_MGMT_TRACK ||
          this.planDetailsData?.status === PlanStatusEnum.UNDER_MGMT_IK)
          ? true
          : false;
    }
    if (this.showQdia && this.selectedMoneyMgrId !== MoneyManagerIDEnum.STADION_MONEY_MGMT) {
      this.corePlanForm.controls.qdia.setValidators([Validators.required]);
      this.corePlanForm.controls.qdia.updateValueAndValidity();
    } else {
      this.corePlanForm.controls.qdia.clearValidators();
      this.corePlanForm.controls.qdia.updateValueAndValidity();
    }
    if (this.showProviderMapping) {
      this.corePlanForm.controls.providerMapping.setValidators([Validators.required]);
      this.corePlanForm.controls.providerMapping.updateValueAndValidity();
    } else {
      this.corePlanForm.controls.providerMapping.clearValidators();
      this.corePlanForm.controls.providerMapping.updateValueAndValidity();
    }
    if (this.showAutoMgmt && this.selectedMoneyMgrId !== MoneyManagerIDEnum.STADION_MONEY_MGMT) {
      this.corePlanForm.controls.autoManagement.setValidators([Validators.required]);
      this.corePlanForm.controls.autoManagement.updateValueAndValidity();
    } else {
      this.corePlanForm.controls.autoManagement.clearValidators();
      this.corePlanForm.controls.autoManagement.updateValueAndValidity();
    }
    if (this.showConversion) {
      this.corePlanForm.controls.conversion.setValidators([Validators.required]);
      this.corePlanForm.controls.conversion.updateValueAndValidity();
    } else {
      this.corePlanForm.controls.conversion.clearValidators();
      this.corePlanForm.controls.conversion.updateValueAndValidity();
    }
    if (this.showManagerMapping) {
      this.corePlanForm.controls.managerMapping.setValidators([Validators.required]);
      this.corePlanForm.controls.managerMapping.updateValueAndValidity();
    } else {
      this.corePlanForm.controls.managerMapping.clearValidators();
      this.corePlanForm.controls.managerMapping.updateValueAndValidity();
    }
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.corePlanForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  get corePlanControl() {
    return this.corePlanForm.controls;
  }
  getRequiredInputErrorMessage(preText: string): string {
    return `Please Enter the ${preText}`;
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getRequiredDropdownErrorMessage(preText: string): string {
    return `Please Choose a ${preText}`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a Valid ${preText}`;
  }
  cancel(): void {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    if (!this.isAddMode) {
      this.router.navigate(['..'], { relativeTo: this.route });
    } else {
      this.router.navigate(['/home']);
    }
  }

  saveActivateInIk(): void {
    this.submitted = true;
    this.resetAlertMessages();
    this.loading = true;
    if (this.corePlanForm.valid) {
      this.corePlanRequestObject = {
        trackOrIk: RecordSystemEnum.TRACK_SYSTEM,
        moneyManagerId: +this.corePlanForm.value.mnyMgrId,
        planCaseNumber: this.corePlanForm.value.tpAdminNbr + '-' + this.corePlanForm.value.caseNumber,
      };
      this.planDetailsService.saveActivateTrackOrIk(this.corePlanRequestObject).subscribe(
        (savePlanResponse: PlanSummaryResponse) => {
          this.loading = false;          
          window.scroll(0, 0);        
          this.successMessage = { message: savePlanResponse.status, details: [] };
          this.errorMessage = { message: '', details: [] };
          setTimeout(() => {
            this.router.navigate(['..'], { relativeTo: this.route });
          }, 2000);
        },
        (error) => {
          this.loading = false;
          window.scroll(0, 0);
          if (error) {
            this.errorMessage = { message: error, details: [] };
          } else {
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        }
      );
    } else {
      window.scroll(0, 0);
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }    
    this.navigationStore.set('formChanged', false);
  }

  onSubmit(): void {
    this.submitted = true;
    this.resetAlertMessages();
    this.loading = true;
    if (this.corePlanForm.valid) {
      this.setCorePlanRequestDetails();
      if (this.isAddMode) {
        this.addCorePlanDetails();
      } else {
        this.editCorePlanDetails();
      }
    } else {
      window.scroll(0, 0);
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  setCorePlanRequestDetails(): void {
    this.corePlanRequestObject = {
      planNewToProviderNo: this.corePlanForm.value.planInfo,
      planCaseNumber: this.corePlanForm.value.tpAdminNbr + '-' + this.corePlanForm.value.caseNumber,
      providerProductId: +this.corePlanForm.value.providerProductId,
      planTypeId: +this.corePlanForm.value.planType,
      moneyManagerId: +this.corePlanForm.value.mnyMgrId,
      moneyManagerProgramCode: null,
      planAutoEnroll: this.corePlanForm.value.autoManagement,
      coadvisoryId: null,
      totalAssets: +this.corePlanForm.value.planAssets,
      tpAdminNbr: this.corePlanForm.value.tpAdminNbr,    
      stadionParticCode: this.corePlanForm.value.stadionPartipantCode,    
      planEin: this.corePlanForm.value.ein,
      planTTId: this.corePlanForm.value.trustTaxId,
      planNbr: +this.corePlanForm.value.planNumber,
      planLabel: this.corePlanForm.value.planName,
      planIsTMSActive: this.planIsTmsActive,
      planStatus: this.isAddMode
        ? BinaryResponseEnum.YES
        : this.planDetailsData?.planStatus
        ? this.planDetailsData?.planStatus
        : BinaryResponseEnum.NO,
      planArchived: this.isAddMode
        ? BinaryResponseEnum.YES
        : this.planDetailsData?.planArchived
        ? this.planDetailsData?.planArchived
        : BinaryResponseEnum.NO,
      lastUpdatedUserId: this.loggedInUserId,
      trackOrIk: this.corePlanForm.value.recordSystem ? 'IK' : 'TRAC',
      custodianOrTrustee: this.corePlanForm.value.custodianOrTrustee,
      planParticMap: this.planDetailsData?.planParticMap ? this.planDetailsData?.planParticMap : 0,
    };
    this.setBinaryValuesForForm(this.corePlanForm);
    if (
      this.corePlanRequestObject.moneyManagerId === MoneyManagerIDEnum.CLS_INVESTMENTS ||
      this.corePlanRequestObject.moneyManagerId === MoneyManagerIDEnum.PORTFOLIO_STRATEGIES ||
      this.corePlanRequestObject.moneyManagerId === MoneyManagerIDEnum.MEEDER_ADVISORY_SERVICES
    ) {
      this.corePlanRequestObject.moneyManagerProgramCode = this.corePlanForm.value.mnyMgrProgramCode;
      if (this.corePlanRequestObject.moneyManagerId === MoneyManagerIDEnum.CLS_INVESTMENTS) {
        this.corePlanRequestObject.coadvisoryId = +this.corePlanForm.value.solicitorFirm;
      }
    }
  }

  addCorePlanDetails(): void {
    this.planDetailsService.saveCreateCorePlanInfo(this.corePlanRequestObject).subscribe(
      (savePlanResponse: PlanSummaryResponse) => {
        this.loading = false;
        this.saveResponsePlanId = savePlanResponse.planId;
        if (this.saveResponsePlanId) {
          this.communicationService.getPlanStepsNavigation(this.saveResponsePlanId, this.isPending);
        }
        this.errorMessage = { message: '', details: [] };
        window.scroll(0, 0);
        this.successMessage = { message: savePlanResponse.status, details: [] };
      },
      (error) => {
        this.loading = false;
        window.scroll(0, 0);
        this.successMessage = { message: '', details: [] };
        if (error) {
          this.errorMessage = { message: error, details: [] };
        } else {
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      }
    );
    this.navigationStore.set('formChanged', false);
  }


  editCorePlanDetails(): void {
    this.planDetailsService.saveEditCorePlanInfo(this.corePlanRequestObject, this.planId).subscribe(
      (savePlanResponse: PendingPlanResponse) => {        
        window.scroll(0, 0);
        this.loading = false;        
        this.successMessage = { message: savePlanResponse.message, details: [] };
        this.isPending = savePlanResponse.newPendingPlanCreated;
        this.communicationService.setIsPendingForNewPendingPlan(savePlanResponse);      
        this.errorMessage = { message: '', details: [] };
        this.communicationService.getPlanStepsNavigation(this.planId, this.isPending, true);
      },
      (error) => {
        this.loading = false;
        window.scroll(0, 0);
        this.successMessage = { message: '', details: [] };
        if (error) {
          this.errorMessage = { message: error, details: [] };
        } else {
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      }
    );
    this.navigationStore.set('formChanged', false);
  }
  resetAlertMessages() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.editPlanInfo = { message: '', details: [] };
  }

  setBinaryValuesForForm(form: UntypedFormGroup): void {
    this.corePlanRequestObject.qdiaYn = form.value.qdia ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.conversionYn = form.value.conversion ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.planParticMap = form.value.managerMapping ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.providerMapping = form.value.providerMapping ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.planPrintAccess = form.value.isPrintAccess ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.mgrPrintAccess = form.value.isPrintEnrollment ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.merrillAccess =  form.value.isMerillAccess ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.stadionCoreFund = form.value.isCoreFundStrategy ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.isTrackActive = form.value.recordSystem ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
    this.corePlanRequestObject.storyLineOption = form.value.stadionPlanOption ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
  }

  setCustodianValidation(): void {
    if (!this.hideCustodianORTrustee) {
      this.corePlanForm.controls.custodianOrTrustee.setValidators([Validators.required]);
      this.corePlanForm.controls.custodianOrTrustee.updateValueAndValidity();
    } else {
      this.corePlanForm.controls.custodianOrTrustee.setValue(null);
      this.corePlanForm.controls.custodianOrTrustee.clearValidators();
      this.corePlanForm.controls.custodianOrTrustee.updateValueAndValidity();
    } 
  }

  clearPending(): void {
    this.planDetailsService.clearPendingPlan(this.planId).subscribe(
      (savePlanResponse: PlanSummaryResponse) => {
        this.loading = false;
        this.isPending = false;
        sessionStorage.removeItem('isPending');
        this.communicationService.setPendingStatus(false);
        this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
        this.errorMessage = { message: '', details: [] };
        window.scroll(0, 0);
        this.successMessage = { message: savePlanResponse.status, details: [] };
      },
      (error) => {
        this.loading = false;
        window.scroll(0, 0);
        this.successMessage = { message: '', details: [] };
        if (error) {
          this.errorMessage = { message: error, details: [] };
        } else {
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      }
    );
    this.navigationStore.set('formChanged', false);
  }

  activatePending(): void {
    this.planDetailsService.activatePendingPlan(this.planId,this.userEmail).subscribe(
      (savePlanResponse: PlanSummaryResponse) => {
        this.loading = false;        
        this.successMessage = { message: savePlanResponse.status, details: [] };
        this.clearPending();
        this.errorMessage = { message: '', details: [] };
        window.scroll(0, 0);
      },
      (error) => {
        this.loading = false;
        window.scroll(0, 0);
        this.successMessage = { message: '', details: [] };
        if (error) {
          this.errorMessage = { message: error, details: [] };
        } else {
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      }
    );
    this.navigationStore.set('formChanged', false);
  }

  pendingButtonClick(action: string): void {
    this.pendingChangesModel.showClsModal = true;
    if(action === 'clear') {
      this.pendingChangesModel.message = modalMessage.PlAN_CLEAR_PENDING_CHANGES;
    } else if(action === 'activate') {
      this.pendingChangesModel.message = modalMessage.PlAN_ACTIVATE_PENDING_CHANGES;
    }
  }

  noBtn(): void {
    this.pendingChangesModel.showClsModal = false;
  }

  yesBtn(): void {
    this.pendingChangesModel.showClsModal = false;
    if(this.pendingChangesModel.message === modalMessage.PlAN_CLEAR_PENDING_CHANGES) {
      this.clearPending();
    } else if(this.pendingChangesModel.message === modalMessage.PlAN_ACTIVATE_PENDING_CHANGES) {
      this.activatePending();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
