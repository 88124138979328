<section class="container-fluid section-login" role="main" xmlns="http://www.w3.org/1999/html">
  <div class="mb-5">Please login to access the MAS Retail Enrollment System.</div>
  <div class="row">
    <div class="col-12" style="alignment: left; -ms-flex-align: baseline;">
      <form [formGroup]="loginForm" (ngSubmit)="onClickSubmit()" class="form-signin">
        <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
          <nfrp-alert alertId="login-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
        </div>
        <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
          <nfrp-alert alertId="login-error" [message]="errorMessage" type="error" [page]="false"></nfrp-alert>
        </div>
        <div id="userID" class="userDetails">
          <span class="detailTitle">Username:</span>
          <input
            type="text"
            #input
            id="useremail"
            class="form-control userDetailInput"
            [pattern]="emailPattern"
            formControlName="userEmail"
            placeholder="User Name"
            required
          />
          <div class="inline-error mt-minus-10">
            <nfrp-inline-error
              *ngIf="(loginFormControl.userEmail.touched || submitted) && loginFormControl.userEmail.errors?.required"
              errorId="username-required-error"
              [message]="'Username is required'"
            >
            </nfrp-inline-error>
            <nfrp-inline-error
              *ngIf="loginFormControl.userEmail.touched && loginFormControl.userEmail.errors?.pattern"
              errorId="username-required-error"
              [message]="'Enter a valid email address'"
            >
            </nfrp-inline-error>
          </div>
        </div>
        <div id="FirstName" class="userDetails">
          <span class="detailTitle">Password:</span>
          <input
            type="password"
            id="inputPassword"
            class="form-control userDetailInput"
            formControlName="token"
            placeholder="Password"
            required
          />
          <div class="inline-error mt-minus-10">
            <nfrp-inline-error
              *ngIf="(loginFormControl.token.touched || submitted) && loginFormControl.token.errors?.required"
              errorId="password-required-error"
              [message]="'Password is required'"
            >
            </nfrp-inline-error>
          </div>
        </div>
        <div class="col-sm-1 no-padding search-button">
          <input id="login-button" class="button-primary button hover-right margin-right" type="submit" size="40" value="Login" />
        </div>
        <div><a id="forgot-password-link" [routerLink]="['/forgot-password']" target="_self" class="subLink">Forgot Password</a></div>
      </form>
    </div>
  </div>
</section>
