import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { EkpResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/ekp-detail';
import { CcLandingService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/cc-landing.service';
import { alertMessages } from 'src/app/helpers/common-constants';

@Component({
  selector: 'ekp-print-doc',
  templateUrl: './ekp-print-doc.component.html',
  styleUrls: ['./ekp-print-doc.component.scss'],
})
export class EkpPrintDocComponent implements OnInit {
  planNo: string;
  errorMessage: NfrpAlertMessage;
  trackInfo: any;
  ekpResponse: EkpResponse;
  infoMessage: NfrpAlertMessage;
  loading = false;
  private subscriptions = [];
  private planNumber: string;
  constructor(private route: ActivatedRoute, private ccLandingService: CcLandingService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.planNo = params.planNo;
    });
    this.getEKPDetails();
  }
  getEKPDetails() {
    this.loading = true;
    this.subscriptions.push(
      this.ccLandingService.getEkpPlanDetails(this.planNo).subscribe(
        (data) => {
          this.loading = false;
          this.trackInfo = data?.trackFormDetails;
          this.ekpResponse = data;
          this.planNumber = data.planNumber;
          sessionStorage.setItem('planNumber', JSON.stringify(this.planNumber));
          if (this.trackInfo != null) {
            this.infoMessage = {
              message:
                'Please download and save the following documents to your hardrive. You can generate new documents in the Create Booklet Documents section.',
              details: [],
            };
          } else {
            this.infoMessage = { message: 'There are no documents to generate for this plan', details: [] };
          }
          this.errorMessage = { message: '', details: [] };
          window.scroll(0, 0);
        },
        (error: HttpErrorResponse) => {
          if (error?.error?.status) {
            this.errorMessage = { message: error?.error?.status, details: [] };
          } else {
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        }
      )
    );
  }
}
