<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="
            (planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1
          "
          ><i class="nw-icon-check f-green"></i
        ></span>
        &ensp;{{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }}) &ensp;
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="plan-fee-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="plan-fee-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <div>
        <form [formGroup]="planSetupChecklistForm">
          <ul
            id="plan-setup-checklist-list"
            summary="Plan Setup Checklist"
            class="ul-li-view-edit table stripes no-margin"
          >
            <li class="head">
              <div class="col-sm-12 padding-left">
                <b>Plan Setup Checklist</b>
              </div>
            </li>
            <li *ngIf="revisionToActive && getPlanRevisionChecklistResultData">
              <div class="col-sm-12">
                <ul
                  id="representative-params"
                  summary="employee sponsor"
                  class="ul-li-view-edit table stripes no-margin width-100"
                >
                  <li class="head">
                    <div class="col-sm-12 padding-left">
                      <b>Revisions to Active Plan</b>
                    </div>
                  </li>
                  <li class="no-padding">
                    <div class="col-sm-12 no-padding">
                      <ul
                        id="representative-params"
                        summary="employee sponsor"
                        class="ul-li-view-edit table stripes no-margin width-100 border-none border-top"
                      >
                        <li>
                          <div class="col-sm-6">
                            <div class="col-sm-6 padding-left">Manager Change</div>
                            <div class="col-sm-6 padding-left">
                              <div class="form-group">
                                <label for="manager-change" id="manager-change-label" class="checkbox-inline">
                                  <input
                                    type="checkbox"
                                    formControlName="mgrChgInit"
                                    class="ng-pristine ng-untouched ng-valid ng-not-empty"
                                    id="manager-change"
                                    [checked]="false"
                                    [attr.disabled]="
                                      planSetupChecklistForm.value.mgrChgInit && planSetupChecklistForm.value.mgrChgCon
                                        ? true
                                        : null
                                    "
                                    (change)="toRevisionCheckEnable($event, 'mgrChgInit')"
                                  />
                                  <img
                                    src="assets/flag_mark_green.gif"
                                    *ngIf="
                                      planSetupChecklistForm.value.mgrChgInit && planSetupChecklistForm.value.mgrChgCon
                                    "
                                  />

                                  <img
                                    src="assets/flag_mark_red.gif"
                                    *ngIf="
                                      getPlanRevisionChecklistResultData.mgrChgInit &&
                                      !planSetupChecklistForm.value.mgrChgCon
                                    "
                                  />
                                  {{ getPlanRevisionChecklistResultData.mgrChgInit | checkListName }}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="col-sm-6 padding-left">Fee Change</div>
                            <div class="col-sm-6 padding-left">
                              <div class="form-group">
                                <label for="fee-change" id="fee-change-label" class="checkbox-inline">
                                  <input
                                    type="checkbox"
                                    formControlName="feeChgInit"
                                    class="ng-pristine ng-untouched ng-valid ng-not-empty"
                                    id="fee-change"
                                    [attr.disabled]="
                                      planSetupChecklistForm.value.feeChgInit && planSetupChecklistForm.value.feeChgCon
                                        ? true
                                        : null
                                    "
                                    (change)="toRevisionCheckEnable($event, 'feeChgInit')"
                                  />
                                  <img
                                    src="assets/flag_mark_green.gif"
                                    *ngIf="
                                      planSetupChecklistForm.value.feeChgInit && planSetupChecklistForm.value.feeChgCon
                                    "
                                  />

                                  <img
                                    src="assets/flag_mark_red.gif"
                                    *ngIf="
                                      getPlanRevisionChecklistResultData.feeChgInit &&
                                      !planSetupChecklistForm.value.feeChgCon
                                    "
                                  />
                                  {{ getPlanRevisionChecklistResultData.feeChgInit | checkListName }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="col-sm-6">
                            <div class="col-sm-6 padding-left">Rep Change</div>
                            <div class="col-sm-6 padding-left">
                              <div class="form-group">
                                <label for="rep-change" id="rep-change-label" class="checkbox-inline">
                                  <input
                                    type="checkbox"
                                    class="ng-pristine ng-untouched ng-valid ng-not-empty"
                                    id="rep-change"
                                    [attr.disabled]="
                                      planSetupChecklistForm.value.repChgInit && planSetupChecklistForm.value.repChgCon
                                        ? true
                                        : null
                                    "
                                    (change)="toRevisionCheckEnable($event, 'repChgInit')"
                                    formControlName="repChgInit"
                                  />
                                  <img
                                    src="assets/flag_mark_green.gif"
                                    *ngIf="
                                      planSetupChecklistForm.value.repChgInit && planSetupChecklistForm.value.repChgCon
                                    "
                                  />

                                  <img
                                    src="assets/flag_mark_red.gif"
                                    *ngIf="
                                      getPlanRevisionChecklistResultData.repChgInit &&
                                      !planSetupChecklistForm.value.repChgCon
                                    "
                                  />
                                  {{ getPlanRevisionChecklistResultData.repChgInit | checkListName }}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="col-sm-6 padding-left">Solicitor Change</div>
                            <div class="col-sm-6 padding-left">
                              <div class="form-group">
                                <label for="solicitor-change" id="solicitor-change-label" class="checkbox-inline">
                                  <input
                                    type="checkbox"
                                    class="ng-pristine ng-untouched ng-valid ng-not-empty"
                                    id="solicitor-change"
                                    [attr.disabled]="
                                      planSetupChecklistForm.value.solChgInit && planSetupChecklistForm.value.solChgCon
                                        ? true
                                        : null
                                    "
                                    (change)="toRevisionCheckEnable($event, 'solChgInit')"
                                    formControlName="solChgInit"
                                  />
                                  <img
                                    src="assets/flag_mark_green.gif"
                                    *ngIf="
                                      planSetupChecklistForm.value.solChgInit && planSetupChecklistForm.value.solChgCon
                                    "
                                  />

                                  <img
                                    src="assets/flag_mark_red.gif"
                                    *ngIf="
                                      getPlanRevisionChecklistResultData.solChgInit &&
                                      !planSetupChecklistForm.value.solChgCon
                                    "
                                  />
                                  {{ getPlanRevisionChecklistResultData.solChgInit | checkListName }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="col-sm-12">
                            <div class="col-sm-3 padding-left">Other</div>
                            <div class="col-sm-1 padding-left">
                              <div class="form-group margin-left-5px">
                                <label for="others" id="others-label" class="checkbox-inline">
                                  <input
                                    type="checkbox"
                                    class="ng-pristine ng-untouched ng-valid ng-not-empty"
                                    id="others"
                                    [attr.disabled]="
                                      planSetupChecklistForm.value.otherChgInit &&
                                      planSetupChecklistForm.value.otherChgCon
                                        ? true
                                        : null
                                    "
                                    (change)="toRevisionCheckEnable($event, 'otherChgInit')"
                                    formControlName="otherChgInit"
                                  />
                                </label>
                              </div>
                            </div>
                            <div class="col-sm-5 padding-left">
                              <input
                                type="text"
                                id="others-text-box"
                                class="width-100"
                                name="others-text-box"
                                formControlName="otherChgText"
                              />
                              <img
                                src="assets/flag_mark_green.gif"
                                *ngIf="
                                  planSetupChecklistForm.value.otherChgInit && planSetupChecklistForm.value.otherChgCon
                                "
                              />

                              <img
                                src="assets/flag_mark_red.gif"
                                *ngIf="
                                  getPlanRevisionChecklistResultData.otherChgInit &&
                                  !planSetupChecklistForm.value.otherChgCon
                                "
                              />
                              {{ getPlanRevisionChecklistResultData.otherChgInit | checkListName }}
                            </div>
                            <div class="col-sm-3 padding-left"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li [ngClass]="{ block: !getPlanRevisionChecklistResult || !moneyManagerList }">
              <ng-container *ngIf="getPlanRevisionChecklistResult && moneyManagerList; else checklistLoading">
                <div class="col-sm-6">
                  <ul
                    id="representative-params"
                    summary="employee sponsor"
                    class="ul-li-view-edit table stripes no-margin width-100"
                  >
                    <li class="head">
                      <div class="col-sm-12 padding-left">
                        <b>Document Verification</b>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">Money Manager</div>
                      <div class="col-sm-6 padding-left flex-column">
                        <select
                          name="moneyManagerId"
                          id="moneyManagerId"
                          formControlName="moneyManagerId"
                          [ngClass]="{
                            'has-error': isControlInvalid('moneyManagerId')
                          }"
                        >
                          <option [ngValue]="null">--- Select from list ---</option>
                          <option *ngFor="let moneyManager of moneyManagerList" [value]="moneyManager.moneyManagerId">
                            {{ moneyManager.moneyManagerLabel }}
                          </option>
                        </select>
                        <nfrp-inline-error
                          *ngIf="
                            (planSetupChecklistControl.moneyManagerId.touched || submitted) &&
                            planSetupChecklistControl.moneyManagerId.errors?.required
                          "
                          errorId="plan-setup-checklist-mnymgrid-valid-error"
                          [message]="getRequiredDropdownErrorMessage('Money Manager')"
                        >
                        </nfrp-inline-error>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">
                        Emailed Documents
                        <nfrp-help-icon
                          message="Emailed Documents for Manager Signature"
                          idLabel="emailed-doc-level"
                        ></nfrp-help-icon>
                      </div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="emailed-documents" id="emailed-documents-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="emailed-documents"
                              formControlName="emailedDocuments"
                            />
                            {{ planSetupChecklistForm.value.emailedDocuments | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">
                        NWPA Approved
                        <nfrp-help-icon
                          message="National Program Agreement Has Been Approved"
                          idLabel="national-pgm-agreement"
                        ></nfrp-help-icon>
                      </div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="nwpa-approved" id="nwpa-approved-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="nwpa-approved"
                              formControlName="nwpaApproved"
                            />
                            {{ planSetupChecklistForm.value.nwpaApproved | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">
                        Signed PSA
                        <nfrp-help-icon
                          message="Signed Plan Sponsor Agreement Received"
                          idLabel="signed-plan-agreement"
                        ></nfrp-help-icon>
                      </div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="signed-psa" id="signed-psa-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="signed-psa"
                              formControlName="signedPSA"
                            />
                            {{ planSetupChecklistForm.value.signedPSA | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">Signed Schedule of Fees</div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label
                            for="signed-schedule-of-fees"
                            id="signed-schedule-of-fees-label"
                            class="checkbox-inline"
                          >
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="signed-schedule-of-fees"
                              formControlName="signedSOF"
                            />
                            {{ planSetupChecklistForm.value.signedSOF | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">Solicitor & Rep Verified</div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="solicitor-rep-verified" id="solicitor-rep-verified-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="solicitor-rep-verified"
                              formControlName="solRepVerified"
                            />
                            {{ planSetupChecklistForm.value.solRepVerified | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">Automatic Management</div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="automatic-management" id="automatic-management-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="automatic-management"
                              formControlName="autoManagement"
                            />
                            {{ planSetupChecklistForm.value.autoManagement | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">Participant Mapping</div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="participant-mapping" id="participant-mapping-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="participant-mapping"
                              formControlName="particMapping"
                            />
                            {{ planSetupChecklistForm.value.particMapping | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-sm-6">
                  <ul
                    id="representative-params"
                    summary="employee sponsor"
                    class="ul-li-view-edit table stripes no-margin width-100"
                  >
                    <li class="head">
                      <div class="col-sm-12 padding-left">
                        <b>TMS Verification</b>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">Fees Verified</div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="fees-entered" id="fees-entered-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="fees-entered"
                              formControlName="feesEntered"
                            />
                            {{ planSetupChecklistForm.value.feesEntered | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">Funds Entered</div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="funds-entered" id="funds-entered-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="funds-entered"
                              formControlName="fundsEntered"
                            />
                            {{ planSetupChecklistForm.value.fundsEntered | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-sm-6 padding-left">Activated in TMS</div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="activated-in-tms" id="activated-in-tms-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="activated-in-tms"
                              formControlName="activatedTMS"
                            />
                            {{ planSetupChecklistForm.value.activatedTMS | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                    <!-- <li>
                      <div class="col-sm-6 padding-left">
                        Restricted Fund Letter
                        <nfrp-help-icon message="Restricted Fund Letter Emailed" idLabel="fund-letter"></nfrp-help-icon>
                      </div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="restricted-fund-letter" id="restricted-fund-letter-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="restricted-fund-letter"
                              formControlName="restrictedFundLetter"
                            />
                            {{ planSetupChecklistForm.value.restrictedFundLetter | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li> -->
                    <li>
                      <div class="col-sm-6 padding-left">
                        Activation Emailed
                        <nfrp-help-icon
                          message="Activation Emailed to Manager"
                          idLabel="activation-letter"
                        ></nfrp-help-icon>
                      </div>
                      <div class="col-sm-6 padding-left">
                        <div class="form-group">
                          <label for="activation-emailed" id="activation-emailed-label" class="checkbox-inline">
                            <input
                              type="checkbox"
                              class="ng-pristine ng-untouched ng-valid ng-not-empty"
                              id="activation-emailed"
                              formControlName="activationEmailed"
                            />
                            {{ planSetupChecklistForm.value.activationEmailed | checkListName }}
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </li>
          </ul>
          <ul
            id="revision-history-list"
            summary="Revision History"
            *ngIf="revisionHistory && getHistoryObj"
            class="ul-li-view-edit table stripes margin-top"
          >
            <li class="head">
              <div class="col-sm-12 padding-left">
                <b>Revision History</b>
              </div>
            </li>
            <li>
              <div class="col-sm-12 flex-column">
                <div
                  *ngFor="let historyObj of getHistoryObj | orderReversedBy: 'planRevisionTrackingId'; let i = index"
                  class="box summary compartment custom_accordian_style animation-fade-in"
                  role="tablist"
                >
                  <div class="section__header" (click)="toggleAccordian(i)">
                    <span class="toggle-plus" *ngIf="selectedAccordianTab === i; else togglePlus">-</span>
                    <ng-template #togglePlus>
                      <span class="toggle-plus">+</span>
                    </ng-template>
                    {{ historyObj.mnyMgrLbl }} Completed On: {{ historyObj.revisionCommittedDate }} &nbsp; &nbsp; &nbsp;
                    By:{{ historyObj.revisionCommittedBy }}
                  </div>
                  <div class="content collapse no-screen" [ngClass]="{ isDisplayBlock: selectedAccordianTab === i }">
                    <p>The following revisions were completed on {{ historyObj.revisionCommittedDate }}</p>
                    <ul>
                      <li *ngIf="historyObj.mgrChgInit && historyObj.mgrChgCon">Manager Change</li>
                      <li *ngIf="historyObj.feeChgInit && historyObj.feeChgCon">Fee Change</li>
                      <li *ngIf="historyObj.repChgInit && historyObj.repChgCon">Rep Change</li>
                      <li *ngIf="historyObj.solChgInit && historyObj.solChgCon">Solicitor Change</li>
                      <li *ngIf="historyObj.otherChgInit && historyObj.otherChgCon">
                        Other
                        <span *ngIf="historyObj.otherChgText">&nbsp; - &nbsp; {{ historyObj.otherChgText }}</span>
                      </li>
                    </ul>

                    <ul
                      id="representative-params"
                      summary="employee sponsor"
                      class="ul-li-view-edit table stripes no-margin width-100"
                    >
                      <li>
                        <div class="col-sm-6 padding-left">Emailed Documents for Manager Signature:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.emailedDocuments }}</div>
                      </li>
                      <li>
                        <div class="col-sm-6 padding-left">Nationwide Program Agreement Approved:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.nwpaApproved }}</div>
                      </li>
                      <li>
                        <div class="col-sm-6 padding-left">Signed Plan Sponsor Agreement:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.signedPSA }}</div>
                      </li>
                      <li>
                        <div class="col-sm-6 padding-left">Signed Schedule of Fees:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.signedSOF }}</div>
                      </li>
                      <li>
                        <div class="col-sm-6 padding-left">Solicitor & Rep Verified:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.solRepVerified }}</div>
                      </li>
                      <li>
                        <div class="col-sm-6 padding-left">Auto Management/Participant Mapping:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.particMapping }}</div>
                      </li>
                      <li>
                        <div class="col-sm-6 padding-left">Fees Entered:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.feesEntered }}</div>
                      </li>
                      <li>
                        <div class="col-sm-6 padding-left">Funds Entered:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.fundsEntered }}</div>
                      </li>
                      <li>
                        <div class="col-sm-6 padding-left">Activated in TMS:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.activatedTMS }}</div>
                      </li>
                      <!-- <li>
                        <div class="col-sm-6 padding-left">Restricted Fund letter Emailed:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.restrictedFundLetter }}</div>
                      </li> -->
                      <li>
                        <div class="col-sm-6 padding-left">Activation Emailed to Manager:</div>
                        <div class="col-sm-6 padding-left">{{ historyObj.activationEmailed }}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul
            id="checklist-button-list"
            summary="checklist-button"
            class="ul-li-view-edit table stripes margin-top-2rem border-none"
          >
            <li class="background-none no-padding">
              <div class="col-sm-3 no-padding">
                <input
                  id="search-button"
                  class="button-primary button hover-left margin-left"
                  type="submit"
                  size="40"
                  (click)="onSaveRevisions()"
                  value="Save Revisions To Plan"
                />
              </div>
              <div class="col-sm-2 no-padding"></div>
              <div class="col-sm-3 no-padding">
                <input
                  id="search-button"
                  class="button button-secondary hover-left margin-left"
                  type="submit"
                  size="40"
                  (click)="onCommitRevisions()"
                  value="Commit Revisions To History"
                />
              </div>
              <div class="col-sm-1 no-padding"></div>
              <div class="col-sm-3 no-padding flex-direction-row-reverse">
                <input
                  type="button"
                  class="button-primary button hover-right margin-right"
                  (click)="onDeleteCurrent()"
                  value="Delete Current Revisions"
                />
              </div>
            </li>
          </ul>
        </form>
        <ng-template #checklistLoading>
          <nfrp-spinner></nfrp-spinner>
        </ng-template>
      </div>
      <ng-container *ngIf="loading">
        <nfrp-spinner></nfrp-spinner>
      </ng-container>
    </div>
  </div>
</section>

<nfrp-navigate-away-modal></nfrp-navigate-away-modal>

<nfrp-default-modal
  [header]="modalOptions.header"
  [message]="modalOptions.message"
  [noButtonValue]="modalOptions.noButtonValue"
  [yesButtonValue]="modalOptions.yesButtonValue"
  [showModal]="modalOptions.showModal"
  (noClicked)="noClicked()"
  (yesClicked)="yesClicked()"
>
</nfrp-default-modal>
