import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NfrpAlertMessage, NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { SysMsgResponse } from '../../model/system-msg';
import { CcLandingService } from '../../services/cc-landing.service';
import { FooterFeaturesService } from '../../services/footer-features.service';

@Component({
  selector: 'system-messages',
  templateUrl: './system-messages.component.html',
  styleUrls: ['./system-messages.component.scss'],
})
export class SystemMessagesComponent extends NfrpDefaultGridComponent<SysMsgResponse> implements OnInit, OnDestroy {
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  dataSource: SysMsgResponse[];
  loading = false;
  userEmail: string;
  subscriptions = [];
  addSystemMsgForm: UntypedFormGroup;
  formSubscription: Subscription;
  numberOfItems: number;
  msgId: number;
  submitted = false;
  constructor(
    private footerFeaturesService: FooterFeaturesService,
    public router: Router,
    nfrpGridService: NfrpGridService<SysMsgResponse>,
    private ccLandingService: CcLandingService
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.SYSTEM_MESSAGES, this.userEmail).subscribe()
    );
    this.getSystemMsgs();
  }
  rowDataClicked(data) {
    this.router.navigate(['sysMessages/editSysMg', data.id]);
  }
  getSystemMsgs() {
    this.subscriptions.push(
      this.ccLandingService
        .getSystemMsgDetails()
        .pipe(
          tap((data) => {
            this.dataSource = data?.messageResponseList;
            this.numberOfItems = this.dataSource?.length;
            if (this.dataSource) {
              this.setupGrid();
              this.paginate({
                limit: 5,
                offset: 1,
              });
            }
          })
        )
        .subscribe()
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
