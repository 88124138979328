import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { statesList, validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { UserResponse } from '../../../model/edit-user';
import { MoneyManager, MoneyManagerData } from '../../../model/money-manager-data';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { SearchUsersService } from '../../../services/search-users.service';

@Component({
  selector: 'edit-money-manager',
  templateUrl: './edit-money-manager.component.html',
  styleUrls: ['./edit-money-manager.component.css'],
})
export class EditMoneyManagerComponent implements OnInit, OnDestroy {
  moneyManagerMode: string;
  showDeactivateModal = false;
  mnyMgrStates = statesList;
  moneyManagerId: string;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  showModal = false;
  submitted = false;
  moneyMgrInfo: MoneyManager;
  moneyManagerRequestObj: MoneyManagerData;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  userEmail: string;
  mnyMgrForm: UntypedFormGroup;
  formSubscription: Subscription;
  private subscriptions = [];

  constructor(
    private searchUsersService: SearchUsersService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private footerFeaturesService: FooterFeaturesService,
    private navigationStore: NavigationStore
  ) {}

  isControlInvalid(controlName: string): boolean {
    const control = this.mnyMgrForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  get moneyManagerControl() {
    return this.mnyMgrForm.controls;
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.MNY_MGR_MGMT, this.userEmail).subscribe()
    );
    this.mnyMgrForm = this.fb.group({
      moneyManagerNumber: [
        null,
        [Validators.required,Validators.maxLength(8), Validators.pattern(validationPatterns.alphaNumericPattern)],
      ],
      nwId: [
        null,
        [Validators.required, Validators.maxLength(20), Validators.pattern(validationPatterns.numberPattern)],
      ],
      moneyManagerName: [
        null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern),
        ],
      ],
      firstName: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern)]],
      lastName: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern)]],
      address1: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      address2: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      city: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumericPattern)]],
      state: [''],
      zip: [null, [Validators.maxLength(10)]],
      phone: [null, [Validators.maxLength(30)]],
      fax: [null, [Validators.maxLength(30)]],
      email: [null, [Validators.maxLength(255), Validators.pattern(validationPatterns.emailPattern)]],
      contact: [null, [Validators.maxLength(255)]],
      active: [null],
      walledOff: [null],
      feeInvoicePrefix: [null, [Validators.maxLength(10), Validators.pattern(validationPatterns.alphaNumericPattern)]],
      feeInvoiceStart: [null, [Validators.maxLength(11), Validators.pattern(validationPatterns.numberPattern)]],
      feeInvoiceMessage: [
        null,
        [Validators.maxLength(2000), Validators.pattern(validationPatterns.alphaNumericPattern)],
      ],
      feeReportAccess: [null],
      feeMessageAccess: [null],
      allowToPrintDocuments: [null],
      enrollmentBookletAccess: [null],
      overridePlanLevelPrintAccess: [null],
      showConversion: [null, [Validators.required]],
      showQDIA: [null, [Validators.required]],
      merrillAutoMgmt: [null, [Validators.required]],
      merrillParticipantMap: [null, [Validators.required]],
      provideMapping: [null, [Validators.required]],
    });
    this.formSubscription = this.mnyMgrForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    this.route.params.subscribe((params) => {
      this.moneyManagerId = params.mnyMgrId;
      this.searchUsersService.getMoneyManagerWithId(this.moneyManagerId).subscribe((moneyManger: MoneyManager) => {
        this.moneyMgrInfo = moneyManger;
        this.setMoneyManagerData(moneyManger);
      },
      (error) => {
        this.errorMessage = { message: error, details : [] };
      })
    });
  }
  setMoneyManagerData(mnyManagerData: MoneyManager): void {
    const moneyMgrData = mnyManagerData;
    this.mnyMgrForm.patchValue({
      moneyManagerNumber: moneyMgrData.moneyManagerNbr,
      nwId: moneyMgrData.moneyManagerId,
      moneyManagerName: moneyMgrData.moneyManagerLabel,
      firstName: moneyMgrData.firstName,
      lastName: moneyMgrData.lastName,
      address1: moneyMgrData.addressA,
      address2: moneyMgrData.addressB,
      city: moneyMgrData.city,
      state: moneyMgrData.state,
      zip: moneyMgrData.zip,
      phone: moneyMgrData.phone,
      fax: moneyMgrData.fax,
      email: moneyMgrData.email,
      contact: moneyMgrData.contact,
      active: moneyMgrData.active ? true : false,
      walledOff: moneyMgrData.wallOff ? true : false,
      feeInvoicePrefix: moneyMgrData.feeInvPrefix,
      feeInvoiceStart: moneyMgrData.feeInvStartNbr,
      feeInvoiceMessage: moneyMgrData.feeInvMsg,
      feeReportAccess: moneyMgrData.feeRptAccess ? true : false,
      feeMessageAccess: moneyMgrData.feeMsgAccess ? true : false,
      allowToPrintDocuments: moneyMgrData.printAccess ? true : false,
      enrollmentBookletAccess: moneyMgrData.bookletDocsYn ? true : false,
      overridePlanLevelPrintAccess: moneyMgrData.printOverride ? true : false,
      showConversion: moneyMgrData.showConversion ? true : false,
      showQDIA: moneyMgrData.showQdia ? true : false,
      merrillAutoMgmt: moneyMgrData.merrAmDisplay ? true : false,
      merrillParticipantMap: moneyMgrData.merrPmDisplay ? true : false,
      provideMapping: moneyMgrData.provMapDisplay ? true : false,
    });
    this.navigationStore.set('formChanged', false);
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }
  cancel() {
    this.router.navigate(['/mnyMgrMgmt']);
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.showModal = false;
  }
  setAllExistingPlanClicked() {
    this.showModal = true;
  }
  onSubmit(): void {
    this.submitted = true;
    this.resetAlertMessages();
    if (this.mnyMgrForm.valid) {
      const moneyManagerRequestObj: MoneyManager = {
        moneyManagerNbr: this.mnyMgrForm.getRawValue().moneyManagerNumber,
        moneyManagerId: this.mnyMgrForm.getRawValue().nwId,
        moneyManagerLabel: this.mnyMgrForm.value.moneyManagerName,
        firstName: this.mnyMgrForm.value.firstName,
        lastName: this.mnyMgrForm.value.lastName,
        addressA: this.mnyMgrForm.value.address1,
        addressB: this.mnyMgrForm.value.address2,
        city: this.mnyMgrForm.value.city,
        state: this.mnyMgrForm.value.state,
        zip: this.mnyMgrForm.value.zip,
        phone: this.mnyMgrForm.value.phone,
        fax: this.mnyMgrForm.value.fax,
        email: this.mnyMgrForm.value.email,
        contact: this.mnyMgrForm.value.contact,
        active: this.checkMoneyManagerObj(this.mnyMgrForm.value.active),
        wallOff: this.checkMoneyManagerObj(this.mnyMgrForm.value.walledOff),
        feeInvPrefix: this.mnyMgrForm.value.feeInvoicePrefix ? this.mnyMgrForm.value.feeInvoicePrefix : '0',
        feeInvStartNbr: this.mnyMgrForm.value.feeInvoiceStart ? +this.mnyMgrForm.value.feeInvoiceStart : 0,
        feeInvMsg: this.mnyMgrForm.value.feeInvoiceMessage ? this.mnyMgrForm.value.feeInvoiceMessage : '0',
        feeRptAccess: this.checkMoneyManagerObj(this.mnyMgrForm.value.feeReportAccess),
        feeMsgAccess: this.checkMoneyManagerObj(this.mnyMgrForm.value.feeMessageAccess),
        printAccess: this.checkMoneyManagerObj(this.mnyMgrForm.value.allowToPrintDocuments),
        bookletDocsYn: this.checkMoneyManagerObj(this.mnyMgrForm.value.enrollmentBookletAccess), // need to confirm
        printOverride: this.checkMoneyManagerObj(this.mnyMgrForm.value.overridePlanLevelPrintAccess),
        showConversion: this.checkMoneyManagerObj(this.mnyMgrForm.value.showConversion),
        showQdia: this.checkMoneyManagerObj(this.mnyMgrForm.value.showQDIA),
        merrAmDisplay: this.checkMoneyManagerObj(this.mnyMgrForm.value.merrillAutoMgmt),
        merrPmDisplay: this.checkMoneyManagerObj(this.mnyMgrForm.value.merrillParticipantMap),
        provMapDisplay: this.checkMoneyManagerObj(this.mnyMgrForm.value.provideMapping),
      };
      this.subscriptions.push(
        this.searchUsersService.saveEditMoneyManagerData(moneyManagerRequestObj).subscribe(
          (moneyManagerResponse: UserResponse) => {
            window.scroll(0, 0);
            this.successMessage = { message: moneyManagerResponse.status, details: [] };
          },
          (error) => {
            window.scroll(0, 0);
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }
  checkMoneyManagerObj(moneyManagerData) {
    return moneyManagerData ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
  }
  showSuccessMessage() {
    this.successMessage = { message: alertMessages.EDIT_MONEY_MANAGER_SUCCESS_MESSAGE, details: [] };
  }

  resetAlertMessages() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
