import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// components
import { MasRetailShellComponent } from './components/mas-retail-shell/mas-retail-shell.component';
import { MasRetailFooterComponent } from './components/mas-retail-footer/mas-retail-footer.component';
import { MasRetailHeaderComponent } from './components/mas-retail-header/mas-retail-header.component';
import {
  NfrpBaseModule,
  NfrpNavigationModule,
  NfrpGridUtilsModule,
  NfrpComponentsModule,
  NfrpWidgetsModule,
  NfrpPipesModule,
} from 'nfrp-shared-angular';
import { RouterModule, Routes } from '@angular/router';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { MasRetailHomeComponent } from '../../../../../src/app/mas-retail-home/mas-retail-home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { CcHomeComponent } from '../../../../../src/app/cc-home/cc-home.component';
import { NfrpMasRetailComponent } from '../../container/nfrp-mas-retail.component';
import { EkpHomeComponent } from '../../../../../src/app/ekp-home/ekp-home.component';

const routes: Routes = [
  {
    path: '',
    component: MasRetailHomeComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('../../../../../src/app/mas-retail-home/mas-retail-home.component').then((m) =>
        m.MasRetailHomeComponent),
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: 'cc-home',
    component: CcHomeComponent,
    children: [
      {
        path: 'cc-home',
        loadChildren: () => import('../../../../../src/app/cc-home/cc-home.component').then((m) =>
        m.CcHomeComponent),
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: 'ekp-home',
    component: EkpHomeComponent,
    children: [
      {
        path: 'ekp-home',
        loadChildren: () => import('../../../../../src/app/ekp-home/ekp-home.component').then((m) =>
        m.EkpHomeComponent),
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: 'userMgmt',
    component: NfrpMasRetailComponent,
    data: {
      breadcrumb: 'User Management',
    },
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    component: MasRetailFooterComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  declarations: [MasRetailFooterComponent, MasRetailShellComponent, MasRetailHeaderComponent, MasRetailHomeComponent, CcHomeComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        NfrpNavigationModule,
        NfrpBaseModule,
        ReactiveFormsModule,
        NfrpGridUtilsModule,
        NfrpComponentsModule,
        NfrpWidgetsModule,
        NfrpPipesModule,
        NgbTooltipModule,
        FormsModule,
    ],
  exports: [MasRetailShellComponent, RouterModule],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MasRetailBaseModule {}
