import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { GetPlans } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/get-plan.model';
import { EditTrusteeResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/trustee.model';
import { FooterFeaturesService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/footer-features.service';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { Subscription } from 'rxjs';
import { actionText, alertMessages, modalMessage, statesList, validationPatterns } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, ActionTypeEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';


@Component({
  selector: 'edit-trustee',
  templateUrl: './edit-trustee.component.html',
  styleUrls: ['./edit-trustee.component.css'],
})
export class EditTrusteeComponent implements OnInit, OnDestroy {
  selectedAction: string;
  headerString: string;
  buttonString: string;
  submitted = false;
  loading = false;
  trusteeId: string;
  trusteeForm: UntypedFormGroup;
  statesList = statesList;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  planId: number;
  userEmail: string;
  planDetails: GetPlans;
  formSubscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  loggedInUserId: number;

  modalOptions: any = {
    header: modalMessage.CONFIRMATION,
    message: modalMessage.REMOVE_TRUSTEE_MSG,
    showModal: false,
    noButtonValue: modalMessage.NO_TEXT,
    yesButtonValue: modalMessage.YES_TEXT,
  };

  buttonText: any = {
    save: actionText.SAVE,
    remove: actionText.REMOVE,
    add: actionText.ADD,
  };
  headerText: any = {
    save: actionText.UPDATE,
    remove: actionText.REMOVE,
    add: actionText.ADD,
  };
  private subscriptions = [];
  isPending: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private route: ActivatedRoute,
    private navigationStore: NavigationStore,
    private router: Router,
    private communicationService: CommunicationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.loggedInUserId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.planId = params.planId;
        this.selectedAction = params.action;
        this.getPlanDetails();
      })
    );
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe(pending => {
        this.isPending = pending;
      })
    );
    this.trusteeForm = this.fb.group({
      trusteeFirstName: [
        null,
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern),
        ],
      ],
      trusteeLastName: [
        null,
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern),
        ],
      ],

      planId: [null],
      trusteeAddrA: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      trusteeAddrB: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      trusteeCity: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.cityPattern)]],
      trusteeState: [null],
      trusteeZip: [null],
      trusteePhone: ['', [Validators.maxLength(30)]],
      trusteeFax: [null, [Validators.maxLength(30)]],
      trusteeEmail: [null, [Validators.maxLength(255), Validators.pattern(validationPatterns.emailPattern)]],
      lastUpdatedUserId: [this.loggedInUserId],
      trusteeStatus: [null],
      trackFormActive: [null],
    });
    this.formSubscription = this.trusteeForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });

    if (this.selectedAction) {
      this.setButtonText(this.selectedAction.toLowerCase());
    }
  }

  setButtonText(action) {
    if (action === ActionTypeEnum.EDIT) {
      this.buttonString = this.buttonText.save;
      this.headerString = this.headerText.save;
      this.getExistingData();
    } else if (action === ActionTypeEnum.REMOVE) {
      this.buttonString = this.buttonText.remove;
      this.headerString = this.headerText.remove;

      this.getExistingData();
    } else {
      this.buttonString = this.buttonText.add;
      this.headerString = this.headerText.add;
    }
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  getExistingData(): void {
    this.subscriptions.push(
      this.planDetailsService.getTrustee(this.planId, this.isPending).subscribe((data: any) => {
        this.setExistingFormData(data.planTrusteeResponseList[0]);
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  setExistingFormData(data) {
    if (data) {
      this.trusteeId = data.trusteeId;
      this.trusteeForm.patchValue({
        trusteeFirstName: data.trusteeFirstName,
        trusteeLastName: data.trusteeLastName,
        trusteeAddrA: data.trusteeAddrA,
        trusteeAddrB: data.trusteeAddrB,
        trusteeCity: data.trusteeCity,
        trusteeState: data.trusteeState,
        trusteeZip: data.trusteeZip,
        trusteePhone: data.trusteePhone,
        trusteeFax: data.trusteeFax,
        trusteeEmail: data.trusteeEmail,
        lastUpdatedUserId: this.loggedInUserId,
        trusteeStatus: data.trusteeStatus,
        trackFormActive: data.trackFormActive,
      });
    }
    this.navigationStore.set('formChanged', false);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.trusteeForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  get trusteeFormControl() {
    return this.trusteeForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.trusteeFormControl.planId.setValue(this.planId);
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };

    if (this.selectedAction.toLowerCase() === ActionTypeEnum.EDIT) {
      this.editTrusteeSubmit();
    } else if (this.selectedAction.toLowerCase() === ActionTypeEnum.REMOVE) {
      this.modalOptions.showModal = true;
    } else {
      this.addTrusteeSubmit();
    }
  }

  noClicked() {
    this.modalOptions.showModal = false;
  }
  yesClicked() {
    this.modalOptions.showModal = false;
    this.removeTrusteeSubmit();
  }

  addTrusteeSubmit() {
    if (this.trusteeForm.valid) {
      this.loading = true;
      this.subscriptions.push(
        this.planDetailsService.addTrustee(this.planId, this.trusteeForm.value, this.isPending).subscribe(
          (trusteeResponse: EditTrusteeResponse) => {
            this.loading = false;
            this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
            this.errorMessage = { message: '', details: [] };
            this.successMessage = { message: alertMessages.TRUSTEE_CREATED, details: [] };
          },
          (error: HttpErrorResponse) => {
            this.errorHandlingTrustee();
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }

    this.navigationStore.set('formChanged', false);
  }

  editTrusteeSubmit() {
    if (this.trusteeForm.valid) {
      this.loading = true;
      const editTrusteeRequest = this.trusteeForm.value;
      editTrusteeRequest.trusteeId = this.trusteeId;
      this.subscriptions.push(
        this.planDetailsService.updateTrustee(this.planId, editTrusteeRequest, this.isPending).subscribe(
          (trusteeResponse: EditTrusteeResponse) => {
            this.loading = false;
            this.errorMessage = { message: '', details: [] };
            this.successMessage = { message: alertMessages.TRUSTEE_UPDATED, details: [] };
            this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
          },
          (error: HttpErrorResponse) => {
            this.errorHandlingTrustee();
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }

    this.navigationStore.set('formChanged', false);
  }

  removeTrusteeSubmit() {
    this.loading = true;
    const editTrusteeRequest = this.trusteeForm.value;
    editTrusteeRequest.trusteeId = this.trusteeId;
    this.subscriptions.push(
      this.planDetailsService.removeTrustee(this.planId, editTrusteeRequest.trusteeId, this.isPending).subscribe(
        (trusteeResponse: EditTrusteeResponse) => {
          this.loading = false;
          this.errorMessage = { message: '', details: [] };
          this.router.navigate(['/plan-summary', this.planId]);
          this.successMessage = { message: '', details: [] };
        },
        (error: HttpErrorResponse) => {
          this.errorHandlingTrustee();
        }
      )
    );
    this.navigationStore.set('formChanged', false);
  }
  errorHandlingTrustee() {
    this.loading = false;
    this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
  }
  onCancel() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.router.navigate(['/plan-summary', this.planId]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
