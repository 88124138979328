<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2 padding-left">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10 padding-left">
      <h1 id="main-title" class="page-title ng-binding">
        Book Case
      </h1>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="infoMessage?.message?.length > 0">
        <nfrp-alert alertId="info-msg" [message]="infoMessage" type="info" [page]="false"> </nfrp-alert>
      </div>
      <div *ngIf="generalTitle?.length > 0">
        <ul class="ul-li-view-edit table stripes no-margin border-ul">
          <li class="head">
            <div class="col-sm-12 padding-left">
              <b>General Titles</b>
            </div>
          </li>
          <li *ngFor="let data of generalTitle">
            <div class="col-sm-12">
              <a href="javascript:void(0);" (click)="downloadBookCaseFile(data)">{{ data?.bookLabel }}</a>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="thirdPartyAdmin?.length > 0">
        <ul class="ul-li-view-edit table stripes no-margin border-ul mt-10">
          <li class="head">
            <div class="col-sm-12 padding-left">
              <b>Third-Party Administrator Information</b>
            </div>
          </li>
          <li *ngFor="let data of thirdPartyAdmin">
            <div class="col-sm-12">
              <a href="javascript:void(0);" (click)="downloadBookCaseFile(data)">{{ data?.bookLabel }}</a>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="tpa?.length > 0">
        <ul class="ul-li-view-edit table stripes no-margin border-ul mt-10">
          <li class="head">
            <div class="col-sm-12 padding-left">
              <b>TPA Team Information</b>
            </div>
          </li>
          <li *ngFor="let data of tpa">
            <div class="col-sm-12">
              <a href="javascript:void(0);" (click)="downloadBookCaseFile(data)">{{ data?.bookLabel }}</a>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="moneyManager?.length > 0">
        <ul class="ul-li-view-edit table stripes no-margin border-ul mt-10">
          <li class="head">
            <div class="col-sm-12 padding-left">
              <b>Money Manager Information</b>
            </div>
          </li>
          <li *ngFor="let data of moneyManager">
            <div class="col-sm-12">
              <a href="javascript:void(0);" (click)="downloadBookCaseFile(data)">{{ data?.bookLabel }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
