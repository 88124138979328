<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="
            (planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1
          "
          ><i class="nw-icon-check f-green"></i
        ></span>
        &ensp;{{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }}) &ensp;
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <form [formGroup]="empSponsorForm" (ngSubmit)="onSubmit()">
          <ul id="representative-params" summary="employee sponsor" class="ul-li-view-edit table stripes no-margin">
            <li class="head" [ngClass]="{'pending-status-bg':isPending}">
              <div class="col-sm-12 padding-left">
                <b>{{ headerString }} Plan Sponsor Information</b>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Company Name*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="companyLabel"
                  formControlName="companyLabel"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('companyLabel')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyLabel.touched || submitted) &&
                      empSponsorFormControl.companyLabel.errors?.required
                    "
                    errorId="company-name-required"
                    [message]="getRequiredErrorMessage('Company Name')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyLabel.touched || submitted) &&
                      empSponsorFormControl.companyLabel.errors?.pattern
                    "
                    errorId="company-name-valid-error"
                    [message]="getValidErrorMessage('Company Name')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Address Line One*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="companyAddrA"
                  formControlName="companyAddrA"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('companyAddrA')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyAddrA.touched || submitted) &&
                      empSponsorFormControl.companyAddrA.errors?.required
                    "
                    errorId="address-line-one-required"
                    [message]="getRequiredErrorMessage('Address Line One')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyAddrA.touched || submitted) &&
                      empSponsorFormControl.companyAddrA.errors?.pattern
                    "
                    errorId="address-line-one-valid-error"
                    [message]="getValidErrorMessage('Address Line One')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Address Line Two</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="companyAddrB"
                  formControlName="companyAddrB"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('companyAddrB')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyAddrB.touched || submitted) &&
                      empSponsorFormControl.companyAddrB.errors?.pattern
                    "
                    errorId="address-line-two-valid-error"
                    [message]="getValidErrorMessage('Address Line Two')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">City*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="companyCity"
                  formControlName="companyCity"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('companyCity')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyCity.touched || submitted) &&
                      empSponsorFormControl.companyCity.errors?.required
                    "
                    errorId="companyCity-required"
                    [message]="getRequiredErrorMessage('City')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyCity.touched || submitted) &&
                      empSponsorFormControl.companyCity.errors?.pattern
                    "
                    errorId="companyCity-valid-error"
                    [message]="getValidErrorMessage('City')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">State*</div>
              <div class="col-sm-8 padding-left">
                <select
                  name="companyState"
                  id="companyState"
                  formControlName="companyState"
                  [ngClass]="{
                    'has-error margin-right-20': isControlInvalid('companyState')
                  }"
                >
                  <option [ngValue]="null">-- Choose from List --</option>
                  <option *ngFor="let state of statesList" [value]="state.value">{{ state.label }}</option>
                </select>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyState.touched || submitted) &&
                      empSponsorFormControl.companyState.errors?.required
                    "
                    errorId="companyState-required"
                    [message]="getRequiredErrorMessage('State')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Zip*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="companyZip"
                  formControlName="companyZip"
                  size="20"
                  mask="00000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('companyZip')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyZip.touched || submitted) &&
                      empSponsorFormControl.companyZip.errors?.required
                    "
                    errorId="companyZip-required"
                    [message]="getRequiredErrorMessage('Zip')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      ((empSponsorFormControl.companyZip.touched || submitted) &&
                        empSponsorFormControl.companyZip.errors?.maxLength) ||
                      empSponsorFormControl.companyZip.errors?.pattern
                    "
                    errorId="companyZip-valid-error"
                    [message]="getValidErrorMessage('Zip')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Phone<span *ngIf="isPhoneRequired">*</span></div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="companyPhone"
                  formControlName="companyPhone"
                  size="25"
                  mask="(000)-000-0000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('companyPhone')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyPhone.touched || submitted) &&
                      empSponsorFormControl.companyPhone.errors?.required
                    "
                    errorId="companyPhone-required"
                    [message]="getRequiredErrorMessage('Phone')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      ((empSponsorFormControl.companyPhone.touched || submitted) &&
                        empSponsorFormControl.companyPhone.errors?.maxLength) ||
                      empSponsorFormControl.companyPhone.errors?.pattern
                    "
                    errorId="companyPhone-valid-error"
                    [message]="getValidErrorMessage('Phone')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Fax</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="companyFax"
                  formControlName="companyFax"
                  size="25"
                  mask="(000)-000-0000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('companyFax')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      ((empSponsorFormControl.companyFax.touched || submitted) &&
                        empSponsorFormControl.companyFax.errors?.maxLength) ||
                      empSponsorFormControl.companyFax.errors?.pattern
                    "
                    errorId="companyFax-valid-error"
                    [message]="getValidErrorMessage('Fax')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">E-mail</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="companyEmail"
                  formControlName="companyEmail"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('companyEmail')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (empSponsorFormControl.companyEmail.touched || submitted) &&
                      empSponsorFormControl.companyEmail.errors?.pattern
                    "
                    errorId="companyEmail-valid-error"
                    [message]="getValidErrorMessage('Email')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
          </ul>
          <div class="row margin-top">
            <div class="col-sm-1 no-padding">
              <input
                id="search-button"
                class="button-primary button hover-left margin-left"
                type="submit"
                size="40"
                value="{{ buttonString }}"
              />
            </div>
            <div class="col-sm-10 no-padding"></div>
            <div class="col-sm-1 no-padding">
              <input
                type="button"
                class="button button-secondary hover-right margin-right"
                (click)="onCancel()"
                value="Cancel"
              />
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="loading">
        <nfrp-spinner></nfrp-spinner>
      </ng-container>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>

<nfrp-default-modal
  [header]="modalOptions.header"
  [message]="modalOptions.message"
  [noButtonValue]="modalOptions.noButtonValue"
  [yesButtonValue]="modalOptions.yesButtonValue"
  [showModal]="modalOptions.showModal"
  (noClicked)="noClicked()"
  (yesClicked)="yesClicked()"
>
</nfrp-default-modal>
