import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrusteeResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/trustee.model';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { UserTypeIDEnum } from 'src/app/helpers/enum';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'view-trustee',
  templateUrl: './view-trustee.component.html',
  styleUrls: ['./view-trustee.component.css'],
})
export class ViewTrusteeComponent implements OnInit,OnChanges, OnDestroy {
  @Input() status: boolean;
  @Input() pendingStatus: boolean;
  planId: number;
  trusteeObj: TrusteeResponse;
  archivePlanStatus = false;
  userTypeID: number;
  isMoneyMgrUser = false;
  private subscriptions = [];
  errorMessage: NfrpAlertMessage;
  constructor(
    private planDetailsService: PlanDetailsService,
    private route: ActivatedRoute,
    private communicationService: CommunicationService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeID = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.planId = params.planId;
        this.getTrustee();
      })
    );
    //Archive plan event
    this.subscriptions.push(
      this.communicationService.archivePlanStatusEvent.subscribe((archivePlanStatus: boolean) => {
        this.archivePlanStatus = archivePlanStatus;
      })
    );   
    if (this.userTypeID === UserTypeIDEnum.MONEY_MANAGER) {
      this.isMoneyMgrUser = true;
    }
  }

  getTrustee(): void {
    this.subscriptions.push(
      this.planDetailsService.getTrustee(this.planId, this.pendingStatus).subscribe((data: any) => {
        this.trusteeObj = data?.planTrusteeResponseList[0];
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.planId && changes.pendingStatus && (changes.pendingStatus.currentValue || changes.pendingStatus.previousValue)) {
      this.getTrustee();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
