import { Component, OnInit, Inject, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { SubFooterFeatures } from '../../model/subfooter-features.model';
import { FooterFeaturesService } from '../../services/footer-features.service';
@Component({
  selector: 'security-navigation',
  templateUrl: './security-navigation.component.html',
})
@Inject({ providedIn: 'root' })
export class SecurityNavigationComponent implements OnInit, OnDestroy, OnChanges {
  subFooterFeatureResult$: Observable<SubFooterFeatures[]> = this.footerFeaturesService.subFooterFeatureResult;
  planId: number;
  @Input() planStatus : boolean;
  loadedSubFooters: SubFooterFeatures[];
  sfLevelOne: SubFooterFeatures[];
  sfOrderBy: string;
  sfLevelTwo: SubFooterFeatures[];
  status: boolean;
  private featureID: number;
  private userEmail: string;
  private subscriptions = [];
  private router: Router;
  constructor(
    private communicationService: CommunicationService,
    private footerFeaturesService: FooterFeaturesService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
    });
  }

  changeUserSearchViewToAddUser() {
    this.communicationService.loadAddUserView();
  }
  changeUserSearchViewToUserAccess() {
    this.communicationService.loadUserAccessView();
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.featureID = this.footerFeaturesService.footerFeatureID;
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.getSubFooterDetails();
  }
  getSubFooterDetails() {
    this.showSponsorDocument();
    this.subscriptions.push(
      this.footerFeaturesService
        .getSubFooterDetails(this.featureID, this.userEmail, this.status)
        .pipe(
          tap((data: SubFooterFeatures) => {
            this.loadedSubFooters = data.subFeatureResponseList;
            this.sfLevelOne = this.loadedSubFooters.filter((loadedSubFooters) => loadedSubFooters.sfLevel === 1);
            this.sfLevelTwo = this.loadedSubFooters.filter((loadedSubFooters) => loadedSubFooters.sfLevel === 2);
          })
        )
        .subscribe()
    );
  }

  onClickNav(sfTraget: string) {
    this.router.navigate(['/', sfTraget]);
    this.getSubFooterDetails();
  }

  showSponsorDocument(): void {
    this.subscriptions.push(
      this.footerFeaturesService.getFeatureListForLeftNav().subscribe(status=> {
        this.status = status;
      })
    );   
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.planStatus && (changes.planStatus.currentValue === false) || (changes.planStatus.currentValue  === true)) {
      this.getSubFooterDetails();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
