import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage, NavigationStore, NfrpDateService } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { SysMsgResponse } from '../../../model/system-msg';
import { CcLandingService } from '../../../services/cc-landing.service';
import { FooterFeaturesService } from '../../../services/footer-features.service';

@Component({
  selector: 'add-view-sys-msgs',
  templateUrl: './add-view-sys-msgs.component.html',
  styleUrls: ['./add-view-sys-msgs.component.scss'],
})
export class AddViewSysMsgsComponent implements OnInit, OnDestroy {
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  loading = false;
  userEmail: string;
  subscriptions = [];
  addSystemMsgForm: UntypedFormGroup;
  formSubscription: Subscription;
  submitted = false;
  msgId: number;
  headerString = 'Add';
  msgDetails: SysMsgResponse;
  userId: number;
  constructor(
    private footerFeaturesService: FooterFeaturesService,
    private fb: UntypedFormBuilder,
    private navigationStore: NavigationStore,
    public router: Router,
    public nfrpDateService: NfrpDateService,
    private ccLandingService: CcLandingService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.SYSTEM_MESSAGES, this.userEmail).subscribe()
    );

    this.addSystemMsgForm = this.fb.group({
      sysMsgTitle: [null, [Validators.required]],
      sysMsgBody: [null, [Validators.required, Validators.maxLength(255)]],
      sysMsgType: [0, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      importance: [1, [Validators.required]],
      sysMsgActive: [1, [Validators.required]],
    });
    this.formSubscription = this.addSystemMsgForm.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    this.route.params.subscribe((params) => {
      this.msgId = params.id;
      if (this.msgId) {
        this.getMsgIdDetails();
        this.headerString = 'Edit';
      }
    });
  }
  getMsgIdDetails() {
    this.subscriptions.push(
      this.ccLandingService.getSystemMsgDetailsById(this.msgId).subscribe((msgDetails: SysMsgResponse) => {
        this.setSysMsgFormData(msgDetails);
      })
    );
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.addSystemMsgForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  get sysMsgFormControl() {
    return this.addSystemMsgForm.controls;
  }
  get startDate(): string {
    return this.addSystemMsgForm.get('startDate').value;
  }

  get endDate(): string {
    return this.addSystemMsgForm.get('endDate').value;
  }

  get minStartDate(): string {
    return this.nfrpDateService.today;
  }
  setSysMsgFormData(data) {
    if (data) {
      const formattedStartDate = formatDate(data.startDate, 'MM-dd-yyyy', 'en');
      const formattedEndDate = formatDate(data.endDate, 'MM-dd-yyyy', 'en');
      const formattedStartTime = formatDate(data.startDate, 'HH:mm', 'en');
      const formattedEndTime = formatDate(data.endDate, 'HH:mm', 'en');
      this.addSystemMsgForm?.patchValue({
        sysMsgTitle: data.title,
        sysMsgBody: data.body,
        sysMsgType: data.type,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        importance: data.importance,
        sysMsgActive: data.active,
      });
    }
    this.navigationStore.set('formChanged', false);
  }
  onCancel() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.router.navigate(['/sysMessages']);
  }
  onSubmit() {
    this.submitted = true;
    if (this.addSystemMsgForm.valid) {
      const saveMsgReqobj = {
        userId: this.userId,
        title: this.addSystemMsgForm.value.sysMsgTitle,
        body: this.addSystemMsgForm.value.sysMsgBody,
        type: +this.addSystemMsgForm.value.sysMsgType,
        startDate: this.addSystemMsgForm.value.startDate + ' ' + this.addSystemMsgForm.value.startTime,
        endDate: this.addSystemMsgForm.value.endDate + ' ' + this.addSystemMsgForm.value.endTime,
        importance: +this.addSystemMsgForm.value.importance,
        active: +this.addSystemMsgForm.value.sysMsgActive,
      };
      if (this.msgId) {
        this.updateSysMessageDetails(saveMsgReqobj);
      } else {
        this.saveSystemMessageDetails(saveMsgReqobj);
      }
      this.navigationStore.set('formChanged', false);
    } else {
      window.scroll(0, 0);
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
  }
  saveSystemMessageDetails(saveMsgReqobj: SysMsgResponse) {
    this.subscriptions.push(
      this.ccLandingService.saveSystemMessages(saveMsgReqobj).subscribe(
        (msgResponse: SysMsgResponse) => {
          window.scroll(0, 0);
          this.errorMessage = { message: '', details: [] };
          this.successMessage = { message: alertMessages.SYSTEM_MESSAGE_ADDED, details: [] };
        },
        (error: HttpErrorResponse) => {
          window.scroll(0, 0);
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      )
    );
  }
  updateSysMessageDetails(saveMsgReqobj: SysMsgResponse) {
    this.subscriptions.push(
      this.ccLandingService.updateSystemMessages(saveMsgReqobj, this.msgId).subscribe(
        (msgResponse: SysMsgResponse) => {
          window.scroll(0, 0);
          this.errorMessage = { message: '', details: [] };
          this.successMessage = { message: alertMessages.SYSTEM_MESSAGE_UPDATED, details: [] };
        },
        (error: HttpErrorResponse) => {
          window.scroll(0, 0);
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      )
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
