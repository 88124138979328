import { Component, OnInit } from '@angular/core';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PlanDetailsService } from '../../services/plan-details.service';
import { HttpErrorResponse } from '@angular/common/http';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { FooterFeaturesService } from '../../services/footer-features.service';

@Component({
  selector: 'lib-solicitor-mgmt',
  templateUrl: './solicitor-mgmt.component.html',
  styleUrls: ['./solicitor-mgmt.component.css'],
})
export class SolicitorMgmtComponent implements OnInit {
  submitted = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  fileSelectErrorMessage: string;
  loading: boolean;
  alreadyExistSolMsg: string;
  fileName: File | null;
  userEmail: string;
  formSubmitted = false;
  solicitorForm = this.fb.group({
    file: [null, [Validators.required]],
  });
  solicitorFormName = this.fb.group({
    appSolicitorName: [null, [Validators.required]],
  });
  private subscriptions = [];
  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private footerFeaturesService: FooterFeaturesService
  ) {
    this.fileName = null;
  }
  get solicitorFormControl() {
    return this.solicitorForm.controls;
  }
  get solicitorFormNameControl() {
    return this.solicitorFormName.controls;
  }
  isFileControlInvalid(controlName: string): boolean {
    const control = this.solicitorForm.get(controlName);
    return control.invalid && this.formSubmitted;
  }
  getRequiredErrorMessage(preText: string): string {
    return `Please Enter a ${preText}`;
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.footerFeaturesService
        .getSubFooterDetails(FeatureTypeIDEnum.CLIENT_SETUP_SOLICITOR_MAINT, this.userEmail)
        .subscribe()
    );
  }
  onChangeFile(event) {
    const file: File = event.target.files[0];
    if (file.name.substring(file.name.length - 4, file.name.length) !== '.csv'){
      this.fileName = null;
      this.fileSelectErrorMessage = alertMessages.CSV_FILE_ONLY;
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    } else {
      this.fileName = file;
      this.fileSelectErrorMessage = null;
      this.errorMessage = { message: '', details: [] };
    }
  }

  isImportApprovedSolicitorsDisabled(): boolean {
    return !!this.fileSelectErrorMessage || !this.fileName;
  }

  updateFileStatus(fileInput) {
    fileInput.value = null;
    this.fileName = null;
  }
  onSubmit() {
    this.formSubmitted = true;
    if (this.fileName != null) {
      this.loading = true;
      this.planDetailsService.updateSolicitorByFile(this.fileName).subscribe(
        (solicitorResponse) => {
          window.scroll(0, 0);
          this.loading = false;
          if (solicitorResponse?.solicitorsAlreadyExist && solicitorResponse?.solicitorsAlreadyExist.length === 0) {
            this.errorMessage = { message: '', details: [] };
          }
          this.successMessage = { message: alertMessages.APP_SOLICITOR_ADDED, details: [] };
          if (solicitorResponse?.solicitorsAlreadyExist && solicitorResponse?.solicitorsAlreadyExist.length !== 0) {
            this.successMessage = { message: '', details: [] };
            const errorMsg = solicitorResponse?.solicitorsAlreadyExist
              .map((solicitor) => solicitor.appSolicitorName)
              .join(',');
            this.alreadyExistSolMsg = `Approved Solicitor(s) ${errorMsg} already exists in the repository`;
            this.errorMessage = { message: this.alreadyExistSolMsg, details: [] };
          }
        },
        (error) => {
          this.loading = false;
          window.scroll(0, 0);
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      );
    } else {
      this.successMessage = { message: '', details: [] };
      this.errorMessage = { message: alertMessages.SOLICITOR_FILE_UPLOAD, details: [] };
    }
  }
  saveAppSolicitorName() {
    this.submitted = true;
    if (this.solicitorFormName.value.appSolicitorName) {
      this.loading = true;
      this.planDetailsService.updateSolicitorByName(this.solicitorFormName.value.appSolicitorName).subscribe(
        (solicitorResponse) => {
          window.scroll(0, 0);
          this.loading = false;
          this.solicitorFormName.controls.appSolicitorName.setValue('');
          this.solicitorFormName.controls.appSolicitorName.setErrors(null);
          this.errorMessage = { message: '', details: [] };
          this.successMessage = { message: alertMessages.APP_SOLICITOR_ADDED, details: [] };
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          window.scroll(0, 0);
          this.solicitorFormName.controls.appSolicitorName.setValue('');
          this.solicitorFormName.controls.appSolicitorName.setErrors(null);
          this.successMessage = { message: '', details: [] };
          if (error?.error?.status) {
            this.errorMessage = { message: error?.error?.status, details: [] };
          } else {
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        }
      );
    } else {
      this.successMessage = { message: '', details: [] };
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
  }
}
