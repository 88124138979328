<div class="padding-top">
  <ul class="ul-li-view-edit table stripes no-margin border-ul">
    <li class="row" [ngClass]="{'pending-status-bg':pendingStatus}">
      <div id="internal-header" class="col-sm- padding-left"><b>Representative</b></div>
    </li>
    <li *ngIf="dataSource?.length === 0"><div class="col-sm-12"></div></li>
    <li *ngFor="let data of dataSource; i as index">
      <div class="col-sm-6" [ngClass]="{'has-error': !data.complete}">{{ data.repFirstName }} {{ data.repLastName }} &nbsp;<span *ngIf="!data.complete"> - Information Incomplete</span></div>
      <div class="col-sm-6" *ngIf="!archivePlanStatus && !isMoneyMgrUser">
        <b
          ><a
            href="javascript:void(0);"
            *ngIf="dataSource && userTypeID === riaUserId && status"
            class="subLink"
            routerLink="representative/edit/{{ data.repId }}"
            >Edit</a
          >&nbsp;<span *ngIf="dataSource && userTypeID === riaUserId">-</span>&nbsp;
          <a
            href="javascript:void(0);"
            *ngIf="dataSource && status"
            class="subLink"
            routerLink="representative/remove/{{ data.repId }}"
          >
            Remove From Plan</a
          ></b
        >
      </div>
    </li>
    <li class="padding-left" *ngIf="!archivePlanStatus && !isMoneyMgrUser && status">
      <b>
        <a href="javascript:void(0);" class="subLink" routerLink="representative/add">
          Add <span *ngIf="numberOfItems > 0">Another</span> Representative</a
        ></b
      >
    </li>
  </ul>
</div>
