<div class="flex-none">
  <header id="header" class="header" role="banner" aria-label="header-section">
    <nav id="header-navigation" class="header__topnav" role="navigation" aria-label="Header navigation">
      <div class="header__logo-container">
        <div id="header-nw-logo" class="header__logo">
          <a id="nationwide-logo" routerLink="/home" routerLinkActive="active">
            <img src="/assets/logo_nw@2x.png" alt="MAS Retail Private Home" />
            <span class="srt">Click to return to MAS Retail home page</span>
          </a>
        </div>
      </div>

      <div class="header__title-wrapper">
        <span id="header-title" class="header__app-title"> MAS Retail Private </span>
        <ul class="header__rightnav">
          <li>
            <a class="contact-link" [routerLink]="['/contact-us']">Contact Us</a>
            <a class="privacy-link" [routerLink]="['/privacy-policy']">Privacy Policy</a>
            <a *ngIf="login" id="logout-link" (click)="navigateToHome()" tabindex="0">Log out</a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <!-- SearchBar Component-->
  <div class="clearfix" *ngIf="login && !isHideSearchBox">
    <div class="dashboard tab-controls">
      <nav id="sticky" class="dashboard__submenu submenu" aria-label="Search navigation">
        <div class="searchbox">
          <div class="search-inner">
            <!-- serach boxes -->
            <form [formGroup]="searchPlanForm" (ngSubmit)="onSearchPlanSubmit()">
              <div class="search-horizontal"></div>
              <div class="search-horizontal">
                <input
                  id="internal-user-input"
                  type="text"
                  size="60"
                  name="searchQuery"
                  title="user search"
                  maxlength="100"
                  formControlName="searchQuery"
                  placeholder="Plan Name Or Number "
                  ng-reflect-maxlength="100"
                  ng-reflect-name="searchQuery"
                  class="ng-pristine ng-valid ng-touched"
                />
              </div>
              <div class="search-horizontal nwpii col-md-offset-*">
                <span></span>
              </div>
              <div class="search-horizontal">
                <input id="search-button" class="button button-primary" type="submit" value="Search" />
              </div>
              <div *ngIf="userTypeId !== 49" class="search-horizontal">
                <a
                  [routerLink]="['/advanced-search']"
                  id="advanced-participant-search-link"
                  class="orange-arrow line-height-2-3 margin-left-3"
                  url="advanced-search"
                >
                  Advanced Search
                </a>
              </div>
            </form>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
