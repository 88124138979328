import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'print-docs',
  templateUrl: './print-docs.component.html',
  styleUrls: ['./print-docs.component.css']
})
export class PrintDocsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
