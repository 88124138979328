<section class="container-fluid section-login" role="main" xmlns="http://www.w3.org/1999/html">
  <div class="col-12">
    <form [formGroup]="resetForm" (ngSubmit)="onClickSubmit()">
      <div class="contentBody">
        <h2>Password has expired on this user account. Please reset your password.</h2>
        <br /><br />
        <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
          <nfrp-alert alertId="login-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
        </div>
        <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
          <nfrp-alert alertId="login-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
        </div>
        <div id="userID" class="userDetails">
          <span class="detailTitle">New Password:</span>
          <input formControlName="newPassword" type="password" class="userDetailInput" [pattern]="passwordPattern" />
          <div class="inline-error">
            <nfrp-inline-error
              *ngIf="((newPassword.touched && newPassword.invalid) || submitted) && newPassword.errors?.required"
              errorId="password-required-error"
              [message]="'Password is required'"
            >
            </nfrp-inline-error>
            <nfrp-inline-error
              *ngIf="newPassword.touched && newPassword.errors?.pattern"
              errorId="username-required-error"
              [message]="'Enter a valid password'"
            >
            </nfrp-inline-error>
          </div>
        </div>
        <ul>
          <li>Password Must be at least 12 characters</li>
          <li>A mixture of both uppercase and lowercase letters</li>
          <li>A mixture of letters and numbers</li>
          <li>Inclusion of at least one special character, e.g., ! &#64; # ?</li>
        </ul>
        <div id="FirstName" class="userDetails">
          <span class="detailTitle">Confirm:</span>
          <input formControlName="confirmPassword" type="password" class="userDetailInput" />
        </div>
        <div class="inline-error">
          <nfrp-inline-error
            *ngIf="(confirmPassword.touched || submitted) && confirmPassword.errors?.required"
            errorId="confirm-pwd-required-error"
            [message]="'Confirm password is required'"
          >
          </nfrp-inline-error>
          <nfrp-inline-error
            *ngIf="confirmPassword.valid && resetForm.invalid && resetForm.errors.passwordsShouldMatch"
            errorId="confirm-pwd-valid-error"
            [message]="'Passwords do not match.'"
          >
          </nfrp-inline-error>
        </div>
        <div class="search-button" style="padding-left: 200px;">
          <input class="button-primary button" type="submit" value="Reset" />
        </div>
      </div>
    </form>
  </div>
</section>
