import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NfrpGridService, NavigationStore, NfrpDefaultGridComponent, NfrpAlertMessage } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, UserTypeIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { GetPlanMMDetails, GetPlanFeeDetails, GetPlanRepresentative, GetParticipantDetails } from '../../../model/get-plan.model';
import { MoneyManager, MoneyManagerData } from '../../../model/money-manager-data';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { PlanDetailsService } from '../../../services/plan-details.service';
@Component({
  selector: 'lib-partic-activation',
  templateUrl: './partic-activation.component.html',
  styleUrls: ['./partic-activation.component.css'],
})
export class ParticActivationComponent extends NfrpDefaultGridComponent<any> implements OnInit, OnDestroy {
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  infoMessage: NfrpAlertMessage;
  particActivateForm: UntypedFormGroup;
  dataSource: any;
  selectedParticList = [];
  selectedRep: any;
  selectedFeeprogram: any;
  selectedRepList = [];
  numberOfItems: number;
  submitted = false;
  userEmail: string;
  formSubscription: Subscription;
  moneyManagerList: MoneyManager[];
  mmNonWalledOffList: MoneyManager[];
  planMnyMgrDetails: GetPlanMMDetails[];
  planFeeDetails: GetPlanFeeDetails[];
  planRepresentativeDetails: GetPlanRepresentative[];
  planParticipantDetails: GetParticipantDetails[];
  planId: number;
  planNo: string;
  selectedPlanObj: any;
  mnyMgr: string;
  loading = false;
  userTypeId: number;
  private subscriptions = [];

  constructor(
    private fb: UntypedFormBuilder,
    nfrpGridService: NfrpGridService<any>,
    private footerFeaturesService: FooterFeaturesService,
    private navigationStore: NavigationStore,
    private planDetailsServce: PlanDetailsService,
    private communicationService: CommunicationService
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    this.particActivateForm = this.fb.group({
      planNo: [null, [Validators.required]],
      feeProgram: [null, [Validators.required]],
      representative: [null, [Validators.required]],
    });
    this.formSubscription = this.particActivateForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : '';

    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.IK_PARTICIPANT, this.userEmail).subscribe()
    );
    if (this.userTypeId !== UserTypeIDEnum.MONEY_MANAGER) {
      this.communicationService.getMnyMgr().subscribe((mnyMgr) => {
        if (mnyMgr) {
          this.mnyMgr = mnyMgr;
          this.getPlanDetailsFromMnyMgr();
        } else {
          this.errorMessage = { message: alertMessages.SELECT_MNY_MGR, details: [] };
        }
      });
    } else {
      this.mnyMgr = loginUser ? JSON.parse(loginUser)?.moneyManagerId : '';
      this.getPlanDetailsFromMnyMgr();
    }

    this.particActivateForm.get('representative').valueChanges.subscribe((rep) => {
      this.selectedRepList = [];
      rep?.forEach((oneRep) => {
        this.selectedRepList.push(oneRep);
      });
    });
    this.navigationStore.set('formChanged', false);
  }
  changePlanNoEvent(event) {
    const selectedPlanId = event.target.value;
    this.selectedPlanObj = this.planMnyMgrDetails.filter((plans) => plans.planId === +selectedPlanId);
    this.navigationStore.set('formChanged', false);
  }
  getSelectedfeePgm(event) {
    const planIndex = event.target.value;
    this.selectedFeeprogram = this.planFeeDetails.filter((plans) => plans.feeProgramId === +planIndex);
    this.navigationStore.set('formChanged', false);
  }
  getSelectedRep(event) {
    this.navigationStore.set('formChanged', false);
  }
  checkPlanSelect() {
    this.getPlanFeeDetailsFromPlanId();
    this.getPlanRepresentativeFromPlanId();
    this.getPlanParticipantDetails();
    this.navigationStore.set('formChanged', false);
  }
  getPlanDetailsFromMnyMgr() {
    this.loading = true;
    this.subscriptions.push(
      this.planDetailsServce.getPlanDetailsFromMnyMgrId(this.mnyMgr).subscribe(
        (planMnyMgrDetails: GetPlanMMDetails) => {
          this.loading = false;
          this.planMnyMgrDetails = planMnyMgrDetails.planDetails;
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          if (error?.error?.status === 500) {
            this.successMessage = { message: '', details: [] };
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        }
      )
    );
  }
  getPlanFeeDetailsFromPlanId() {
    this.subscriptions.push(
      this.planDetailsServce
        .getPlanFeeDetailsFromPlanId(this.selectedPlanObj[0]?.planId, this.mnyMgr)
        .subscribe((planFeeDetails: GetPlanFeeDetails) => {
          this.planFeeDetails = planFeeDetails?.planFeeDetails;
        })
    );
  }
  getPlanRepresentativeFromPlanId() {
    this.subscriptions.push(
      this.planDetailsServce
        .getPlanRepresentativeFromPlanId(this.selectedPlanObj[0]?.planId)
        .subscribe((planRepresentativeDetails: GetPlanRepresentative) => {
          this.planRepresentativeDetails = planRepresentativeDetails.planRepDetails;
        })
    );
  }
  getPlanParticipantDetails() {
    this.subscriptions.push(
      this.planDetailsServce.getParticipantDetails(this.selectedPlanObj[0]?.planNo).subscribe(
        (planParticipantDetails: GetParticipantDetails) => {
          this.planParticipantDetails = planParticipantDetails?.planParticipantDetails;
          this.dataSource = planParticipantDetails?.planParticipantDetails;
          this.numberOfItems = this.dataSource?.length;
          this.setupGrid();
          this.paginate({
            limit: 5,
            offset: 1,
          });
        },
        (error: HttpErrorResponse) => {
          this.dataSource = [];
          this.selectedParticList = [];
          this.setupGrid();
        }
      )
    );
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.particActivateForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  getChooseErrorMessage(preText: string): string {
    return `Please select a ${preText}`;
  }
  get participantIkControl() {
    return this.particActivateForm.controls;
  }
  checkAll() {
    for (const value of this.dataSource) {
      value.selected = true;
    }
    this.selectedParticList = this.dataSource;
  }

  uncheckAll() {
    for (const value of this.dataSource) {
      value.selected = false;
    }
    this.selectedParticList = [];
  }
  resetParticIkForm() {
    this.particActivateForm.reset();
    window.location.reload();
  }
  clearDates() {
    for (const value of this.dataSource) {
      value.contractDtInString = '';
    }
  }
  onKeyUpContractDate($event) {
    for (const value of this.dataSource) {
      value.contractDtInString = $event.target.value;
    }
  }
  updateCheckedOptions(option, event) {
    if (event.target.checked) {
      this.selectedParticList.push(option);
    } else {
      for (let i = 0; i < this.dataSource.length; i++) {
        if (this.selectedParticList[i]?.ssn === option.ssn) {
          this.selectedParticList.splice(i, 1);
        }
      }
    }
  }

  activateParticipants() {
    this.submitted = true;
    if (this.particActivateForm.valid) {
      const activateParticReqObj = {
        planId: this.selectedPlanObj[0]?.planId,
        planNo: this.selectedPlanObj[0]?.planNo,
        mgrId: this.mnyMgr,
        feeProgId: this.selectedFeeprogram[0]?.feeProgramId,
        ppaFeePct: this.selectedFeeprogram[0]?.ppaFeePct,
        riaFeePct: this.selectedFeeprogram[0]?.riaFeePct,
        mgrFeePct: this.selectedFeeprogram[0]?.mgrFeePct,
        solFeePct: this.selectedFeeprogram[0]?.solicitorFeePct,
        totFeePct: this.selectedFeeprogram[0]?.totMgmtFeePct,
        tpaTeamFeePct: this.selectedFeeprogram[0]?.tpaFeePct,
        repIdList: this.getRepData(),
        solId: this.selectedRepList ? this.selectedRepList[0]?.solicitorId : '',
        apsStagedParticipantsList: this.getParticList(),
      };

      this.subscriptions.push(
        this.planDetailsServce.activateParticpantsData(activateParticReqObj).subscribe(
          (activateParticResponse) => {
            window.scroll(0, 0);
            this.successMessage = { message: activateParticResponse.status, details: [] };
            this.errorMessage = { message: '', details: [] };
          },
          (error: HttpErrorResponse) => {
            window.scroll(0, 0);
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            this.successMessage = { message: '', details: [] };
          }
        )
      );
    } else {
      window.scroll(0, 0);
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }

    this.navigationStore.set('formChanged', false);
  }
  getRepData(): any[] {
    const repList = this.selectedRepList.map((oneRep) => oneRep.repId);
    return repList;
  }
  getParticList(): any[] {
    const mappedParticList = this.selectedParticList.map(({ potentialParticipantId, contractDt }) => ({
      potentialParticipantId,
      contractDt,
    }));
    return mappedParticList;
  }
  removeParticipants() {
    this.submitted = true;
    const removeRequestList = [];
    this.particActivateForm.controls.representative.clearValidators();
    this.particActivateForm.controls.representative.updateValueAndValidity();
    this.selectedParticList.forEach((participant) => removeRequestList.push(participant.potentialParticipantId));
    this.subscriptions.push(
      this.planDetailsServce.removeParticipantsData(removeRequestList).subscribe(
        (removeParticResponse) => {
          window.scroll(0, 0);
          this.successMessage = { message: removeParticResponse.status, details: [] };
          this.errorMessage = { message: '', details: [] };
          this.getPlanParticipantDetails();
        },
        (error: HttpErrorResponse) => {
          window.scroll(0, 0);
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      )
    );
    this.navigationStore.set('formChanged', false);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
