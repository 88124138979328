import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'forgot-password-link',
  templateUrl: './forgot-password-link.component.html',
  styleUrls: ['./forgot-password-link.component.scss'],
})
export class ForgotPasswordLinkComponent implements OnInit {
  constructor() {
    //do nothing
  }

  ngOnInit(): void {
    //do nothing
  }
}
