<section class="container-fluid section-login" role="main" xmlns="http://www.w3.org/1999/html">
  <div class="mb-5">Please enter your username to reset your password.</div>
  <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
    <nfrp-alert alertId="login-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
  </div>
  <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
    <nfrp-alert alertId="login-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
  </div>
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onClickSubmit()" class="form-forgot-password">
    <div id="userID" class="userDetails">
      <span class="detailTitle">Username:</span>
      <input
        type="text"
        #input
        id="useremail"
        class="form-control userDetailInput"
        [pattern]="emailPattern"
        formControlName="userName"
        placeholder="Username"
        (input)="input.value = $event.target.value.toLowerCase()"
        required
      />
      <div class="inline-error">
        <nfrp-inline-error
          *ngIf="
            (forgotPasswordControl.userName.touched || submitted) && forgotPasswordControl.userName.errors?.required
          "
          errorId="username-required-error"
          [message]="'Username is required'"
        >
        </nfrp-inline-error>
        <nfrp-inline-error
          *ngIf="forgotPasswordControl.userName.touched && forgotPasswordControl.userName.errors?.pattern"
          errorId="username-valid-error"
          [message]="'Enter a valid email address'"
        >
        </nfrp-inline-error>
      </div>
    </div>
    <div class="row">
      <a href="" class="blueButtonLink" target="wireframeFrame"></a>
      <div class="search-button" style="padding-left: 280px;">
        <input class="button-primary button hover-right margin-right" type="submit" value="Reset" />
      </div>
    </div>
  </form>
</section>
