import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NfrpGridService, NfrpDefaultGridComponent, NfrpAlertMessage } from 'nfrp-shared-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, UserTypeIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { GetPlanMMDetails, GetPlanFeeDetails, GetPlanRepresentative, GetParticipantDetails } from '../../../model/get-plan.model';
import { MoneyManager } from '../../../model/money-manager-data';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { PlanDetailsService } from '../../../services/plan-details.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'lib-partic-track-activation',
  templateUrl: './partic-track-activation.component.html',
  styleUrls: ['./partic-track-activation.component.css'],
})
export class ParticTrackActivationComponent extends NfrpDefaultGridComponent<any> implements OnInit, OnDestroy {
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  particTrackActivateForm: UntypedFormGroup;
  particContractDateForm: UntypedFormGroup;
  dataSource: any;
  selectedParticList = [];
  selectedRep: any;
  selectedFeeprogram: any;
  selectedRepList = [];
  numberOfItems: number;
  submitted = false;
  userEmail: string;
  moneyManagerList: MoneyManager[];
  mmNonWalledOffList: MoneyManager[];
  planMnyMgrDetails: GetPlanMMDetails[];
  planFeeDetails: GetPlanFeeDetails[];
  planRepresentativeDetails: GetPlanRepresentative[];
  planParticipantDetails: GetParticipantDetails[];
  planId: number;
  planNo: string;
  selectedPlanObj: any;
  mnyMgr: string;
  loading = false;
  userTypeId: number;
  private subscriptions = [];
  isActivateParticipantsAllowed = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DATE_FORMAT = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;

  constructor(
    private fb: UntypedFormBuilder,
    nfrpGridService: NfrpGridService<any>,
    private footerFeaturesService: FooterFeaturesService,
    private planDetailsServce: PlanDetailsService,
    private communicationService: CommunicationService
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    this.particTrackActivateForm = this.fb.group({
      planNo: [null, [Validators.required]],
      feeProgram: [null, [Validators.required]],
      representative: [null, [Validators.required]],
      globalContractDate: [null],
    });
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : '';

    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.TRACK_PARTICIPANT, this.userEmail).subscribe()
    );
    if (this.userTypeId !== UserTypeIDEnum.MONEY_MANAGER) {
      this.communicationService.getTracMnyMgr().subscribe((mnyMgr) => {
        if (mnyMgr) {
          this.mnyMgr = mnyMgr;
          this.getPlanDetailsFromMnyMgr();
        } else {
          this.errorMessage = { message: alertMessages.SELECT_MNY_MGR, details: [] };
        }
      });
    } else {
      this.mnyMgr = loginUser ? JSON.parse(loginUser)?.moneyManagerId : '';
      this.getPlanDetailsFromMnyMgr();
    }

    this.particTrackActivateForm.get('representative').valueChanges.subscribe((rep) => {
      this.selectedRepList = [];
      rep?.forEach((oneRep) => {
        this.selectedRepList.push(oneRep);
      });
    });
  }
  changePlanNoEvent(event) {
    const selectedPlanId = event.target.value;
    this.selectedPlanObj = this.planMnyMgrDetails.filter((plans) => plans.planId === +selectedPlanId);
    this.checkPlanSelect();
  }
  getSelectedfeePgm(event) {
    const planIndex = event.target.value;
    this.selectedFeeprogram = this.planFeeDetails.filter((plans) => plans.feeProgramId === +planIndex);
    this.setIsActivateParticipantsAllowed();
  }

  checkPlanSelect() {
    this.getPlanFeeDetailsFromPlanId();
    this.getPlanRepresentativeFromPlanId();
    this.getPlanParticipantDetails();
    this.selectedRepList = [];
    this.isActivateParticipantsAllowed = false;
  }
  getPlanDetailsFromMnyMgr() {
    this.loading = true;
    this.subscriptions.push(
      this.planDetailsServce.getTracPlanDetailsFromMnyMgrId(this.mnyMgr).subscribe(
        (planMnyMgrDetails: GetPlanMMDetails) => {
          this.loading = false;
          this.planMnyMgrDetails = planMnyMgrDetails.planDetails;
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          if (error?.error?.status === 500) {
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        }
      )
    );
  }
  getPlanFeeDetailsFromPlanId() {
    this.subscriptions.push(
      this.planDetailsServce
        .getTracPlanFeeDetailsFromPlanId(this.selectedPlanObj[0]?.planId, this.mnyMgr)
        .subscribe((planFeeDetails: GetPlanFeeDetails) => {
          this.planFeeDetails = planFeeDetails?.planFeeDetails;
        })
    );
  }
  getPlanRepresentativeFromPlanId() {
    this.subscriptions.push(
      this.planDetailsServce.getTracPlanRepresentativeFromPlanId(this.selectedPlanObj[0]?.planId).subscribe(
        (planRepresentativeDetails: GetPlanRepresentative) => {
          this.planRepresentativeDetails = planRepresentativeDetails.planRepDetails;
        },
        (error: HttpErrorResponse) => {
          this.planRepresentativeDetails = [];
        }
      )
    );
  }
  getPlanParticipantDetails() {
    this.selectedParticList = []
    this.subscriptions.push(
      this.planDetailsServce.getTracParticipantsDetailsFromPlanCaseNo(this.selectedPlanObj[0]?.planNo).subscribe(
        (planParticipantDetails: GetParticipantDetails) => {
          this.planParticipantDetails = planParticipantDetails ? planParticipantDetails.planParticipantDetails : [];
          this.particTrackActivateForm.get('globalContractDate').setValue(undefined);
          this.buildParticipantContractDateFormController(this.planParticipantDetails);
          this.dataSource = this.planParticipantDetails;
          this.numberOfItems = this.dataSource.length;
          this.setupGrid();
          this.paginate({
            limit: 5,
            offset: 1,
          });
        },
        (error: HttpErrorResponse) => {
          this.dataSource = [];
          this.setupGrid();
        }
      )
    );
  }

  buildParticipantContractDateFormController(planParticipantDetails: GetParticipantDetails[]): void {
    const contractDateControllerObject = {};
    planParticipantDetails.forEach((participant) => {
      const part = participant.potentialParticipantId.toString();
      let formatedDate = undefined;
      if (!!participant.contractDtInString){
        formatedDate = formatDate(participant.contractDtInString, 'MM-dd-yyyy', 'en');
      }
      contractDateControllerObject[part] = [formatedDate, [Validators.required]];
    })
    this.particContractDateForm = this.fb.group(contractDateControllerObject);
  }

  onContractDateChange($event: any, getIkParticipant: GetParticipantDetails): void {
    const inputContractDate = formatDate(`${$event.month}/${$event.day}/${$event.year}`, 'MM/dd/yyyy', 'en');
    getIkParticipant.contractDtInString = inputContractDate;
    this.setIsActivateParticipantsAllowed();
  }

  onGlobalContractDateChange($event: any): void {
    const inputContractDate = formatDate(`${$event.month}/${$event.day}/${$event.year}`, 'MM/dd/yyyy', 'en');
    this.dataSource.forEach((participant: GetParticipantDetails) => {
      participant.contractDtInString = inputContractDate;
    })
    this.buildParticipantContractDateFormController(this.dataSource);
    this.setIsActivateParticipantsAllowed();
  }

  onContractDateKeyUp(potentialParticipantId: number): void {
    const potentialParticId = this.particContractDateForm.get(`${potentialParticipantId}`).value;
    if (this.DATE_FORMAT.test(potentialParticId) || potentialParticId === null) {
      this.dataSource.forEach((participant: GetParticipantDetails) => {
        if (participant.potentialParticipantId === potentialParticipantId) {
            participant.contractDtInString = potentialParticId;
            this.setIsActivateParticipantsAllowed();
        }
      })
    }
  }

  onGlobalContractDateKeyUp(): void {
    const myValue = this.particTrackActivateForm.get('globalContractDate').value;
    if (this.DATE_FORMAT.test(myValue)) {
      const month = myValue.substring(0, 2);
      const day = myValue.substring(3, 5);
      const year = myValue.substring(6, 10) ;
      this.onGlobalContractDateChange({month:month, day:day, year:year});
    }
  }
  
  isControlInvalid(controlName: string): boolean {
    const control = this.particTrackActivateForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  getChooseErrorMessage(preText: string): string {
    return `Please select a ${preText}`;
  }
  get participantIkControl() {
    return this.particTrackActivateForm.controls;
  }
  checkAll() {
    for (const value of this.dataSource) {
      value.selected = true;
    }
    this.selectedParticList = this.dataSource;
    this.setIsActivateParticipantsAllowed();
  }

  uncheckAll() {
    for (const value of this.dataSource) {
      value.selected = false;
    }
    this.selectedParticList = [];
    this.isActivateParticipantsAllowed = false;
  }

  clearDates() {
    for (const value of this.dataSource) {
      value.contractDtInString = '';
    }
    this.buildParticipantContractDateFormController(this.dataSource);
    this.isActivateParticipantsAllowed = false;
    this.particTrackActivateForm.get('globalContractDate').setValue(undefined);
  }

  updateCheckedOptions(option, event) {
    if (event.target.checked) {
      this.selectedParticList.push(option);
    } else {
      for (let i = 0; i < this.dataSource.length; i++) {
        if (this.selectedParticList[i]?.ssn === option.ssn) {
          this.selectedParticList.splice(i, 1);
        }
      }
    }
    this.setIsActivateParticipantsAllowed();
  }

  setIsActivateParticipantsAllowed(): void {
    const hasProgramFee = this.selectedFeeprogram != null && this.selectedFeeprogram.length > 0;
    const hasRep = this.getRepData().length > 0;
    const selectedParticipantList = this.getParticList();
    let hasParticipantWithDate = selectedParticipantList.length > 0;
    for (let i = 0; i < selectedParticipantList.length; i++) {
      if(!selectedParticipantList[i].contractDt) {
        hasParticipantWithDate = false;
        break;
      }
    }
    this.isActivateParticipantsAllowed = hasProgramFee && hasRep && hasParticipantWithDate;
  }

  activateParticipants() {
    this.submitted = true;
    if (this.particTrackActivateForm.valid) {
      const activateParticReqObj = {
        planId: this.selectedPlanObj[0]?.planId,
        planNo: this.selectedPlanObj[0]?.planNo,
        mgrId: +this.mnyMgr,
        feeProgId: this.selectedFeeprogram[0]?.feeProgramId,
        ppaFeePct: this.selectedFeeprogram[0]?.ppaFeePct,
        riaFeePct: this.selectedFeeprogram[0]?.riaFeePct,
        mgrFeePct: this.selectedFeeprogram[0]?.mgrFeePct,
        solFeePct: this.selectedFeeprogram[0]?.solicitorFeePct,
        totFeePct: this.selectedFeeprogram[0]?.totMgmtFeePct,
        tpaTeamFeePct: this.selectedFeeprogram[0]?.tpaFeePct,
        repIdList: this.getRepData(),
        solId: this.selectedRepList ? this.selectedRepList[0]?.solicitorId : '',
        stagedParticipantsList: this.getParticList(),
      };

      this.subscriptions.push(
        this.planDetailsServce.activateParticpantsData(activateParticReqObj).subscribe(
          (activateParticResponse) => {
            window.scroll(0, 0);
            this.successMessage = { message: activateParticResponse.status, details: [] };
            this.errorMessage = { message: '', details: [] };
            this.getPlanParticipantDetails();
          },
          (error: HttpErrorResponse) => {
            window.scroll(0, 0);
            this.successMessage = { message: '', details: [] };
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        )
      );
    } else {
      window.scroll(0, 0);
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
  }
  getRepData(): any[] {
    const repList = this.selectedRepList.map((oneRep) => oneRep.repId.toString());
    return repList;
  }
  getParticList(): any[] {
    this.selectedParticList.forEach((participant) => {
        participant.contractDt = participant.contractDtInString ? 
          formatDate(participant.contractDtInString, 'yyyy-MM-dd', 'en') : null;
    });
    const mappedParticList = this.selectedParticList.map(({ potentialParticipantId, contractDt }) => ({
      potentialParticipantId,
      contractDt,
    }));
    return mappedParticList;
  }

  removeParticipants() {
    this.submitted = true;
    const removeRequestList = [];
    this.particTrackActivateForm.controls.representative.clearValidators();
    this.particTrackActivateForm.controls.representative.updateValueAndValidity();
    this.selectedParticList.forEach((participant) => removeRequestList.push(participant.potentialParticipantId));
    this.subscriptions.push(
      this.planDetailsServce.removeTracParticipants(removeRequestList).subscribe(
        (removeParticResponse) => {
          window.scroll(0, 0);
          this.successMessage = { message: removeParticResponse.status, details: [] };
          this.errorMessage = { message: '', details: [] };
          this.getPlanParticipantDetails();
        },
        (error: HttpErrorResponse) => {
          window.scroll(0, 0);
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          this.successMessage = { message: '', details: [] };
        }
      )
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
