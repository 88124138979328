/* eslint-disable @typescript-eslint/naming-convention */
export const alertMessages = {
  // Login Messages
  LOGIN_SUCCESS_MESSAGE: 'You were successfully logged in',
  INVALID_EMAIL_MESSAGE:
    'You entered an improperly formatted email address. If you feel like you have received this error by mistake, please contact support',
  ERROR_LOGIN_ATTEMPT_MESSAGE:
    'This account is currently locked out for the next 20 minutes due to excessive login attempts. Please try logging in again in 20 minutes. If you have forgotten your password, please click forgot password',
  LOGIN_ERROR_MESSAGE:
    'Login Failed Please try again. If you have forgotten your password, please click Forgot Password',
  // Add&Edit User Messages
  SUCCESS: 'Success',
  USER_SUCCESS_MESSAGE: 'User details saved successfully',
  USER_ERROR_MESSAGE: 'You must correct the information below before proceeding.',
  USER_ALREADY_EXIST: 'User Already Exists',
  USER_CREATED: 'user created',
  USER_ROLE_REQUIRED: 'User role required',
  IS_ADMIN_MESSAGE: 'User added as administrator',
  NO_MAKE_ADMIN_MESSAGE: 'User is removed as administrator',
  // TPA&MM add/edit messages
  TPA_USER_CREATED: 'TPA user and fees successfully created',
  TPA_USER_ALREADY_EXISTS: 'TPA User already exists',
  TPA_USER_UPDATED: 'TPA user and fees updated successfully',
  MM_USER_UPDATED: 'MM user is updated',
  MM_USER_ALREADY_EXISTS: 'MM User already exists',
  EDIT_MONEY_MANAGER_SUCCESS_MESSAGE: 'Money Manager details saved successfully',
  MANDATORY_FIELDS_ERROR_MESSAGE: 'Please select all required fields',
  NEW_ACTIVE_MONEY_MANAGER_MESSAGE: 'New Active Money Manager added',
  NEW_INACTIVE_MONEY_MANAGER_MESSAGE: 'New Inactive Money Manager added',
  MONEY_MANAGER_ALREADY_EXISTS: 'Money Manager already exists',
  EDIT_ACTIVATE_MNYMGR_MESSAGE: 'Money Manager is active now',
  EDIT_DEACTIVATE_MNYMGR_MESSAGE: 'Money Manager is not active now',
  ADD_MNYMGR_NATIONWIDE_ID_EXIST: 'Nationwide ID already exists',
  NEW_ACTIVE_TPA_ADDED: 'New Active TPA added',
  NEW_INACTIVE_TPA_ADDED: 'New Inactive TPA added',
  // CC landing search
  PLAN_NUMBER_NOT_EXIST: 'Plan number does not exist',
  PROFILE_NOT_ASSIGNED: 'Profile not assigned',
  PRO_ACCOUNT_NOT_EXIST: 'ProAccount is not available on this plan',
  PARTICIPANT_NOT_EXIST: 'A participant with that SSN does not exist for the plan.',
  // TPA
  TPA_ALREADY_EXISTS: 'TPA User already exists',
  TPA_IS_ACTIVE_NOW: 'TPA is active now',
  TPA_IS_NOT_ACTIVE_NOW: 'TPA is not active now',
  TPA_DETAILS_SAVED_SUCCESSFULLY: 'TPA details saved successfully',
  // Edit Core Plan Messages
  PLAN_FEE_EDIT_MESSAGE: 'Fee Verification Required',
  STADITION_PLAN_MESSAGE:
    'Stadion does not offer 403(b) plan or 457 plans. Do not proceed with this plan entry. It may be necessary to consult investment Rep associated with this plan',
  ENTER_PROVIDER_PRODUCT_ID: 'Please choose a Provider’s Product',
  ENTER_PLAN_TYPE: 'Please choose a Plan Type',
  ENTER_TPA: 'Please choose a Third-Party Administrator',
  ENTER_MONEY_MANAGER: 'Please choose a Money Manager',
  ENTER_INITIAL_ASSETS: 'Please enter the Plans Initial Assets',
  ENTER_PLAN_NAME: 'Please enter the Plan name',
  ENTER_CASE_NUMBER: 'Please enter the Plan Case Number',
  ENTER_PLAN_EIN: 'Please enter the Plan EIN', // stadition
  ENTER_PLAN_NUMBER: 'Please select the Plan number', // stadition
  ENTER_PROVIDER_MAPPING: 'Does this plan use Provider Mapping?', // stadition
  ENTER_SOLICITOR_FIRM: 'You must select a BD Firm or “Not Listed” to continue', // stadition
  ENTER_STADITION_PLAN_OPTION: 'Please Select A Stadion StoryLine Option', // stadition
  ENTER_COADVISORY_FIRM: 'Please Select a co-advisory Firm', // Brinker
  CORE_FUND_STRATEGY_STADION_MM:
    'Stadions Core Fund Investment Strategy requires Trustee selection of Participant Choice or Stadion QDIA/Aut"omatic Management. Please make the selection below',
  FLEXIBLE_INVEST_INFO: 'Flexible Plan Investments Ltd. is allowed on "New Start-Up" or "New Takeover" options only.',
  EDIT_CORE_PLAN_INFO:
    'Editing Plan Information will invalidate any Plan Sponsor Documents and Enrollment forms you have previously created. You will need to recreate your documents in order to reflect the changes made on this page as previous documents will no longer be available for printing.',
  MANAGER_MAPPING_INFO:
    'This feature can only be utilized if the plan is moving from one RIA Money Manager firm to another. Only participants currently utilizing MAS will be mapped to the new manager.',
  AUTO_MGMT_INFO:
    'This feature can be used to default enroll all plan participants into the managed accounts program. When selected, this process will require all participants that wish to opt out of the manangement service to complete an opt out form.',
  STADION_PLAN_MSG_403B_457: 'Stadion does not offer MAS on Erisa 403(b) and Non-Profit 457 Plans. Do not proceed with this plan entry. It may be necessary to consult the Investment Rep associated with this plan.',
    // Sponsor and trustee messages
  EMPLOYEE_SPONSOR_CREATED: 'Plan Sponsor has been created.',
  EMPLOYEE_SPONSOR_REMOVED: 'Plan Sponsor has been removed.',
  EMPLOYEE_SPONSOR_UPDATED: 'Plan Sponsor has been updated.',
  TRUSTEE_CREATED: 'Plan Trustee has been created.',
  TRUSTEE_REMOVED: 'Plan Trustee has been removed.',
  TRUSTEE_UPDATED: 'Trustee  has been updated.',
  // Plan Fee Schedule messages
  PLAN_FEE_SCHEDULE_UPDATED: 'Plan Fee Schedule is updated',
  PLAN_FEE_SCHEDULE_ADDED: 'Plan Fee Schedule is added',
  PLAN_FEE_VERIFICATION_UPDATED: 'Plan Fee Verification is updated',
  SELECT_PLAN_VERIFICATION_FEE: 'Please choose a Plan Verification Fee',
  // Solicitor messages
  SOLICITOR_UPDATED: 'Solicitor is updated',
  NO_APPROVED_SOLICITOR: 'There is no approved solicitor',
  APP_SOLICITOR_UPDATED: 'Assigned solicitor is updated',
  APP_SOLICITOR_ADDED: 'App solicitor is added',
  CSV_FILE_ONLY: 'Please select a csv file',
  // Core plan messages
  CORE_PLAN_CREATED: 'Plan is created',
  CORE_PLAN_UPDATED: 'Plan is updated',
  CORE_PLAN_ACTIVATED: 'Plan is activated',
  // System not available messages
  SYSTEM_UNAVAILABLE_MESSAGE:
    'The system is currently unavailable. Please try back later, or contact support if you want immediate assistance',
  REPRESENTATIVE_CREATED: 'Representative  has been created',
  REPRESENTATIVE_UPDATED: 'Representative  has been updated',
  REPRESENTATIVE_REMOVED: 'Representative has been removed.',
  BOOK_CASE_INFO: 'The selected document will open in a new window',

  // plan checklist messages
  PLAN_CHECKLIST_SAVE_SUCCESS: 'Save plan revision completed successfully.',
  PLAN_CHECKLIST_COMMIT_SUCCESS: 'Commit plan revision completed successfully.',
  PLAN_CHECKLIST_DELETE_SUCCESS: 'Plan revision deleted successfully.',

  // Matrix document
  EDIT_MATRIX_DOCUMENT_UPDATED: 'Document details updated successfully.',
  DELETE_MATRIX_DOCUMENT: 'Document details deleted successfully',
  // Plan notes
  PLAN_NOTES_ADDED: 'Plan note is added',
  // Plan Fee schedule
  PLAN_FEE_COMPENSATION_EXCEEDS:
    'Denotes Plan fee compensation exceeds the revised fee structure implemented in August 2008',
  STADION_PLAN_SOLICITOR_FEES: 'Stadion Money Management does not pay solicitor fees to plan advisors',
  NRP_BUNDLED_NRP_TPA_EXCEEDS: 'This plan requires custom documents contact RIA_Service',
  // Solicitor upload file
  SOLICITOR_FILE_UPLOAD: 'Please choose a file',
  SOLICITOR_FEE_MAX_ERROR: 'Please enter a fee between 0.00 and 0.20.',
  SELECT_MNY_MGR: 'Please select a money manager from participant upload',
  NO_PLAN_SELECTED_MONEY_MANAGER: 'There is no plan for selected Money Manager',
  //System Message
  SYSTEM_MESSAGE_ADDED: 'System message is added',
  SYSTEM_MESSAGE_UPDATED: 'System message is updated',
  //Money manager program message
  CLS_MEEDER_INFO: 'Contact your Plan Representative for Program and Fee Structure Information',
  //Document option matrix
  DOCUMENT_OPTION: 'Document options populated successfully',
  //500 server error
  INTERNAL_SERVER_ERROR : 'Internal server error. Please try again later',
  USER_NOT_ALLOWED_TO_SEARCH_WITH_NW_CREDENTIALS: 'Please use other search options',
  NO_DOCUMENT_FOUND : 'There is no available booklet documents'
};

export const modalMessage = {
  CONFIRMATION: 'Confirmation',
  NO_TEXT: 'No',
  YES_TEXT: 'Yes',
  OK_TEXT: 'Ok',
  REMOVE_EMPLOYEE_SPONSOR_MSG: 'Do you want to remove the employee sponsor ?',
  REMOVE_TRUSTEE_MSG: 'Do you want to remove the trustee ?',
  REMOVE_REP_MSG: 'Do you want to remove the representative ?',
  CORE_PLAN_MSG:
    'Selecting ADVISOR ONE will prevent you from returning to the CLS Hybrid-75 program currently utilized by this plan. In addition, a new Plan Sponsor Agreement and an Affiliated Funds Schedule Fees will be required in order to implement the ADVISOR ONE program. Advisor One CLS Participant Enrollment Agreements will not be available untill the new PSA is approved. Would you like to switch to ADVISOR ONE ?',

  // Plan check list messages
  PLAN_CHECKLIST_DELETE_CONFIRMATION:
    'The action cannot be undone. Are you sure you want to Delete the current plan revision?',
  PLAN_CHECKLIST_COMPLETE_CONFIRMATION:
    'The action cannot be undone. Are you sure you want to complete the plan revision?',
  PLAN_CHECKLIST_COMPLETE_WARNING: 'You must Save changes before you can complete them',
  PLAN_CHECKLIST_DELETE_NO_ITEM_MESSAGE: 'You dont have any saved revisions to delete',
  
  //core plan pending changes
  PlAN_CLEAR_PENDING_CHANGES: 'The action cannot be undone. Are you sure you want to clear pending changes?',
  PlAN_ACTIVATE_PENDING_CHANGES: 'The action cannot be undone. Are you sure you want to activate pending changes?'
};

export const actionText = {
  SAVE: 'Save',
  UPDATE: 'Update',
  REMOVE: 'Remove',
  ADD: 'Add',
};

export const validationPatterns = {
  emailPattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$',
  namePattern: '/(^[a-zA-Z][a-zA-Zs]{0,20}[a-zA-Z]$)/',
  numberPattern: '^(0|[0-9][0-9]*)$',
  alphaNumWithSpecialCharPattern: '^([^0-9]*)$',
  alphaNumericPattern: '^[ A-Za-z0-9_@./#&+-]*$',
  addressPattern: '^[#.0-9a-zA-Zs, -]+$',
  cityPattern: '^[a-zA-Z]+(?:(?:\\s+|-)[a-zA-Z]+)*$',
  zipPattern: '^[0-9]{5}(-[0-9])?$',
  phonePatternMask: /^\(\d{3}\)\s\d{3}-\d{4}$/,
  faxPattern: '^[0-9]*$',
  planIdPattern: '^d{3}-d{5}$',
  planNoPattern: '^(\\d{3}-?\\d{5}|XXX-XXXXX)$',
  einPattern: '^d{2}-d{7}$',
  planNumberPattern: '^(\\d{3}-?\\d{5}|XXX-XXXXX)$',
  ssnPattern: '^(\\d{3}-?\\d{2}-?\\d{4}|XXX-XX-XXXX)$',
  cmpNamePattern:`^[ A-Za-z0-9',./()&-]*$`
};

export const statesList = [
  {
    label: 'AL : Alabama',
    value: 'AL',
  },
  {
    label: 'AK : Alaska',
    value: 'AK',
  },
  {
    label: 'AS : American Samoa',
    value: 'AS',
  },
  {
    label: 'AZ : Arizona',
    value: 'AZ',
  },
  {
    label: 'AR : Arkansas',
    value: 'AR',
  },
  {
    label: 'CA : California',
    value: 'CA',
  },
  {
    label: 'CO : Colorado',
    value: 'CO',
  },
  {
    label: 'CT : Connecticut',
    value: 'CT',
  },
  {
    label: 'DE : Delaware',
    value: 'DE',
  },
  {
    label: 'DC : District of Columbia',
    value: 'DC',
  },
  {
    label: 'FM : Federated States of Micronesia',
    value: 'FM',
  },
  {
    label: 'FL : Florida',
    value: 'FL',
  },
  {
    label: 'GA : Georgia',
    value: 'GA',
  },
  {
    label: 'GU : Guam',
    value: 'GU',
  },
  {
    label: 'HI : Hawaii',
    value: 'HI',
  },
  {
    label: 'ID : Idaho',
    value: 'ID',
  },
  {
    label: 'IL : Illinois',
    value: 'IL',
  },
  {
    label: 'IN : Indiana',
    value: 'IN',
  },
  {
    label: 'IA : Iowa',
    value: 'IA',
  },
  {
    label: 'KS : Kansas',
    value: 'KS',
  },
  {
    label: 'KY : Kentucky',
    value: 'KY',
  },
  {
    label: 'LA : Louisiana',
    value: 'LO',
  },
  {
    label: 'ME : Maine',
    value: 'ME',
  },
  {
    label: 'MH : Marshall Islands',
    value: 'MH',
  },
  {
    label: 'MD : Maryland',
    value: 'MD',
  },
  {
    label: 'MA : Massachusetts',
    value: 'MA',
  },
  {
    label: 'MI : Michigan',
    value: 'MI',
  },
  {
    label: 'MN : Minnesota',
    value: 'MN',
  },
  {
    label: 'MS : Mississippi',
    value: 'MS',
  },
  {
    label: 'MO : Missouri',
    value: 'MO',
  },
  {
    label: 'MT : Montana',
    value: 'MT',
  },
  {
    label: 'NE : Nebraska',
    value: 'NE',
  },
  {
    label: 'NV : Nevada',
    value: 'NV',
  },
  {
    label: 'NH : New Hampshire',
    value: 'NH',
  },
  {
    label: 'NJ : New Jersey',
    value: 'NJ',
  },
  {
    label: 'NM : New Mexico',
    value: 'NM',
  },
  {
    label: 'NY : New York',
    value: 'NY',
  },
  {
    label: 'NC : North Carolina',
    value: 'NC',
  },
  {
    label: 'ND : North Dakota',
    value: 'ND',
  },
  {
    label: 'MP : Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'OH : Ohio',
    value: 'OH',
  },
  {
    label: 'OK : Oklahoma',
    value: 'OK',
  },
  {
    label: 'OR : Oregon',
    value: 'OR',
  },
  {
    label: 'PW : Palau',
    value: 'PW',
  },
  {
    label: 'PA : Pennsylvania',
    value: 'PA',
  },
  {
    label: 'PR : Puerto Rico',
    value: 'PR',
  },
  {
    label: 'RI : Rhode Island',
    value: 'RI',
  },
  {
    label: 'SC : South Carolina',
    value: 'SC',
  },
  {
    label: 'SD : South Dakota',
    value: 'SD',
  },
  {
    label: 'TN : Tennessee',
    value: 'TN',
  },
  {
    label: 'TX : Texas',
    value: 'TX',
  },
  {
    label: 'UT : Utah',
    value: 'UT',
  },
  {
    label: 'VT : Vermont',
    value: 'VT',
  },
  {
    label: 'VI : Virgin Islands',
    value: 'VI',
  },
  {
    label: 'VA : Virginia',
    value: 'VA',
  },
  {
    label: 'WA : Washington',
    value: 'WA',
  },
  {
    label: 'WV : West Virginia',
    value: 'WV',
  },
  {
    label: 'WI : Wisconsin',
    value: 'WI',
  },
  {
    label: 'WY : Wyoming',
    value: 'WY',
  },
];

export const feeLimit = [
  0.0,
  0.01,
  0.02,
  0.03,
  0.04,
  0.05,
  0.06,
  0.07,
  0.08,
  0.09,
  0.1,
  0.11,
  0.12,
  0.13,
  0.14,
  0.15,
  0.16,
  0.17,
  0.18,
  0.19,
  0.2,
  0.21,
  0.22,
  0.23,
  0.24,
  0.25,
  0.26,
  0.27,
  0.28,
  0.29,
  0.3,
  0.31,
  0.32,
  0.33,
  0.34,
  0.35,
  0.36,
  0.37,
  0.38,
  0.39,
  0.4,
  0.41,
  0.42,
  0.43,
  0.44,
  0.45,
  0.46,
  0.47,
  0.48,
  0.49,
  0.5,
  0.51,
  0.52,
  0.53,
  0.54,
  0.55,
  0.56,
  0.57,
  0.58,
  0.59,
  0.6,
  0.61,
  0.62,
  0.63,
  0.64,
  0.65,
  0.66,
  0.67,
  0.68,
  0.69,
  0.7,
  0.71,
  0.72,
  0.73,
  0.74,
  0.75,
  0.76,
  0.77,
  0.78,
  0.79,
  0.8,
  0.81,
  0.82,
  0.83,
  0.84,
  0.85,
  0.86,
  0.87,
  0.88,
  0.89,
  0.9,
  0.91
];
export const feeLimitMGO = [0.13];
export const feeLimitZero = [0.0];
export const feeLimitCLS = [0.2];
export const solicitorFeeLimit = [
  0.0,
  0.01,
  0.02,
  0.03,
  0.04,
  0.05,
  0.06,
  0.07,
  0.08,
  0.09,
  0.1,
  0.11,
  0.12,
  0.13,
  0.14,
  0.15,
  0.16,
  0.17,
  0.18,
  0.19,
  0.2,
  0.21,
];
export const solicitorMMFeeLimit = [
  0.0,
  0.01,
  0.02,
  0.03,
  0.04,
  0.05,
  0.06,
  0.07,
  0.08,
  0.09,
  0.1,
  0.11,
  0.12,
  0.13,
  0.14,
  0.15,
  0.16,
  0.17,
  0.18,
  0.19,
  0.2,
];
export const TpaMMLimit = [
  0.0,
  0.01,
  0.02,
  0.03,
  0.04,
  0.05,
  0.06,
  0.07,
  0.08,
  0.09,
  0.1,
  0.11,
  0.12,
  0.13,
  0.14,
  0.15,
];

export const TpaAdminFee = [
  {
    value:0,
    label:0.00
  },
  {
    value:1,
    label:0.01
  },
  {
    value:2,
    label:0.02
  },
  {
    value:3,
    label:0.03
  },
  {
    value:4,
    label:0.04
  },
  {
    value:5,
    label:0.05
  },
  {
    value:6,
    label:0.06
  },
  {
    value:7,
    label:0.07
  },
  {
    value:8,
    label:0.08
  },
  {
    value:9,
    label:0.09
  },
  {
    value:10,
    label:0.10
  },
  {
    value:11,
    label:0.11
  },
  {
    value:12,
    label:0.12
  },
  {
    value:13,
    label:0.13
  },
  {
    value:14,
    label:0.14
  },
  {
    value:15,
    label:0.15
  }
]
