<section class="container-fluid temp-color">
  <ng-container>
    <div class="row">
      <div class="col-sm-2">
        <security-navigation class="sticky-nav"> </security-navigation>
      </div>
      <div class="col-sm-10">
        <main>
          <router-outlet></router-outlet>
        </main>
      </div>
    </div>
  </ng-container>
</section>
