import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'marketing-material',
  templateUrl: './marketing-material.component.html',
  styleUrls: ['./marketing-material.component.css']
})
export class MarketingMaterialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
