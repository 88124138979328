<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2 padding-left">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10 padding-left">
      <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-core-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-core-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <form class="mt-10" [formGroup]="particTrackActivateForm">
          <ul id="plan-params" summary="activate plan" class="ul-li-view-edit table stripes no-margin border-common">
            <li class="head">
              <div class="col-sm-12 padding-left">
                <b>Activate Participants</b>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left"><b>Plan:</b></div>
              <div class="col-sm-3">
                <select
                  formControlName="planNo"
                  name="planNo"
                  id="planId"
                  tabindex="5"
                  [ngClass]="{
                    'has-error': isControlInvalid('planNo')
                  }"
                  #selectedValue
                  (change)="changePlanNoEvent($event)"
                >
                  <option [ngValue]="null">--- Select from list ---</option>
                  <option
                    *ngFor="let plan of planMnyMgrDetails | orderBy: 'planDesc'; let i = index"
                    [value]="plan.planId"
                  >
                    {{ plan.planDesc }}
                  </option>
                </select>
              </div>
              <div class="col-sm-5"></div>
            </li>
            <div>
              <nfrp-inline-error
                *ngIf="
                  (participantIkControl.planNo.touched || submitted) && participantIkControl.planNo.errors?.required
                "
                errorId="planNo-valid-error"
                [message]="getChooseErrorMessage('Plan')"
              >
              </nfrp-inline-error>
            </div>
          </ul>
          <ul
            id="core-plan-params"
            summary="ik plan"
            *ngIf="planFeeDetails || planRepresentativeDetails"
            class="ul-li-view-edit table stripes margin-top-2 border-common"
          >
            <li class="head">
              <div class="col-sm-12 padding-left">
                <b>Participant Information</b>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left"><b>Fee Program:</b></div>
              <div class="col-sm-10">
                <select
                  formControlName="feeProgram"
                  name="feeProgram"
                  id="planPgm"
                  tabindex="5"
                  [ngClass]="{
                    'has-error': isControlInvalid('feeProgram')
                  }"
                  (change)="getSelectedfeePgm($event)"
                >
                  <option [ngValue]="null">--- Select from list ---</option>
                  <option *ngFor="let feeProgram of planFeeDetails; let i = index" [value]="feeProgram.feeProgramId">
                    {{ feeProgram.feeProgramDesc }} &nbsp;[PPA ={{ feeProgram.ppaFeePct }},RIA={{
                      feeProgram.riaFeePct
                    }},MGR={{ feeProgram.mgrFeePct }},SOL={{ feeProgram.solicitorFeePct }},TOT={{
                      feeProgram.totMgmtFeePct
                    }}]
                  </option>
                </select>
                <span>
                  <nfrp-inline-error
                    *ngIf="
                      (participantIkControl.feeProgram.touched || submitted) &&
                      participantIkControl.feeProgram.errors?.required
                    "
                    errorId="feeProgram-valid-error"
                    [message]="getChooseErrorMessage('Fee Program')"
                  >
                  </nfrp-inline-error
                ></span>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left"><b>Representative:</b></div>
              <div class="col-sm-4">
                <select
                  multiple
                  size="3"
                  name="representative"
                  class="rep-width"
                  (change)="setIsActivateParticipantsAllowed()"
                  formControlName="representative"
                >
                  <option *ngFor="let rep of planRepresentativeDetails; let i = index" [value]="rep">
                    {{ rep.firstName }}&nbsp;{{ rep.lastName }}&nbsp;[{{ rep.repNo }}] &nbsp;-&nbsp;{{
                      rep.solFirmName
                    }}&nbsp;[{{ rep.solicitorNbr }}]
                  </option>
                </select>
              </div>
              <div class="col-sm-6">
                <b>Global Contract Date:</b>&nbsp;
                <div class="form-group col-sm-4">
                  <nfrp-datepicker
                    [uniqueId]="'global-contract-date'"
                    [parentForm]="particTrackActivateForm"
                    [controlName]="'globalContractDate'"
                    [formSubmitted]="submitted"
                    (pickDate)="onGlobalContractDateChange($event)"
                    (keyup)="onGlobalContractDateKeyUp()"
                  >
                  </nfrp-datepicker>
                </div>
              </div>
            </li>
            <div>
              <nfrp-inline-error
                *ngIf="
                  (participantIkControl.representative.touched || submitted) &&
                  participantIkControl.representative.errors?.required
                "
                errorId="representative-valid-error"
                [message]="'Please select atleast one representative'"
              >
              </nfrp-inline-error>
            </div>
          </ul>
        </form>
        <ng-container *ngIf="dataSource">
          <ul id="core-plan-params" summary="ik plan" class="ul-li-view-edit table stripes margin-top-2">
            <li class="head">
              <div class="col-sm-3 padding-left">
                <span class="margin-left-2" (click)="checkAll()"><a>[Select All]</a></span
                >&nbsp;&nbsp;&nbsp;<span (click)="uncheckAll()"><a>[Unselect All]</a></span>
              </div>
              <div class="col-sm-4"></div>
              <div class="col-sm-2">
                <label (click)="checkPlanSelect()">[<a>Restore Default Values</a>]</label>
              </div>
              <div class="col-sm-1"></div>
              <div class="col-sm-2">
                <label class="float-right" (click)="clearDates()">[<a>Clear Dates</a>]</label>
              </div>
            </li>
          </ul>

          <ul class="ul-li-view-edit">
            <ng-template #headerTemplate>
              <div nfrpColumn [width]="10" widthType="%" [id]="'select-label'" defaultDirection="ASCENDING">
                Select
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'ssn-label'">
                SSN
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'last-name-label'">
                Last Name
              </div>
              <div nfrpColumn [width]="10" widthType="%" [id]="'first-name-label'">
                First Name
              </div>
              <div nfrpColumn [width]="20" widthType="%" [id]="'birth-date-label'">
                Birth Date
              </div>
              <div nfrpColumn [width]="20" widthType="%" [id]="'hire-date-label'">
                Hire Date
              </div>
              <div nfrpColumn [width]="20" widthType="%" [id]="'contract-date-label'">
                Contract Date
              </div>
            </ng-template>
            <ng-template #rowTemplate let-getIkParticipant="item" let-index="index">
              <div>
                <div [id]="'select-' + index">
                  <input
                    type="checkbox"
                    id="participant-{{ index }}"
                    [checked]="getIkParticipant.selected"
                    [(ngModel)]="getIkParticipant.selected"
                    (change)="updateCheckedOptions(getIkParticipant, $event)"
                  />
                </div>
                <div [id]="'ssn-' + index">
                  {{ getIkParticipant?.ssn | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'last-name-' + index">
                  {{ getIkParticipant?.lastName | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'first-name-' + index">
                  {{ getIkParticipant?.firstName | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'birth-date-' + index">
                  {{ getIkParticipant?.birthDtInString | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'hire-date-' + index">
                  {{ getIkParticipant?.hireDtInString | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'contract-date-' + index">
                  <div class="form-group">
                    <nfrp-datepicker
                      [uniqueId]="'part-contract-date-' + getIkParticipant.potentialParticipantId.toString()"
                      [parentForm]="particContractDateForm"
                      [controlName]="getIkParticipant.potentialParticipantId.toString()"
                      [formSubmitted]="submitted"
                      (pickDate)="onContractDateChange($event, getIkParticipant)"
                      (keyup)="onContractDateKeyUp(getIkParticipant.potentialParticipantId)"
                    >
                    </nfrp-datepicker>
                  </div>
                  <div>
                    <nfrp-inline-error
                      *ngIf="getIkParticipant.selected && !getIkParticipant.contractDtInString"
                      errorId="start-date-error"
                      [message]="'Required'"
                    >
                    </nfrp-inline-error>
                  </div>
                </div>
              </div>
            </ng-template>
            <nfrp-grid
              [headerTemplate]="headerTemplate"
              [rowTemplate]="rowTemplate"
              [iterable]="data"
              [width]="100"
              rowIdPrefix="ik-details"
            ></nfrp-grid>
            <nfrp-grid-paginator
              (paginate)="paginate($event)"
              [numberOfItems]="numberOfItems"
              [itemsPerPageOptions]="[5, 10, 20]"
              [defaultItemsPerPage]="5"
            ></nfrp-grid-paginator>
          </ul>
          <div class="row margin-top-1">
            <div class="col-sm-3 no-padding">
              <input
                type="button"
                class="button-primary button hover-right margin-right"
                (click)="removeParticipants()"
                value="Remove Participant(s)"
                [disabled]="selectedParticList.length === 0"
              />
            </div>
            <div class="col-sm-2 no-padding"></div>
            <div class="col-sm-3 no-padding">
              <input
                id="search-button"
                class="button-primary button hover-right margin-right"
                type="submit"
                size="40"
                (click)="activateParticipants()"
                value="Activate Participant(s)"
                [disabled]="!isActivateParticipantsAllowed"
              />
            </div>
            <div class="col-sm-4"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="loading"> <nfrp-spinner></nfrp-spinner> </ng-container>
      </ng-container>
    </div>
  </div>
</section>
