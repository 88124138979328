<section class="container-fluid temp-color">
  <ng-container *ngIf="planDetails; else loading">
    <div class="row"  *ngIf="!showOldWorkflow">
      <div class="col-sm-10">
        <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
          <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
        </div>
        <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
          <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
        </div>
        <div class="margin-bottom" *ngIf="infoMessage?.message?.length > 0">
          <nfrp-alert alertId="mm-error" [message]="infoMessage" type="info" [page]="false"> </nfrp-alert>
        </div>
        <ul
        id="representative-params"
        summary="employee sponsor"
        class="ul-li-view-edit table stripes no-margin border-common"
      >
        <li class="head" [ngClass]="{'pending-status-bg':isPending}">
          <div class="col-sm-12 padding-left">
            <b>Select a previously entered Solicitor.</b>
          </div>
        </li>
        <li>
          <div class="col-sm-3 padding-left">
            <span id="state-label" class="text-align-right">Existing Solicitors:</span>
          </div>
          <div class="col-sm-6 padding-left">
            <select name="selectedSolicitor" id="solicitor-list"  [(ngModel)]="selectedSolicitor">
              <option value="">-- Select Solicitor --</option>
              <option *ngFor="let solicitor of solicitorList" [value]="solicitor.solPrimaryId">{{ solicitor.solicitorFirmName }}</option>
            </select>
          </div>
        </li>
        </ul>
        <div class="row margin-top button-center">
          <div class="col-sm-3 no-padding">
            <input
              id="select-sol-button"
              class="button-primary button hover-left"
              type="button"
              size="40"
              (click)="chooseSolicitor()"
              value="Choose Solicitor"
            />
          </div>
          <div class="col-sm-3 no-padding" *ngIf="!hideAddNewSolicitor">
            <input
              id="new-sol-button"
              class="button-primary button hover-left"
              type="button"
              size="40"
              (click)="showNewSolicitor()"
              value="Add New Solicitor"
            />
          </div>
          <div class="col-sm-1 no-padding">
            <input
              type="button"
              class="button button-secondary hover-right margin-right"
              (click)="onCancel()"
              value="Cancel"
            />
          </div>
        </div>    
      </div>
    </div>
    <div class="row" *ngIf="showOldWorkflow">
      <div class="col-sm-10">
        <h1 id="main-title" class="page-title ng-binding">Solicitor Information</h1>
        <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
          <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
        </div>
        <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
          <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
        </div>
        <div class="margin-bottom" *ngIf="infoMessage?.message?.length > 0">
          <nfrp-alert alertId="mm-error" [message]="infoMessage" type="info" [page]="false"> </nfrp-alert>
        </div>
        <ng-container>
          <form [formGroup]="oldWorkflowForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="id-label" class="text-align-right">Solicitor ID:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                <input type="text" id="solicitor-id" formControlName="solicitorId" name="solicitorId" />                 
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="name-label" class="text-align-right">Solicitor Name*:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                <input type="text" required id="solicitor-name" formControlName="solicitorName" name="solicitorName" />
                <div>
                    <nfrp-inline-error
                    *ngIf="
                        (oldWorkflowFormControl.solicitorName.touched || submitted) && oldWorkflowFormControl.solicitorName.errors?.pattern
                    "
                    errorId="old-workflow-solicitor-name-valid-error"
                    [message]="getValidErrorMessage('Solicitor Name')"
                    >
                    </nfrp-inline-error>
                </div>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (oldWorkflowFormControl.solicitorName.touched || submitted) && oldWorkflowFormControl.solicitorName.errors?.required
                    "
                    errorId="old-workflow-solicitor-name-required"
                    [message]="getRequiredErrorMessage('Solicitor Name')"
                  ></nfrp-inline-error>
                </div>
              </div>   
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="address-one-label" class="text-align-right">Address Line One:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                  <input type="text" id="address-one" formControlName="addressLineOne" name="addressLineOne" />
                  <div>
                      <nfrp-inline-error
                      *ngIf="
                          (oldWorkflowFormControl.addressLineOne.touched || submitted) && oldWorkflowFormControl.addressLineOne.errors?.pattern
                      "
                      errorId="old-workflow-address-line-one-valid-error"
                      [message]="getValidErrorMessage('Address Line')"
                      >
                      </nfrp-inline-error>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="address-two-label" class="text-align-right">Address Line Two:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                  <input type="text" id="address-two" formControlName="addressLineTwo" name="addressLineTwo" />
                  <div>
                      <nfrp-inline-error
                      *ngIf="
                          (oldWorkflowFormControl.addressLineTwo.touched || submitted) && oldWorkflowFormControl.addressLineTwo.errors?.pattern
                      "
                      errorId="old-workflow-address-line-two-valid-error"
                      [message]="getValidErrorMessage('Address Line')"
                      >
                      </nfrp-inline-error>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="city-label" class="text-align-right">City:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                  <input type="text" id="city" formControlName="city" name="city" />
                  <div>
                      <nfrp-inline-error
                      *ngIf="
                          (oldWorkflowFormControl.city.touched || submitted) && oldWorkflowFormControl.city.errors?.pattern
                      "
                      errorId="old-workflow-city-valid-error"
                      [message]="getValidErrorMessage('City')"
                      >
                      </nfrp-inline-error>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="state-label" class="text-align-right">State:</span>
              </div>
              <div class="col-sm-5 padding-left padding-bottom-select">
                <select name="state" id="mny-mgr-state" formControlName="state">
                  <option value="">-- Choose from List --</option>
                  <option *ngFor="let state of statesList" [value]="state.value">{{ state.label }}</option>
                </select>
              </div>           
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="zip-label" class="text-align-right">Zip:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                  <input type="text" id="zip" formControlName="zip" mask="00000"
                  [patterns]="numberMaskPattern" name="zip" />
                  <div>
                      <nfrp-inline-error
                      *ngIf="
                          (oldWorkflowFormControl.zip.touched || submitted) && oldWorkflowFormControl.zip.errors?.pattern
                      "
                      errorId="old-workflow-zip-valid-error"
                      [message]="getValidErrorMessage('Zip')"
                      >
                      </nfrp-inline-error>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="phone-label" class="text-align-right">Phone:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                  <input type="text" id="phone" formControlName="phone"  mask="000-000-0000"
                  [patterns]="numberMaskPattern" name="phone" />
                  <div>
                      <nfrp-inline-error
                      *ngIf="
                          (oldWorkflowFormControl.phone.touched || submitted) && oldWorkflowFormControl.phone.errors?.pattern
                      "
                      errorId="old-workflow-phone-valid-error"
                      [message]="getValidErrorMessage('Phone')"
                      >
                      </nfrp-inline-error>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="fax-label" class="text-align-right">FAX:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                  <input type="text" id="fax" formControlName="fax" name="fax"   mask="000-000-0000"
                  [patterns]="numberMaskPattern"/>
                  <div>
                      <nfrp-inline-error
                      *ngIf="
                          (oldWorkflowFormControl.fax.touched || submitted) && oldWorkflowFormControl.fax.errors?.pattern
                      "
                      errorId="old-workflow-fax-valid-error"
                      [message]="getValidErrorMessage('FAX')"
                      >
                      </nfrp-inline-error>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 padding-left">
                  <span id="email-label" class="text-align-right">Email:</span>
              </div>
              <div class="col-sm-6 padding-left flex-direction-col">
                  <input type="text" id="email" formControlName="email" name="eamil" />
                  <div>
                      <nfrp-inline-error
                      *ngIf="
                          (oldWorkflowFormControl.email.touched || submitted) && oldWorkflowFormControl.email.errors?.pattern
                      "
                      errorId="old-workflow-email-error"
                      [message]="getValidErrorMessage('Email')"
                      >
                      </nfrp-inline-error>
                  </div>
              </div>
            </div>
            <div class="row margin-top">
              <div class="col-sm-1 no-padding">
                <input
                  id="save-button"
                  class="button-primary button hover-left margin-left"
                  type="submit"
                  size="40"
                  value="Save"
                />
              </div>
              <div class="col-sm-10 no-padding"></div>
              <div class="col-sm-1 no-padding">
                <input
                  type="button"
                  class="button button-secondary hover-right margin-right"
                  (click)="onCancel()"
                  value="Cancel"
                />
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </ng-container>
 
</section>

<nfrp-navigate-away-modal></nfrp-navigate-away-modal>

<nfrp-default-modal
  [header]="modalOptions.header"
  [message]="modalOptions.message"
  [noButtonValue]="modalOptions.noButtonValue"
  [yesButtonValue]="modalOptions.yesButtonValue"
  [showModal]="modalOptions.showModal"
  (noClicked)="noClicked()"
  (yesClicked)="yesClicked()"
>
</nfrp-default-modal>
<ng-template #loading>
  <nfrp-spinner></nfrp-spinner>
</ng-template>
