<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2 padding-left">
      <security-navigation class="sticky-nav">
      </security-navigation>
    </div>
    <div class="col-sm-10 padding-left">
      <h1 id="main-title" class="page-title ng-binding">
        <span *ngIf="planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1"><i class="nw-icon-check f-green"></i></span>
        &ensp;{{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }})&ensp;
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom alert-container--error" *ngIf="errorMessage?.message?.length > 0">

        <div class="col-sm-12 no-padding">
          <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"></nfrp-alert>
        </div>
      </div>
      <form [formGroup]="planSponsorForm">
        <div class="box compartment summary animation-fade-in">
          <!-- *ngIf="createDoc && !planNoRecord" -->
          <ng-container *ngIf="createDoc">
            <div class="section__header" role="button" [ngClass]="{'pending-status-bg':isPending}">
              <h2 class="content-title"><b>Required Plan Sponsor Documents </b></h2>
              <i id="ul-li-view-header-chevron"></i>
            </div>
            <div class="content form-group">
              <div class="section__body" >
                <div *ngIf="searchPlanSponsorDoc">
                <div *ngFor="let psd of searchPlanSponsorDoc; let i = index">
                  <label for="documentDetails">
                    <input type="checkbox" [value]="psd.documentId" (change)="onCheckboxChange($event, psd)"  />
                    {{ psd.documentName }}<span  class="f-red" *ngIf="psd.documentId === 625 && shouldShowHiddenMessage"> Hidden From User</span></label
                  >
                </div>
              </div>
              </div>
            </div>
            <ng-container *ngIf="loading">
              <nfrp-spinner></nfrp-spinner>
            </ng-container>            
          </ng-container>
        </div>
        <div class="row">
          <div class="padding-left-2 col-sm-2"><input
            type="button"
            type="submit"
            value="Create Docs"
            (click)="createDocument()"
            [disabled]="sponsorDisabled"
            class="button button-primary"
          />
          </div>
          <div class="col-sm-8"></div>
          <div class="col-sm-2"><input type="button" type="submit" value="Cancel" (click)="onClickCancel()"  class="button button-secondary"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
