<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2 padding-left">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10 padding-left">
      <!-- <h1 id="main-title" class="page-title ng-binding">
        Digimation 401(k) Profit Sharing
      </h1> -->
      <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-core-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="edit-core-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <form class="mt-10" [formGroup]="particIkForm">
          <div class="padding-left" *ngIf="userTypeID === riaUserId">
            Money Manager:
            <select
              formControlName="mnyMgrId"
              name="mnyMgrId"
              id="MNY_MGR_ID"
              tabindex="5"
              title="Money Manager"
              [ngClass]="{
                'has-error': isControlInvalid('mnyMgrId')
              }"
              (change)="getSelectedMM($event.target.value)"
            >
              <option [ngValue]="null">--- Select from list ---</option>
              <option
                *ngFor="let moneyManager of mmNonWalledOffList | orderBy: 'moneyManagerLabel'; let i = index"
                [value]="moneyManager.moneyManagerId"
              >
                {{ moneyManager.moneyManagerLabel }} {{ moneyManager.wallOff ? '(W)' : '' }}
              </option>
            </select>
            <span>
              <nfrp-inline-error
                *ngIf="
                  (participantIkControl.mnyMgrId.touched || submitted) && participantIkControl.mnyMgrId.errors?.required
                "
                errorId="mnymgrid-valid-error"
                [message]="getChooseErrorMessage('Money Manager')"
              >
              </nfrp-inline-error
            ></span>
          </div>
          <div class="padding-left" *ngIf="userTypeID === mnyMgrUser">
            <b>Money Manager:</b>&nbsp;&nbsp;{{ loggedInMnyMgr }}
          </div>
          <ul
            id="core-plan-params"
            summary="core plan"
            class="ul-li-view-edit table stripes margin-top-1 border-common"
          >
            <li class="head">
              <div class="col-sm-12 padding-left">
                <b>Upload Participant Data </b>
              </div>
            </li>
            <li>
              <div class="margin-left-6">
                <b>CSV File:</b>&nbsp;<span>
                  <input
                    type="file"
                    id="add-select-file-input"
                    class="no-border-shadow no-padding-left"
                    name="file"
                    #fileInput
                    formControlName="file"
                    (change)="onChangeFile($event)"
                    (click)="updateFileStatus(fileInput)"
                    [ngClass]="{ 'has-error': isFileControlInvalid('file') }"
                  />
                  <div>
                    <nfrp-inline-error
                      *ngIf="isFileControlInvalid('file')"
                      errorId="add-select-file-input-required"
                      [message]="getChooseErrorMessage('File')"
                    >
                    </nfrp-inline-error>
                  </div>
                </span>
                <div class="row margin-top-2">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-2 padding-left">
                    <input
                      id="search-button"
                      class="button-primary button hover-right margin-right"
                      type="submit"
                      (click)="saveParticipantFile()"
                      value="Upload"
                    />
                  </div>
                  <div class="col-sm-2"></div>
                  <div class="col-sm-1">
                    <input
                      type="button"
                      class="button button-secondary hover-left margin-right"
                      (click)="cancel()"
                      value="Cancel"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ng-container *ngIf="dataSource">
            <ul id="core-plan-params" summary="core plan" class="ul-li-view-edit table stripes margin-top-2">
              <li class="head">
                <div class="col-sm-12 padding-left">
                  <b>Uploaded Participant Files</b
                  ><span class="margin-left-15 f-red">(Files will only be available for 14 days.)</span>
                </div>
              </li>
            </ul>
            <ul class="ul-li-view-edit">
              <ng-template #headerTemplate>
                <div nfrpColumn [width]="60" widthType="%" [id]="'file-name-label'" defaultDirection="ASCENDING">
                  File name
                </div>
                <div nfrpColumn [width]="20" widthType="%" [id]="'records-label'">
                  Records Staged/Updated
                </div>
                <div nfrpColumn [width]="20" widthType="%" [id]="'errors-label'">
                  Errors
                </div>
              </ng-template>
              <ng-template #rowTemplate let-getIkParticipant="item" let-index="index">
                <div>
                  <div [id]="'file-name-' + index">
                    <b>{{ getIkParticipant?.fileName | nfrpNormalizeValue: '-' }}</b
                    ><span>&nbsp;| {{ getIkParticipant?.oldDays }} Day(s) Old | <a href="javascript:void(0);" (click)="downloadOriginalFile(getIkParticipant.tracCsvId)">Original File</a></span>
                    <div class="margin-top-1">
                      <label
                        >Case Numbers &nbsp;{{ getIkParticipant?.planCaseNumbers | nfrpNormalizeValue: '-' }}</label
                      >
                    </div>
                  </div>
                  <div [id]="'records-' + index">
                    {{ getIkParticipant?.stagedRecords | nfrpNormalizeValue: '-' }}
                  </div>
                  <div [id]="'errord-' + index">
                    {{ getIkParticipant?.errorRecords | nfrpNormalizeValue: '-' }}
                    <div class="margin-top-1" *ngIf="getIkParticipant?.errorRecords"><a href="javascript:void(0);" (click)="downloadErrorFile(getIkParticipant.tracCsvId)">Error CSV</a></div>
                  </div>
                </div>
              </ng-template>
              <nfrp-grid
                [headerTemplate]="headerTemplate"
                [rowTemplate]="rowTemplate"
                [iterable]="data"
                [width]="100"
                rowIdPrefix="ik-details"
              ></nfrp-grid>
            </ul>
            <nfrp-grid-paginator
              (paginate)="paginate($event)"
              [numberOfItems]="numberOfItems"
              [itemsPerPageOptions]="[5, 10, 20]"
              [defaultItemsPerPage]="5"
            ></nfrp-grid-paginator>
          </ng-container>
        </form>
        <ng-container *ngIf="loading"> <nfrp-spinner></nfrp-spinner> </ng-container>
      </ng-container>
    </div>
  </div>
</section>
