<div class="padding-top">
  <ng-container *ngIf="dataSource">
    <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
      <nfrp-alert alertId="plan-note-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
    </div>
    <ul class="padding-sides" id="plan-head" summary="employee dates" class="ul-li-view-edit">
      <li [ngClass]="{'pending-status-bg':pendingStatus}">
        <div class="col-sm-12 padding-left">
          <b class="margin-right-10">Plan Notes</b>
          <a
            *ngIf="!archivePlanStatus && status"
            class="subLink text-decor-underline"
            href="javascript:void(0);"
            routerLink="add-notes"
            ><b>Add&nbsp; & View Notes</b></a
          >
        </div>
      </li>
    </ul>
    <ul summary="employee dates" class="ul-li-view-edit">
      <ng-template #headerTemplate>
        <div nfrpColumn [width]="50" widthType="%" [id]="'plan-note-label'" defaultDirection="ASCENDING">
          Note
        </div>
        <div nfrpColumn [width]="50" widthType="%" [id]="'plan-note-created-label'">
          Created
        </div>
      </ng-template>
      <ng-template #rowTemplate let-getPlanNotes="item" let-index="index">
        <div>
          <div [id]="'plan-note-' + index">
            {{ getPlanNotes.planNotes | nfrpNormalizeValue: '-' }}
          </div>
          <div [id]="'plan-note-created-' + index">
            {{ getPlanNotes.createdDetails | nfrpNormalizeValue: '-' }}
          </div>
        </div>
      </ng-template>
      <nfrp-grid
        [headerTemplate]="headerTemplate"
        [rowTemplate]="rowTemplate"
        [iterable]="data"
        [width]="100"
        rowIdPrefix="plan-notes-details"
      ></nfrp-grid>
    </ul>
  </ng-container>
  <ng-template #loading>
    <nfrp-spinner></nfrp-spinner>
  </ng-template>
</div>
