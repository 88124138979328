import { Injectable, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PendingPlanResponse, PlanStep } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/get-plan.model';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  constructor(private planDetailsService: PlanDetailsService, private router: Router) {}
  private mnyMgr$: BehaviorSubject<string> = new BehaviorSubject(null);
  private tracMnyMgr$: BehaviorSubject<string> = new BehaviorSubject(null);
  private pendingStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userEmail: string;
  userTypeID: number;
  @Output() loadSearchStrEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() userLoggedInStatusEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadAddUserEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() loadUserAccessEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() loadMoneyManagerEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadTpAdminEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() archivePlanStatusEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() participantMMEvent: EventEmitter<any> = new EventEmitter<any>();

  public loadSearchData(searchStr: string) {
    this.loadSearchStrEvent.emit(searchStr);
  }
  public userLoggedInStatus(loggedInStatus: boolean) {
    this.userLoggedInStatusEvent.emit(loggedInStatus);
  }
  public loadAddUserView() {
    this.loadAddUserEvent.emit();
  }
  public loadUserAccessView() {
    this.loadUserAccessEvent.emit();
  }
  public loadMoneyManagerView(moneyManagerMode: string, moneyManagerData: any) {
    this.loadMoneyManagerEvent.emit({ moneyManagerMode, moneyManagerData });
  }
  public loadParticipantMMData(mnyMgr) {
    this.loadUserAccessEvent.emit();
  }
  getMnyMgr(): Observable<string> {
    return this.mnyMgr$.asObservable();
  }
  setMnyMgr(mnyMgr: string) {
    this.mnyMgr$.next(mnyMgr);
  }
  getTracMnyMgr(): Observable<string> {
    return this.tracMnyMgr$.asObservable();
  }
  setTracMnyMgr(mnyMgr: string) {
    this.tracMnyMgr$.next(mnyMgr);
  }
  public loadTPAdminView(tpAdminMode: string, tpAdminData: any) {
    this.loadTpAdminEvent.emit({ tpAdminMode, tpAdminData });
  }
  public changeArchivePlanStatus(archivePlanStatus: boolean) {
    this.archivePlanStatusEvent.emit(archivePlanStatus);
  }
  set loginUserId(userEmail: string) {
    this.userEmail = userEmail;
  }

  get loginUserId() {
    return this.userEmail;
  }
  set loginUserTypeId(userTypeID: number) {
    this.userTypeID = userTypeID;
  }
  get loginUserTypeId() {
    return this.userTypeID;
  }

  // To get next step navigation for workflow
  getPlanStepsNavigation(planId: number, isPending: boolean): any {
    let planStepOrder;
    this.planDetailsService.getPlanNextSteps(planId, isPending).subscribe((planStepResponse: PlanStep) => {
      if (planStepResponse.planStepOrder === 1) {
        planStepOrder = this.router.navigate(['/plan-summary', planId, 'employee-sponsor', 'add']);
      } else if (planStepResponse.planStepOrder === 2) {
        planStepOrder = this.router.navigate(['/plan-summary', planId, 'trustee', 'add']);
      } else if (planStepResponse.planStepOrder === 3) {
        planStepOrder = this.router.navigate(['/plan-summary', planId, 'plan-solicitor', 'add']);
      } else if (planStepResponse.planStepOrder === 4) {
        planStepOrder = this.router.navigate(['/plan-summary', planId, 'representative', 'add']);
      } else if (planStepResponse.planStepOrder === 5) {
        planStepOrder = this.router.navigate(['/plan-summary', planId, 'plan-fee-schedule', 'add']);
      } else if (planStepResponse.planStepOrder === 6) {
        planStepOrder = this.router.navigate(['/plan-summary', planId]);
      }
    });
    return planStepOrder;
  }

  setPendingStatus(pendingStatus: boolean) {
    this.pendingStatus$.next(pendingStatus);
  }

  getPendingStatus(): Observable<boolean> {
    if(sessionStorage.getItem('isPending')) {
      const status  = JSON.parse(sessionStorage.getItem('isPending'));
      this.pendingStatus$.next(status);
    }
    return this.pendingStatus$.asObservable();
  }

  setIsPendingForNewPendingPlan(savePlanResponse: PendingPlanResponse): void {
    if(savePlanResponse.newPendingPlanCreated) {      
      sessionStorage.setItem('isPending', JSON.stringify(savePlanResponse.newPendingPlanCreated));
      this.setPendingStatus(savePlanResponse.newPendingPlanCreated);
    }  
  }
}
