import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage, NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { Observable } from 'rxjs';
import { FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { TpadminSearch, TpAdminData } from '../../model/tpadmin-data';
import { FooterFeaturesService } from '../../services/footer-features.service';
import { SearchUsersService } from '../../services/search-users.service';

@Component({
  selector: 'tpaMgmt',
  templateUrl: './tpa-mgmt.component.html',
  styleUrls: ['./tpa-mgmt.component.css'],
})
export class TpaMgmtComponent extends NfrpDefaultGridComponent<TpadminSearch> implements OnInit, OnDestroy {
  @Output() loadEditTpAdmin: EventEmitter<string> = new EventEmitter<string>();
  isUserLoggedIn = false;
  tpAdminResult$: Observable<TpAdminData[]> = this.searchUserService.tpAdminResult$;
  numberOfItems: number;
  dataSource: TpadminSearch[];
  userEmail: string;
  tpaListView = true;
  tpAdminNbr: string;
  isTpAddMode = false;
  isTpEditMode = false;
  private subscriptions = [];
  searchQuery: string;
  userId: number;
  errorMessage: NfrpAlertMessage;
  successMessage: NfrpAlertMessage;
  constructor(
    nfrpGridService: NfrpGridService<TpadminSearch>,
    private searchUserService: SearchUsersService,
    private route: ActivatedRoute,
    private footerFeaturesService: FooterFeaturesService,
    private router: Router
  ) {
    super(nfrpGridService);
  }

  ngOnInit() {
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : null;
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.TPA_MGMT, this.userEmail).subscribe()
    );
    this.getTpAdmin();
  }
  getTpAdmin() {
    this.subscriptions.push(
      this.searchUserService.getTpAdmin().subscribe((response: any) => {
        this.dataSource = response;
        this.numberOfItems = this.dataSource?.length;
        this.setupGrid();
        this.paginate({
          limit: 20,
          offset: 1,
        });
      })
    );
  }

  tpAdminRowClicked(tpAdminData: TpAdminData) {
    this.tpAdminNbr = tpAdminData.tpAdminNbr;
    this.router.navigate(['tpaMgmt/edit-tpa', this.tpAdminNbr]);
  }

  searchSubmit(): void {
    this.subscriptions.push(
      this.searchUserService.getTpaBySearch(this.userId, this.searchQuery).subscribe((response: any) => {
        this.dataSource = response;
        this.numberOfItems = this.dataSource?.length;
        this.setupGrid();
        this.paginate({
          limit: 20,
          offset: 1,
        })
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
        this.successMessage = { message: '', details: [] };
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscriptions$ of this.subscriptions) {
      subscriptions$.unsubscribe();
    }
  }
}
