import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { BookCase, BookCaseDetail } from '../../model/book-case';
import { BookCaseService } from '../../services/book-case.service';
import { FooterFeaturesService } from '../../services/footer-features.service';

@Component({
  selector: 'book-case',
  templateUrl: './book-case.component.html',
  styleUrls: ['./book-case.component.css'],
})
export class BookCaseComponent implements OnInit {
  userTypeId: number;
  errorMessage: NfrpAlertMessage;
  infoMessage: NfrpAlertMessage;
  bookCaseResponse: BookCase[];
  tpa: BookCaseDetail[];
  generalTitle: BookCaseDetail[];
  moneyManager: BookCaseDetail[];
  thirdPartyAdmin: BookCaseDetail[];
  subscriptions = [];
  userEmail: string;

  constructor(private bookCaseService: BookCaseService, private footerFeaturesService: FooterFeaturesService) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : '';
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.getBookCase();
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.BOOK_CASE, this.userEmail).subscribe()
    );
  }
  getBookCase() {
    this.bookCaseService.bookCase(this.userTypeId).subscribe(
      (data: BookCase) => {
        this.bookCaseResponse = data.bookResponseList;
        this.infoMessage = { message: alertMessages.BOOK_CASE_INFO, details: [] };
        this.iterateBookCaseResponse();
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
      }
    );
  }
  downloadBookCaseFile(bookCaseData: BookCase): void {
    this.bookCaseService.getBookCaseLink(bookCaseData.bookFileName).subscribe((fileResponse) => {
      const pdfBlob = new Blob([fileResponse], { type: 'application/pdf' });
      const pdfFile = window.URL.createObjectURL(pdfBlob);
      window.open(pdfFile, '_blank');
    });
  }
  iterateBookCaseResponse() {
    this.bookCaseResponse?.forEach((oneItem) => {
      if (oneItem.generalTitles) {
        this.generalTitle = oneItem.generalTitles;
      }
      if (oneItem.moneyManagerInformation) {
        this.moneyManager = oneItem.moneyManagerInformation;
      }
      if (oneItem.thirdPartyAdministratorInformation) {
        this.thirdPartyAdmin = oneItem.thirdPartyAdministratorInformation;
      }
      if (oneItem.tpaTeamInformation) {
        this.tpa = oneItem.tpaTeamInformation;
      }
    });
  }
}
