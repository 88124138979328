import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nfrp-mas-retail',
  templateUrl: './nfrp-mas-retail.component.html',
  styleUrls: ['./nfrp-mas-retail.component.scss'],
})
export class NfrpMasRetailComponent implements OnInit {
  constructor() {
    //do nothing
  }

  ngOnInit(): void {
    //do nothing
  }
}
