import { Injectable } from '@angular/core';
import { Store } from './store';
export class FooterFeatureState {}

@Injectable({
  providedIn: 'root',
})
export class FooterFeatureStore extends Store {
  constructor() {
    super(new FooterFeatureState());
  }
}
