import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { TrackPlan } from '../../../model/get-plan.model';
import { PlanDetailsService } from '../../../services/plan-details.service';

@Component({
  selector: 'lib-plan-dates',
  templateUrl: './plan-dates.component.html',
  styleUrls: ['./plan-dates.component.css'],
})
export class PlanDatesComponent extends NfrpDefaultGridComponent<TrackPlan> implements OnInit, OnDestroy {
  @Input() pendingStatus: boolean;
  planId: number;
  trackPlans: TrackPlan;
  isViewPlanMode = true;
  isEditPlanMode = false;
  numberOfItems: number;
  private userEmail: string;
  private subscriptions = [];
  constructor(
    private route: ActivatedRoute,
    private planDetailsService: PlanDetailsService,
    nfrpGridService: NfrpGridService<TrackPlan>
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.trackPlan();
    });
  }
  trackPlan() {
    this.subscriptions.push(
      this.planDetailsService
        .trackPlan(this.planId)
        .pipe(
          tap((data) => {
            this.trackPlans = data;
          })
        )
        .subscribe()
    );
  }
  editCorePlanDetails() {
    this.isViewPlanMode = false;
    this.isEditPlanMode = true;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
