import { Injectable } from '@angular/core';
import { Store } from './store';

export class SearchUsersState {}

@Injectable({
  providedIn: 'root',
})
export class SearchUsersStore extends Store {
  constructor() {
    super(new SearchUsersState());
  }
}
