import { Component, OnInit } from '@angular/core';
import { KitProcessingService } from '../services/kit-processing.service';
import { NfrpAlertMessage } from 'nfrp-shared-angular';

@Component({
  selector: 'app-track-ssnc',
  templateUrl: './track-ssnc.component.html',
})
export class TrackSsncComponent implements OnInit {
  processingKitData: any;
  s3Data: any;
  private subscriptions = [];
  planIDCleanUp: string;
  planIdForEnrollment: string;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  constructor(private kitProcessingService:KitProcessingService) {}

  ngOnInit() {
    this.kitStatus();
    this.s3KitStatus();
  }

  kitStatus(): void {
    this.subscriptions.push(
      this.kitProcessingService.kitStatus().subscribe((data) => {
        this.processingKitData = data;
      },
      (error) => {
        window.scroll(0, 0);
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  s3KitStatus(): void {
    this.subscriptions.push(
      this.kitProcessingService.s3KitStatus().subscribe((data) => {
        this.s3Data = data;
      },
      (error) => {
        window.scroll(0, 0);
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  cleanUpKits(): void {
    this.subscriptions.push(
      this.kitProcessingService.cleanUpKits(this.planIDCleanUp).subscribe((data) => {
        this.planIDCleanUp = '';
        this.successMessage = { message: 'Plans have been cleaned up in processing.', details: []};
        this.errorMessage = { message: '', details: [] };
      },
      (error) => {
        window.scroll(0, 0);
        this.errorMessage = { message: error, details: [] };
        this.successMessage = { message: '', details: [] };
      })
    );
  }

  submitKits(): void {
    this.subscriptions.push(
      this.kitProcessingService.flagEnrollment(this.planIdForEnrollment).subscribe((data) => {
        this.planIdForEnrollment = '';
        this.successMessage = { message: 'Plans have been submitted for processing.', details: []};
        this.errorMessage = { message: '', details: [] };
      },
      (error) => {
        window.scroll(0, 0);
        this.errorMessage = { message: error, details: [] };
        this.successMessage = { message: '', details: [] };
      })
    );
  }

  validateValue(event): void{
    const elem = event.target;
    const value = elem.value;
    const numVal = value.replace(/[^\d\n\,]+/g, "");
    elem.value = numVal;
  }
}
