<nav aria-labelledby="left-nav-heading">
  <div class="pagenav">
    <div *ngIf="loadedSubFooters">
      <ul *ngFor="let i of loadedSubFooters ">
        <li>
          <a id="security-nav-{{i.subFeatureCode}}" routerLink="/{{i.sfTraget}}/{{planId}}"  routerLinkActive="active">
            <div class="row">
              <div  class="col-md-2" *ngIf="i.sfLevel === 2"></div>
              <div  class="col-md-10">{{i.subFeatureLabel}}</div></div></a>
        </li>
      </ul>
    </div>
<!--
    <router-outlet></router-outlet>
-->
  </div>
</nav>
