<!-- <ng-container *ngIf="employeeSponsorObj" style="border: 0.25px solid #9c9c9c">
                <ng-template #empSponsorheaderTemplate>
                  <div nfrpColumn [width]="50" widthType="%"  [id]="'empSponsor-label'" defaultDirection="ASCENDING">
                    Employee/Sponsor
                  </div>
                  <div nfrpColumn [width]="50" widthType="%"  [id]="'empSponsor-label'" defaultDirection="ASCENDING">
                  </div>
                </ng-template>
                <ng-template #empSponsorrowTemplate let-empSponsor="item" let-index="index">
                  <div>
                    <div [id]="'empSponsor' + index">
                      {{ empSponsor.companyLabel | nfrpNormalizeValue: '' }}
                    </div>
                    <div [id]="'empSponsor' + index">
                      <a href="javascript:void(0);" (click)="navigateEmployeeSponsor('edit', empSponsor);" class="subLink">Edit</a>&nbsp;-&nbsp;
                      <a href="javascript:void(0);" (click)="navigateEmployeeSponsor('remove', empSponsor);" class="subLink">Remove</a>
                    </div>
                  </div>
                </ng-template>
                <nfrp-grid
                  [headerTemplate]="empSponsorheaderTemplate"
                  [rowTemplate]="empSponsorrowTemplate"
                  [iterable]="employeeSponsorObj"
                  [width]="100"
                  rowIdPrefix="empSponsor-details"
                ></nfrp-grid>
                <ul class="ul-li-view-edit no-border-bottom" style="border: 0.25px solid #9c9c9c">
                  <li>
                    <div class="col-sm-12 padding-left">
                      <a href="javascript:void(0);" (click)="navigateEmployeeSponsor('add', null);" class="subLink">Add Separate Sponsor</a>
                    </div>
                  </li>
                </ul>
            </ng-container> -->
<div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
  <nfrp-alert alertId="employee-sponsor-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
</div>
<ul
  id="empSponsor-params"
  summary="Employee Sponsor"
  class="ul-li-view-edit table stripes no-margin"
  style="border: 1px solid #9c9c9c"
>
  <li class="head" [ngClass]="{'pending-status-bg':pendingStatus}">
    <div class="col-sm-12 padding-left">
      <b>Employee/Sponsor</b>
    </div>
  </li>
  <li>
    <div class="col-sm-6 padding-left" *ngIf="employeeSponsorObj">
      <span id="empSponsor-value">{{ employeeSponsorObj?.companyLabel | nfrpNormalizeValue : '' }}</span>
    </div>
    <!-- <div class="col-sm-6 padding-left" *ngIf="!employeeSponsorObj">
      <span id="empSponsor-no-value">No records found</span>
    </div> -->
  </li>
  <!-- *ngIf="showEditCorePlanOption||showArchiveOption" -->
  <li *ngIf="!isPlanArchive && !isMoneyMgrUser">
    <div class="col-sm-12 padding-left">
      <b>
        <a
          href="javascript:void(0);"
          class="subLink"
          *ngIf="employeeSponsorObj && status"
          routerLink="employee-sponsor/edit"
          >Edit </a
        ><span *ngIf="employeeSponsorObj && status">&nbsp; - &nbsp;</span>
        <a
          href="javascript:void(0);"
          class="subLink"
          *ngIf="employeeSponsorObj && status"
          routerLink="employee-sponsor/remove"
        >
          Remove
        </a>
        <a href="javascript:void(0);" class="subLink" *ngIf="!employeeSponsorObj && status" routerLink="employee-sponsor/add">
          Add Sponsor</a
        ></b
      >
    </div>
  </li>
</ul>
