import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeSponsorResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/employee-sponsor.model';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { UserTypeIDEnum } from 'src/app/helpers/enum';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
 selector: 'view-employee-sponsor',
 templateUrl: './view-employee-sponsor.component.html',
 styleUrls: ['./view-employee-sponsor.component.css'],
})
export class ViewEmployeeSponsorComponent implements OnInit, OnChanges, OnDestroy {
 @Input() status: boolean;
 @Input() pendingStatus: boolean;
 planId: number;
 employeeSponsorObj: EmployeeSponsorResponse;
 isPlanArchive = false;
 userTypeID: number;
 isMoneyMgrUser = false;
 private subscriptions = [];
 errorMessage: NfrpAlertMessage;

 constructor(
  private planDetailsService: PlanDetailsService,
  private route: ActivatedRoute,
  private communicationService: CommunicationService
 ) {}

 ngOnInit() {
  window.scroll(0, 0);
  const loginUser = sessionStorage.getItem('loginUser');
  this.userTypeID = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
  this.subscriptions.push(
   this.route.params.subscribe((params) => {
    this.planId = params.planId;
    this.getEmployeeSponsor();
   })
  );  
  // Archive plan event
  this.subscriptions.push(
    this.communicationService.archivePlanStatusEvent.subscribe((archivePlanStatus: boolean) => {
      this.isPlanArchive = archivePlanStatus;
    })
  );
  if (this.userTypeID === UserTypeIDEnum.MONEY_MANAGER) {
   this.isMoneyMgrUser = true;
  }
 }
  getEmployeeSponsor(): void {
  this.subscriptions.push(
    this.planDetailsService.getEmployeeSponsor(this.planId, this.pendingStatus).subscribe(
      (data: any) => {
        this.employeeSponsorObj = data?.planEmployeeSponsorResponseList[0];
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      }
    )
  );
 }

 ngOnChanges(changes: SimpleChanges): void {
  if (this.planId && changes.pendingStatus && (changes.pendingStatus.currentValue || changes.pendingStatus.previousValue)) {
    this.getEmployeeSponsor();
  }
 }

 ngOnDestroy() {
  this.subscriptions.forEach((subscription) => subscription.unsubscribe());
 }
}
