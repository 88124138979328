import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';

@Injectable()
export class EndpointInterceptor implements HttpInterceptor {

  constructor( private config: ConfigService ){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reformattedURL;
    const modifiedHeaders = {};
    modifiedHeaders['X-Requested-With'] = 'XMLHttpRequest';
    if (!this.hasFile(request)) {
      // eslint-disable-next-line @typescript-eslint/naming-convention  
      modifiedHeaders['Content-Type'] = 'application/json';
    }

    if (request.url.indexOf('/v2/') > -1) {
      modifiedHeaders['client_id'] = this.config.config['clientId'];
      modifiedHeaders['X-NW-message-id'] = this.newGuid();
      modifiedHeaders['Access-Control-Allow-Origin'] = '*';
    reformattedURL = request.url.replace('/v2/', '/v1/');
    }

    request = request.clone({
      url: reformattedURL,
      headers: new HttpHeaders(modifiedHeaders)
    });
    return next.handle(request);
  }

  newGuid() {
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    const guid = crypto.getRandomValues(array);
    return guid[0];
  }

  hasFile(request: HttpRequest<any>): boolean {
    let hasFile = false
    if (request.body) {
      hasFile = request.body instanceof FormData || request.body.hasOwnProperty('givenFile')
    }
    return hasFile;
  }
}