import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { FooterFeatures } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/footer-features.model';
import { LoginUser } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-user';
import { FooterFeaturesService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/footer-features.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserTypeIDEnum, MoneyManagerIDEnum, FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'mas-retail-footer',
  templateUrl: './mas-retail-footer.component.html',
})
export class MasRetailFooterComponent implements OnInit {
  isUserLoggedIn = false;
  submitted = false;
  userEmail: string;
  footerFeatureResult$: Observable<FooterFeatures[]> = this.footerFeaturesService.footerFeatureResult$;
  loadedFooters: FooterFeatures[];
  processCategory: FooterFeatures[];
  commCategory: FooterFeatures[];
  adminCategory: FooterFeatures[];
  clientCategory: FooterFeatures[];
  login = false;
  userTypeId: number;
  mnyMgrId: number;
  constructor(
    private communicationService: CommunicationService,
    private footerFeaturesService: FooterFeaturesService,
    private router: Router
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/login' || event.url === '/'||event.url === '/reset-password') {
          this.login = false;
        } else {
          this.login = true;
        }
      }
    });
  }

  ngOnInit() {
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.communicationService.userLoggedInStatusEvent.subscribe((loggedInStatus: LoginUser) => {
      this.isUserLoggedIn = loggedInStatus ? true : false;
      this.userEmail = loggedInStatus.userEmail;
      this.getFooterDetails();
    });
    this.getFooterDetails();
  }
  getFooterDetails() {
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : '';
    this.mnyMgrId = loginUser ? JSON.parse(loginUser)?.moneyManagerId : '';
    this.footerFeaturesService
      .getFooterDetails(this.userEmail)
      .pipe(
        tap((data: FooterFeatures) => {
          this.loadedFooters = data.featureResponseList;
          this.processCategory = this.loadedFooters.filter((loadedFooters) => loadedFooters.categoryOrder === 1);
          this.commCategory = this.loadedFooters.filter((loadedFooters) => loadedFooters.categoryOrder === 2);
          this.adminCategory = this.loadedFooters.filter((loadedFooters) => loadedFooters.categoryOrder === 3);
          this.clientCategory = this.loadedFooters.filter((loadedFooters) => loadedFooters.categoryOrder === 4);
        })
      )
      .subscribe();
  }
  onClick(featureID: number) {
    this.footerFeaturesService.footerFeatureID = featureID;
  }
}
