<div class="padding-top">

  <ng-container *ngIf="dataSource">
    <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
      <nfrp-alert alertId="plan-sponsor-docs-success" type="success" [message]="successMessage" [page]="false">
      </nfrp-alert>
    </div>
    <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
      <nfrp-alert alertId="plan-sponsor-docs-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
    </div>
    <ul class="ul-li-view-edit table stripes no-margin-bottom">
      <li [ngClass]="{'pending-status-bg':pendingStatus}">
        <div class="col-sm-10 padding-left">
          <span id="plan-sponsor-docs-label"><b>Track Plan Sponsor Documents</b></span>
        </div>
      </li>
    </ul>
    <ng-template #headerTemplate>
      <div nfrpColumn [width]="50" widthType="%" [id]="'plan-sponsor-label'" defaultDirection="ASCENDING">
        Document Label

      </div>
      <div nfrpColumn [width]="15" widthType="%" [id]="'plan-created-label'">
        Created

      </div>
      <div nfrpColumn [width]="15" widthType="%" [id]="'plan-printed-label'">
        Printed
      </div>

      <div nfrpColumn [width]="20" widthType="%" [id]="'plan-received-label'">
        Received
      </div>
    </ng-template>
    <ng-template #rowTemplate let-sponsorDocs="item" let-index="index">
      <div>
        <div [id]="'plan-sponsor' + index">
          <b>{{ sponsorDocs.formTypeLabel | nfrpNormalizeValue: '' }}</b
          ><br />
          {{ sponsorDocs.documentLable | nfrpNormalizeValue: '-' }}
        </div>
        <div [id]="'plan-created-' + index">
          {{ sponsorDocs.timeStampCreated | nfrpNormalizeValue: '' }}<br />
          {{ sponsorDocs.userCreated | nfrpNormalizeValue: '' }}
        </div>
        <div [id]="'plan-printed-' + index">
          {{ sponsorDocs.timeStampPrinted | nfrpNormalizeValue: '' }}<br />
          {{ sponsorDocs.userPrinted | nfrpNormalizeValue: '' }}
        </div>
        <div [id]="'plan-Received-' + index" *ngIf="sponsorDocs.timeStampReceived">
          {{ sponsorDocs.timeStampReceived }}<br />
          {{ sponsorDocs.userReceived | nfrpNormalizeValue: 'Received' }}
        </div>
        <div [id]="'plan-Received-' + index" *ngIf="(!sponsorDocs.timeStampReceived && isAdmin)">
            <input
              type="button"
              id="cancel-matrix-document-button"
              class="button button-primary inline padding-right"
              value="Mark Received"
              (click)="markRecieved(sponsorDocs.trackFormId)"
            />
        </div>
      </div>
    </ng-template>
    <nfrp-grid
      [headerTemplate]="headerTemplate"
      [rowTemplate]="rowTemplate"
      [iterable]="data"
      [width]="100"
      rowIdPrefix="plan-sponsor-details"
    ></nfrp-grid>
 </ng-container></div>
