import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { CommunicationService } from '../services/communication.service';
import { LoginUsersStore } from '../login-users.store';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { validationPatterns, alertMessages } from '../helpers/common-constants';
import { Observable } from 'rxjs';
import { LoginResponse, UserResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-response';

const ERROR_MESSAGE = 'You must correct the information below before proceeding.';

@Component({
  selector: 'mas-retail-login',
  templateUrl: './mas-retail-login.component.html',
  styleUrls: ['./mas-retail-login.component.scss'],
})
export class MasRetailLoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  submitted = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  emailPattern = validationPatterns.emailPattern;
  loginUserResponse$: Observable<LoginResponse[]> = this.loginUsersStore.loginUserResponse$;
  loginResponseCode: string;
  loginResponseType: string;
  userResponse: UserResponse;
  isUserLoggedIn = false;
  userTypeId: number;
  private subscriptions = [];
  constructor(
    private fb: UntypedFormBuilder,
    private loginUsersStore: LoginUsersStore,
    private loginService: LoginService,
    private router: Router,
    private communicationService: CommunicationService
  ) {}
  ngOnInit() {
    window.scroll(0, 0);
    this.loginForm = this.fb.group({
      userEmail: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      token: ['', Validators.required],
    });
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }
  onClickSubmit(): void {
    this.submitted = true;
    this.resetLoginInfo();
    if (this.loginForm.valid) {
      this.subscriptions.push(
        this.loginService.saveLoginUserData(this.loginForm.value).subscribe(
          (response: any) => {
            window.scroll(0, 0);
            this.userResponse = response?.userResponse;
            this.loginResponseCode = response.responseCodeMap.p_error_code;
            this.loginResponseType = response.responseCodeMap.p_page_type;
            this.userTypeId = response?.userResponse?.userTypeId;
            console.log('Error Code>>>>', this.loginResponseCode);
            console.log('Page Code>>>>>', this.loginResponseType);
            if (this.loginResponseType === 'Homeland Login' && this.userTypeId !== 29 && this.userTypeId !== 69) {
              this.router.navigate(['/home']);
              /*Checking loggedIn Status*/
              this.setLoginInfo(this.userResponse);
            } else if (this.loginResponseType === 'Homeland Login' && this.userTypeId === 69) {
              this.router.navigate(['/cc-home']);
              /*Checking loggedIn Status*/
              this.setLoginInfo(this.userResponse);
            } else if (this.loginResponseType === 'Homeland Login' && this.userTypeId === 29) {
              this.router.navigate(['/ekp-home']);
              /*Checking loggedIn Status*/
              this.setLoginInfo(this.userResponse);
            } else if (this.loginResponseType === 'Update Password') {
              this.setLoginInfo(this.userResponse);
              this.router.navigate(['/reset-password']);
            } else if (this.loginResponseType === 'Default Login') {
              this.errorMessage = { message: this.loginResponseCode, details: [] };
            } else {
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          },
          (error) => {
            window.scroll(0, 0);
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    } else {
      window.scroll(0, 0);
      this.errorMessage = { message: ERROR_MESSAGE, details: [] };
    }
  }
  setLoginInfo(userResponse) {
    this.isUserLoggedIn = this.loginForm.value;
    sessionStorage.setItem('loginUser', JSON.stringify(userResponse));
    this.communicationService.userLoggedInStatus(this.isUserLoggedIn);
    this.communicationService.loginUserTypeId = userResponse ? userResponse?.userId : 0;
    this.communicationService.loginUserId = this.loginFormControl.userEmail.value;
  }
  resetLoginInfo() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }
  ngOnDestroy(): void {
    for (const subscriptions$ of this.subscriptions) {
      subscriptions$.unsubscribe();
    }
  }
}
