<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="(planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1"
          ><i class="nw-icon-check f-green"></i
        ></span>
        {{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }})
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="plan-fee-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-view-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <div>
        <form [formGroup]="addNotesForm">
          <ng-container>
            <ul id="add-notes-list" summary="Add Notes" class="ul-li-view-edit table stripes no-margin border-common">
              <li class="head" [ngClass]="{'pending-status-bg':isPending}">
                <div class="col-sm-12 padding-left">
                  <b>Add A Plan Note</b>
                </div>
              </li>
              <li>
                <div class="col-sm-10 padding-left">
                  <div class="flex-column">
                    <div>
                      <textarea
                        name="addNoteText"
                        formControlName="addNoteText"
                        id="note_text"
                        rows="5"
                        maxlength="1000"
                        cols="90"
                        [ngClass]="{
                          'has-error': isControlInvalid('addNoteText')
                        }"
                      ></textarea>
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (addNotesControl.addNoteText.touched || submitted) &&
                          addNotesControl.addNoteText.errors?.required
                        "
                        errorId="addNoteText-required"
                        [message]="getRequiredErrorMessage('Plan Note Text')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2 padding-left">
                  <div class="flex-column right">
                    <div><button class="button button-tertiary" (click)="savePlanNotes()">Add Note</button></div>
                    <br /><br />
                    <div>
                      <button class="button button-tertiary" (click)="onCancel()">Cancel</button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <br />
            <br />
          </ng-container>
          <ul
            id="add-notes-list-view"
            summary="Add Notes View"
            class="ul-li-view-edit table stripes no-margin border-common"
          >
            <ng-template #headerTemplate>
              <div nfrpColumn [width]="50" widthType="%" [id]="'plan-note-label'">
                Plan Notes
              </div>
              <div nfrpColumn [width]="50" widthType="%" [id]="'plan-note-created-label'">
                Created
              </div>
            </ng-template>
            <ng-template #rowTemplate let-planNotesList="item" let-index="index">
              <div>
                <div [id]="'plan-note-' + index">
                  {{ planNotesList.planNotes | nfrpNormalizeValue: '-' }}
                </div>
                <div [id]="'plan-note-created-' + index">
                  {{ planNotesList.createdDetails | nfrpNormalizeValue: '-' }}
                </div>
              </div>
            </ng-template>
            <nfrp-grid
              [headerTemplate]="headerTemplate"
              [rowTemplate]="rowTemplate"
              [iterable]="data"
              [width]="100"
              rowIdPrefix="plan-notes-details"
            ></nfrp-grid>
          </ul>
          <!-- <nfrp-grid-paginator
            (paginate)="paginate($event)"
            [numberOfItems]="numberOfItems"
            [itemsPerPageOptions]="[5, 10, 20]"
            [defaultItemsPerPage]="5"
          ></nfrp-grid-paginator>-->
        </form>
      </div>
      <ng-template #loading>
        <nfrp-spinner></nfrp-spinner>
      </ng-template>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
