import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { Observable, combineLatest, Subscription, of } from 'rxjs';
import { switchMap, filter, tap } from 'rxjs/operators';
import { validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { BinaryResponseEnum, UserRoleEnum, FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { PasswordValidators, RESET_VALIDATION_PATTERNS } from 'src/app/helpers/password-validators';
import { LoginService } from 'src/app/services/login.service';
import { AssignedRoles } from '../../../model/assigned-roles';
import { EditRoles } from '../../../model/edit-roles';
import { UserInfo, UserResponse } from '../../../model/edit-user';
import { LoginUser } from '../../../model/login-user';
import { SearchUser } from '../../../model/search-user';
import { TpAdminData } from '../../../model/tpadmin-data';
import { SearchUsersStore } from '../../../search-users.store';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { SearchUsersService } from '../../../services/search-users.service';

@Component({
  selector: 'edit-add-user-access',  
  styleUrls: ['./edit-add-user-access.component.css'],
  templateUrl: './edit-add-user-access.component.html',
})
export class EditAddUserAccessComponent implements OnInit, OnDestroy {
  userId: string;
  userTypeId: number;
  userTpaNbr: string;
  submitted = false;
  editForm: UntypedFormGroup;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  isAdminMessage: NfrpAlertMessage;
  noMakeAdminMessage: NfrpAlertMessage;
  assignedRoleCollection: AssignedRoles;
  editFormValue: UserInfo;
  searchUserData: SearchUser;
  selectedFirmId: string;
  isInternal = false; 
  passwordPattern = RESET_VALIDATION_PATTERNS.resetPasswordPattern;
  userIDStored: number;
  showPasswordOptions = false;
  userSearchResult$: Observable<SearchUser[]> = this.searchUsersService.searchUserResult$;
  assignedRoleResult$: Observable<AssignedRoles> = this.searchUsersService.assignedRoleResult$;
  assignedRoleByUserIdResult$: Observable<AssignedRoles> = this.searchUsersService.searchUserResult$.pipe(
    switchMap(() => {
      return this.searchUsersService.getAssignedRoleByUserId(this.userEmail);
    })
  );
  editUser$ = combineLatest([this.userSearchResult$, this.assignedRoleByUserIdResult$]).pipe(
    filter(([userSearch, assignedRoles]) => !!userSearch && !!assignedRoles),
    tap(([userSearch, assignedRoles]: [SearchUser[], AssignedRoles]) => {
      this.searchUserData = this.editUser ? userSearch[0] : {};
      this.assignedRoleCollection = assignedRoles;
      this.isInternal = this.searchUserData?.isInternal === 1 ? true : false;
      this.editForm = this.createEditForm(userSearch, assignedRoles);
      this.showPasswordOptions =  (this.editUser && this.searchUserData && (this.userIDStored === JSON.parse(this.searchUserData.userId))) ? true: false;
    })
  );
 
  moneyManagerSubscription: Subscription;
  moneyManagerFirmData: any[];
  tpAdminSubscription: Subscription;
  tpAdminFirmData: TpAdminData[];
  selectedUserRole: string;
  selectedUserId: string;
  isMakeAdmin = false;
  userEmail: string;
  formSubscription: Subscription;
  private subscriptions = [];
  editUser = false;

  constructor(
    private searchUsersService: SearchUsersService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private footerFeaturesService: FooterFeaturesService,
    private navigationStore: NavigationStore,
    private loginService: LoginService,
    private searchUsersStore: SearchUsersStore
  ) {}

  createEditForm(searchResult, availableRoles): UntypedFormGroup {
    const result = searchResult[0];
    const formBuilder = this.fb.group({
      userEmail: [result?.userEmail ? result.userEmail:''],
      userNameFirst: [result?.userNameFirst ? result.userNameFirst:''],
      userNameLast: [result?.userNameLast ? result.userNameLast:''],
      userActive: [result?.userActive === BinaryResponseEnum.YES],
      userRoles: [null],
      selectedUserFirm: [result?.moneyManagerId ? result.moneyManagerId : result?.tpAdminNbr ? result.tpAdminNbr: null ],
      isAdmin: [result?.isAdmin === BinaryResponseEnum.YES],
      roles: this.setAvailableRole(availableRoles.roles, result?.userTypeId),
      nwId:[result?.nwId ? result.nwId : ''],
      newPassword:[''],
      confirmPassword:[''],
      nwEmail:[result?.nwEmail ? result.nwEmail : ''],
     
    },
    {
      validator: PasswordValidators.passwordsShouldMatch,
      }
    );
    this.formSubscription = this.editForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    formBuilder.controls.userEmail.setValidators([
      Validators.required,
      Validators.pattern(validationPatterns.emailPattern),
    ]);
    formBuilder.controls.userNameFirst.setValidators([
      Validators.required,
      Validators.maxLength(50),
    ]);
    formBuilder.controls.userNameLast.setValidators([
      Validators.required,
      Validators.maxLength(50),
    ]);
    if(result?.nwId) {
      formBuilder.controls.nwId.setValidators([
        Validators.required,
        Validators.maxLength(50),
      ]);
      formBuilder.controls.nwEmail.setValidators([
        Validators.required,
        Validators.maxLength(50),
      ]);
      formBuilder.controls.newPassword.setValidators([
        Validators.pattern(this.passwordPattern),
      ]);       
    }
    return formBuilder;
  }

  get editFormControl() {
    return this.editForm.controls;
  }
  get availableResultsFormArray(): UntypedFormArray {
    return this.editForm.get('roles') as UntypedFormArray;
  }
  isUserAdminEnabled() {
    this.isMakeAdmin =
      this.selectedUserRole === UserRoleEnum.RIA_SERVICES_EMPLOYEE ||
      this.selectedUserRole === UserRoleEnum.MONEY_MANAGER ||
      this.selectedUserRole === UserRoleEnum.THIRD_PARTY_ADMINISTRATOR
        ? true
        : false;
    return this.isMakeAdmin;
  }
  setAvailableRole(availableRoles, userTypeId): UntypedFormArray {
    const agreementList = new UntypedFormArray([]);
    availableRoles?.map((role: EditRoles) => {
      agreementList.push(
        this.fb.group({
          roleName: [role.userTypeLabel],
          selected: [role.userTypeId === userTypeId ? role.userTypeLabel : false],
          roleId: [role.userTypeId],
        })
      );
      if (role.userTypeId === userTypeId) {
        this.selectedUserRole = role.userTypeLabel;
        this.selectedUserId = role.userTypeId;
      }
    });
    return agreementList;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userIDStored = loginUser ? JSON.parse(loginUser)?.userId : '';
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : null;
    this.userTpaNbr = loginUser ? JSON.parse(loginUser)?.tpAdminNbr : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.USER_MGMT, this.userEmail).subscribe()
    );
    this.route.params.subscribe((params) => {
      if(params.userId) {
        this.userId = params.userId;
        this.editUser = true;
        this.subscriptions.push(
          this.searchUsersService.getUserDetails(this.userIDStored, this.userId).subscribe((userDetails: EditRoles) => {
            this.selectedUserRole = userDetails[0].userTypeLabel;
            this.selectedUserId = userDetails[0].userTypeId;
            this.getSelectedUSerDetails(this.userEmail);
            this.getTpAdminFirmData();
          })
        );
      }
      else {
        this.searchUsersStore.set('searchUserResult', of([]));
        this.userId = null;
        this.getSelectedUSerDetails(this.userEmail);
        this.getTpAdminFirmData();
      }    
    });
  }
  compareSelectedUserRole(role: string, roleToCompare: string): boolean {
    return role === roleToCompare;
  }

  getSelectedUSerDetails(selctedUser: string) {
    this.subscriptions.push(
      this.searchUsersService.getMoneyManagerForUser(selctedUser).subscribe((firmData: any[]) => {
        this.moneyManagerFirmData = firmData;
        this.moneyManagerFirmData?.forEach((userFirm) => {
          if (userFirm.moneyManagerId === this.searchUserData?.moneyManagerId) {
            this.editFormControl.selectedUserFirm.setValue(userFirm.moneyManagerId);
            this.selectedFirmId = userFirm.moneyManagerId;
          }
        });
      },
      (error)=>{
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  get userRoles(): UntypedFormControl {
    return this.editForm.get('userRoles') as UntypedFormControl;
  }

  getTpAdminFirmData(): void {
    const searchTpaNbr = this.userTypeId !== 1 && this.userTpaNbr ? this.userTpaNbr : '';
    this.subscriptions.push(
      this.searchUsersService.getTpAdmin(searchTpaNbr).subscribe((firmData: TpAdminData[]) => {
        this.tpAdminFirmData = firmData;
        this.tpAdminFirmData.forEach((userFirm) => {
          if (userFirm.tpAdminNbr === this.searchUserData?.tpAdminNbr) {
            this.editFormControl.selectedUserFirm.setValue(userFirm.tpAdminNbr);
            this.selectedFirmId = userFirm.tpAdminNbr;
          }
        });
      },
      (error) => {
        window.scroll(0, 0);
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  onAssignedRoleChange(assignedRoleEvent: any): void {
    this.selectedUserRole = assignedRoleEvent.value.roleName;
    this.selectedUserId = assignedRoleEvent.value.roleId;
    this.assignedRoleCollection.roles.every(role=>{
      if((role.userTypeId === assignedRoleEvent.value.roleId) && role.visibility === 'Visible') {        
        this.setInternal(true);
      }
      else {
        this.setInternal(false);
      }
    })
  }
  userAdminStatus(userAdminStatus: boolean) {
    this.editForm.controls.isAdmin.setValue(userAdminStatus);
    this.editForm.value.isAdmin = userAdminStatus ? BinaryResponseEnum.YES : BinaryResponseEnum.NO;
  }

  selectedFirmData(firmEvent: string): void {
    this.selectedFirmId = firmEvent ? firmEvent.split(':')[1].trim() : '';
    const mmData = this.moneyManagerFirmData.find(mm=>(mm.moneyManagerId === JSON.parse(this.selectedFirmId) && mm.visibility === 'Visible'));
      if(mmData){
        this.setInternal(true);  
      }
      else {
        this.setInternal(false);
      }
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }

  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  cancel(): void {
    this.userId = null;
    this.errorMessage = { message: '', details: [] };
    this.showSuccessMessage('');
    this.router.navigate(['/userMgmt']);
  }

  onSubmit(): void {
    this.submitted = true;
    this.editFormValue = this.editForm.value;
    this.resetAlertMessages();
    if (this.editForm.valid) {
      if (this.editFormValue.isAdmin === BinaryResponseEnum.YES && this.isMakeAdmin) {
        this.isAdminMessage = { message: alertMessages.IS_ADMIN_MESSAGE, details: [] };
      } else if (this.editFormValue.isAdmin === BinaryResponseEnum.NO && this.isMakeAdmin) {
        this.noMakeAdminMessage = { message: alertMessages.NO_MAKE_ADMIN_MESSAGE, details: [] };
      } else {
        this.isAdminMessage = { message: '', details: [] };
        this.noMakeAdminMessage = { message: '', details: [] };
      }
      const userDetailData = this.setRequestPayloadForSubmit();
      this.subscriptions.push(
        this.searchUsersService.saveAddUserData(userDetailData).subscribe(
          (editUserResponse: UserResponse) => {
            window.scroll(0, 0);            
            this.showSuccessMessage(editUserResponse.status);
            setTimeout(() => {
              if (editUserResponse.status && !this.editUser) {
                const currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
              }
              else {
                this.router.navigate(['/userMgmt']);
              }
            }, 2000);
          },
          (error) => {
            window.scroll(0, 0);
            this.showSuccessMessage('');
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    } else {
      window.scroll(0, 0);
      this.showSuccessMessage('');
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  showSuccessMessage(message: string): void {
    this.successMessage = { message: message, details: [] };
  }

  resetAlertMessages(): void {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.isAdminMessage = { message: '', details: [] };
    this.noMakeAdminMessage = { message: '', details: [] };
  }

  setRequestPayloadForSubmit(): UserInfo {
    const userDetailData = {
      userId: this.searchUserData?.userId ? this.searchUserData.userId : null,
      userActive: this.editFormValue.userActive ? BinaryResponseEnum.YES : BinaryResponseEnum.NO,
      userTypeId: this.selectedUserId ? this.selectedUserId : null,
      nwId: this.editFormValue.nwId ? this.editFormValue.nwId : '',
      userNameLast: this.editFormValue.userNameLast ? this.editFormValue.userNameLast : '',
      userNameFirst: this.editFormValue.userNameFirst ? this.editFormValue.userNameFirst : '',
      userEmail: this.editFormValue.userEmail ? this.editFormValue.userEmail : '',
      mnyMgrId: this.searchUserData?.moneyManagerId ? this.searchUserData.moneyManagerId : '',
      tpAdminNbr: this.searchUserData?.tpAdminNbr ? this.searchUserData.tpAdminNbr: '',
      isAdmin: this.editForm.value.isAdmin ? BinaryResponseEnum.YES : BinaryResponseEnum.NO,
      isInternal: this.searchUserData?.isInternal?this.searchUserData?.isInternal: 0,
      sendEmails: this.searchUserData?.sendEmail ? this.searchUserData.sendEmail :0,
      secLvlCode: this.searchUserData?.secLvlCode ? this.searchUserData.secLvlCode : '',
      nwEmail: this.editFormValue.nwEmail ? this.editFormValue.nwEmail : '',
    };
    if (this.selectedUserRole === UserRoleEnum.MONEY_MANAGER) {
      userDetailData.mnyMgrId = this.selectedFirmId;
    } else if (this.selectedUserRole === UserRoleEnum.THIRD_PARTY_ADMINISTRATOR) {
      userDetailData.tpAdminNbr = this.selectedFirmId;
    }
    return userDetailData;
  }

  changePassword():  void {
    const resetPasswordRequest: LoginUser = {
      userEmail: this.userEmail,
      token: this.editForm.value.confirmPassword,
    };
    this.subscriptions.push(
      this.loginService.changePasswordData(resetPasswordRequest).subscribe(
        (response: any) => {
          window.scroll(0, 0);
          this.editFormControl.newPassword.reset();
          this.editFormControl.confirmPassword.reset();
          this.showSuccessMessage(response.status);
        },
        (error) => {
          window.scroll(0, 0);
          this.errorMessage = { message: error, details: [] };
        }
      )
    );
  }

  setInternal(isInternal: boolean): void {
    if(!isInternal && this.searchUserData?.isInternal === 1) {
      this.isInternal = true;
    }
    else {
      this.isInternal = isInternal;
    }
  }

  ngOnDestroy(): void {
    this.moneyManagerSubscription?.unsubscribe();
    this.tpAdminSubscription?.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }  
}