import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PlanStore } from '../../../../../src/app/store/plan.store';
import {
  GetPlanFees,
  GetPlanLabels,
  GetPlanNotes,
  GetPlanRepresentatives,
  GetPlans,
  TrackPlan,
  TrackPlanSpnserDocs,
  MoneyMgrProgram,
  CoAdvisoryFirm,
  GetPlanTrustees,
  GetPlanSolicitor,
  PlanSummaryResponse,
  SearchPlanSponsorDoc,
  FileUploadResponse,
  SaveSponsorDoc,
  SearchPrintSponsorDoc,
  GetPlanMMDetails,
  GetPlanFeeDetails,
  GetPlanRepresentative,
  GetParticipantDetails,
  PlanStep,
  TpaFees,
  PendingPlanResponse,
} from '../model/get-plan.model';
import {
  EmployeeSponsorResponse,
  EditEmployeeSponsorResponse,
  AddEmployeeSponsorRequest,
} from '../model/employee-sponsor.model';

import { TrusteeResponse, EditTrusteeResponse, AddTrusteeRequest } from '../model/trustee.model';

import {
  AddRepresentativeRequest,
  EditRepresentativeResponse,
  RepresentativeResponse,
  SuccessResponse,
} from '../model/representative.model';
import {
  PlanSetupChecklistResponse,
  PlanSetupChecklistSuccessResponse,
} from '../model/plan-setup-checklist-response.model';
import {
  ApprovedSolicitorsMnyMgr,
  UpdateSolicitorRequest,
  ParticipantFileResponseList,
  PreviousSolicitor,
} from '../model/money-manager-data';
import { ErrorUtilsService } from 'src/app/services/error-utils.service';
import { ConfigService } from '../../../../../src/app/services/config.service';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class PlanDetailsService {
  baseUrl = this.config.config?.masRetailPrivateProcessing;
  @Output() getRepStrEvent: EventEmitter<string> = new EventEmitter<string>();
  trackPlanSpnserDocsResult$: Observable<TrackPlanSpnserDocs>;
  getAllProductsResult$: Observable<any> = this.planStore.select('getAllProductsResult');
  getPlansResult$: Observable<any> = this.planStore.select('getPlansResult');
  getPlanRepresentativesResult$: Observable<any> = this.planStore.select('getPlanRepresentativesResult');
  getParticipantIKFileResponseResult$: Observable<any> = this.planStore.select('getPlanRepresentativesResult');
  getPlanRevisionChecklistResult$: Observable<PlanSetupChecklistResponse> = this.planStore.select(
    'getPlanRevisionChecklistResult'
  );
  getPlanParticipantResult$: Observable<any> = this.planStore.select('getPlanParticipantResult');
  private headers: HttpHeaders;
  private planStatusSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient, private planStore: PlanStore, private errorUtilsService: ErrorUtilsService, private config: ConfigService) {
    this.headers = new HttpHeaders({
      accessControlAllowOrigin: '*',
      print2Image: 'true',
      proxyAlternateURI: 'http://localhost:8080/',
    });
  }

  getPlanDetails(planId: number, userEmail?: string, isPending?: boolean): Observable<GetPlans> {
    const params = new HttpParams()
      .set('plan-id', planId ? planId.toString() : '')
      .set('email-id', userEmail ? userEmail : '')
      .set('is-pending', isPending ? isPending : false);
    return this.http.get<GetPlans>(this.baseUrl + '/plan/get-plan-details', { params }).pipe(
      tap((getPlans: GetPlans) => {
        this.planStore.set('getPlansResult', getPlans);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  trackPlan(planId: number): Observable<TrackPlan> {
    return this.http.get<TrackPlan>(this.baseUrl + '/plan/track-plan/' + planId).pipe(
      tap((trackPlan: TrackPlan) => {
        this.planStore.set('TrackPlanResult', trackPlan);
      })
    );
  }

  getPlanLabels(planId: number): Observable<GetPlanLabels> {
    return this.http.get<GetPlanLabels>(this.baseUrl + '/plan/getPlanLabels/' + planId).pipe(
      tap((getPlanLabels: GetPlanLabels) => {
        this.planStore.set('getPlanLabelsResult', getPlanLabels);
      })
    );
  }
  searchPlanSponsorDoc(planNumber: string, isPending?: boolean, hasPendingChanges?: boolean): Observable<SearchPlanSponsorDoc> {
    const params = new HttpParams()
      .set('plan-number', planNumber ? planNumber.toString() : '')
      .set('is-pending', isPending ? isPending : false)
      .set('has-pending-changes', hasPendingChanges ? hasPendingChanges : false)
    return this.http.get<SearchPlanSponsorDoc>(this.baseUrl + '/plan/search-sponsor-document', { params }).pipe(
      tap((searchPlanSponsorDoc: SearchPlanSponsorDoc) => {
        this.planStore.set('SearchPlanSponsorDocResult', searchPlanSponsorDoc);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  searchPrintSponsorDocument(planNumber: string): Observable<SearchPrintSponsorDoc> {
    const params = new HttpParams().set('plan-number', planNumber ? planNumber.toString() : '');
    return this.http.get<SearchPrintSponsorDoc>(this.baseUrl + '/plan/search-print-sponsor-document', { params }).pipe(
      tap((searchPrintSponsorDocument: SearchPrintSponsorDoc) => {
        this.planStore.set('searchPrintSponsorDocumentResult', searchPrintSponsorDocument);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  searchPrintEnrollmentDocument(planNumber: string): Observable<SearchPrintSponsorDoc> {
    const params = new HttpParams().set('plan-number', planNumber ? planNumber.toString() : '');
    return this.http.get<SearchPrintSponsorDoc>(this.baseUrl + '/plan/search-print-enrollment-document', { params }).pipe(
      tap((searchPrintEnrollmentDocument: SearchPrintSponsorDoc) => {
        this.planStore.set('searchPrintEnrollmentDocumentResult', searchPrintEnrollmentDocument);
      })
    );
  }

  enrollmentDocs(planNumber: string, isPending?: boolean, hasPendingChanges?: boolean): Observable<SearchPlanSponsorDoc> {
    const params = new HttpParams()
      .set('plan-number', planNumber ? planNumber.toString() : '')
      .set('is-pending', isPending ? isPending : false)
      .set('has-pending-changes', hasPendingChanges ? hasPendingChanges : false);
    return this.http.get<SearchPlanSponsorDoc>(this.baseUrl + '/plan/search-enrollment-document', { params }).pipe(
      tap((enrollmentDocs: SearchPlanSponsorDoc) => {
        this.planStore.set('enrollmentDocsResult', enrollmentDocs);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }


  getPlanFees(planId: number, userId: number, isPending?: boolean): Observable<GetPlanFees> {
    const params = new HttpParams()
      .set('user-id', userId)
      .set('is-pending', isPending ? isPending : false)
    return this.http.get<GetPlanFees>(this.baseUrl + '/plan/get-plan-fees/' + planId, { params }).pipe(
      tap((getPlanFees: GetPlanFees) => {
        this.planStore.set('getPlanFeesResult', getPlanFees);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  getVerificationFees(planId: number, isPending?: boolean): Observable<GetPlanFees> {
    const params = new HttpParams().set('is-pending', isPending ? isPending : false);
    return this.http.get<GetPlanFees>(this.baseUrl + '/plan/' + planId + '/get-plan-fee-flags', { params }).pipe(
      tap((getPlanFees: GetPlanFees) => {
        this.planStore.set('getPlanVerificationFees', getPlanFees);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  getTPAFees(tpAdminNbr: number, mnyMgrId: number): Observable<TpaFees> {
    const params = new HttpParams()
      .set('mny-mgr-id', mnyMgrId ? mnyMgrId.toString() : '')
      .set('tpadmin-nbr', tpAdminNbr ? tpAdminNbr.toString() : '');
    return this.http.get<TpaFees>(this.baseUrl + '/tpa-mgr-fee', { params }).pipe(
      tap((getPlanFees: TpaFees) => {
        this.planStore.set('getTPAFees', getPlanFees);
      })
    );
  }

  trackPlanSpnserDocs(planId: number): Observable<TrackPlanSpnserDocs> {
    return this.http.get<TrackPlanSpnserDocs>(this.baseUrl + '/plan/track-plan-sponsor-docs/' + planId).pipe(
      tap((trackPlanSpnserDocs: TrackPlanSpnserDocs) => {
        this.planStore.set('trackPlanSpnserDocsResult', trackPlanSpnserDocs);
      })
    );
  }

  getPlanNotes(planId: number): Observable<GetPlanNotes> {
    return this.http.get<GetPlanNotes>(this.baseUrl + '/plan/get-plan-notes/' + planId).pipe(
      tap((getPlanNotes: GetPlanNotes) => {
        this.planStore.set('getPlanNotesResult', getPlanNotes);
      })
    );
  }

  getAllProducts(mnyMgrId?: number): Observable<GetPlans> {
    const mgrId = mnyMgrId ? mnyMgrId : null;
    const params = new HttpParams().set('mny-mgr-id', mgrId);
    return this.http.get<GetPlans>(this.baseUrl + '/plan/get-all-product', {params}).pipe(
      tap((getAllProducts: GetPlans) => {
        this.planStore.set('getAllProductsResult', getAllProducts);
      })
    );
  }

  getAllPlanType(): Observable<GetPlans> {
    return this.http.get<GetPlans>(this.baseUrl + '/plan/get-all-plan-type').pipe(
      tap((getAllPlanType: GetPlans) => {
        this.planStore.set('getAllPlanTypeResult', getAllPlanType);
      })
    );
  }

  getMoneyMgrPgmOptions(planId: number): Observable<MoneyMgrProgram> {
    return this.http.get<MoneyMgrProgram>(this.baseUrl + '/plan/mny-manager-programs-option/' + planId).pipe(
      tap((getMoneyMgrPgmOptions: MoneyMgrProgram) => {
        this.planStore.set('getMoneyMgrPgmOptionsResult', getMoneyMgrPgmOptions);
      })
    );
  }

  getCoAdvisoryFirm(): Observable<CoAdvisoryFirm[]> {
    return this.http.get<CoAdvisoryFirm[]>(this.baseUrl + '/plan/get-all-coadvisory-firm').pipe(
      tap((getCoAdvisoryFirm: CoAdvisoryFirm[]) => {
        this.planStore.set('getCoAdvisoryFirmResult', getCoAdvisoryFirm);
      })
    );
  }

  getPlanTrustees(planId: number): Observable<GetPlanTrustees> {
    return this.http.get<GetPlanTrustees>(this.baseUrl + '/plan/' + planId + '/trustee').pipe(
      tap((getPlanTrustees: GetPlanTrustees) => {
        this.planStore.set('getPlanRepresentativesResult', getPlanTrustees);
      })
    );
  }

  getPlanSolicitor(planId: number, isPending?: boolean): Observable<GetPlanSolicitor> {
    const params = new HttpParams().set('is-pending', isPending ? isPending : false);
    return this.http.get<GetPlanSolicitor>(this.baseUrl + '/plan/' + planId + '/get-solicitor', { params }).pipe(
      tap((getPlanSolicitor: GetPlanSolicitor) => {
        this.planStore.set('getPlanRepresentativesResult', getPlanSolicitor);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  /** HTTP POST  to save the core plan information to the database table */
  saveCreateCorePlanInfo(editCorePlanInfo: GetPlans): Observable<any> {
    return this.http
      .post(this.baseUrl + '/plan/save-plan', editCorePlanInfo)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  saveEditCorePlanInfo(editCorePlanInfo: GetPlans, planId: number): Observable<PendingPlanResponse> {
    return this.http
      .patch(this.baseUrl + '/plan/edit-plan/' + planId, editCorePlanInfo)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  saveActivateTrackOrIk(editCorePlanInfo: GetPlans): Observable<any> {
    return this.http
      .post(this.baseUrl + '/plan/activate-trac-or-ik', editCorePlanInfo)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  // Employee Sponsor APIs

  getEmployeeSponsor(planId: number, isPending?: boolean): Observable<EmployeeSponsorResponse> {
    const params = new HttpParams().set('is-pending', isPending ? isPending : false);
    return this.http.get<EmployeeSponsorResponse>(this.baseUrl + '/plan/' + planId + '/sponsor', { params }).pipe(
      tap((getEmployeeSponsors: EmployeeSponsorResponse) => {
        this.planStore.set('getEmployeeSponsorResult', getEmployeeSponsors);
      }),
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  addEmployeeSponsor(
    planId: number,
    employeeSponsorRequest: AddEmployeeSponsorRequest,
    isPending?: boolean
  ): Observable<EditEmployeeSponsorResponse> {
    const reqParam = `?is-pending=${isPending ? isPending : false}`;
    return this.http
      .post(this.baseUrl + '/plan/' + planId + `/sponsor${reqParam}`, employeeSponsorRequest)
      .pipe(catchError(ErrorUtilsService.catchError));
  }

  updateEmployeeSponsor(
    planId: number,
    employeeSponsorRequest: EditEmployeeSponsorResponse
  ): Observable<EditEmployeeSponsorResponse> {
    return this.http
      .patch(this.baseUrl + '/plan/' + planId + '/sponsor', employeeSponsorRequest)
      .pipe(catchError(ErrorUtilsService.catchError));
  }

  removeEmployeeSponsor(planId: number, sponsorId: string, isPending?: boolean): Observable<EditEmployeeSponsorResponse> {
    const reqParam = `?is-pending=${isPending ? isPending : false}`;
    return this.http
      .delete(this.baseUrl + '/plan/' + planId + `/sponsor/${sponsorId}${reqParam}`)
      .pipe(catchError(ErrorUtilsService.catchError));
  }

  // Trustee APIs

  getTrustee(planId: number, isPending?: boolean): Observable<TrusteeResponse> {
    const params = new HttpParams().set('is-pending', isPending ? isPending : false);
    return this.http.get<any>(this.baseUrl + '/plan/' + planId + '/trustee', { params }).pipe(
      tap((getTrustees: any) => {
        this.planStore.set('getTrusteeResult', getTrustees);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  addTrustee(planId: number, trusteeRequest: AddTrusteeRequest, isPending?: boolean,): Observable<EditTrusteeResponse> {
    const reqParam = `?is-pending=${isPending ? isPending : false}`;
    return this.http
      .post(this.baseUrl + '/plan/' + planId + `/trustee${reqParam}`, trusteeRequest)
      .pipe(catchError(ErrorUtilsService.catchError));
  }

  updateTrustee(planId: number, trusteeRequest: EditTrusteeResponse, isPending?: boolean): Observable<EditTrusteeResponse> {
    const reqParam = `?is-pending=${isPending ? isPending : false}`;
    return this.http
      .patch(this.baseUrl + '/plan/' + planId + `/trustee${reqParam}`, trusteeRequest)
      .pipe(catchError(ErrorUtilsService.catchError));
  }

  removeTrustee(planId: number, trusteeId: string, isPending?: boolean): Observable<EditTrusteeResponse> {
    const reqParam = `?is-pending=${isPending ? isPending : false}`;
    return this.http
      .delete(this.baseUrl + '/plan/' + planId + `/trustee/${trusteeId}${reqParam}`)
      .pipe(catchError(ErrorUtilsService.catchError));
  }

  savePlanFeeSchedule(
    planId: number,
    planFeeSheduleReqObj: GetPlanFees,
    mnyMgr: number
  ): Observable<PendingPlanResponse> {
    return this.http
      .post(
        this.baseUrl + '/plan/' + planId + `/save-plan-fee-schedule/${mnyMgr}`,
        planFeeSheduleReqObj
      )
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))))
  }
  savePlanFeeFlag(planId: number, planFeeSFlagReqObj: GetPlanFees): Observable<any> {
    return this.http
      .post(this.baseUrl + '/plan/' + planId + '/verify-plan-fee-flag', planFeeSFlagReqObj)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))))
  }

  updatePlanFeeSchedule(
    planId: number,
    planFeeSheduleReqObj: GetPlanFees,
  ): Observable<PendingPlanResponse> {
    return this.http
      .patch(
        this.baseUrl + '/plan/' + planId + '/edit-plan-fee-schedule',
        planFeeSheduleReqObj
      )
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))))
  }

  // Representative API's

  getNameRepresentatives(lstName: string, fstName: string): Observable<GetPlanRepresentatives> {
    let params;
    if (lstName != null && fstName != null) {
      params = new HttpParams().set('lst-name', lstName).set('fst-name', fstName);
    } else {
      if (lstName != null) {
        params = new HttpParams().set('lst-name', lstName);
      }
    }
    return this.http.get<GetPlanRepresentatives>(this.baseUrl + '/plan/representative', { params }).pipe(
      tap((getPlanRepresentatives: GetPlanRepresentatives) => {
        this.planStore.set('getPlanRepresentativesResult', getPlanRepresentatives);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  getPlanRepresentatives(planId: number, isPending?: boolean): Observable<GetPlanRepresentatives> {
    const params = new HttpParams().set('is-pending', isPending ? isPending : false);
    return this.http.get<GetPlanRepresentatives>(this.baseUrl + '/plan/' + planId + '/representative', { params }).pipe(
      tap((getPlanRepresentatives: GetPlanRepresentatives) => {
        this.planStore.set('getPlanRepresentativesResult', getPlanRepresentatives);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  getAnotherRepresentatives(planId: number, tpAdminNbr: number): Observable<GetPlanRepresentatives> {
    return this.http.get<GetPlanRepresentatives>(this.baseUrl + '/plan/' + planId + `/representative/previous-rep/${tpAdminNbr}`).pipe(
      tap((getPlanRepresentatives: GetPlanRepresentatives) => {
        this.planStore.set('getPlanRepresentativesResult', getPlanRepresentatives);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  addAnotherRepresentative(planId: number, repId: number, isPending: boolean, userId: number): Observable<PendingPlanResponse> {
    const reqParam = `?is-pending=${isPending ? isPending : false}&user-id=${userId}`;

    return this.http
      .post(this.baseUrl + '/plan/' + planId + `/representative/${repId}${reqParam}`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  addRepresentative(
    addRepresentativeRequest: AddRepresentativeRequest,
    planId: number,
    isPending: boolean,
  ): Observable<PendingPlanResponse> {
    const reqParam = `?is-pending=${isPending ? isPending : false}`;
    return this.http
      .post(this.baseUrl + '/plan/' + planId + `/representative${reqParam}`, addRepresentativeRequest)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  updateRepresentative(
    editRepresentativeRequest: RepresentativeResponse,
    planId: number
  ): Observable<EditRepresentativeResponse> {
    return this.http
      .patch(this.baseUrl + '/plan/' + planId + '/representative', editRepresentativeRequest)
      .pipe(catchError(ErrorUtilsService.catchError));
  }
  removeRepresentative(
    removeRepresentativeRequest: EditRepresentativeResponse,
    planId: number,
    repId: number,
    isPending: boolean,
    userId: number
  ): Observable<SuccessResponse> {
    const reqParam = `?is-pending=${isPending ? isPending : false}&user-id=${userId}`;
    return this.http
      .delete(this.baseUrl + '/plan/' + planId + `/representative/${repId}${reqParam}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  saveUnlicensedRep(planId: number, userId: number): Observable<PendingPlanResponse> {
    return this.http
      .post(this.baseUrl + '/plan/' + planId + '/representative/na/' + userId, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  // Solicitor API's
  getApprovedSolicitorList(planId: number, moneyMgrId: number): Observable<ApprovedSolicitorsMnyMgr> {
    return this.http.get<ApprovedSolicitorsMnyMgr>(this.baseUrl + '/plan/' + planId + '/get-approved-solicitor/' + moneyMgrId).pipe(
      tap((getApprovedSolicitorList: ApprovedSolicitorsMnyMgr) => {
        this.planStore.set('getApprovedSolicitorListResult', getApprovedSolicitorList);
      })
    );
  }

  getApprovedSolicitorListByMnyMgrId(moneyMgrId: number): Observable<ApprovedSolicitorsMnyMgr> {
    const params = new HttpParams().set('mm-id', moneyMgrId ? moneyMgrId.toString() : '');
    return this.http.get<ApprovedSolicitorsMnyMgr>(this.baseUrl + '/plan/get-approved-solicitor-list', { params }).pipe(
      tap((getApprovedSolicitorList: ApprovedSolicitorsMnyMgr) => {
        this.planStore.set('getApprovedSolicitorListResult', getApprovedSolicitorList);
      })
    );
  }

  getAllApprovedMnyMgrSolicitor(moneyMgrId: number): Observable<ApprovedSolicitorsMnyMgr> {
    const params = new HttpParams().set('mny-mgr-id', moneyMgrId ? moneyMgrId.toString() : '');
    return this.http.get<ApprovedSolicitorsMnyMgr>(this.baseUrl + '/plan/get-all-approved-solicitor', { params }).pipe(
      tap((getAllApprovedMnyMgrSolicitor: ApprovedSolicitorsMnyMgr) => {
        this.planStore.set('getApprovedSolicitorListResult', getAllApprovedMnyMgrSolicitor);
      })
    );
  }
  getAllApprovedSolicitor(): Observable<ApprovedSolicitorsMnyMgr> {
    return this.http.get<ApprovedSolicitorsMnyMgr>(this.baseUrl + '/plan/get-all-approved-solicitor').pipe(
      tap((getAllApprovedSolicitorList: ApprovedSolicitorsMnyMgr) => {
        this.planStore.set('getApprovedSolicitorListResult', getAllApprovedSolicitorList);
      })
    );
  }

  updateApprovedSolicitor(
    planId: number,
    updateSolicitorRequest: UpdateSolicitorRequest,
    isPending?: boolean,
  ): Observable<PendingPlanResponse> {
    return this.http
      .patch(this.baseUrl + '/plan/' + planId + `/edit-approved-solicitor?is-pending=${isPending ? isPending : false}`, updateSolicitorRequest)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }
  updateSolicitorMnyMgr(updateSolicitorRequest: UpdateSolicitorRequest): Observable<PlanSummaryResponse> {
    return this.http
      .put(this.baseUrl + '/solicitor/edit-solicitor-mny-mgr', updateSolicitorRequest)
      .pipe(catchError(ErrorUtilsService.catchError));
  }
  deleteApprovedSolicitor(solicitorId: number, moneyManager: number): Observable<PlanSummaryResponse> {
    return this.http
      .delete(
        this.baseUrl + '/solicitor/delete-solicitor-mny-mgr/' + solicitorId + '/money-manager/' + moneyManager
      )
      .pipe(catchError(ErrorUtilsService.catchError));
  }
  updateSolicitorByFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(this.baseUrl + '/solicitor/add-solicitor-by-file', formData);
  }
  updateSolicitorByName(solicitorName: string) {
    return this.http.put(this.baseUrl + '/solicitor/add-solicitor-by-name/' + solicitorName, {});
  }
  set planId(planId: number) {
    this.planId = planId;
  }
  get planId() {
    return this.planId;
  }

  // plan checklist API's list

  getPlanRevisionChecklist(planId: number): Observable<PlanSetupChecklistResponse[]> {
    return this.http.get<PlanSetupChecklistResponse[]>(this.baseUrl + '/plan/' + planId + '/get-plan-revision-check-list').pipe(
      tap((getPlanRevisionChecklist: PlanSetupChecklistResponse[]) => {
        this.planStore.set('getPlanRevisionChecklistResult', getPlanRevisionChecklist);
      })
    );
  }

  getRevisionHistory(planId: number): Observable<PlanSetupChecklistResponse[]> {
    return this.http.get<PlanSetupChecklistResponse[]>(this.baseUrl + '/plan/' + planId + '/get-revision-history').pipe(
      tap((getPlanRevisionHistory: PlanSetupChecklistResponse[]) => {
        this.planStore.set('getPlanRevisionHistoryResult', getPlanRevisionHistory);
      }), catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  savePlanRevision(
    planId: number,
    savePlanRequest: PlanSetupChecklistResponse,
    emailId: string
  ): Observable<PlanSetupChecklistSuccessResponse> {
    return this.http.post(this.baseUrl + '/plan/' + planId + '/save-plan-revision' + '?email-id=' + 
      emailId, savePlanRequest).pipe(catchError(ErrorUtilsService.catchError));
  }

  commitPlanRevision(
    planId: number,
    savePlanRequest: PlanSetupChecklistResponse,
    emailId: string
  ): Observable<PlanSetupChecklistSuccessResponse> {
    return this.http.patch(this.baseUrl + '/plan/' + planId + `/commit-plan-revision/${savePlanRequest?.planRevisionTrackingId}` +
      '?email-id=' + emailId, savePlanRequest).pipe(catchError(ErrorUtilsService.catchError));
  }

  deletePlanRevision(planId: number, planRevisionTrackingId: number): Observable<PlanSetupChecklistSuccessResponse> {
    return this.http.delete(this.baseUrl + '/plan/' + planId + '/delete-plan-revision/' + planRevisionTrackingId).pipe(catchError(ErrorUtilsService.catchError));
  }
  // Archive Plan
  updateArchivePlan(planId: number): Observable<PlanSummaryResponse> {
    return this.http.patch(this.baseUrl + '/plan/' + planId + '/archive-plan', { planId }).pipe(
      map((res) => res),
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  // unarchive Plan
  updateUnarchivePlan(planId: number): Observable<PlanSummaryResponse> {
    return this.http.patch(this.baseUrl + '/plan/' + planId + '/unarchive-plan', { planId }).pipe(
      map((res) => res),
      catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }
  savePlanNotes(planId: number, planNotesRequest: GetPlanNotes): Observable<PlanSummaryResponse> {
    return this.http.post(this.baseUrl + '/plan/' + planId + '/add-plan-note', planNotesRequest).pipe(catchError(ErrorUtilsService.catchError));
  }

  // Save sponsor Docs
  saveSponsorDocument(saveSponsorDoc: any): Observable<SaveSponsorDoc> {
    return this.http.post(this.baseUrl + '/plan/save-sponsor-document', saveSponsorDoc).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  // Participant Ik upload
  saveParticipantIKUpload(file: File, partcipantRequest: GetPlanNotes): Observable<FileUploadResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const DTO_OBJECT = JSON.stringify(partcipantRequest);
    formData.append('participant-activation-request', DTO_OBJECT);
    return this.http.post(this.baseUrl + '/plan/ik-participant-activation', formData).pipe(catchError(ErrorUtilsService.catchError));
  }

  getParticipantIKFileResponse(moneyMgrId: number): Observable<ParticipantFileResponseList> {
    const params = new HttpParams().set('mny-mgr-id', moneyMgrId ? moneyMgrId.toString() : '');
    return this.http.get<ParticipantFileResponseList>(this.baseUrl + '/plan/get-ik-participant-file-response', { params }).pipe(
      tap((getParticipantIKFileResponse: ParticipantFileResponseList) => {
        this.planStore.set('getParticipantIKFileResponseResult', getParticipantIKFileResponse);
      })
    );
  }
  //Participant activation
  getPlanDetailsFromMnyMgrId(moneyMgrId: string): Observable<GetPlanMMDetails> {
    const params = new HttpParams().set('mny-mgr-id', moneyMgrId ? moneyMgrId.toString() : '');
    return this.http.get<GetPlanMMDetails>(this.baseUrl + '/plan/get-plan-details-by-mm-id', { params }).pipe(
      tap((getPlanMMDetailsResponse: GetPlanMMDetails) => {
        this.planStore.set('getPlanParticipantResult', getPlanMMDetailsResponse);
      })
    );
  }
  getPlanFeeDetailsFromPlanId(planId: number, mnyMgrId: string): Observable<GetPlanFeeDetails> {
    const params = new HttpParams()
      .set('plan-id', planId ? planId.toString() : '')
      .set('mny-mgr-id', mnyMgrId ? mnyMgrId : '');
    return this.http.get<GetPlanFeeDetails>(this.baseUrl + '/plan/get-plan-fee-details-by-plan-id', { params }).pipe(
      tap((getPlanFeeDetailsResponse: GetPlanFeeDetails) => {
        this.planStore.set('getPlanParticipantResult', getPlanFeeDetailsResponse);
      })
    );
  }
  getPlanRepresentativeFromPlanId(planId: number): Observable<GetPlanRepresentative> {
    const params = new HttpParams().set('plan-id', planId ? planId.toString() : '');
    return this.http.get<GetPlanRepresentative>(this.baseUrl + '/plan/get-plan-representative-details-by-plan-id', { params }).pipe(
      tap((getPlanRepresentativeResponse: GetPlanRepresentative) => {
        this.planStore.set('getPlanParticipantResult', getPlanRepresentativeResponse);
      })
    );
  }
  getTracPlanRepresentativeFromPlanId(planId: number): Observable<GetPlanRepresentative> {
    const params = new HttpParams().set('plan-id', planId ? planId.toString() : '');
    return this.http.get<GetPlanRepresentative>(this.baseUrl + '/plan/get-trac-plan-rep-details-by-plan-id', { params }).pipe(
      tap((getPlanRepresentativeResponse: GetPlanRepresentative) => {
        this.planStore.set('getTracPlanParticipantResult', getPlanRepresentativeResponse);
      })
    );
  }
  getParticipantDetails(planNo: string): Observable<GetParticipantDetails> {
    const params = new HttpParams().set('plan-nbr', planNo ? planNo.toString() : '');
    return this.http.get<GetParticipantDetails>(this.baseUrl + '/plan/get-participants-details-by-plan-case-nbr', { params }).pipe(
      tap((getParticipantDetailsResponse: GetParticipantDetails) => {
        this.planStore.set('getPlanParticipantResult', getParticipantDetailsResponse);
      })
    );
  }
  removeParticipantsData(removeIkData): Observable<PlanSummaryResponse> {
    return this.http.post(this.baseUrl + '/plan/remove-participants', removeIkData).pipe(catchError(ErrorUtilsService.catchError));
  }
  activateParticpantsData(activateTracData): Observable<PlanSummaryResponse> {
    return this.http.post(this.baseUrl + '/plan/activate-participants', activateTracData).pipe(catchError(ErrorUtilsService.catchError));
  }
  /*Trac Participant upload*/
  saveParticipantTracUpload(file: File, partcipantRequest: GetPlanNotes): Observable<FileUploadResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const DTO_OBJECT = JSON.stringify(partcipantRequest);
    formData.append('participant-activation-request', DTO_OBJECT);
    return this.http.post(this.baseUrl + '/plan/trac-participant-activation', formData).pipe(catchError(ErrorUtilsService.catchError));
  }

  getParticipantTracFileResponse(moneyMgrId: number): Observable<ParticipantFileResponseList> {
    const params = new HttpParams().set('mny-mgr-id', moneyMgrId ? moneyMgrId.toString() : '');
    return this.http.get<ParticipantFileResponseList>(this.baseUrl + '/plan/get-trac-participant-file-response', { params }).pipe(
      tap((getParticipantIKFileResponse: ParticipantFileResponseList) => {
        this.planStore.set('getParticipantIKFileResponseResult', getParticipantIKFileResponse);
      })
    );
  }
  //Participant activation
  getTracPlanDetailsFromMnyMgrId(moneyMgrId: string): Observable<GetPlanMMDetails> {
    const params = new HttpParams().set('mny-mgr-id', moneyMgrId ? moneyMgrId.toString() : '');
    return this.http.get<GetPlanMMDetails>(this.baseUrl + '/plan/get-trac-plan-details-by-mm-id', { params }).pipe(
      tap((getPlanMMDetailsResponse: GetPlanMMDetails) => {
        this.planStore.set('getPlanParticipantResult', getPlanMMDetailsResponse);
      })
    );
  }
  getTracPlanFeeDetailsFromPlanId(planId: number, mnyMgrId: string): Observable<GetPlanFeeDetails> {
    const params = new HttpParams()
      .set('plan-id', planId ? planId.toString() : '')
      .set('mny-mgr-id', mnyMgrId ? mnyMgrId : '');
    return this.http.get<GetPlanFeeDetails>(this.baseUrl + '/plan/get-trac-plan-fee-details-by-plan-id', { params }).pipe(
      tap((getPlanFeeDetailsResponse: GetPlanFeeDetails) => {
        this.planStore.set('getPlanParticipantResult', getPlanFeeDetailsResponse);
      })
    );
  }
  getTracPlanRepresentativeDetailsFromPlanId(planId: number): Observable<GetPlanRepresentative> {
    const params = new HttpParams().set('plan-id', planId ? planId.toString() : '');
    return this.http.get<GetPlanRepresentative>(this.baseUrl + '/plan/get-trac-plan-rep-details-by-plan-id', { params }).pipe(
      tap((getPlanRepresentativeResponse: GetPlanRepresentative) => {
        this.planStore.set('getPlanParticipantResult', getPlanRepresentativeResponse);
      })
    );
  }
  getTracParticipantsDetailsFromPlanCaseNo(planNo: string): Observable<GetParticipantDetails> {
    const params = new HttpParams().set('plan-nbr', planNo ? planNo.toString() : '');
    return this.http.get<GetParticipantDetails>(this.baseUrl + '/plan/get-trac-participant-details-by-plan-case-nbr', { params }).pipe(
      tap((getParticipantDetailsResponse: GetParticipantDetails) => {
        this.planStore.set('getPlanParticipantResult', getParticipantDetailsResponse);
      })
    );
  }
  removeTracParticipants(removeTracData): Observable<PlanSummaryResponse> {
    return this.http.post(this.baseUrl + '/plan/remove-trac-participants', removeTracData).pipe(catchError(ErrorUtilsService.catchError));
  }
  getPlanNextSteps(planId: number, isPending?: boolean, afterEdit?: boolean): Observable<PlanStep> {
    return this.http.get<PlanStep>(this.baseUrl + '/plan/' + planId + `/get-plan-step?is-pending=${isPending ? isPending : false}&after-edit=${afterEdit ? afterEdit : false}`).pipe(
      tap((planSteps: PlanStep) => {
        this.planStore.set('getPlanStepResult', planSteps);
      })
    );
  }

  toggleSideNav(opening: boolean): void {
    this.planStatusSubject$.next(opening);
  }

  onSideNavToggle(): Observable<boolean> {
    return this.planStatusSubject$.asObservable();
  }

  activatePendingPlan(planId: number, emailId: string): Observable<PlanSummaryResponse> {
    const reqParam = `?plan-id=${planId}&email-id=${emailId}`;
    return this.http.post(this.baseUrl + `/plan/activate-pending${reqParam}`, {}).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  clearPendingPlan(planId: number): Observable<any> {
    const params = new HttpParams().set('plan-id', planId);
    return this.http.get(this.baseUrl + `/plan/clear-pending`, {params}).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  getPreviousSolicitorList(tpaNo: string): Observable<PreviousSolicitor[]> {
    const params = new HttpParams().set('tpa-nbr', tpaNo);
    return this.http.get<PreviousSolicitor[]>(this.baseUrl +'/plan/get-previous-solicitors', { params } ).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  chooseSolicitor(planId: number, solId:number, isPending: boolean,userId: number): Observable<PendingPlanResponse> {
    const params = new HttpParams()
      .set('is-pending', isPending)
      .set('user-id', userId ? userId : '');
    return this.http.get<any>(this.baseUrl + `/plan/` + planId + `/add-plan-solicitor/`+ solId, { params } ).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  saveNewSolicitor(planId: number,isPending: boolean, solicitorPlanInfo: PreviousSolicitor): Observable<PendingPlanResponse> {
    const reqParam = `?is-pending=${ isPending }`;
    return this.http
      .post(this.baseUrl + `/plan/` + planId +`/add-new-plan-solicitor${reqParam}`, solicitorPlanInfo)
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  participantTrackOriginalFile(fileId: number): Observable<any>{
    return this.http.get(this.baseUrl + `/plan/download-file/${fileId}`, { responseType: 'blob' }).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  participantTrackErrorFile(fileId: number): Observable<any> {
    return this.http.get(this.baseUrl + `/plan/download-file-errors/${fileId}`, {  responseType: 'blob'}).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

   /** HTTP POST  to download plan document */
  downloadPlanDocument(userId:number, trackFormData: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      "content-type": "application/json",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "Accept": "application/zip",
    });
    const reqParam = `?user-id=${ userId }`;
    return this.http
      .post(this.baseUrl + `/matrix/download-plan-document${reqParam}`, trackFormData, { headers, responseType: 'blob'})
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  checkboxFormChange(event:any,form: UntypedFormArray): void {
    if (event.target.checked) {
      form.push(new UntypedFormControl(+event.target.value));
    } else {
      const index = form.controls.findIndex((x) => x.value === +event.target.value);
      form.removeAt(index);
    }
  }

   /** HTTP POST  to mark user received in track sponsor*/
   markUserRecieved(userId: number,formId: number): Observable<any> {
    const reqParam = new HttpParams()
      .set('?form-id', formId)
      .set('user-id', userId ? userId : '');
    return this.http
      .post(this.baseUrl + `/matrix/update-track-form${reqParam}`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

}
