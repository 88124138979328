import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NfrpAlertMessage, NfrpTypeaheadService, NavigationStore } from 'nfrp-shared-angular';
import { EkpDetail, EkpResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/ekp-detail';
import { CcLandingService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/cc-landing.service';
import { Subscription } from 'rxjs';
import { validationPatterns, alertMessages } from '../helpers/common-constants';
import { BooleanEnum } from '../helpers/enum';
import { CommunicationService } from '../services/communication.service';
@Component({
  selector: 'ekp-home',
  templateUrl: './ekp-home.component.html',
  styleUrls: ['./ekp-home.component.scss'],
})
export class EkpHomeComponent implements OnInit, OnDestroy {
  planNoValue: string;
  createDoc = false;
  ssn: string;
  planDesc: string;
  checkedIDs = [];
  planNoRecord = false;
  planDetails: any;
  planNoExist = false;
  selectedAction: string;
  submitted = false;
  loading = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  planId: number;
  userEmail: string;
  planNumberView: EkpDetail[];
  subscriptions = [];
  formSubscription: Subscription;
  ekpHomeForm: UntypedFormGroup;
  ekpResponse: EkpResponse;
  private planNumber: string;

  constructor(
    private fb: UntypedFormBuilder,
    private ccLandingService: CcLandingService,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private router: Router,
    private nfrpTypeaheadService: NfrpTypeaheadService,
    private navigationStore: NavigationStore
  ) {}
  get ekpHomeFormControl() {
    return this.ekpHomeForm.controls;
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.userEmail = this.communicationService.loginUserId;
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.planId = params.planId;
        this.selectedAction = params.action;
      })
    );
    this.ekpHomeForm = this.fb.group({
      planNo: [
        '',
        [Validators.maxLength(9), Validators.minLength(9), Validators.pattern(validationPatterns.alphaNumericPattern)],
      ],
      ekpDoc: this.fb.array([]),
    });
    this.formSubscription = this.ekpHomeForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.ekpHomeForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }

  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }
  onCheckboxChange($event, ekp) {
    const ekpDoc: UntypedFormArray = this.ekpHomeForm.get('ekpDoc') as UntypedFormArray;
    if ($event.target.checked) {
      const ekpRequest = {
        documentId: ekp.documentId,
        documentName: ekp.documentName,
      };
      ekpDoc.push(new UntypedFormControl(ekpRequest));
    } else {
      const index = ekpDoc.controls.findIndex((x) => x.value === $event.target.value);
      ekpDoc.removeAt(index);
    }
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.ekpHomeForm.valid) {
      this.loading = true;
      this.subscriptions.push(
        this.ccLandingService.getEkpPlanDetails(this.ekpHomeForm.value.planNo).subscribe(
          (data) => {
            window.scroll(0, 0);
            this.loading = false;
            this.planNumberView = data.searchDocumentResponses;
            this.ekpResponse = data;
            this.planNumber = data.planNumber;
            sessionStorage.setItem('planNumber', this.planNumber);
            this.navigationStore.set('formChanged', false);
            if (this.ekpResponse.printFlag === BooleanEnum.YES) {
              this.createDoc = false;
              this.router.navigate(['enroll-doc', this.ekpResponse.planId]);
            } else {
              this.createDoc = true;
              this.errorMessage = { message: '', details: [] };
            }
          },
          (error: HttpErrorResponse) => {
            this.createDoc = false;
            window.scroll(0, 0);
            this.loading = false;
            if (error.error?.errorMessage) {
              this.errorMessage = { message: error.error?.errorMessage, details: [] };
            } else {
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
  }
  createDocument() {
    this.submitted = true;
    this.router.navigate(['enroll-doc', this.ekpResponse.planId]);
  }
  get planNo(): UntypedFormControl {
    return this.ekpHomeForm.get('planNo') as UntypedFormControl;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
    this.navigationStore.set('formChanged', false);
  }
}
