import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { MatrixDocumentService } from 'projects/nfrp-mas-retail-private-angular/src/matrix/services/matrix-document.service';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { PlanSummaryResponse } from '../../../model/get-plan.model';
import { MoneyManagerMgmt } from '../../../model/mny-mgr-mgmt';
import { ApprovedSolicitorsMnyMgr, MoneyManager, SolicitorInfo, UpdateSolicitorRequest } from '../../../model/money-manager-data';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { PlanDetailsService } from '../../../services/plan-details.service';
import { SearchUsersService } from '../../../services/search-users.service';

@Component({
  selector: 'lib-assign-solicitor',
  templateUrl: './assign-solicitor.component.html',
  styleUrls: ['./assign-solicitor.component.css'],
})
export class AssignSolicitorComponent implements OnInit, OnDestroy {
  submitted = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  solicitorFee: any;
  moneyManagerList: MoneyManager[];
  approvedSolicitorList: ApprovedSolicitorsMnyMgr;
  approvedSolicitor: SolicitorInfo[];
  assignedSolicitor: SolicitorInfo[];
  showAssignedSolicitor: boolean;
  loading = false;
  filterApprovedSolicitor: SolicitorInfo[];
  filterAssignedSolicitor: SolicitorInfo[];
  assignPageNum = 1;
  approvedPageNum = 1;
  totalApprovedItem = 0;
  totalAssignedItem = 0;
  updateSolicitorRequest: UpdateSolicitorRequest;
  userEmail: string;
  showSolicitorError = false;
  changeArrowText: number;
  changeRemoveArrowText: number;
  assignedSol: string;
  assignSolForm = this.fb.group({
    mnyMgrId: [],
  });
  private subscriptions = [];
  constructor(
    private fb: UntypedFormBuilder,
    private searchUserService: SearchUsersService,
    private planDetailsService: PlanDetailsService,
    private footerFeaturesService: FooterFeaturesService,
    private matrixDocumentService: MatrixDocumentService
  ) {}
  get solicitorFormControl() {
    return this.assignSolForm.controls;
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.assignSolForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getChooseErrorMessage(preText: string): string {
    return `Please choose a valid ${preText}`;
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.footerFeaturesService
        .getSubFooterDetails(FeatureTypeIDEnum.CLIENT_SETUP_SOLICITOR_MAINT, this.userEmail)
        .subscribe()
    );
    this.getMoneyManager();
    this.assignSolForm.get('mnyMgrId').valueChanges.subscribe((mnyMgr) => {
      if (mnyMgr != null) {
        this.errorMessage = { message: '', details: [] };
        this.successMessage = { message: '', details: [] };
        this.getAllApprovedSolicitor(mnyMgr);
      }
    });
  }
  setTwoNumberDecimal($event) {
    $event.target.value = parseFloat($event.target.value).toFixed(2);
  }
  onAppSolClicked(solIndex, enterOrLeave) {
    if (enterOrLeave) {
      this.changeArrowText = solIndex;
    } else {
      this.changeArrowText = null;
    }
  }
  onRemoveSolClicked(solIndex, enterOrLeave) {
    if (enterOrLeave) {
      this.changeRemoveArrowText = solIndex;
    } else {
      this.changeRemoveArrowText = null;
    }
  }
  getMoneyManager(): void {
    this.subscriptions.push(
      this.matrixDocumentService
      .getMoneyManagerMatrix(this.userEmail)
        .subscribe((firmData: MoneyManager[]) => {
          this.moneyManagerList = firmData;
        },
        (error) => {
          this.errorMessage = { message: error, details: [] };
        }
        )
    );
  }
  getAllApprovedMnyMgrSolicitor(mnyMgr?: number) {
    this.errorMessage = { message: '', details: [] };
    this.subscriptions.push(
      this.planDetailsService.getAllApprovedMnyMgrSolicitor(mnyMgr).subscribe((firmData: ApprovedSolicitorsMnyMgr) => {
        this.assignedSolicitor = firmData?.approvedSolicitorResponseList;
        this.filterAssignedSolicitor = firmData?.approvedSolicitorResponseList;
        this.approvedSolicitor = this.approvedSolicitor?.filter(
          (approveSol) => !this.assignedSolicitor.find((assSol) => assSol.appSolicitorId === approveSol.appSolicitorId)
        );
        this.filterApprovedSolicitor = this.approvedSolicitor;
      })
    );
  }
  getAllApprovedSolicitor(mnyMgr) {
    this.loading = true;
    this.errorMessage = { message: '', details: [] };
    this.subscriptions.push(
      this.planDetailsService.getAllApprovedSolicitor().subscribe((firmData: ApprovedSolicitorsMnyMgr) => {
        this.loading = false;
        this.showAssignedSolicitor = true;
        this.approvedSolicitor = firmData?.approvedSolicitorResponseList;
        this.getAllApprovedMnyMgrSolicitor(mnyMgr);
      })
    );
  }
  assignSolicitorFilter(filterValue, solicitorList) {
    if (solicitorList === 'APPROVED_SOL') {
      this.approvedSolicitor = this.filterApprovedSolicitor?.filter((solicitor) =>
        solicitor?.appSolicitorName.toLowerCase().trim().startsWith(filterValue.toLowerCase())
      );
    }
    if (solicitorList === 'ASSIGNED_SOL') {
      this.assignedSolicitor = this.filterAssignedSolicitor?.filter((solicitor) =>
        solicitor?.appSolicitorName.toLowerCase().trim().startsWith(filterValue.toLowerCase())
      );
    }
  }

  addToAssignedSolicitor(solicitor, indexSol) {
    this.updateSolicitorRequest = {
      appSolicitorFee: solicitor?.fee,
      appSolicitorId: solicitor?.appSolicitorId,
      mnyMgrId: +this.assignSolForm.value.mnyMgrId,
    };
    this.planDetailsService.updateSolicitorMnyMgr(this.updateSolicitorRequest).subscribe(
      (solicitorResponse: PlanSummaryResponse) => {
        this.errorMessage = { message: '', details: [] };
        this.getAllApprovedMnyMgrSolicitor(this.assignSolForm.value.mnyMgrId);
        const solObj = document.getElementById('list-' + indexSol);
        solObj.remove();
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
      }
    );
  }
  removeFromAssignedSolicitor(solicitorId) {
    this.planDetailsService.deleteApprovedSolicitor(solicitorId, this.assignSolForm.value.mnyMgrId).subscribe(
      (deleteSolicitorResponse: PlanSummaryResponse) => {
        this.errorMessage = { message: '', details: [] };
        this.getAllApprovedSolicitor(this.assignSolForm.value.mnyMgrId);
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
      }
    );
  }
  onKeyPressSolicitorFee(solFee) {
    if (solFee.toFixed(1) > 0.2) {
      this.showSolicitorError = true;
    } else {
      this.showSolicitorError = false;
    }
  }
  updateSolicitorFee(appSolicitorId, fee) {
    if (fee?.toFixed(1) > 0.2) {
      this.successMessage = { message: '', details: [] };
      this.errorMessage = { message: alertMessages.SOLICITOR_FEE_MAX_ERROR, details: [] };
    } else {
      this.updateSolicitorRequest = {
        appSolicitorFee: +fee,
        appSolicitorId,
        mnyMgrId: +this.assignSolForm.value.mnyMgrId,
      };
      this.loading = true;
      this.planDetailsService.updateSolicitorMnyMgr(this.updateSolicitorRequest).subscribe(
        (solicitorResponse: PlanSummaryResponse) => {
          this.loading = false;
          this.errorMessage = { message: '', details: [] };
          this.successMessage = { message: alertMessages.APP_SOLICITOR_UPDATED, details: [] };
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      );
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
