<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="(planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1"
          ><i class="nw-icon-check f-green"></i
        ></span>
        {{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }})
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom" *ngIf="infoMessage?.message?.length > 0">
        <nfrp-alert alertId="info-display-message" [message]="infoMessage" type="info" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="plan-fee-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="plan-fee-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <div>
        <form [formGroup]="planFeeScheduleForm" (ngSubmit)="onSubmitScheduleForm()">
          <ul id="representative-params" summary="employee sponsor" class="ul-li-view-edit table stripes no-margin">
            <li class="head" [ngClass]="{'pending-status-bg':isPending}">
              <div class="col-sm-12 padding-left">
                <b>Plan Fee Schedule</b>
              </div>
            </li>
            <li>
              <div class="col-sm-4">
                <ul
                  id="representative-params"
                  summary="employee sponsor"
                  class="ul-li-view-edit table stripes no-margin width-100"
                >
                  <li class="head">
                    <div class="col-sm-12 padding-left">
                      <b>Admin Fees</b>
                    </div>
                  </li>
                  <li *ngIf="isAdminstratior">
                    <div class="col-sm-6 padding-left">NRP Bundled</div>
                    <div class="col-sm-6 padding-left">
                      <select
                        name="nrpBundled"
                        (ngModelChange)="onChangeAdminFees()"
                        id="nrpBundled"                        
                        [class.disabled]="hideTpaFee"
                        formControlName="nrpBundled"
                      >  
                      <ng-container>
                        <option *ngFor="let fee of tpaLimit" [value]="fee">{{ fee.toFixed(2) }}</option></ng-container>
                      </select>
                    </div>
                  </li>
                  <li>
                    <div class="col-sm-6 padding-left"><span *ngIf="isAdminstratior">NRP&nbsp;</span>TPA</div>
                    <div class="col-sm-6 padding-left">
                      <select name="nrpTpa" (ngModelChange)="onChangeAdminFees()"  [class.disabled]="hideTptFee" id="nrpTpa" formControlName="nrpTpa">
                        <ng-container
                          ><option *ngFor="let fee of tptLimit" [value]="fee">{{
                            fee.toFixed(2)
                          }}</option></ng-container
                        >
                      </select>
                    </div>
                  </li>
                  <li>
                    <div class="col-sm-6 padding-left">RIA Services</div>
                    <div class="col-sm-6 padding-left">
                      <select
                        name="riaServices"
                        (ngModelChange)="onChangeAdminFees()"
                        id="riaServices"
                        formControlName="riaServices"
                        [class.disabled]="hideRiaFee"
                      >
                        <ng-container>
                          <option *ngFor="let fee of riaLimit" [value]="fee">{{ fee.toFixed(2) }}</option></ng-container
                        >
                      </select>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-sm-4">
                <ul
                  id="representative-params"
                  summary="employee sponsor"
                  class="ul-li-view-edit table stripes no-margin width-100"
                >
                  <li class="head">
                    <div class="col-sm-12 padding-left">
                      <b>Advisory Fees</b>
                    </div>
                  </li>
                  <li>
                    <div class="col-sm-6 padding-left">Solicitor</div>
                    <div class="col-sm-6 padding-left">
                      <select
                        name="companyState"
                        id="companyState"
                        formControlName="advisoryFeesSolicitor"
                        (ngModelChange)="onChangeAdvisoryFees()"
                        [class.disabled]="hideSolFee"
                      >
                        <ng-container>
                          <option *ngFor="let fee of solLimit" [value]="fee">{{ fee.toFixed(2) }}</option></ng-container
                        >                      
                      </select>
                    </div>
                  </li>
                  <li>
                    <div class="col-sm-6 padding-left">Manager</div>
                    <div class="col-sm-6 padding-left">
                      <select
                        name="companyState"
                        id="companyState"
                        formControlName="advisoryFeesManager"
                        (ngModelChange)="onChangeAdvisoryFees()"
                        [class.disabled]="hideMgrFee"
                      >
                        <ng-container>
                          <option *ngFor="let fee of mgrLimit" [value]="fee">{{ fee.toFixed(2) }}</option></ng-container
                        >
                      </select>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-sm-4">
                <ul
                  id="representative-params"
                  summary="employee sponsor"
                  class="ul-li-view-edit table stripes no-margin width-100"
                >
                  <li class="head">
                    <div class="col-sm-12 padding-left">
                      <b>Fee Totals</b>
                    </div>
                  </li>
                  <li>
                    <div class="col-sm-6 padding-left">Total Admin Fee</div>
                    <div class="col-sm-6 padding-left">{{ totalAdminFee.toFixed(2) }}%</div>
                  </li>
                  <li>
                    <div class="col-sm-6 padding-left">Total Advisory Fee</div>
                    <div class="col-sm-6 padding-left">{{ totalAdvisoryFee.toFixed(2) }}%</div>
                  </li>
                  <li>
                    <div class="col-sm-6 padding-left">Total Plan Fee</div>
                    <div class="col-sm-6 padding-left">{{ (totalAdminFee + totalAdvisoryFee).toFixed(2) }}%</div>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div class="col-sm-3 no-padding">
                <input
                  id="search-button"
                  class="button-primary button hover-left margin-left"
                  type="submit"
                  size="40"
                  value="{{saveButton}}"
                />
              </div>
              <div class="col-sm-8 no-padding"></div>
              <div class="col-sm-1 no-padding">
                <input
                  type="button"
                  class="button button-secondary hover-right margin-right"
                  (click)="onCancel()"
                  value="Cancel"
                />
              </div>
            </li>
          </ul>
        </form>
      </div>

      <div class="margin-top" *ngIf="feeVerification && selectedAction === 'edit'">
        <form [formGroup]="feeVerificationForm" (ngSubmit)="onSubmitVerificationForm()">
          <ul
            id="representative-params"
            summary="emp
          loyee sponsor"
            class="ul-li-view-edit table stripes no-margin"
          >
            <li class="head">
              <div class="col-sm-12 padding-left">
                <b>Fee Verifications</b>
              </div>
            </li>
            <li>
              <div class="form-group padding-left">
                <label
                  *ngIf="isManagerFee"
                  [class.disabled]="feeVerificationForm.value.isMnyMgrFee && isPlanManagerVerified"
                  for="checkbox-label-1-id"
                  id="checkbox-label-1-label-id"
                  class="checkbox-inline"
                >
                  <input
                    type="checkbox"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty"
                    formControlName="isMnyMgrFee"
                    name="isMnyMgrFee"
                    id="checkbox-label-1-id"
                    (change)="planFeeChangeEvent($event, 'isMnyMgrFee')"
                  />
                  Money Manager fee
                </label>
                <label
                  *ngIf="isRiaFee"
                  [class.disabled]="feeVerificationForm.value.isRiaServiceFee && isPlanRiaVerified"
                  for="checkbox-label-2-id"
                  id="checkbox-label-2-label-id"
                  class="checkbox-inline"
                >
                  <input
                    type="checkbox"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty"
                    formControlName="isRiaServiceFee"
                    name="isRiaServiceFee"
                    id="checkbox-label-2-id"
                    (change)="planFeeChangeEvent($event, 'isRiaServiceFee')"
                  />
                  RIA Services fee
                </label>
              </div>
            </li>
            <li>
              <div class="col-sm-3 no-padding">
                <input
                  id="search-button"
                  class="button-primary button hover-left margin-left"
                  type="submit"
                  size="40"
                  value="Verify Fee Schedule"
                />
              </div>
              <div class="col-sm-8 no-padding"></div>
              <div class="col-sm-1 no-padding">
                <input
                  type="button"
                  class="button button-secondary hover-right margin-right"
                  (click)="onCancel()"
                  value="Cancel"
                />
              </div>
            </li>
          </ul>
        </form>
      </div>
      <div class="margin-top" *ngIf="showVerificationMsg && selectedAction === 'edit'">
        <ul
          id="representative-params"
          summary="employee sponsor"
          class="ul-li-view-edit table stripes no-margin width-100"
        >
          <li class="head">
            <div class="col-sm-12 padding-left">
              <b>Fee Verifications</b>
            </div>
          </li>       
          <li *ngIf="verificationFees.riaFeeFlag === 1">
            <div class="col-sm-8 padding-left">{{verificationFees.riaFeeFlagMessage}}</div>
          </li>
          <li *ngIf="verificationFees.mnyMgrFeeFlag === 1">
            <div class="col-sm-8 padding-left">{{verificationFees.mnyMgrFeeFlagMessage}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
