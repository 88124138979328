<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">Add Money Manager</h1>
      <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container *ngIf="loading"> <nfrp-spinner></nfrp-spinner> </ng-container>
      <ng-container>
        <form class="mt-10" id="edit-view-money-manager-form" [formGroup]="mnyMgrForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-sm-6">
              <ul
                id="fixed-period-distribution-params"
                summary="fixed period distribution parameters"
                class="ul-li-view-edit table stripes no-margin border-ul"
              >
                <li>
                  <div class="col-sm-5 padding-left">
                    <span id="monry-mgr-label">Money Manager Number*</span>
                  </div>
                  <div class="col-sm-1"><label>RIA</label></div>
                  <div class="col-sm-6 flex-direction-col">
                    <input
                      type="text"
                      id="money-manager-num"
                      required
                      formControlName="moneyManagerNumber"
                      maxlength="5"
                      minlength="5"
                      name="moneyManagerNumber"
                      [ngClass]="{
                        'has-error': isControlInvalid('moneyManagerNumber')
                      }"
                    />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.moneyManagerNumber.touched || submitted) &&
                          moneyManagerControl.moneyManagerNumber.errors?.required
                        "
                        errorId="money-manager-no-required"
                        [message]="getRequiredErrorMessage('Money Manager Number')"
                      >
                      </nfrp-inline-error>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.moneyManagerNumber.touched || submitted) &&
                          (moneyManagerControl.moneyManagerNumber.errors?.pattern ||
                            moneyManagerControl.moneyManagerNumber.errors?.minLength)
                        "
                        errorId="money-manager-valid-error"
                        [message]="getValidErrorMessage('Money Manager Number')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="nationwide-ID-label">Money Manager ID*</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <input
                      type="number"
                      id="nation-wide-id"
                      formControlName="nwId"
                      maxlength="11"
                      name="nwId"
                      oninput="javascript: if (this.value.length > 11) this.value = this.value.slice(0, 11);"
                      [ngClass]="{
                        'has-error': isControlInvalid('nwId')
                      }"
                    />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.nwId.touched || submitted) && moneyManagerControl.nwId.errors?.required
                        "
                        errorId="money-manager-nwId-required"
                        [message]="getRequiredErrorMessage('Money Manager ID')"
                      >
                      </nfrp-inline-error>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.nwId.touched || submitted) && moneyManagerControl.nwId.errors?.pattern
                        "
                        errorId="money-manager-nwId-valid-error"
                        [message]="getValidErrorMessage('Money Manager ID')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="monry-mgr-name-label">Money Manager Name*</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <input
                      type="text"
                      id="money-manager-name"
                      formControlName="moneyManagerName"
                      name="moneyManagerName"
                      [ngClass]="{
                        'has-error': isControlInvalid('moneyManagerName')
                      }"
                    />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.moneyManagerName.touched || submitted) &&
                          moneyManagerControl.moneyManagerName.errors?.required
                        "
                        errorId="money-manager-name-required"
                        [message]="getRequiredErrorMessage('Money Manager Name')"
                      >
                      </nfrp-inline-error>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.moneyManagerName.touched || submitted) &&
                          moneyManagerControl.moneyManagerName.errors?.pattern
                        "
                        errorId="money-manager-name-valid-error"
                        [message]="getValidErrorMessage('Money Manager Name')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="monry-mgr-name-label">First Name</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="text"
                        id="first-name"
                        [ngClass]="{
                          'has-error': isControlInvalid('firstName')
                        }"
                        formControlName="firstName"
                        name="firstName"
                      />
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.firstName.touched || submitted) &&
                          moneyManagerControl.firstName.errors?.pattern
                        "
                        errorId="money-manager-fn-valid-error"
                        [message]="getValidErrorMessage('First Name')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="monry-mgr-name-label">Last Name</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="text"
                        id="last-name"
                        [ngClass]="{
                          'has-error': isControlInvalid('lastName')
                        }"
                        formControlName="lastName"
                        name="lastName"
                      />
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.lastName.touched || submitted) &&
                          moneyManagerControl.lastName.errors?.pattern
                        "
                        errorId="money-manager-ln-valid-error"
                        [message]="getValidErrorMessage('Last Name')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="address1-label">Address 1</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="text"
                        id="mny-mgr-address1"
                        [ngClass]="{
                          'has-error': isControlInvalid('address1')
                        }"
                        formControlName="address1"
                        name="address1"
                      />
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.address1.touched || submitted) &&
                          moneyManagerControl.address1.errors?.pattern
                        "
                        errorId="money-manager-addr-valid-error"
                        [message]="getValidErrorMessage('Address 1')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="address2-label">Address 2</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="text"
                        [ngClass]="{
                          'has-error': isControlInvalid('address2')
                        }"
                        id="mny-mgr-address2"
                        formControlName="address2"
                        name="address2"
                      />
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.address2.touched || submitted) &&
                          moneyManagerControl.address2.errors?.pattern
                        "
                        errorId="money-manager-addr2-valid-error"
                        [message]="getValidErrorMessage('Address 2')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="city-label">City</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="text"
                        [ngClass]="{
                          'has-error': isControlInvalid('city')
                        }"
                        id="mny-mgr-city"
                        formControlName="city"
                        name="city"
                      />
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.city.touched || submitted) && moneyManagerControl.city.errors?.pattern
                        "
                        errorId="money-manager-city-valid-error"
                        [message]="getValidErrorMessage('City')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="state-label">State</span>
                  </div>
                  <div class="col-sm-6 padding-left">
                    <select name="state" id="mny-mgr-state" formControlName="state">
                      <option value="">-- Choose from List --</option>
                      <option *ngFor="let state of mnyMgrStates" [value]="state.value">{{ state.label }}</option>
                    </select>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="zip-label">Zip</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <input
                      type="text"
                      id="mny-mgr-zip"
                      [ngClass]="{
                        'has-error': isControlInvalid('zip')
                      }"
                      mask="00000"
                      [patterns]="numberMaskPattern"
                      formControlName="zip"
                      name="zip"
                    />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.zip.touched || submitted) && moneyManagerControl.zip.errors?.maxLength
                        "
                        errorId="money-manager-zip-valid-error"
                        [message]="getValidErrorMessage('Zip')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="phone-label">Phone</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <input
                      type="text"
                      id="mny-mgr-phone"
                      [ngClass]="{
                        'has-error': isControlInvalid('phone')
                      }"
                      formControlName="phone"
                      name="phone"
                      mask="000-000-0000"
                      [patterns]="numberMaskPattern"
                    />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.phone.touched || submitted) &&
                          moneyManagerControl.phone.errors?.maxLength
                        "
                        errorId="money-manager-phone-valid-error"
                        [message]="getValidErrorMessage('Phone')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="fax-label">Fax</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <input
                      type="text"
                      id="mny-mgr-fax"
                      [ngClass]="{
                        'has-error': isControlInvalid('fax')
                      }"
                      formControlName="fax"
                      name="fax"
                      mask="000-000-0000"
                      [patterns]="numberMaskPattern"
                    />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.fax.touched || submitted) && moneyManagerControl.fax.errors?.maxLength
                        "
                        errorId="money-manager-fax-valid-error"
                        [message]="getValidErrorMessage('Fax')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="email-label">Email</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <input
                      type="text"
                      id="mny-mgr-email"
                      formControlName="email"
                      [ngClass]="{
                        'has-error': isControlInvalid('email')
                      }"
                      name="email"
                    />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.email.touched || submitted) && moneyManagerControl.email.errors?.pattern
                        "
                        errorId="money-manager-email-valid-error"
                        [message]="getValidErrorMessage('Email')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="contact-label">Contact</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <input
                      type="text"
                      id="mny-mgr-contact"
                      formControlName="contact"
                      [ngClass]="{
                        'has-error': isControlInvalid('contact')
                      }"
                      name="contact"
                    />
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.contact.touched || submitted) &&
                          moneyManagerControl.contact.errors?.maxLength
                        "
                        errorId="money-manager-contact-valid-error"
                        [message]="getValidErrorMessage('Contact')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-sm-6">
              <ul
                id="fixed-period-distribution-params"
                summary="fixed period distribution parameters"
                class="ul-li-view-edit table stripes no-margin border-ul"
              >
                <li>
                  <div class="col-sm-6 padding-left displayOptions">
                    <span id="active-label">Active</span>
                  </div>
                  <div class="col-sm-6 padding-left">
                    <input
                      type="radio"
                      id="mny-mgr-active-yes"
                      class="mr-3p"
                      formControlName="active"
                      name="active"
                      [value]="true"
                    />
                    Yes
                    <span class="ml-10">
                      <input
                        type="radio"
                        class="mr-3p mr-l-5"
                        id="mny-mgr-active-no"
                        formControlName="active"
                        name="active"
                        [value]="false"
                      />No
                    </span>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="walled-off-label">Walled Off</span>
                  </div>
                  <div class="col-sm-6 padding-left">
                    <input
                      type="radio"
                      id="mny-mgr-walled-off-yes"
                      formControlName="walledOff"
                      name="walledOff"
                      [value]="true"
                      class="mr-3p"
                    />
                    Yes
                    <span class="ml-10">
                      <input
                        type="radio"
                        id="mny-mgr-walled-off-no"
                        formControlName="walledOff"
                        name="walledOff"
                        class="mr-3p mr-l-5"
                        [value]="false"
                      />No
                    </span>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="fee-invoice-label">Fee Invoice Prefix</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="text"
                        id="fee-invoice-prefix"
                        formControlName="feeInvoicePrefix"
                        name="feeInvoicePrefix"
                        [ngClass]="{
                          'has-error': isControlInvalid('feeInvoicePrefix')
                        }"
                      />
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.feeInvoicePrefix.touched || submitted) &&
                          (moneyManagerControl.feeInvoicePrefix.errors?.maxLength ||
                            moneyManagerControl.feeInvoicePrefix.errors?.pattern)
                        "
                        errorId="money-manager-fee-prefix-valid-error"
                        [message]="getValidErrorMessage('Fee Invoice Prefix')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="fee-invoice-label">Fee Invoice Start</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="text"
                        id="fee-invoice-start"
                        formControlName="feeInvoiceStart"
                        [ngClass]="{
                          'has-error': isControlInvalid('feeInvoiceStart')
                        }"
                        name="feeInvoiceStart"
                      />
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.feeInvoiceStart.touched || submitted) &&
                          (moneyManagerControl.feeInvoiceStart.errors?.maxLength ||
                            moneyManagerControl.feeInvoiceStart.errors?.pattern)
                        "
                        errorId="money-manager-fee-start-valid-error"
                        [message]="getValidErrorMessage('Fee Invoice Start')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="fee-invoice-msg-label">Fee Invoice Message</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="text"
                        id="fee-invoice-message"
                        formControlName="feeInvoiceMessage"
                        [ngClass]="{
                          'has-error': isControlInvalid('feeInvoiceMessage')
                        }"
                        name="feeInvoiceMessage"
                      />
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.feeInvoiceMessage.touched || submitted) &&
                          (moneyManagerControl.feeInvoiceMessage.errors?.maxLength ||
                            moneyManagerControl.feeInvoiceMessage.errors?.pattern)
                        "
                        errorId="money-manager-fee-message-valid-error"
                        [message]="getValidErrorMessage('Fee Invoice Message')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="fee-report-acsess-label">Fee Report Access</span>
                  </div>
                  <div class="col-sm-6 padding-left">
                    <input
                      type="radio"
                      id="fee-report-access-yes"
                      formControlName="feeReportAccess"
                      class="mr-3p"
                      name="feeReportAccess"
                      [value]="true"
                    />
                    Yes
                    <span class="ml-10">
                      <input
                        type="radio"
                        id="fee-report-access-no"
                        class="mr-3p mr-l-5"
                        formControlName="feeReportAccess"
                        name="feeReportAccess"
                        [value]="false"
                      />No
                    </span>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="fee-mesage-acsess-label">Fee Message Access</span>
                  </div>
                  <div class="col-sm-6 padding-left">
                    <input
                      type="radio"
                      id="fee-message-access-yes"
                      class="mr-3p"
                      formControlName="feeMessageAccess"
                      name="feeMessageAccess"
                      [value]="true"
                    />
                    Yes
                    <span class="ml-10">
                      <input
                        type="radio"
                        formControlName="feeMessageAccess"
                        class="mr-3p mr-l-5"
                        name="feeMessageAccess"
                        id="fee-message-access-no"
                        [value]="false"
                      />No
                    </span>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="allow-print-label">Allow to print documents </span>
                  </div>
                  <div class="col-sm-6 padding-left">
                    <input
                      type="radio"
                      id="allow-print-documents-yes"
                      class="mr-3p"
                      formControlName="allowToPrintDocuments"
                      name="allowToPrintDocuments"
                      [value]="true"
                    />
                    Yes
                    <span class="ml-10">
                      <input
                        type="radio"
                        formControlName="allowToPrintDocuments"
                        class="mr-3p mr-l-5"
                        name="allowToPrintDocuments"
                        id="allow-print-documents-no"
                        [value]="false"
                      />No
                    </span>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="booklet-access-label">Enrollment Booklet Access</span>
                  </div>
                  <div class="col-sm-6 padding-left">
                    <input
                      type="radio"
                      class="mr-3p"
                      formControlName="enrollmentBookletAccess"
                      name="enrollmentBookletAccess"
                      id="enrollment-booklet-access-yes"
                      [value]="true"
                    />
                    Yes
                    <span class="ml-10">
                      <input
                        type="radio"
                        formControlName="enrollmentBookletAccess"
                        class="mr-3p mr-l-5"
                        name="enrollmentBookletAccess"
                        id="enrollment-booklet-access-no"
                        [value]="false"
                      />
                      No
                    </span>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="override-print-access-label">Override plan level print access</span>
                  </div>
                  <div class="col-sm-6 padding-left">
                    <input
                      type="radio"
                      formControlName="overridePlanLevelPrintAccess"
                      name="overridePlanLevelPrintAccess"
                      class="mr-3p"
                      id="override-planlevel-print-access-yes"
                      [value]="true"
                    />
                    Yes
                    <span class="ml-10">
                      <input
                        type="radio"
                        formControlName="overridePlanLevelPrintAccess"
                        name="overridePlanLevelPrintAccess"
                        class="mr-3p mr-l-5"
                        id="override-planlevel-print-access-no"
                        [value]="false"
                      />No
                    </span>
                  </div>
                </li>
              </ul>
              <br />
              <ul
                id="fixed-period-distribution-params"
                summary="fixed period distribution parameters"
                class="ul-li-view-edit table stripes no-margin border-ul"
              >
                <li class="head">
                  <div class="col-sm-12 unread-font-weight">
                    <span>Display Options</span>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="show-conversion-label">Show Conversion*</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="radio"
                        formControlName="showConversion"
                        id="show-conversion-yes"
                        class="mr-3p"
                        name="showConversion"
                        [value]="true"
                      />
                      Yes
                      <span class="ml-10">
                        <input
                          type="radio"
                          id="show-conversion-no"
                          formControlName="showConversion"
                          class="mr-3p"
                          name="showConversion"
                          [value]="false"
                        />
                        No
                      </span>
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.showConversion.touched || submitted) &&
                          moneyManagerControl.showConversion.errors?.required
                        "
                        errorId="money-manager-show-conversion-valid-error"
                        [message]="getRequiredErrorMessage('Show Conversion')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="show-conversion-label">Show QDIA*</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="radio"
                        id="show-qdia-yes"
                        class="mr-3p"
                        formControlName="showQDIA"
                        name="showQDIA"
                        [value]="true"
                      />
                      Yes
                      <span class="ml-10">
                        <input
                          type="radio"
                          id="show-qdia-no"
                          class="mr-3p"
                          formControlName="showQDIA"
                          name="showQDIA"
                          [value]="false"
                        />
                        No
                      </span>
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.showQDIA.touched || submitted) &&
                          moneyManagerControl.showQDIA.errors?.required
                        "
                        errorId="money-manager-show-qdia-valid-error"
                        [message]="getRequiredErrorMessage('Show QDIA')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="merill-auto-mgmt-label">Merrill Auto-Management*</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="radio"
                        id="merill-mgmt-yes"
                        formControlName="merrillAutoMgmt"
                        class="mr-3p"
                        name="merrillAutoMgmt"
                        [value]="true"
                      />
                      Yes
                      <span class="ml-10">
                        <input
                          type="radio"
                          id="merill-mgmt-no"
                          class="mr-3p"
                          formControlName="merrillAutoMgmt"
                          name="merrillAutoMgmt"
                          [value]="false"
                        />
                        No
                      </span>
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.merrillAutoMgmt.touched || submitted) &&
                          moneyManagerControl.merrillAutoMgmt.errors?.required
                        "
                        errorId="money-manager-merill-automgmt-valid-error"
                        [message]="getRequiredErrorMessage('Merrill Auto-Management')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="merill-auto-mgmt-label">Merrill Participant Mapping*</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="radio"
                        id="merill-participant-yes"
                        class="mr-3p"
                        formControlName="merrillParticipantMap"
                        name="merrillParticipantMap"
                        [value]="true"
                      />
                      Yes
                      <span class="ml-10">
                        <input
                          type="radio"
                          id="merill-participant-no"
                          class="mr-3p"
                          formControlName="merrillParticipantMap"
                          name="merrillParticipantMap"
                          [value]="false"
                        />
                        No
                      </span>
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.merrillParticipantMap.touched || submitted) &&
                          moneyManagerControl.merrillParticipantMap.errors?.required
                        "
                        errorId="money-manager-merrill-map-valid-error"
                        [message]="getRequiredErrorMessage('Merrill Participant Mapping')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="col-sm-6 padding-left">
                    <span id="merill-auto-mgmt-label">Provider Mapping*</span>
                  </div>
                  <div class="col-sm-6 padding-left flex-direction-col">
                    <div>
                      <input
                        type="radio"
                        id="provide-mapping-yes"
                        class="mr-3p"
                        formControlName="provideMapping"
                        name="provideMapping"
                        [value]="true"
                      />
                      Yes
                      <span class="ml-10">
                        <input
                          type="radio"
                          id="provide-mapping-no"
                          class="mr-3p"
                          formControlName="provideMapping"
                          name="provideMapping"
                          [value]="false"
                        />
                        No
                      </span>
                    </div>
                    <div>
                      <nfrp-inline-error
                        *ngIf="
                          (moneyManagerControl.provideMapping.touched || submitted) &&
                          moneyManagerControl.provideMapping.errors?.required
                        "
                        errorId="money-manager-provide-map-valid-error"
                        [message]="getRequiredErrorMessage('Provider Mapping')"
                      >
                      </nfrp-inline-error>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="row mt-15">
            <div class="col-sm-1 padding-sides">
              <input
                id="search-button"
                class="button-primary button hover-right margin-right"
                type="submit"
                size="40"
                value="Save"
              />
            </div>
            <div class="col-sm-10 no-padding"></div>
            <div class="col-sm-1 no-padding">
              <input
                type="button"
                class="button button-secondary hover-left margin-left"
                (click)="cancel()"
                value="Cancel"
              />
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
