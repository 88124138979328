<section class="container-fluid temp-color">
    <div ui-view="" class="ng-scope">
      <div id="file-cabinet-main" class="row">
        <div class="col-sm-12">
          <div class="compartment box summary animation-fade-in">
            <div class="section__header "> <div class="col-sm-offset-5"><h2 class="content-title">FILE CABINET HAS MOVED</h2></div></div>
            <div class="content padding-left">
              <div class="section__body">
                <h3>What changed?</h3>
                <div class="padding-left-2"> 
                    <p>
                        The File Cabinet has moved to <a href="https://box.com" target="_blank">Box</a>
                        which is a third-party vender Nationwide uses to transfer files to external firms. 
                        A Box account is required to access files that were previously available via the File Cabinet.
                    </p>
                </div>
                <h3>How do I register for a Box account?</h3>
                <div class="padding-left-2"> 
                    <p>
                        If your firm already has a corporate Box account, you can simply follow your 
                        firm's polices to request an indivsual Box account. If your firm does not have 
                        a corporate Box account, you can create a free individual account by visiting 
                        <a href="https://box.com" target="_blank">box.com</a>.
                    </p>
                </div>
                <h3>How do I request access to my firm’s folders?</h3>
                <div class="padding-left-2"> 
                    <p>
                        Once you have registered for a Box account, please contact 
                        <a href="mailto:mas.ops@nationwide.com">mas.ops&#64;nationwide.com</a>
                        to request access.  Please provide your firm’s information and the 
                        e-mail address your Box account is associated with.
                    </p>
                </div>
                <h3>Who can I contact with questions?</h3>
                <div class="padding-left-2"> 
                    <p>
                        If you have questions about setting up a Box account, please contact 
                        <a href="mailto:mas.ops@nationwide.com">mas.ops&#64;nationwide.com</a> for assistance.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </section>