import { Component } from '@angular/core';

@Component({
  selector: 'file-cabinet',
  templateUrl: './file-cabinet.component.html',
  styleUrls: ['./file-cabinet.component.css']
})
export class FileCabinetComponent {

}
