import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { FooterFeatureStore } from '../footer-features.store';
import { BookCase } from '../model/book-case';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class BookCaseService {
  private headers: HttpHeaders;
  baseUrl = this.config.config?.masRetailPrivateProcessing;

  constructor(private http: HttpClient,
    private footerFeatureStore: FooterFeatureStore,
    private config: ConfigService) {
    this.headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      accessControlAllowOrigin: '*',
      print2Image: 'true',
    });
  }
  bookCase(userId): Observable<BookCase> {
    const params = new HttpParams().set('type', userId);
    const serviceURL = this.baseUrl+'/bookcase';
    return this.http
      .get<BookCase>(serviceURL, { params })
      .pipe(
        tap((bookCase: BookCase) => {
          this.footerFeatureStore.set('bookcaseResult', bookCase);
        })
      );
  }
  getBookCaseLink(fileName: string): Observable<any> {
    const serviceURL = this.baseUrl+'/bookcase/download/${fileName}';
    return this.http.get(serviceURL, { responseType: 'blob' });
  }
}
