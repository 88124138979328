<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2" *ngIf="planDetails && !isArchivePlan">
      <security-navigation class="sticky-nav" [planStatus]="subFooterTabShow"> </security-navigation>
    </div>
    <div class="col-sm-10" *ngIf="isViewPlanMode">
      <div class="row">
        <div class="col-sm-6">
          <h1 id="main-title" class="page-title ng-binding ">
            <span
              *ngIf="showTick"><i class="nw-icon-check f-green col-sm-1"></i
            ></span>
            &ensp; {{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }}) &ensp;
            <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red col-sm-10"></i></span>       
          </h1>
        </div>       
        <div class="col-sm-6" *ngIf="pendingPlanStatus && !showSolicitorOption">
          <span class="row">
            <div class="col-sm-3" id="plan-status-title"><b>Plan Status :</b></div>
            <div class="padding-bottom col-sm-9">
              <span>
                <input type="radio"  [(ngModel)]="changeState" id="plan-status-c" name="changeState" [value]="false" (change)="changeInState()" />Current
                <input type="radio" [(ngModel)]="changeState" id="plan-status-p" name="changeState" [value]="true" (change)="changeInState()" />Pending
              </span>
            </div> 
          </span>     
            
        </div>
      </div>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="plan-summary-success" type="success" [message]="successMessage" [page]="false">
        </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="plan-summary-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <ng-container *ngIf="planDetails">
            <ul class="padding-sides" id="plan-head" summary="employee dates" class="ul-li-view-edit border-common">
              <li [ngClass]="{'pending-status-bg':changeState}">
                <div class="col-sm-4 padding-left">
                  <b>Core Plan Information</b>
                </div>
                <div
                  class="col-sm-2 padding-right"
                  *ngIf="showEditCorePlanOption && !isArchivePlan && showStatus"
                >
                  <button class="button button-tertiary" [ngClass]="{'pending-status-bg':changeState}" routerLink="core-plan">Edit</button>
                </div>
                <div class="col-sm-2"></div>
                <div *ngIf="isArchivePlan" class="col-sm-2"></div>
                <div class="col-sm-4 padding-right" *ngIf="isRiaAdmin">
                  <button 
                    class="button button-tertiary" 
                    [ngClass]="{'pending-status-bg':changeState}"
                    *ngIf="!planDetails.hasPendingChanges">
                    <span *ngIf="!isArchivePlan && showStatus" (click)="updateArchivePlanStatus()" >Archive Plan</span>
                    <span *ngIf="isArchivePlan" (click)="updateUnarchivePlanStatus()">Unarchive Plan</span>
                  </button>
                </div>
              </li>
            </ul>
            <ul class="ul-li-view-edit table stripes no-margin-bottom border-common">
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="fixed-period-distribution-parameter-label">Plan Number</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="fixed-period-distribution-value-label">{{ planDetails?.planCaseNumber }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="provider-label">Provider</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="provider-value">{{ planDetails?.providerLabel }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="product-label">Product</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="product-value">{{ planDetails?.providerProductLabel }}</span>
                </div>
              </li>
              <li *ngIf="planDetails.providerProductId !== 1">
                <div class="col-sm-6 padding-left">
                  <span id="C-T-label">Custodian/Trustee</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="C-T-value">{{ planDetails?.custodianOrTrustee }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="plan-type-label">Plan Type</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="plan-type-value">{{ planDetails?.planTypeLabel }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="prefer-admin-label">Preferred Plan Administrator</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="prefer-admin-value">{{ planDetails?.preferredPlanAdmin }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="money-manager-label">Money Manager</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="money-manager-value">{{ planDetails?.moneyManagerLabel }}</span>
                </div>
              </li>
              <li *ngIf="planDetails?.moneyManagerProgram || isStadionMM">
                <div class="col-sm-6 padding-left">
                  <span id="money-manager-program-label">Money Manager Program (s)</span>
                </div>
                <div class="col-sm-6 padding-left" *ngIf="!isStadionMM">
                  <span id="money-manager-program-value">{{ planDetails?.moneyManagerProgram }}</span>
                </div>
                <div class="padding-left" *ngIf="isStadionMM">
                  <p *ngIf="isCoreFundStrategy" class="mr-bottom-0"> <span id="money-manager-core-fund" ></span>Core Fund Strategy</p>
                  <p class="mr-bottom-0"> <span id="money-manager-participant"></span>{{stadionPlanOption}}</p>
                </div>
                                      
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="initial-assets-label">Initial Assets</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="initial-assets-value">{{ planDetails?.totalAssets | currency : 'USD' }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="status-label">Active</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="status-value">{{ planDetails?.status }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="new-exist-label">New/Existing</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="new-exist-value">{{ planDetails?.planNewToProvider }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="enrollees-label">Enrollees</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="enrollees-value">{{ planDetails?.numEnrollees }}</span>
                </div>
              </li>
              <li *ngIf="planDetails?.moneyManagerId === clsInvestments">
                <div class="col-sm-6 padding-left">
                  <span id="co-advisory-firm-label">Co-Advisory Firm</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="co-advisory-firm-value">{{ planDetails?.coAdvisoryFirm }}</span>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left">
                  <span id="options-label">Options</span>
                </div>
                <div class="col-sm-6 padding-left">
                  <span id="options-value">{{ planDetails?.options }}</span>
                </div>
              </li>
            </ul>
          </ng-container>
          <lib-plan-dates [pendingStatus]="changeState"></lib-plan-dates>
        </div>
        <div class="col-sm-6">
          <div>
            <view-employee-sponsor [status]="showStatus"  [pendingStatus]="changeState"></view-employee-sponsor>
          </div>
          <div class="padding-top">
            <view-trustee [status]="showStatus"  [pendingStatus]="changeState"></view-trustee>
          </div>
          <div class="padding-top" [class.disabled]="showChangeSolicitor">
            <ul
              id="solicitor-params"
              summary="employee dates"
              class="ul-li-view-edit table stripes no-margin border-common"
            >
              <li class="head" [ngClass]="{'pending-status-bg':changeState}">
                <div class="col-sm-12 padding-left">
                  <b>Solicitor</b>
                </div>
              </li>
              <li>
                <div class="col-sm-6 padding-left" *ngIf="solicitorObj">
                  <span id="solicitor-value">{{
                    solicitorObj?.appSolicitorName
                      ? solicitorObj?.appSolicitorName
                      : solicitorObj?.appSolicitorId === 0
                      ? ''
                      : ''
                  }}</span>
                </div>
              </li>
              <li *ngIf="showEditCorePlanOption && !isArchivePlan">
                <div class="col-sm-12 padding-left">
                  <b
                    ><a
                      *ngIf="showSolicitorInfo && showStatus"
                      href="javascript:void(0);"
                      routerLink="plan-solicitor/edit"
                      class="subLink"
                      >Change Solicitor</a
                    >
                    <a
                      *ngIf="!showSolicitorInfo"
                      href="javascript:void(0);"
                      routerLink="plan-solicitor/add"
                      class="subLink"
                      >Add Solicitor</a
                    ></b
                  >
                </div>
              </li>
            </ul>
          </div>
          <plan-rep [showEditOption]="showEditCorePlanOption" [status]="showStatus"  [pendingStatus]="changeState" *ngIf="!hidePlanRepSection"></plan-rep>
          <lib-plan-fee
            [showEditOption]="showEditCorePlanOption"
            [showArchiveOption]="showArchiveOption"
            [pendingStatus]="changeState"
            [status]="showStatus"
          ></lib-plan-fee>
        </div>
      </div>
      <div *ngIf="showEditCorePlanOption">
        <plan-sponsor-docs [pendingStatus]="changeState"></plan-sponsor-docs>
        <div *ngIf="isRiaAdmin">
          <lib-plan-notes [status]="showStatus"  [pendingStatus]="changeState"></lib-plan-notes>
        </div>
      </div>
      <ng-template #loading>
        <nfrp-spinner></nfrp-spinner>
      </ng-template>
    </div>
  </div>
</section>
