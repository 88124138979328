import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { statesList, TpaMMLimit, validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { UserResponse } from '../../../model/edit-user';
import { MoneyManager, MoneyManagerData } from '../../../model/money-manager-data';
import { TpAdminData, TpaMnyMgrFee } from '../../../model/tpadmin-data';
import { FooterFeaturesService } from '../../../services/footer-features.service';
import { SearchUsersService } from '../../../services/search-users.service';

@Component({
  selector: 'edit-tpa',
  templateUrl: './edit-tpa.component.html',
  styleUrls: ['./edit-tpa.component.css'],
})
export class EditTpaComponent implements OnInit, OnDestroy {
  feeRangeList = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  STARTING_FEE_RANGE = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ENDING_FEE_RANGE = 15;
  tpAdminMode: string;
  tpAdminNbr: string;
  tpAdminStates = statesList;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  submitted = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  tpAdminForm: UntypedFormGroup;
  tpAdminInfo: TpAdminData;
  tpAdminRequestObj: TpAdminData;
  isToggleAdminNw = false;
  userEmail: string;
  formSubscription: Subscription;
  private subscriptions = [];
  moneyManagerList: MoneyManager[];
  tpaLimit = TpaMMLimit;
  tpaMMFees: TpaMnyMgrFee[];
  tpaMnyMgrDefaultFees = [];

  constructor(
    private searchUsersService: SearchUsersService,
    private fb: UntypedFormBuilder,
    private footerFeaturesService: FooterFeaturesService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationStore: NavigationStore
  ) {}

  isControlInvalid(controlName: string): boolean {
    const control = this.tpAdminForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  get tpAdminControl() {
    return this.tpAdminForm.controls;
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.TPA_MGMT, this.userEmail).subscribe()
    );
    this.getMoneyManager();
    this.tpAdminForm = this.fb.group({
      tpaNumber: [
        null,
        [Validators.required, Validators.maxLength(3), Validators.pattern(validationPatterns.alphaNumericPattern)],
      ],
      tpaName: [null, [Validators.required, Validators.maxLength(255)]],
      tpaAddress1: [null, [Validators.maxLength(100)]],
      tpaAddress2: [null, [Validators.maxLength(100)]],
      tpaCity: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.alphaNumericPattern)]],
      tpaState: [''],
      tpaZip: [null, [Validators.maxLength(10)]],
      tpaActive: [null],
      tpAdminNW: [null],
      tpAdminFeePrct: [null],
      mmFeePrct: [0],
      tpaPhone: [null, [Validators.maxLength(30)]],
      tpaFax: [null, [Validators.maxLength(30)]],
      tpaEmail: [null, [Validators.maxLength(255), Validators.pattern(validationPatterns.emailPattern)]],
      tpaContact: [null, [Validators.maxLength(255)]],
    });
    this.formSubscription = this.tpAdminForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    this.route.params.subscribe((params) => {
      this.tpAdminNbr = params.tpAdminNbr;
      this.getTpAdminDetails();
    });
  }
  getTpAdminDetails() {
    this.subscriptions.push(
      this.searchUsersService.getTpADetails(this.tpAdminNbr).subscribe((tpAdminData: TpAdminData) => {
        this.tpAdminInfo = tpAdminData;
        this.buildAdminFeeRangeList(tpAdminData.tpAdminFeePercent, this.STARTING_FEE_RANGE, this.ENDING_FEE_RANGE);
        this.setTpAdminData(tpAdminData);
      })
    );
  }

  buildAdminFeeRangeList(currentTpAdminFeePercent: number, startingFeeRange: number, endingFeeRange: number): void {
    this.feeRangeList = [];
    const tempFee = this.normalizeTpaFee(currentTpAdminFeePercent);
    for (let fee = startingFeeRange; fee < endingFeeRange + 1; fee++) {
      this.feeRangeList.push(
        {
          tpAdminFeeBasisPoints:fee,
          isCurrentFee: tempFee === fee
        });
    }
  }

  normalizeTpaFee(tpaFeeDecimal: number): number{
    return tpaFeeDecimal >= 1 ? tpaFeeDecimal : Math.round(tpaFeeDecimal * 100);
  }

  getMoneyManager(): void{
    this.subscriptions.push(
      this.searchUsersService.getMoneyManagerMaintenance().subscribe((firmData: MoneyManager[]) => {
        this.moneyManagerList = firmData;
        this.moneyManagerList = this.moneyManagerList?.filter(
          (mnyMgr) => mnyMgr.wallOff === BinaryResponseEnum.NO && mnyMgr.active === BinaryResponseEnum.YES
        );
      },
      (error) => {
        this.errorMessage = { message: error, details : [] };
      })
    );
  }
  setTpAdminData(tpAdminData) {
    const tpAdminInfo = tpAdminData;
    this.toggleFeeField(tpAdminInfo?.tpAdminNWAdmin);
    this.tpaMMFees = tpAdminInfo?.tpaMnyMgrFees;
    this.tpAdminForm.patchValue({
      tpaNumber: tpAdminInfo.tpAdminNbr,
      tpaName: tpAdminInfo.tpAdminLabel,
      tpaAddress1: tpAdminInfo.tpAdminAddrA,
      tpaAddress2: tpAdminInfo.tpAdminAddrB,
      tpaCity: tpAdminInfo.tpAdminCity,
      tpaState: tpAdminInfo.tpAdminState,
      tpaZip: tpAdminInfo.tpAdminZip,
      tpaActive: tpAdminInfo.tpAdminActive ? true : false,
      tpAdminNW: tpAdminInfo.tpAdminNWAdmin ? true : false,
      tpAdminFeePercent: tpAdminInfo.tpAdminFeePrcnt,
      tpaPhone: tpAdminInfo.tpAdminPhone,
      tpaFax: tpAdminInfo.tpAdminFax,
      tpaEmail: tpAdminInfo.tpAdminEmail,
      tpaContact: tpAdminInfo.tpAdminContact,
      tpaMnyMgrFees: tpAdminInfo.tpaMnyMgrFees,
    });
    this.navigationStore.set('formChanged', false);
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }
  selectedMMFees(event: any, mnyMgr: number): void {
    const tpaMnyMgrFee = {
      mnyMgrId: mnyMgr,
      defaultFee: +event.target.value,
    };
    this.tpaMnyMgrDefaultFees.push(tpaMnyMgrFee);
  }
  cancel() {
    this.router.navigate(['/tpaMgmt']);
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }
  toggleFeeField(adminNw) {
    if (adminNw === BinaryResponseEnum.YES) {
      this.isToggleAdminNw = true;
    } else {
      this.isToggleAdminNw = false;
    }
  }

  onSubmit(): void {
    this.submitted = true;
    this.resetAlertMessages();
    if (this.tpAdminForm.valid) {
      const tpAdminRequestObj = {
        tpAdminNbr: this.tpAdminForm.getRawValue().tpaNumber,
        tpAdminLabel: this.tpAdminForm.value.tpaName,
        tpAdminAddrA: this.tpAdminForm.value.tpaAddress1,
        tpAdminAddrB: this.tpAdminForm.value.tpaAddress2,
        tpAdminCity: this.tpAdminForm.value.tpaCity,
        tpAdminState: this.tpAdminForm.value.tpaState,
        tpAdminZip: this.tpAdminForm.value.tpaZip,
        tpAdminPhone: this.tpAdminForm.value.tpaPhone,
        tpAdminFax: this.tpAdminForm.value.tpaFax,
        tpAdminEmail: this.tpAdminForm.value.tpaEmail,
        tpAdminContact: this.tpAdminForm.value.tpaContact,
        tpAdminActive: this.tpAdminForm.value.tpaActive ? BinaryResponseEnum.YES : BinaryResponseEnum.NO,
        tpAdminNWAdmin: this.tpAdminForm.value.tpAdminNW ? BinaryResponseEnum.YES : BinaryResponseEnum.NO,
        tpAdminFeePercent: this.tpAdminForm.value.tpAdminFeePrct 
          ? this.tpAdminForm.value.tpAdminFeePrct 
          : BinaryResponseEnum.NO,
        tpaMnyMgrFees: this.getMnyMgrTpaFees(),
      };
      this.subscriptions.push(
        this.searchUsersService.saveEditTpAdminData(tpAdminRequestObj).subscribe(
          (tpAdminResponse: UserResponse) => {
            window.scroll(0, 0);
            this.successMessage = { message: tpAdminResponse.status, details: [] };
            this.errorMessage = { message: '', details: [] };
          },
          (error) => {
            window.scroll(0, 0);
            this.successMessage = { message: '', details: [] };
            if (error) {
              this.errorMessage = { message: error, details: [] };
            } else {
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          }
        )
      );
    } else {
      this.successMessage = { message: '', details: [] };
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }
  showSuccessMessage() {
    this.successMessage = { message: alertMessages.TPA_DETAILS_SAVED_SUCCESSFULLY, details: [] };
  }

  resetAlertMessages() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  getMnyMgrTpaFees(): any[] {
    const mnyMgrFilterArray = [];
    const responseArray = [];
    if (!this.tpAdminForm.value.tpAdminNW) {
      this.tpaMMFees.forEach((obj) => {
        const existMM = this.tpaMnyMgrDefaultFees.find((fee) => obj.mnyMgrId === fee.mnyMgrId);
        if (!existMM) {
          mnyMgrFilterArray.push({ mnyMgrId: obj.mnyMgrId, defaultFee: obj.defaultFee });
        }
      });
      responseArray.push(...mnyMgrFilterArray, ...this.tpaMnyMgrDefaultFees);
    } else {
      this.tpaMMFees.forEach((obj) => {
        responseArray.push({ mnyMgrId: obj.mnyMgrId, defaultFee: 0.0 });
      });
    }
    return responseArray;
  }
}
