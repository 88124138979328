import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'reset-password-info',
  templateUrl: './reset-password-info.component.html',
  styleUrls: ['./reset-password-info.component.scss'],
})
export class ResetPasswordInfoComponent implements OnInit {
  constructor() {
    //do nothing
  }

  ngOnInit(): void {
    //do nothing
  }
}
