import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxEntry } from '../model/boxEntry';
import { BoxFolderContents } from '../model/boxFolderContents';
import { ErrorUtilsService } from 'src/app/services/error-utils.service';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class BoxService {
  public results: any;
  baseUrl = this.config.config?.masRetailPrivateProcessing;

  constructor(private http: HttpClient, 
    private errorUtilsService: ErrorUtilsService,
    private config: ConfigService) {}

  getFolderItemsById(folderId: string): Observable<BoxFolderContents> {
    const serviceURL = `${this.baseUrl}/box/folders/${folderId}/items`;
    return this.http
      .get<BoxFolderContents>(serviceURL)
      .pipe(
        map((response: any) => response.response as BoxFolderContents),
        catchError((error: HttpErrorResponse) => throwError(error.error.response))
      );
  }

  getFolderByPath(folderPath: string): Observable<BoxFolderContents> {
    const serviceURL = `${this.baseUrl}/box/folders/path`;
    return this.http
      .post<BoxFolderContents>(serviceURL, this.createPathBodyPayload(folderPath))
      .pipe(
        map((response: any) => response.response as BoxFolderContents),
        catchError((error: HttpErrorResponse) => throwError(error.error.response))
      );
  }

  createFolder(folderName: string, parentId: string): Observable<BoxEntry> {
    const serviceURL = `${this.baseUrl}/box/folders/name/${folderName}/parent/${parentId}/createFolder`;
    return this.http
      .post<BoxEntry>(serviceURL, null)
      .pipe(
        map((response: any) => response.response as BoxEntry),
        catchError((error: HttpErrorResponse) => throwError(error.error.response))
      );
  }

  createFoldersForEntity(entityType: string, entityNumber: string): Observable<BoxEntry> {
    return this.http
      .post<BoxEntry>(this.baseUrl + `/box/folders/entity-type/${entityType}/entity-number/${entityNumber}/create-folders`, null)
      .pipe(
        map((response: any) => response.response as BoxEntry),
        catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
      );
  }

  addFolderPathMetadataToFolder(folderId: string, folderPath: string): Observable<string> {
    const serviceURL = `${this.baseUrl}/box/folders/${folderId}/addPathMetadata`;
    return this.http
      .post<string>(serviceURL, this.createPathBodyPayload(folderPath))
      .pipe(
        map((response: any) => response.response),
        catchError((error: HttpErrorResponse) => throwError(error.error.response))
      );
  }

  addFolderRetentionPolityMetadataToFolder(folderId: string, retentionPolicyInDays: string): Observable<string> {
    const serviceURL = `${this.baseUrl}/box/folders/${folderId}/addRetentionPolicy/days/${retentionPolicyInDays}`;
    return this.http
      .post<string>(serviceURL, null)
      .pipe(
        map((response: any) => response.response),
        catchError((error: HttpErrorResponse) => throwError(error.error.response))
      );
  }

  writeFileToFolder(fileName: string, folderPath: string, file: Blob): Observable<string> {
    const serviceURL = `${this.baseUrl}/box/folders/fileName/${fileName}/addDocument`;
    return this.http
      .post<string>(serviceURL, this.createBodyFormData(folderPath, file))
      .pipe(
        map((response: any) => response.response),
        catchError((error: HttpErrorResponse) => throwError(error.error.response))
      );
  }

  createPathBodyPayload(folderPath: string): Record<string, string> {
    return {path:folderPath};
  }

  createBodyFormData(folderPath: string, file: Blob): FormData {
    const body = new FormData();
    if (folderPath) {
      body.append('path', folderPath);
    }
    if (file) {
      body.append('file', file);
    }
    return body;
  }
}
