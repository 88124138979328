import { Component, OnInit, OnDestroy } from '@angular/core';
import { NfrpAlertMessage, NfrpDefaultGridComponent, NfrpGridService, NavigationStore } from 'nfrp-shared-angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { alertMessages } from 'src/app/helpers/common-constants';
import { UserTypeIDEnum, FeatureTypeIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
import { ParticipantFileResponseList, MoneyManager } from '../../model/money-manager-data';
import { FooterFeaturesService } from '../../services/footer-features.service';
import { PlanDetailsService } from '../../services/plan-details.service';
import { SearchUsersService } from '../../services/search-users.service';
@Component({
  selector: 'lib-participant-ik',
  templateUrl: './participant-ik.component.html',
  styleUrls: ['./participant-ik.component.css'],
})
export class ParticipantIkComponent extends NfrpDefaultGridComponent<ParticipantFileResponseList>
  implements OnInit, OnDestroy {
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  particIkForm: UntypedFormGroup;
  dataSource: ParticipantFileResponseList[];
  moneyManagerList: MoneyManager[];
  mmNonWalledOffList: MoneyManager[];
  numberOfItems: number;
  submitted = false;
  userEmail: string;
  riaUser = UserTypeIDEnum.RIA;
  moneyMgrUser = UserTypeIDEnum.MONEY_MANAGER;
  formSubscription: Subscription;
  userTypeID: number;
  fileName: File;
  userId: number;
  moneyMgrId: string;
  loggedInMnyMgr: string;
  loading = false;
  particpantFileResponse: ParticipantFileResponseList;
  selectedMM: string;
  private subscriptions = [];

  constructor(
    private fb: UntypedFormBuilder,
    nfrpGridService: NfrpGridService<ParticipantFileResponseList>,
    private searchUserService: SearchUsersService,
    private footerFeaturesService: FooterFeaturesService,
    private navigationStore: NavigationStore,
    private planDetailsService: PlanDetailsService,
    private communicationService: CommunicationService
  ) {
    super(nfrpGridService);
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.particIkForm = this.fb.group({ file: [null, [Validators.required]], mnyMgrId: [null, [Validators.required]] });
    this.formSubscription = this.particIkForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeID = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : '';
    this.moneyMgrId = loginUser ? JSON.parse(loginUser)?.moneyManagerId : '';
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.IK_PARTICIPANT, this.userEmail).subscribe()
    );
    this.getMoneyManager();
    if (this.userTypeID === UserTypeIDEnum.RIA) {
      this.particIkForm.controls.mnyMgrId.setValidators([Validators.required]);
      this.particIkForm.controls.mnyMgrId.updateValueAndValidity();
    } else {
      this.particIkForm.controls.mnyMgrId.clearValidators();
      this.particIkForm.controls.mnyMgrId.updateValueAndValidity();
    }
    this.navigationStore.set('formChanged', false);
    if (this.userTypeID === UserTypeIDEnum.MONEY_MANAGER) {
      this.getMnyMgrInfo();
      this.getParticipantFileResponse();
    }
  }
  getChooseErrorMessage(preText: string): string {
    return `Please select a ${preText}`;
  }
  get participantIkControl() {
    return this.particIkForm.controls;
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.particIkForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  isFileControlInvalid(controlName: string): boolean {
    const control = this.particIkForm.get(controlName);
    return control.invalid && this.submitted;
  }

  getMoneyManager(): void {
    this.subscriptions.push(
      this.searchUserService.getMoneyManagerForUser().subscribe((firmData: MoneyManager[]) => {
        this.moneyManagerList = firmData;
        this.mmNonWalledOffList = this.moneyManagerList?.filter(
          (mnyMgr) => mnyMgr.active === BinaryResponseEnum.YES
        );
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  getMnyMgrInfo(): void {
    this.subscriptions.push(
      this.searchUserService.getMoneyManagerWithId(this.moneyMgrId).subscribe((firmData: MoneyManager) => {
        this.loggedInMnyMgr = firmData ?firmData.moneyManagerLabel : '';
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  onChangeFile(event) {
    const file: File = event.target.files[0];
    this.fileName = file;
  }
  updateFileStatus(fileInput) {
    fileInput.value = null;
    this.fileName = null;
  }
  getSelectedMM(mnyMgr) {
    this.selectedMM = mnyMgr;
    this.getParticipantFileResponse();
    this.errorMessage = { message: '', details: [] };
    this.communicationService.setMnyMgr(mnyMgr);
  }
  cancel() {
    this.particIkForm.reset();
  }
  saveParticipantFile() {
    this.submitted = true;
    if (this.particIkForm.valid) {
      this.loading = true;
      const participantRequest = {
        moneyManagerId: this.userTypeID === this.riaUser ? this.particIkForm.value.mnyMgrId : this.moneyMgrId,
        userId: this.userId,
      };
      this.planDetailsService.saveParticipantIKUpload(this.fileName, participantRequest).subscribe(
        (uploadFileResponse) => {
          window.scroll(0, 0);
          this.loading = false;
          this.errorMessage = { message: '', details: [] };
          if (
            uploadFileResponse?.errorCode == null &&
            uploadFileResponse?.errorMessage === 'File data staged successfully'
          ) {
            this.getParticipantFileResponse();
          } else {
            this.errorMessage = { message: uploadFileResponse?.errorMessage, details: [] };
          }
        },
        (error) => {
          this.loading = false;
          window.scroll(0, 0);
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      );
    } else {
      this.successMessage = { message: '', details: [] };
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }
  getParticipantFileResponse() {
    const mnyMgrId =
      this.userTypeID !== UserTypeIDEnum.MONEY_MANAGER ? this.particIkForm.value.mnyMgrId : this.moneyMgrId;
    this.planDetailsService.getParticipantIKFileResponse(mnyMgrId).subscribe(
      (ikFileResponse: ParticipantFileResponseList) => {
        this.dataSource =
          ikFileResponse?.ikParticipantFileResponseList != null ? ikFileResponse?.ikParticipantFileResponseList : [];
        this.numberOfItems = this.dataSource?.length;
        this.setupGrid();
        this.paginate({
          limit: 5,
          offset: 1,
        });
      },
      (error: HttpErrorResponse) => {
        this.dataSource = [];
        this.setupGrid();
      }
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
