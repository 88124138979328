<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <ng-container>
        <ng-container *ngIf="dataSource; else loading">
          <h1 id="main-title" class="page-title ng-binding">Money Managers</h1>
          <ng-template #headerTemplate>
            <div nfrpColumn [width]="15" widthType="%" [id]="'nationwide-id-label'">
              Nationwide ID
              <nfrp-grid-sorter [column]="'moneyManagerId'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
            <div nfrpColumn [width]="25" widthType="%" [id]="'money-manager-label'">
              Money Manager
              <nfrp-grid-sorter [column]="'moneyManagerLabel'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
            <div nfrpColumn [width]="15" widthType="%" [id]="'active-label'">
              Active
              <nfrp-grid-sorter [column]="'active'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
            <div nfrpColumn [width]="15" widthType="%" [id]="'walled-off-label'">
              Walled Off
              <nfrp-grid-sorter [column]="'wallOff'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
            <div nfrpColumn [width]="15" widthType="%" [id]="'print-access-label'">
              Print Access
              <nfrp-grid-sorter [column]="'printAccess'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
            <div nfrpColumn [width]="15" widthType="%" [id]="'file-cabinet-label'">
              File Cabinet
              <nfrp-grid-sorter [column]="'fileCabinet'" dataType="string" (sort)="sort($event)"> </nfrp-grid-sorter>
            </div>
          </ng-template>

          <ng-template #rowTemplate let-mnyMgrData="item" let-index="index">
            <div>
              <div [id]="'nationwide-id' + index">
                <a (click)="mnyManagerClicked(mnyMgrData)" class="text-decor-underline">{{
                  mnyMgrData.moneyManagerId | nfrpNormalizeValue: '-'
                }}</a>
              </div>
              <div [id]="'money-manager-' + index">
                <a (click)="mnyManagerClicked(mnyMgrData)" class="text-decor-underline">
                  {{ mnyMgrData.moneyManagerLabel | nfrpNormalizeValue: '-' }}</a
                >
              </div>
              <div [id]="'active-' + index">
                {{ mnyMgrData.active === 1 ? 'Yes' : ('No' | nfrpNormalizeValue: '-') }}
              </div>
              <div [id]="'walled-off-' + index">
                {{ mnyMgrData.wallOff === 1 ? 'Yes' : ('No' | nfrpNormalizeValue: '-') }}
              </div>
              <div [id]="'print-access-' + index">
                {{ mnyMgrData.printAccess === 1 ? 'Yes' : ('No' | nfrpNormalizeValue: '-') }}
              </div>
              <div [id]="'file-cabinet-' + index">               
              </div>
            </div>
          </ng-template>

          <nfrp-grid
            [headerTemplate]="headerTemplate"
            [rowTemplate]="rowTemplate"
            [iterable]="data"
            [width]="100"
            [expandOnRowClick]="true"
          ></nfrp-grid>
          <nfrp-grid-paginator
            (paginate)="paginate($event)"
            [numberOfItems]="numberOfItems"
            [itemsPerPageOptions]="[20, 50, 100]"
            [defaultItemsPerPage]="20"
          ></nfrp-grid-paginator>
        </ng-container>
      </ng-container>
      <ng-template #loading>
        <nfrp-spinner></nfrp-spinner>
      </ng-template>
    </div>
  </div>
</section>
