import { Injectable } from '@angular/core';
import { Store } from '../store';
import { Observable } from 'rxjs';
import { LoginResponse, UserResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-response';

export class LoginUsersState {}

@Injectable({
  providedIn: 'root',
})
export class LoginUsersStore extends Store {
  loginUserResponse$: Observable<LoginResponse[]>;
  userResponse$: Observable<UserResponse[]>;
  constructor() {
    super(new LoginUsersState());
  }
}
