import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NavigationStore, NfrpAlertMessage } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { alertMessages } from 'src/app/helpers/common-constants';
import { SearchPlanSponsorDoc, GetPlans } from '../../../model/get-plan.model';
import { PlanDetailsService } from '../../../services/plan-details.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { UserTypeIDEnum } from 'src/app/helpers/enum';

@Component({
  selector: 'plan-docs',
  templateUrl: './plan-docs.component.html',
  styleUrls: ['./plan-docs.component.css'],
})
export class PlanDocsComponent implements OnInit {
  userEmail: string;
  planId: number;
  loading = false;
  formSubscription: Subscription;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  planSponsorForm: UntypedFormGroup;
  selectedAction: string;
  sponsorDisabled: boolean;
  searchPlanSponsorDoc: any[];
  submitted = false;
  createDoc = false;
  createDocMsg = false;
  planNumber: string;
  userId: string;
  userTypeID: number;
  planLabel: string;
  planDetails: GetPlans;
  private subscriptions = [];
  private planCaseNumber: string;
  isPending: boolean;
  hasPendingChanges = false;
  revisionHistory = false;
  shouldShowHiddenMessage = false;
  private searchDoc: SearchPlanSponsorDoc;

  constructor(
    private route: ActivatedRoute,
    private planDetailsService: PlanDetailsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private navigationStore: NavigationStore,
    private communicationService: CommunicationService
  ) {}
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.userTypeID = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe((pending) => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.selectedAction = params.action;
      this.getRevisionHistory();
      this.getPlanDetails();
    });
    this.planSponsorForm = this.fb.group({
      planNumber: this.planCaseNumber,
      userId: this.userId,
      documentDetails: this.fb.array([]),
      isSponsorDocument: 1,
    });
    this.formSubscription = this.planSponsorForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.planSponsorForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }

  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe(
        (data: GetPlans) => {
          this.planDetails = data.planOverviews;
          this.planCaseNumber = data.planOverviews?.planCaseNumber;
          this.hasPendingChanges = data.planOverviews?.hasPendingChanges;
          this.searchPlanSponsorDocs();
        },
        (error) => {
          this.errorMessage = { message: error, details: [] };
        }
      )
    );
  }
  onCheckboxChange($event, psd) {
    const documentDetails: UntypedFormArray = this.planSponsorForm.get('documentDetails') as UntypedFormArray;
    if ($event.target.checked) {
      const sponsorRequest = {
        documentId: psd.documentId,
        docName: psd.documentName,
      };
      documentDetails.push(new UntypedFormControl(sponsorRequest));
    } else {
      const index = documentDetails.controls.findIndex((x) => x.value === $event.target.value);
      documentDetails.removeAt(index);
    }
  }

  createDocument(): void {
    this.submitted = true;
    if (this.planSponsorForm.valid && this.planSponsorForm.value.documentDetails?.length > 0) {
      this.loading = true;
      const planSponsorRequest = this.planSponsorForm.value;
      planSponsorRequest.planNumber = this.planDetails?.planCaseNumber;
      this.subscriptions.push(
        this.planDetailsService.saveSponsorDocument(planSponsorRequest).subscribe(
          (res) => {
            window.scroll(0, 0);
            this.loading = false;
            this.errorMessage = { message: '', details: [] };
            this.successMessage = { message: res.status, details: [] };
            this.router.navigate(['/print-sponsor-doc', this.planId]);
          },
          (error) => {
            window.scroll(0, 0);
            this.loading = false;
            this.successMessage = { message: '', details: [] };
            if (error) {
              this.errorMessage = { message: error, details: [] };            
            } else {
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  searchPlanSponsorDocs(): void {
    if (this.planSponsorForm.valid) {
      this.loading = true;
      this.createDocMsg = false;
      this.createDoc = true;
      this.sponsorDisabled = true;
      this.planDetailsService
        .searchPlanSponsorDoc(this.planCaseNumber, this.isPending, this.hasPendingChanges)
        .subscribe(
          (res) => {
            this.loading = false;
            this.planNumber = this.planCaseNumber;
            this.searchDoc = res;
            this.searchPlanSponsorDoc = res.searchDocumentResponses;
            this.sponsorDisabled = false;
            this.shouldShowHiddenMessage = false;
            if(this.revisionHistory) {
              if (this.userTypeID !== UserTypeIDEnum.RIA) {
                this.searchPlanSponsorDoc = this.searchPlanSponsorDoc.filter((doc) => doc.documentId !== 625);
              } else {
                this.shouldShowHiddenMessage = true;
              }
            } 
          },
          (error) => {
            this.createDoc = false;
            window.scroll(0, 0);
            this.loading = false;
            if (error) {
              this.errorMessage = { message: error, details: [] };
            }
          }
        );
    }
  }

  onClickCancel(): void {
    this.router.navigate(['/plan-summary', this.planId]);
  }

  getRevisionHistory(): void {
    this.subscriptions.push(
      this.planDetailsService.getRevisionHistory(this.planId).subscribe((data: any) => {
        if (data?.revisionHistoryResponseList && data.revisionHistoryResponseList.length > 0) {
          this.revisionHistory = true;
        }
        else {
          this.revisionHistory = false;
        }
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
}
