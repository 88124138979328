import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { alertMessages } from '../helpers/common-constants';
@Injectable({
  providedIn: 'root',
})
export class ErrorUtilsService {
  static catchError(error: HttpErrorResponse) {
    if (error instanceof Error) {
      console.error('An error occurred:', error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `message: ${error.statusText}`);
    }
    return throwError(error.status);
  }

  getErrorMessage(error: any): string {
    let message = alertMessages.INTERNAL_SERVER_ERROR.toString();
    if (error?.error) {
      message = error.error.message ? error.error.message : 'An unknown error occurred:  ' + error.error;
    } else if (error?.status) {
      message = error.status;
    } else if (error?.code === 400) {
      message = this.formateDeveloperMessage(error.developerMessage);
    }
    return message;
  }

  formateDeveloperMessage(message: string): string {
    if (JSON.parse(message).status) {
      message = JSON.parse(message).status
    }
    return message;
  }
}
