<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
      <!-- <a routerLink="assign-solicitor" routerLinkActive="active"> Assign App Solicitor</a> -->
    </div>
    <div class="col-sm-10">
      <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container *ngIf="moneyManagerList?.length > 0">
        <ul
          id="solicitor-params"
          summary="core plan"
          class="ul-li-view-edit table stripes no-margin border-common mt-10"
        >
          <form class="mt-10" id="solicitor-form" [formGroup]="assignSolForm">
            <li class="head">
              <div class="col-sm-12 padding-left font-size-15">
                <b>Assign Approved Solicitors to a Money Manager:</b>
              </div>
            </li>
            <li>
              <div class="flex-column">
                <div class="padding-left">
                  Currently Managing:
                  <select
                    formControlName="mnyMgrId"
                    name="mnyMgrId"
                    id="MNY_MGR_ID"
                    tabindex="5"
                    title="Money Manager"
                    [ngClass]="{
                      'has-error': isControlInvalid('mnyMgrId')
                    }"
                  >
                    <option [ngValue]="null">--- Select from list ---</option>
                    <option
                      *ngFor="let moneyManager of moneyManagerList | orderBy: 'moneyManagerLabel'; let i = index"
                      [value]="moneyManager.moneyManagerId"
                      >{{ moneyManager.moneyManagerLabel }}
                    </option>
                  </select>
                  <span class="ml-20">
                    <nfrp-inline-error
                      *ngIf="
                        (solicitorFormControl.mnyMgrId.touched || submitted) &&
                        solicitorFormControl.mnyMgrId.errors?.required
                      "
                      errorId="mnymgrid-valid-error"
                      [message]="getChooseErrorMessage('Money Manager')"
                    >
                    </nfrp-inline-error>
                  </span>
                </div>
                <div class="padding" *ngIf="!showAssignedSolicitor">
                  <b>Please Select a money manager to assign Approved Solicitors.</b>
                </div>
              </div>
            </li>
          </form>
          <div *ngIf="loading">
            <nfrp-spinner></nfrp-spinner>
          </div>
          <li class="align_items-start" *ngIf="showAssignedSolicitor && !loading">
            <div class="col-sm-5 flex-column">
              <ul
                id="approved-sol-params"
                summary="core plan"
                class="ul-li-view-edit table stripes no-margin border-common no-border-bottom"
              >
                <li class="head">
                  <div class="col-sm-12 padding-left">
                    <b>Approved Solicitors</b>
                  </div>
                </li>
                <li>
                  <div class="col-sm-1 padding-left">
                    Filter:
                  </div>
                  <div class="col-sm-8 padding-left">
                    <input
                      type="text"
                      class="assign-sol-filter"
                      (keyup)="assignSolicitorFilter($event.target.value, 'APPROVED_SOL')"
                    />
                  </div>
                  <div class="col-sm-3"></div>
                </li>
              </ul>
              <ul
                id="approved-sol-params"
                summary="core plan"
                class="ul-li-view-edit table stripes no-margin border-common no-border-top progress-bar-width-100 overflowy"
              >
                <li
                  class="list-group-item padding-left"
                  id="list-{{ i }}"
                  *ngFor="let solicitor of approvedSolicitor | orderBy: 'appSolicitorName'; let i = index"
                >
                  <label class="form-check-label width-100" id="radio-{{ i }}">
                    <span
                      class="font-bolder pointer"
                      (mouseenter)="onAppSolClicked(i, true)"
                      (mouseleave)="onAppSolClicked(i, false)"
                      (click)="addToAssignedSolicitor(solicitor, i)"
                      ><img [ngClass]="{ 'width-13': changeArrowText === i }" src="assets/left_arrows.png"
                    /></span>
                    {{ solicitor?.appSolicitorName }}
                  </label>
                </li>
              </ul>
            </div>
            <div class="col-sm-7 flex-column">
              <ul
                id="approved-sol-params"
                summary="core plan"
                class="ul-li-view-edit table stripes no-margin border-common no-border-bottom progress-bar-width-100"
              >
                <li class="head">
                  <div class="col-sm-12 padding-left">
                    <b>Solicitors Assigned to Money Manager</b>
                  </div>
                </li>
                <li *ngIf="filterAssignedSolicitor?.length">
                  <div class="col-sm-1 padding-left">
                    Filter:
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="assign-sol-filter margin-left-minus-11"
                      [(ngModel)]="assignedSol"
                      (keyup)="assignSolicitorFilter($event.target.value, 'ASSIGNED_SOL')"
                    />
                  </div>
                  <div class="col-sm-3"></div>
                </li>
              </ul>
              <ul
                id="assigned-sol-params"
                summary="core plan"
                class="ul-li-view-edit table stripes no-margin border-common no-border-top progress-bar-width-100 overflowy"
              >
                <li
                  class="list-group-item padding-left"
                  *ngFor="let solicitor of assignedSolicitor | orderBy: 'appSolicitorName'; let i = index"
                >
                  <label class="form-check-label width-100" id="radio-{{ i }}">
                    <span
                      class="font-bolder pointer"
                      (mouseenter)="onRemoveSolClicked(solicitor?.appSolicitorId, true)"
                      (mouseleave)="onRemoveSolClicked(solicitor?.appSolicitorId, false)"
                      (click)="removeFromAssignedSolicitor(solicitor?.appSolicitorId)"
                      ><img
                        [ngClass]="{ 'width-13': changeRemoveArrowText === solicitor?.appSolicitorId }"
                        src="assets/right_arrows.png"
                    /></span>
                    {{ solicitor?.appSolicitorName }}
                  </label>

                  <span class="right assign-sol"
                    ><input
                      id="solicitor-{{ i }}"
                      class="solicitorFee no-margin"
                      [(ngModel)]="solicitor.fee"
                      type="number"
                      min="0.00"
                      max="0.90"
                      step="0.01"
                      name="solicitorFee"
                      (change)="setTwoNumberDecimal($event)"
                      (ngModelChange)="solicitor.value = $event"
                      [ngModelOptions]="{ updateOn: 'blur' }"
                      #solicitorFee="ngModel" />%&nbsp;&nbsp;
                    <span
                      class="sv-icon pointer"
                      (click)="updateSolicitorFee(solicitor?.appSolicitorId, solicitor?.fee)"
                      ><img width="22" src="assets/sv_icon.gif" /></span
                  ></span>
                  <div *ngIf="solicitorFee.touched || submitted">
                    <nfrp-inline-error
                      *ngIf="showSolicitorError"
                      errorId="add-select-file-input-required"
                      [message]="'Enter a valid solcitor fee'"
                    ></nfrp-inline-error>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</section>
