import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationStore, NfrpAlertMessage } from 'nfrp-shared-angular';
import { GetPlanRepresentatives, GetPlans, GetPlanSolicitor, PendingPlanResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/get-plan.model';
import { EditRepresentativeResponse, SuccessResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/representative.model';
import { FooterFeaturesService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/footer-features.service';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { Subscription } from 'rxjs';
import { statesList, modalMessage, actionText, validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, ActionTypeEnum, MoneyManagerIDEnum, BinaryResponseEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';


@Component({
  selector: 'edit-add-rep',
  templateUrl: './edit-add-rep.component.html',
  styleUrls: ['./edit-add-rep.component.css'],
})
export class EditAddRepComponent implements OnInit, OnDestroy {
  selectedRepObj: GetPlanRepresentatives[];
  newRep: boolean;
  repList: any;

  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationStore: NavigationStore,
    private communicationService: CommunicationService
  ) {}
  get repFormControl(): { [key: string]: AbstractControl } {
    return this.repForm.controls;
  }
  get repAnotherFormControl(): { [key: string]: AbstractControl } {
    return this.anotherRepForm.controls;
  }
  repNameList: any;
  selectedAction: string;
  buttonString: string;
  selectedRepId: string;
  selectedProduct = [];
  submitted = false;
  repDisplay = true;
  loading = false;
  repId: string;
  repForm: UntypedFormGroup;
  statesList = statesList;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  userEmail: string;
  selectedEventId: number;
  planDetails: GetPlans;
  anotherRep: GetPlanRepresentatives[];
  loggedInUserId: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  formSubscription: Subscription;
  modalOptions: any = {
    header: modalMessage.CONFIRMATION,
    message: modalMessage.REMOVE_REP_MSG,
    showModal: false,
    noButtonValue: modalMessage.NO_TEXT,
    yesButtonValue: modalMessage.YES_TEXT,
  };
  solicitorObj: GetPlanSolicitor;
  showBDFirmName = false;
  buttonText: any = {
    save: actionText.SAVE,
    remove: actionText.REMOVE,
    add: actionText.ADD,
  };
  headerText: any = {
    save: actionText.UPDATE,
    remove: actionText.REMOVE,
    add: actionText.ADD,
  };
  anotherRepForm: UntypedFormGroup;
  anotherRepFirstName: string;
  anotherRepLastName: string;
  headerString: string;
  private subscriptions = [];
  private planId: number;
  repSearchDisplay: boolean;
  anotherFormSubmitted = false;
  isPending: boolean;
  isIncompleteRep = false;
  hideNotApplicableLink = false;

  ngOnInit() {
    window.scroll(0, 0);
    this.loading = false;
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.loggedInUserId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe(pending => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.selectedAction = params.action;
      this.selectedRepId = params.repId ? params.repId : null;
      this.getPlanDetails();
    });
    this.anotherRepForm = this.fb.group({
      planId: [null],
      repId: [null],
      repFirstName: [],
      repLastName: [null, [Validators.required]],
    });
    this.repForm = this.fb.group({
      planId: [this.planId],
      firmName: [
        null,
        [Validators.maxLength(255), Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern)],
      ],
      repFirstName: [
        null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern),
        ],
      ],
      repLastName: [
        null,
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern(validationPatterns.alphaNumWithSpecialCharPattern),
        ],
      ],
      repAddrA: [
        null,
        [Validators.required, Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)],
      ],
      repAddrB: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      repCity: [
        null,
        [Validators.required, Validators.maxLength(100), Validators.pattern(validationPatterns.cityPattern)],
      ],
      repState: [null, [Validators.required]],
      repZip: [null, [Validators.required, Validators.maxLength(10)]],
      repPhone: ['', [Validators.required, Validators.maxLength(30)]],
      repFax: [null, [Validators.maxLength(30)]],
      repEmail: [null, [Validators.maxLength(255), Validators.pattern(validationPatterns.emailPattern)]],
      lastUpdatedUserId: [this.loggedInUserId],
    });

    this.formSubscription = this.repForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });

    if (this.selectedAction) {
      this.setButtonText(this.selectedAction.toLowerCase());
    }
    this.getExistingData();
  }

  setButtonText(action) {
    if (action === ActionTypeEnum.EDIT) {
      this.buttonString = this.buttonText.save;
      this.headerString = this.headerText.save;
      this.getExistingData();
    } else if (action === ActionTypeEnum.REMOVE) {
      this.buttonString = this.buttonText.remove;
      this.headerString = this.headerText.remove;
      this.getExistingData();
    } else {
      this.buttonString = this.buttonText.add;
      this.headerString = this.headerText.add;
    }
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
        if(this.planDetails.moneyManagerId !== MoneyManagerIDEnum.NW_INVESTMENT_ADVISOR_LLC) {
          this.hideNotApplicableLink = true;
        }
        this.getPlanSolicitor();
        if (this.selectedAction === ActionTypeEnum.ADD) {
          this.planDetailsService
            .getAnotherRepresentatives(this.planId, this.planDetails?.tpAdminNbr)
            .subscribe((res) => {
              this.anotherRep = res?.planRepresentativeResponseList;
              this.selectedRepObj = res?.planRepresentativeResponseList?.filter(
                (responseList) => responseList.repId === +this.selectedRepId
              );
              this.setExistingFormData(this.selectedRepObj ? this.selectedRepObj[0] : []);
            },          
            (error) => {
              this.errorMessage = { message: error, details: [] };
            });
        }
      },
       (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  getPlanSolicitor(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanSolicitor(this.planId, this.isPending).subscribe((data: GetPlanSolicitor) => {
        this.solicitorObj = data.approvedPlanSolicitorResponse;
        if (
          this.planDetails?.moneyManagerId === MoneyManagerIDEnum.STADION_MONEY_MGMT &&
          this.solicitorObj?.appSolicitorName === 'Not Listed' &&
          this.solicitorObj?.appSolicitorId === BinaryResponseEnum.NO
        ) {
          this.showBDFirmName = true;
          this.repForm.controls.firmName.setValidators([Validators.required]);
          this.repForm.controls.firmName.updateValueAndValidity();
        }
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  getExistingData(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanRepresentatives(this.planId, this.isPending).subscribe((data: any) => {
        this.repList = data.planRepresentativeResponseList;
        this.selectedRepObj = data.planRepresentativeResponseList?.filter(
          (responseList) => responseList.repId === +this.selectedRepId
        );
        this.isIncompleteRep = data.planRepresentativeResponseList?.some(
          (responseList) => responseList.complete === false
        );
        this.setExistingFormData(this.selectedRepObj ? this.selectedRepObj[0] : []);
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  setAnotherRepData(data): void {
    if (data) {
      this.repId = data.repId;
      this.anotherRepForm.setValue({
        repId: data?.repId,
        repFirstName: data?.repFirstName,
        repLastName: data?.repLastName,
      });
    }
    this.navigationStore.set('formChanged', false);
  }

  setExistingFormData(data): void {
    if (data) {
      this.repId = data.repId;
      this.repForm?.patchValue({
        firmName: data.firmName,
        repFirstName: data.repFirstName,
        repLastName: data.repLastName,
        repAddrA: data.repAddrA,
        repAddrB: data.repAddrB,
        repCity: data.repCity,
        repState: data.repState,
        repZip: data.repZip,
        repPhone: data.repPhone,
        repFax: data.repFax,
        repEmail: data.repEmail,
      });
    }
    this.navigationStore.set('formChanged', false);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.repForm.get(controlName) || this.anotherRepForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  noClicked() {
    this.modalOptions.showModal = false;
  }
  yesClicked() {
    this.modalOptions.showModal = false;
    this.removeRepSubmit();
  }
  onSubmit(): void {
    this.submitted = true;
    this.repFormControl.planId.setValue(+this.planId);
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    if (this.selectedAction.toLowerCase() === ActionTypeEnum.EDIT) {
      this.editRepSubmit();
    } else if (this.selectedAction.toLowerCase() === ActionTypeEnum.REMOVE) {
      this.modalOptions.showModal = true;
    } else {
      this.addRepSubmit();
    }
  }
  addRepSubmit(): void {
    if (this.repForm.valid) {
      const addRepRes = this.repForm.value;
      this.loading = true;
      this.subscriptions.push(
        this.planDetailsService.addRepresentative(addRepRes, this.planId, this.isPending).subscribe(
          (repResponse: PendingPlanResponse) => {
            this.loading = false;
            this.errorMessage = { message: '', details: [] };
            this.successMessage = { message: repResponse.message, details: [] };
            this.isPending = repResponse.newPendingPlanCreated;
            this.communicationService.setIsPendingForNewPendingPlan(repResponse);
            this.router.navigate(['/plan-summary', this.planId, 'plan-fee-schedule', 'add']);
          },
          (error) => {
            this.loading = false;
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }
  onRepSearch(): void {
    this.anotherFormSubmitted = true;
    if (this.anotherRepForm.valid) {
      this.loading = true;
      this.subscriptions.push(
        this.planDetailsService
          .getNameRepresentatives(this.anotherRepForm.value.repLastName, this.anotherRepForm.value.repFirstName)
          .subscribe(
            (data: any) => {
              this.loading = false;
              this.repSearchDisplay = true;
              this.errorMessage = { message: '', details: [] };
              this.repNameList = data.representativeResultsList;
            },
            (error) => {
              this.loading = false;
              window.scroll(0, 0);
              if (error) {
                this.errorMessage = { message: error, details: [] };
              } else {
                this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
              }
            }
          )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
  }

  anotherRepSubmit(repId: number): void {
    this.loading = true;
    this.subscriptions.push(
      this.planDetailsService.addAnotherRepresentative(this.planId, repId, this.isPending, this.loggedInUserId).subscribe(
        (repResponse: PendingPlanResponse) => {
          this.loading = false;
          this.successMessage = { message: repResponse.message, details: [] };          
          window.scroll(0, 0);
          this.isPending = repResponse.newPendingPlanCreated;
          this.communicationService.setIsPendingForNewPendingPlan(repResponse);
          this.errorMessage = { message: '', details: [] };
          this.selectedProduct.push(this.getTheValue(this.anotherRepForm.value.repId));
          this.router.navigate(['/plan-summary', this.planId, 'plan-fee-schedule', 'add']);
          this.getExistingData();
        },
        (error) => {
          window.scroll(0, 0);
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: error, details: [] };
          if (this.errorMessage) {
            this.repDisplay = false;
          }
        }
      )
    );
    this.navigationStore.set('formChanged', false);
  }
  editRepSubmit() {
    if (this.repForm.valid) {
      this.loading = true;
      const editRepRequest = this.repForm.value;
      editRepRequest.repId = this.repId;
      this.subscriptions.push(
        this.planDetailsService.updateRepresentative(editRepRequest, this.planId).subscribe(
          (repResponse: EditRepresentativeResponse) => {
            window.scroll(0, 0);
            this.newRep = true;
            this.loading = false;
            this.errorMessage = { message: '', details: [] };
            this.successMessage = { message: alertMessages.REPRESENTATIVE_UPDATED, details: [] };
            this.router.navigate(['/plan-summary', this.planId, 'plan-fee-schedule', 'add']);
          },
          (error: HttpErrorResponse) => {
            window.scroll(0, 0);
            this.loading = false;
            this.successMessage = { message: '', details: [] };
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }
  removeRepSubmit(): void {
    this.loading = true;
    const removeRepRequest = this.repForm.value;
    removeRepRequest.repId = this.repId;
    this.subscriptions.push(
      this.planDetailsService.removeRepresentative(removeRepRequest, this.planId, removeRepRequest.repId, this.isPending, this.loggedInUserId).subscribe(
        (repResponse: SuccessResponse) => {                
          this.successMessage = { message: repResponse.status, details: [] };
          window.scroll(0, 0);
          this.loading = false;
          this.newRep = false;
          this.errorMessage = { message: '', details: [] };
          if (this.anotherRep?.length > 1) {
            this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
          } else {
            this.router.navigate(['/plan-summary', this.planId, 'plan-fee-schedule', 'add']);
          }
        },
        (error) => {
          window.scroll(0, 0);
          this.loading = false;
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: error, details: [] };
        }
      )
    );
    this.navigationStore.set('formChanged', false);
  }
  onCancel() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.router.navigate(['/plan-summary', this.planId]);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
  getRequiredDropdownErrorMessage(preText: string): string {
    return `Please choose a ${preText}`;
  }

  onRepSubmit($event: any) {
    this.anotherRepSubmit(this.selectedEventId);
  }
  get anotherRepControl() {
    return this.anotherRepForm.controls;
  }
  getTheValue(selectedRepId: string) {
    let repSelected;
    this.anotherRep?.forEach((repItem) => {
      // eslint-disable-next-line radix
      if (repItem.repId === parseInt(selectedRepId)) {
        repSelected = repItem;
      }
    });
    return repSelected;
  }
  onRepIdChange($event: any) {
    this.selectedEventId = $event;
  }

  onClickNewRep() {
    this.newRep = true;
  }

  onClearResults() {
    this.anotherRepForm.reset();
    this.repSearchDisplay = false;
    this.successMessage = { message: '', details: [] };
    this.errorMessage = { message: '', details: [] };
  }

  onNotLicensed(): void {
    this.planDetailsService.saveUnlicensedRep(this.planId, this.loggedInUserId).subscribe((unlicensedRepRes: PendingPlanResponse) => {
      window.scroll(0, 0);
      this.errorMessage = { message: '', details: [] };
      this.successMessage = { message: unlicensedRepRes?.message, details: [] };
      this.isPending = unlicensedRepRes.newPendingPlanCreated;
      this.communicationService.setIsPendingForNewPendingPlan(unlicensedRepRes);
      this.router.navigate(['/plan-summary', this.planId, 'plan-fee-schedule', 'add']);
    },
    (error) => {
      window.scroll(0, 0);
      this.loading = false;
      this.successMessage = { message: '', details: [] };
      this.errorMessage = { message: error, details: [] };
    });
  }
}
