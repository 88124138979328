import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { modalMessage, alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, PlanStatusEnum, BinaryResponseEnum, PlanSetupCheckListModalActionEnum } from 'src/app/helpers/enum';
import { CustomModal } from '../../model/custom-modal.model';
import { GetPlans } from '../../model/get-plan.model';
import { MoneyManager, MoneyManagerData } from '../../model/money-manager-data';
import { PlanSetupChecklistResponse, PlanSetupChecklistSuccessResponse } from '../../model/plan-setup-checklist-response.model';
import { FooterFeaturesService } from '../../services/footer-features.service';
import { PlanDetailsService } from '../../services/plan-details.service';
import { SearchUsersService } from '../../services/search-users.service';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'plan-setup-checklist',
  templateUrl: './plan-setup-checklist.component.html',
  styleUrls: ['./plan-setup-checklist.component.css'],
})
export class PlanSetupChecklistComponent implements OnInit, OnDestroy {
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  planId: number;
  userEmail: string;
  moneyManagerList: MoneyManager[];
  planDetails: GetPlans;
  selectedAccordianTab: number = null;
  getHistoryObj: PlanSetupChecklistResponse[];
  revisionToActive = false;
  revisionHistory = false;
  planSetupChecklistForm: UntypedFormGroup;
  submitted = false;
  isSaveActionDone: boolean;
  isModalAction = '';
  loading = false;
  formSubscription: Subscription;
  modalOptions: CustomModal = {
    header: modalMessage.CONFIRMATION,
    message: '',
    showModal: false,
    noButtonValue: '',
    yesButtonValue: '',
  };
  showFaxedNWInfo: false;
  clonedFormObject;
  statusRevert = [];
  getPlanRevisionChecklistResult: PlanSetupChecklistResponse[];
  getPlanRevisionChecklistResultData = {
    mgrChgInit: null,
    feeChgInit: null,
    repChgInit: null,
    solChgInit: null,
    otherChgInit: null,
  };
  isPending: boolean;
  private subscriptions = [];

  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private searchUserService: SearchUsersService,
    private navigationStore: NavigationStore,
    private route: ActivatedRoute,
    private footerFeaturesService: FooterFeaturesService,
    private communicationService: CommunicationService
  ) {}
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';    
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe(pending => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.getPlanChecklist();
      this.getPlanDetails();
      this.getMoneyManager();
    });
    this.subscriptions.push(
      this.footerFeaturesService.getSubFooterDetails(FeatureTypeIDEnum.VIEW_PLANS, this.userEmail).subscribe()
    );
    this.planSetupChecklistForm = this.fb.group({
      planRevisionTrackingId: [null],
      mgrChgInit: [null],
      mgrChgCon: [null],
      repChgInit: [null],
      repChgCon: [null],
      feeChgInit: [null],
      feeChgCon: [null],
      solChgInit: [null],
      solChgCon: [null],
      otherChgInit: [null],
      otherChgCon: [null],
      otherChgText: [null],
      moneyManagerId: [null, [Validators.required]],
      emailedDocuments: [null],
      nwpaApproved: [null],
      signedPSA: [null],
      signedSOF: [null],
      solRepVerified: [null],
      autoManagement: [null],
      particMapping: [null],
      feesEntered: [null],
      fundsEntered: [null],
      activatedTMS: [null],
      restrictedFundLetter: [null],
      activationEmailed: [null],
    });
    this.formSubscription = this.planSetupChecklistForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
  }

  getPlanChecklist() {
    this.subscriptions.push(
      this.planDetailsService.getPlanRevisionChecklist(this.planId).subscribe((data: PlanSetupChecklistResponse[]) => {
        this.getPlanRevisionChecklistResult = data;
        this.setExistingFormUpdate(data);
      })
    );
  }

  getRevisionHistory() {
    this.revisionHistory = false;
    this.revisionToActive = false;
    this.subscriptions.push(
      this.planDetailsService.getRevisionHistory(this.planId).subscribe((data: PlanSetupChecklistResponse[]) => {
        this.enableHistoryAccordian(data);
      })
    );
  }

  toRevisionCheckEnable($event, attr) {
    if ($event.currentTarget.checked && this.clonedFormObject[attr]) {
      this.planSetupChecklistForm.patchValue({
        [attr]: this.clonedFormObject[attr],
      });
    }
  }

  enableHistoryAccordian(data) {
    if (data && data.revisionHistoryResponseList && data.revisionHistoryResponseList.length > 0) {
      this.revisionHistory = true;
      if (this.planDetails?.status !== PlanStatusEnum.PENDING) {
        this.revisionToActive = true;
      }
      this.getHistoryObj = data.revisionHistoryResponseList;
    }
  }

  setExistingFormUpdate(data) {
    if (data && data.revisionHistoryResponseList && data.revisionHistoryResponseList[0]) {
      const dataValue = data.revisionHistoryResponseList[0];
      this.getPlanRevisionChecklistResultData = dataValue;
      this.clonedFormObject = Object.assign({}, dataValue);
      this.planSetupChecklistForm.patchValue({
        planRevisionTrackingId: dataValue.planRevisionTrackingId,
        mgrChgInit: dataValue.mgrChgInit && dataValue.mgrChgCon ? dataValue.mgrChgInit : null,
        mgrChgCon: dataValue.mgrChgCon,
        repChgInit: dataValue.repChgInit && dataValue.repChgCon ? dataValue.repChgInit : null,
        repChgCon: dataValue.repChgCon,
        feeChgInit: dataValue.feeChgInit && dataValue.feeChgCon ? dataValue.feeChgInit : null,
        feeChgCon: dataValue.feeChgCon,
        solChgInit: dataValue.solChgInit && dataValue.solChgCon ? dataValue.solChgInit : null,
        solChgCon: dataValue.solChgCon,
        otherChgInit: dataValue.otherChgInit && dataValue.otherChgCon ? dataValue.otherChgInit : null,
        otherChgCon: dataValue.otherChgCon,
        otherChgText: dataValue.otherChgText,
        moneyManagerId: dataValue.mnyMgrId,
        emailedDocuments: dataValue.emailedDocuments,
        nwpaApproved: dataValue.nwpaApproved,
        signedPSA: dataValue.signedPSA,
        signedSOF: dataValue.signedSOF,
        solRepVerified: dataValue.solRepVerified,
        autoManagement: dataValue.autoManagement,
        particMapping: dataValue.particMapping,
        feesEntered: dataValue.feesEntered,
        fundsEntered: dataValue.fundsEntered,
        activatedTMS: dataValue.activatedTMS,
        restrictedFundLetter: dataValue.restrictedFundLetter,
        activationEmailed: dataValue.activationEmailed,
      });
    } else {
      this.planSetupChecklistForm.markAsPristine();
      this.planSetupChecklistForm.markAsUntouched();
      this.planSetupChecklistForm.reset();
      this.getPlanRevisionChecklistResultData = {
        mgrChgInit: null,
        feeChgInit: null,
        repChgInit: null,
        solChgInit: null,
        otherChgInit: null,
      };
      this.clonedFormObject = Object.assign({}, this.planSetupChecklistForm.value);
    }
    this.navigationStore.set('formChanged', false);
  }

  get planSetupChecklistControl() {
    return this.planSetupChecklistForm.controls;
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.planSetupChecklistForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getRequiredDropdownErrorMessage(preText: string): string {
    return `Please choose a ${preText}`;
  }

  toggleAccordian(index) {
    if (this.selectedAccordianTab === index) {
      this.selectedAccordianTab = null;
    } else {
      this.selectedAccordianTab = index;
    }
  }

  getPlanDetails() {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
        this.getRevisionHistory();
      })
    );
  }

  getMoneyManager(): void {
    this.subscriptions.push(
      this.searchUserService.getMoneyManagerForPlans(this.userEmail,this.planId).subscribe((firmData: MoneyManager[]) => {
        this.moneyManagerList = firmData.filter((mnyMgr) => mnyMgr.wallOff === BinaryResponseEnum.NO);
      },
      (error) => {
        this.errorMessage = { message: error, details : [] };
      })
    );
  }

  setFormRequestValue(formValue) {
    this.statusRevert = [];
    // setup for green flag
    if (formValue.mgrChgInit && this.clonedFormObject.mgrChgInit) {
      formValue.mgrChgCon = true;
      this.statusRevert.push('mgrChgCon');
    }
    if (formValue.repChgInit && this.clonedFormObject.repChgInit) {
      formValue.repChgCon = true;
      this.statusRevert.push('repChgCon');
    }
    if (formValue.feeChgInit && this.clonedFormObject.feeChgInit) {
      formValue.feeChgCon = true;
      this.statusRevert.push('feeChgCon');
    }
    if (formValue.solChgInit && this.clonedFormObject.solChgInit) {
      formValue.solChgCon = true;
      this.statusRevert.push('solChgCon');
    }
    if (formValue.otherChgInit && this.clonedFormObject.otherChgInit) {
      formValue.otherChgCon = true;
      this.statusRevert.push('otherChgCon');
    }
    let returnArray;
    // eslint-disable-next-line prefer-const
    returnArray = {
      planRevisionTrackingId: formValue.planRevisionTrackingId,
      mgrChgInit: formValue.mgrChgInit ? '1' : null,
      mgrChgCon: formValue.mgrChgCon ? '1' : null,
      repChgInit: formValue.repChgInit ? '1' : null,
      repChgCon: formValue.repChgCon ? '1' : null,
      feeChgInit: formValue.feeChgInit ? '1' : null,
      feeChgCon: formValue.feeChgCon ? '1' : null,
      solChgInit: formValue.solChgInit ? '1' : null,
      solChgCon: formValue.solChgCon ? '1' : null,
      otherChgInit: formValue.otherChgInit ? '1' : null,
      otherChgCon: formValue.otherChgCon ? '1' : null,
      otherChgText: formValue.otherChgText,
      mnyMgrId: +formValue.moneyManagerId,
      emailedDocuments: formValue.emailedDocuments ? '1' : null,
      nwpaApproved: formValue.nwpaApproved ? '1' : null,
      signedPSA: formValue.signedPSA ? '1' : null,
      signedSOF: formValue.signedSOF ? '1' : null,
      solRepVerified: formValue.solRepVerified ? '1' : null,
      autoManagement: formValue.autoManagement ? '1' : null,
      particMapping: formValue.particMapping ? '1' : null,
      feesEntered: formValue.feesEntered ? '1' : null,
      fundsEntered: formValue.fundsEntered ? '1' : null,
      activatedTMS: formValue.activatedTMS ? '1' : null,
      restrictedFundLetter: formValue.restrictedFundLetter ? '1' : null,
      activationEmailed: formValue.activationEmailed ? '1' : null,
    };
    return returnArray;
  }

  onFailRevertFlag() {
    if (this.statusRevert && this.statusRevert.length > 0) {
      this.statusRevert.forEach((key) => {
        this.planSetupChecklistForm.value[key] = null;
      });
    }
  }

  onSaveRevisions() {
    this.submitted = true;
    if (this.planSetupChecklistForm.valid) {
      this.loading = true;
      this.errorMessage = { message: '', details: [] };
      this.successMessage = { message: '', details: [] };
      this.subscriptions.push(
        this.planDetailsService
          .savePlanRevision(this.planId, this.setFormRequestValue(this.planSetupChecklistForm.value), this.userEmail)
          .subscribe(
            (planSetupChecklistResponse: PlanSetupChecklistSuccessResponse) => {
              this.loading = false;
              this.isSaveActionDone = true;
              this.errorMessage = { message: '', details: [] };
              window.scroll(0, 0);
              this.successMessage = { message: alertMessages.PLAN_CHECKLIST_SAVE_SUCCESS, details: [] };
              this.submitted = false;
              this.getPlanChecklist();
              this.getRevisionHistory();
            },
            (error: HttpErrorResponse) => {
              this.loading = false;
              window.scroll(0, 0);
              this.onFailRevertFlag();
              this.successMessage = { message: '', details: [] };
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          )
      );
      this.navigationStore.set('formChanged', false);
    }
  }

  onCommitRevisionsApi() {
    this.submitted = true;
    if (this.planSetupChecklistForm.valid) {
      this.loading = true;
      this.errorMessage = { message: '', details: [] };
      this.successMessage = { message: '', details: [] };
      this.subscriptions.push(
        this.planDetailsService
          .commitPlanRevision(this.planId, this.setFormRequestValue(this.planSetupChecklistForm.value), this.userEmail)
          .subscribe(
            (planSetupChecklistResponse: PlanSetupChecklistSuccessResponse) => {
              this.loading = false;
              this.isSaveActionDone = false;
              this.errorMessage = { message: '', details: [] };
              window.scroll(0, 0);
              this.successMessage = { message: alertMessages.PLAN_CHECKLIST_COMMIT_SUCCESS, details: [] };
              this.submitted = false;
              this.getPlanChecklist();
              this.getRevisionHistory();
            },
            (error: HttpErrorResponse) => {
              this.loading = false;
              this.onFailRevertFlag();
              window.scroll(0, 0);
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          )
      );
      this.navigationStore.set('formChanged', false);
    }
  }

  onDeleteRevisionsApi() {
    this.submitted = true;
    if (this.planSetupChecklistForm.valid) {
      this.errorMessage = { message: '', details: [] };
      this.successMessage = { message: '', details: [] };
      this.loading = true;
      this.subscriptions.push(
        this.planDetailsService
          .deletePlanRevision(this.planId, this.planSetupChecklistForm.get('planRevisionTrackingId').value)
          .subscribe(
            (planSetupChecklistResponse: PlanSetupChecklistSuccessResponse) => {
              this.loading = false;
              this.errorMessage = { message: '', details: [] };
              window.scroll(0, 0);
              this.successMessage = { message: alertMessages.PLAN_CHECKLIST_DELETE_SUCCESS, details: [] };
              this.submitted = false;
              this.getPlanChecklist();
              this.getRevisionHistory();
            },
            (error: HttpErrorResponse) => {
              this.loading = false;
              this.onFailRevertFlag();
              window.scroll(0, 0);
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          )
      );
      this.navigationStore.set('formChanged', false);
    }
  }

  onCommitRevisions() {
    if (this.isSaveActionDone) {
      this.submitted = true;
      if (this.planSetupChecklistForm.valid) {
        this.isModalAction = PlanSetupCheckListModalActionEnum.COMMIT_CONFIRM;
        this.modalOptions.message = modalMessage.PLAN_CHECKLIST_COMPLETE_CONFIRMATION;
        this.modalOptions.noButtonValue = modalMessage.NO_TEXT;
        this.modalOptions.yesButtonValue = modalMessage.YES_TEXT;
        this.modalOptions.showModal = true;
      }
    } else {
      this.isModalAction = PlanSetupCheckListModalActionEnum.COMMIT_WARNING;
      this.modalOptions.message = modalMessage.PLAN_CHECKLIST_COMPLETE_WARNING;
      this.modalOptions.noButtonValue = null;
      this.modalOptions.yesButtonValue = modalMessage.OK_TEXT;
      this.modalOptions.showModal = true;
    }
  }

  noClicked() {
    this.modalOptions.showModal = false;
  }
  yesClicked() {
    this.modalOptions.showModal = false;
    if (this.isModalAction === PlanSetupCheckListModalActionEnum.COMMIT_CONFIRM) {
      this.onCommitRevisionsApi();
    } else if (this.isModalAction === PlanSetupCheckListModalActionEnum.DELETE_CONFIRM) {
      this.onDeleteRevisionsApi();
    }
  }

  onDeleteCurrent() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };

    if (this.planSetupChecklistForm.get('planRevisionTrackingId').value) {
      // set modal messages
      this.isModalAction = PlanSetupCheckListModalActionEnum.DELETE_CONFIRM;
      this.modalOptions.message = modalMessage.PLAN_CHECKLIST_DELETE_CONFIRMATION;
      this.modalOptions.noButtonValue = modalMessage.NO_TEXT;
      this.modalOptions.yesButtonValue = modalMessage.YES_TEXT;
      this.modalOptions.showModal = true;
    } else {
      this.modalOptions.message = modalMessage.PLAN_CHECKLIST_DELETE_NO_ITEM_MESSAGE;
      this.modalOptions.noButtonValue = null;
      this.modalOptions.yesButtonValue = modalMessage.OK_TEXT;
      this.modalOptions.showModal = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
