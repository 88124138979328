<section class="container-fluid temp-color">
  <div ui-view="" class="ng-scope">
    <div id="section-level-error-msg-section" class="row">
      <div class="col-sm-12">
        <form [formGroup]="advancedSearchForm" (ngSubmit)="onSubmit()">
          <div class="compartment box summary animation-fade-in">
            <div class="content padding-left">
              <div class="section__body">
                <div class="row">
                  <div class="col-sm-12">
                    <ul class="ul-li-view-edit">
                      <h2 class="content-title">Advanced Search</h2>
                      <li>
                        <div class="col-sm-4"><b>Plan Case Number:</b></div>
                        <div class="col-sm-8">
                          <input
                            class="form-input form-input--small"
                            type="text"
                            name="planCaseNumber"
                            formControlName="planCaseNumber"
                            [ngClass]="{
                              'has-error': isControlInvalid('planCaseNumber')
                            }"
                          />
                          <nfrp-inline-error
                            *ngIf="
                              (advancedSearchFormControl.planCaseNumber.touched || submitted) &&
                              advancedSearchFormControl.planCaseNumber.errors?.pattern
                            "
                            errorId="planCaseNumber-valid-error"
                            [message]="getValidErrorMessage('planCaseNumber')"
                          >
                          </nfrp-inline-error>
                        </div>
                      </li>
                      <li>
                        <div class="input-group flex-settings-datepicker-input">
                          <div class="col-sm-12 padding-left">
                            <label for="datepicker"><b>Start date</b></label>
                          </div>
                          <div class="col-sm-7 padding-left-2">
                            <div class="form-group">
                              <nfrp-datepicker
                                [uniqueId]="'save-pension-activation-date'"
                                [parentForm]="advancedSearchForm"
                                [controlName]="'startDate'"
                                [formSubmitted]="submitted"
                                [minDate]="minDate"
                                [maxDate]="maxDate"
                              >
                              </nfrp-datepicker>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="input-group flex-settings-datepicker-input">
                          <div class="col-sm-12 padding-left">
                            <label for="datepicker"><b>End date</b></label>
                          </div>
                          <div class="col-sm-7 padding-left-2">
                            <div class="form-group">
                              <nfrp-datepicker
                                [uniqueId]="'save-pension-activation-date'"
                                [parentForm]="advancedSearchForm"
                                [controlName]="'endDate'"
                                [formSubmitted]="submitted"
                                [maxDate]="maxDate"
                                [minDate]="minDate"
                              >
                              </nfrp-datepicker>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="col-sm-4"><b>Provider Product:</b></div>
                        <div class="col-sm-5">
                          <select
                            formControlName="providerProductId"
                            name="providerProductId"
                            id="PROVIDER_PRODUCT_ID"
                            tabindex="2"
                            title="Provider Product"
                            (change)="getSelectedProduct($event.target.value)"
                          >
                            <option [ngValue]="null" selected="selected"><--- ALL ---></option>
                            <option
                              *ngFor="let product of productsList | orderBy: 'providerProductLabel'"
                              [value]="product.providerProductId"
                              >{{ product.providerProductLabel }}
                            </option>
                          </select>
                        </div>
                      </li>
                      <li>
                        <div class="col-sm-4 align-text-top"><b>Plan Type:</b></div>
                        <div class="col-sm-3">
                          <select
                            id="PLAN_TYPE_ID"
                            formControlName="planTypeId"
                            name="planTypeId"
                            tabindex="3"
                            title="Plan Type"
                            (change)="getSelectedPlanType($event.target.value)"
                          >
                            <option [ngValue]="null" selected="selected"><--- ALL ---></option>
                            <option
                              *ngFor="let planType of planTypeList | orderBy: 'planLabel'"
                              [value]="planType.planTypeId"
                              >{{ planType.planLabel }}
                            </option>
                          </select>
                        </div>
                      </li>
                      <li>
                        <div class="col-sm-4"><b>TPA:</b></div>
                        <div class="col-sm-7">
                          <select
                            class="tpa-max-width"
                            formControlName="tpAdminNbr"
                            name="tpAdminNbr"
                            id="TPADMIN_NBR"
                            tabindex="4"
                            title="TPA"
                            (change)="getSelectedTPA($event.target.value)"
                          >
                            <option *ngIf="!isTpa" value="" selected="selected"><---ALL---></option>
                            <option *ngFor="let tpa of tpaResult | orderBy: 'tpAdminLabel'" [value]="tpa.tpAdminNbr"
                              >{{ tpa.tpAdminNbr }} : {{ tpa.tpAdminLabel }}</option
                            ></select
                          >
                        </div>
                      </li>
                      <li>
                        <div class="col-sm-4"><b>Money Manager:</b></div>
                        <div class="col-sm-7">
                          <select
                            formControlName="mnyMgrId"
                            name="mnyMgrId"
                            id="MNY_MGR_ID"
                            tabindex="5"
                            title="Money Manager"
                            (change)="getSelectedMoneyManager($event.target.value)"
                          >
                            <option *ngIf="!isMoneyManger" value="" class="" selected="selected"><--ALL--></option>
                            <ng-container *ngIf="!showWFMM">
                              <option
                                *ngFor="let moneyManager of moneyMgrInfo | orderBy: 'moneyManagerLabel'; let i = index"
                                [value]="moneyManager.moneyManagerId"
                                >{{ moneyManager.moneyManagerLabel }} {{ moneyManager.wallOff ? '(W)' : '' }}
                              </option>
                            </ng-container>
                            <ng-container *ngIf="showWFMM">
                              <option
                                *ngFor="let moneyManager of mmNonWalledOffList | orderBy: 'moneyManagerLabel'; let i = index"
                                [value]="moneyManager.moneyManagerId"
                                >{{ moneyManager.moneyManagerLabel }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </li>
                      <li>
                        <div class="col-sm-4"><b>Representative:</b></div>
                        <div class="col-sm-7">
                          <select
                            formControlName="repId"
                            name="repId"
                            id="REP_ID"
                            tabindex="2"
                            title="Rep Id"
                            (change)="onRepIdChange($event.target.value)"
                          >
                            <option [ngValue]="null" selected="selected"><--- ALL ---></option>
                            <option *ngFor="let rep of planRep; let i = index" [value]="rep.repId"
                              >{{ rep.repFirstName }} {{ rep.repLastName }}
                            </option>
                          </select>
                        </div>
                      </li>
                      <li>
                        <div class="col-sm-4"><b>Plan Status:</b></div>
                        <div class="col-sm-8">
                          <input type="radio" formControlName="planStatus" name="planStatus" [value]="0" />Pending
                          <span class="ml-20">
                            <input
                              type="radio"
                              formControlName="planStatus"
                              name="planStatus"
                              [value]="1"
                            />Active</span
                          >
                          <span class="ml-20">
                            <input
                              type="radio"
                              formControlName="planStatus"
                              name="planStatus"
                              [value]="2"
                            />Either</span
                          >
                        </div>
                      </li>
                      <li *ngIf="userTypeId === 1">
                        <div class="col-sm-4" unread-font-weight><b>Archived:</b></div>
                        <div class="col-sm-8">
                          <input type="radio" formControlName="isArchived" name="isArchived" [value]="1" />No
                          <span class="ml-20">
                            <input type="radio" formControlName="isArchived" name="isArchived" [value]="0" />Yes</span
                          >
                          <span class="ml-20">
                            <input
                              type="radio"
                              formControlName="isArchived"
                              name="isArchived"
                              [value]="2"
                            />Either</span
                          >
                        </div>
                      </li>
                      <li>
                        <div class="col-sm-4"><b>Auto-Enrollment:</b></div>
                        <div class="col-sm-8">
                          <input type="radio" formControlName="isAutoEnrolled" name="isAutoEnrolled" [value]="0" />No
                          <span class="ml-20">
                            <input
                              type="radio"
                              formControlName="isAutoEnrolled"
                              name="isAutoEnrolled"
                              [value]="1"
                            />Yes</span
                          >
                          <span class="ml-20">
                            <input
                              type="radio"
                              formControlName="isAutoEnrolled"
                              name="isAutoEnrolled"
                              [value]="2"
                              checked="checked"
                            />Either</span
                          >
                        </div>
                      </li>
                      <li *ngIf="userTypeId === 1 || userTypeId === 4">
                        <div class="col-sm-4"><b>PPA/TPA:</b></div>
                        <div class="col-sm-8">
                          <input type="radio" formControlName="isPpaTpa" name="isPpaTpa" [value]="0" />PPA
                          <span class="ml-20">
                            <input type="radio" formControlName="isPpaTpa" name="isPpaTpa" [value]="1" />TPA</span
                          >
                          <span class="ml-20">
                            <input
                              type="radio"
                              formControlName="isPpaTpa"
                              name="isPpaTpa"
                              [value]="2"
                              checked="checked"
                            />Either</span
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-offset-4">
            <div class="row">
              <div class="col-sm-3">
                <button type="submit" id="save-matrix-document-button" class="button button-primary padding-right">
                  Run Report Filter
                </button>
              </div>
              <div class="col-sm-3">
                <button
                  type="submit"
                  id="save-matrix-document"
                  class="button button-primary inline padding-left"
                  (click)="downloadFile(dataSource)"
                  [disabled]="advDisable"
                >
                  Export Report to CSV
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 padding-top-2">
            <ng-container *ngIf="advSearchView">
              <ng-container *ngIf="dataSource; else loading">
                <ng-template #headerTemplate>
                  <div nfrpColumn [width]="14" widthType="%" [id]="'plan-number-label'">
                    Plan Number
                    <nfrp-grid-sorter
                      [column]="'planCaseNumber'"
                      dataType="string"
                      (sort)="sort($event)"
                    ></nfrp-grid-sorter>
                  </div>
                  <div nfrpColumn [width]="17" widthType="%" [id]="'plan-label'">
                    Plan Name
                    <nfrp-grid-sorter [column]="'planLabel'" dataType="string" (sort)="sort($event)"></nfrp-grid-sorter>
                  </div>
                  <div nfrpColumn [width]="17" widthType="%" [id]="'tpa-label'">
                    TPA
                    <nfrp-grid-sorter [column]="'tpaLabel'" dataType="string" (sort)="sort($event)"></nfrp-grid-sorter>
                  </div>
                  <div nfrpColumn [width]="17" widthType="%" [id]="'mnyMgr-label'">
                    Money Manager
                    <nfrp-grid-sorter [column]="'mnyMgr'" dataType="string" (sort)="sort($event)"></nfrp-grid-sorter>
                  </div>
                  <div nfrpColumn [width]="6" widthType="%" [id]="'tracOrIk-label'">
                    Trac/IK
                    <nfrp-grid-sorter [column]="'tracOrIk'" dataType="string" (sort)="sort($event)"></nfrp-grid-sorter>
                  </div>
                  <div nfrpColumn [width]="10" widthType="%" [id]="'createdDate-label'">
                    Created Date
                    <nfrp-grid-sorter
                      [column]="'createdDate'"
                      dataType="string"
                      (sort)="sort($event)"
                      defaultDirection="ASCENDING"
                    ></nfrp-grid-sorter>
                  </div>
                  <div nfrpColumn [width]="10" widthType="%" [id]="'activatedDate-label'">
                    Activated Date
                    <nfrp-grid-sorter
                      [column]="'activatedDate'"
                      dataType="string"
                      (sort)="sort($event)"
                    ></nfrp-grid-sorter>
                  </div>
                  <div nfrpColumn [width]="3" widthType="%" [id]="'isArchived-label'">
                    Archived?
                    <nfrp-grid-sorter
                      [column]="'isArchived'"
                      dataType="number"
                      (sort)="sort($event)"
                    ></nfrp-grid-sorter>
                  </div>
                </ng-template>
                <ng-template #rowTemplate let-advancedSearchResult="item" let-index="index">
                  <div>
                    <div [id]="'plan-number-' + index">
                      {{ advancedSearchResult.planCaseNumber | nfrpNormalizeValue: '-' }}
                    </div>
                    <div [id]="'plan-' + index">
                      <a class="text-decor-underline" (click)="navigateToPlanSummary(advancedSearchResult.planId)">
                        {{ advancedSearchResult.planLabel | nfrpNormalizeValue: '-' }}</a
                      >
                    </div>
                    <div [id]="'tpa-' + index">
                      {{ advancedSearchResult.tpaLabel | nfrpNormalizeValue: '-' }}
                    </div>
                    <div [id]="'mnyMgr-' + index">
                      {{ advancedSearchResult.mnyMgr | nfrpNormalizeValue: '-' }}
                    </div>
                    <div [id]="'tracOrIk-' + index">
                      {{ advancedSearchResult.tracOrIk | nfrpNormalizeValue: '-' }}
                    </div>
                    <div [id]="'createdDate-' + index">
                      {{ advancedSearchResult.createdDate | nfrpNormalizeValue: '-' }}
                    </div>
                    <div [id]="'activatedDate-' + index">
                      {{ advancedSearchResult.activatedDate | nfrpNormalizeValue: '-' }}
                    </div>
                    <div [id]="'isArchived-' + index">
                      {{ advancedSearchResult.isArchived === 'yes' ? 'Yes' : ('No' | nfrpNormalizeValue: '-') }}
                    </div>
                  </div>
                </ng-template>
                <nfrp-grid
                  [headerTemplate]="headerTemplate"
                  [rowTemplate]="rowTemplate"
                  [iterable]="data"
                  [width]="100"
                  rowIdPrefix="advancedSearch-details"
                ></nfrp-grid>
                <nfrp-grid-paginator
                  (paginate)="paginate($event)"
                  [numberOfItems]="numberOfItems"
                  [itemsPerPageOptions]="[20, 50, 100]"
                  [defaultItemsPerPage]="20"
                ></nfrp-grid-paginator>
              </ng-container>
            </ng-container>
            <ng-template #loading>
              <nfrp-spinner></nfrp-spinner>
            </ng-template>
          </div>
        </form>
      </div>
      <div class="col-sm-12 padding-top" *ngIf="errorMessage?.message?.length > 0 && advDisable">
        <nfrp-alert alertId="edit-core-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
    </div>
  </div>
</section>
