import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { GetPlanRepresentatives, GetPlans } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/get-plan.model';
import { RepresentativeResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/representative.model';
import { FooterFeaturesService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/footer-features.service';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { tap } from 'rxjs/operators';
import { UserTypeIDEnum, FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
@Component({
  selector: 'plan-rep',
  templateUrl: './plan-rep.component.html',
  styleUrls: ['./plan-rep.component.css'],
})
export class PlanRepComponent extends NfrpDefaultGridComponent<GetPlanRepresentatives> implements OnInit, OnChanges, OnDestroy {
  @Input() showEditOption: boolean;
  @Input() pendingStatus: boolean;
  @Input() status: boolean;
  planId: number;
  planDetails: GetPlans;
  planDetailsData: GetPlans;
  isViewPlanMode = true;
  isEditPlanMode = false;
  dataSource: GetPlanRepresentatives[];
  showEditCorePlanOption = false;
  numberOfItems: number;
  userEmail: string;
  selectedAction: string;
  repData: RepresentativeResponse;
  isRepresentative = false;
  repObj: RepresentativeResponse;
  showArchiveOption = false;
  archivePlanStatus = false;
  userTypeID: number;
  isMoneyMgrUser = false;
  readonly riaUserId = UserTypeIDEnum.RIA;

  private subscriptions = [];
  constructor(
    private route: ActivatedRoute,
    private planDetailsService: PlanDetailsService,
    private communicationService: CommunicationService,
    nfrpGridService: NfrpGridService<GetPlanRepresentatives>
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeID = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.getPlanRepresentatives();
    });
    //Archive plan event
    this.subscriptions.push(
      this.communicationService.archivePlanStatusEvent.subscribe((archivePlanStatus: boolean) => {
        this.archivePlanStatus = archivePlanStatus;
      })
    );
    if (this.userTypeID === UserTypeIDEnum.MONEY_MANAGER) {
      this.isMoneyMgrUser = true;
    }
  }
  editCorePlanDetails(planDetailsResponseObj) {
    this.isViewPlanMode = false;
    this.isEditPlanMode = true;
    this.planDetailsData = planDetailsResponseObj;
  }

  changeToPlanSummary() {
    this.isViewPlanMode = !this.isViewPlanMode;
    this.isEditPlanMode = false;
  }

  getPlanRepresentatives(): void {
    this.subscriptions.push(
      this.planDetailsService
        .getPlanRepresentatives(this.planId, this.pendingStatus)
        .pipe(
          tap((data) => {
            this.dataSource = data?.planRepresentativeResponseList;
            this.numberOfItems = this.dataSource?.length;
            if (this.dataSource) {
              this.setupGrid();
            }
          })
        )
        .subscribe()
    );
  }

  navigateRep(action, repObj) {
    this.selectedAction = action;
    this.repData = repObj;
    this.isViewPlanMode = false;
    this.isEditPlanMode = false;
    this.isRepresentative = true;
  }

  cancelClick(event) {
    window.scroll(0, 0);
    this.isViewPlanMode = event;
    this.isEditPlanMode = false;
    this.isRepresentative = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.planId && changes.pendingStatus && (changes.pendingStatus.currentValue || changes.pendingStatus.previousValue)) {
      this.getPlanRepresentatives();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
