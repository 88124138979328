<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">Add TPA</h1>
      <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-tpa-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-tpa-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <form class="mt-10" [formGroup]="tpAdminForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-sm-6">
              <table class="corePlanInfo">
                <tbody>
                  <tr>
                    <td>TPA Number*</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaNumber"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaNumber')
                        }"
                        minlength="3"
                        maxlength="3"
                        size="25"
                      />
                      <div>
                        <nfrp-inline-error
                          *ngIf="
                            (tpAdminControl.tpaNumber.touched || submitted) && tpAdminControl.tpaNumber.errors?.required
                          "
                          errorId="tpa-admin-no-required"
                          [message]="getRequiredErrorMessage('TPA Number')"
                        >
                        </nfrp-inline-error>
                        <nfrp-inline-error
                          *ngIf="
                            (tpAdminControl.tpaNumber.touched || submitted) && tpAdminControl.tpaNumber.errors?.pattern
                          "
                          errorId="tpa-admin-valid-error"
                          [message]="getValidErrorMessage('TPA Number')"
                        >
                        </nfrp-inline-error>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Name*</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaName"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaName')
                        }"
                        size="25"
                      />
                      <div>
                        <nfrp-inline-error
                          *ngIf="
                            (tpAdminControl.tpaName.touched || submitted) && tpAdminControl.tpaName.errors?.required
                          "
                          errorId="tpa-admin-name-required"
                          [message]="getRequiredErrorMessage('TPA Name')"
                        >
                        </nfrp-inline-error>
                        <nfrp-inline-error
                          *ngIf="
                            (tpAdminControl.tpaName.touched || submitted) && tpAdminControl.tpaName.errors?.pattern
                          "
                          errorId="tpa-admin-name-valid-error"
                          [message]="getValidErrorMessage('TPA Name')"
                        >
                        </nfrp-inline-error>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Address 1</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaAddress1"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaAddress1')
                        }"
                        size="25"
                      />
                      <nfrp-inline-error
                        *ngIf="
                          (tpAdminControl.tpaAddress1.touched || submitted) &&
                          tpAdminControl.tpaAddress1.errors?.maxLength
                        "
                        errorId="tpa-admin-address1-valid-error"
                        [message]="getValidErrorMessage('Address 1')"
                      >
                      </nfrp-inline-error>
                    </td>
                  </tr>
                  <tr>
                    <td>Address 2</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaAddress2"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaAddress2')
                        }"
                        size="25"
                      /><nfrp-inline-error
                        *ngIf="
                          (tpAdminControl.tpaAddress2.touched || submitted) &&
                          tpAdminControl.tpaAddress2.errors?.maxLength
                        "
                        errorId="tpa-admin-address2-valid-error"
                        [message]="getValidErrorMessage('Address 2')"
                      >
                      </nfrp-inline-error>
                    </td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaCity"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaCity')
                        }"
                        size="25"
                      /><nfrp-inline-error
                        *ngIf="(tpAdminControl.tpaCity.touched || submitted) && tpAdminControl.tpaCity.errors?.pattern"
                        errorId="tpa-admin-city-valid-error"
                        [message]="getValidErrorMessage('City')"
                      >
                      </nfrp-inline-error>
                    </td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>
                      <select name="tpAdminState" id="tpAdminState" formControlName="tpaState">
                        <option value="">-- Choose from List --</option>
                        <option *ngFor="let state of tpAdminStates" [value]="state.value">{{ state.label }}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Zip</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaZip"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaZip')
                        }"
                        size="25"
                        mask="00000-0000"
                        [patterns]="numberMaskPattern"
                      />
                      <nfrp-inline-error
                        *ngIf="(tpAdminControl.tpaZip.touched || submitted) && tpAdminControl.tpaZip.errors?.maxLength"
                        errorId="tpa-admin-zip-valid-error"
                        [message]="getValidErrorMessage('Zip')"
                      >
                      </nfrp-inline-error>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
            <div class="col-sm-6">
              <table class="corePlanInfo">
                <tbody>
                  <tr>
                    <td>Active</td>
                    <td class="planfeeData">
                      <input type="radio" name="tpaActive" checked formControlName="tpaActive" [value]="true" /> Yes
                      <span class="ml-10">
                        <input type="radio" name="tpaActive" formControlName="tpaActive" [value]="false" /> No</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>Admin by NW</td>
                    <td class="planfeeData">
                      <input
                        type="radio"
                        name="tpAdminNW"
                        formControlName="tpAdminNW"
                        [value]="true"
                        (change)="toggleFeeField(1)"
                      />
                      Yes
                      <span class="ml-10">
                        <input
                          type="radio"
                          name="tpAdminNW"
                          formControlName="tpAdminNW"
                          checked=""
                          [value]="false"
                          (change)="toggleFeeField(0)"
                        />
                        No
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="isToggleAdminNw">
                    <td>Fee</td>
                    <td>
                      <div>
                        <select name="tpAdminFeePrct1" formControlName="tpAdminFeePrct" id="TPADMIN_FEE_UNLOCKED">
                          <option *ngFor="let fee of tpaAdminFeeList" [value]="fee.value">{{fee.label  | number : '1.2-2'}}</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaPhone"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaPhone')
                        }"
                        mask="000-000-0000"
                        [patterns]="numberMaskPattern"
                        size="25"
                      />
                      <nfrp-inline-error
                        *ngIf="
                          (tpAdminControl.tpaPhone.touched || submitted) && tpAdminControl.tpaPhone.errors?.maxLength
                        "
                        errorId="tpa-admin-phone-valid-error"
                        [message]="getValidErrorMessage('Phone')"
                      >
                      </nfrp-inline-error>
                    </td>
                  </tr>
                  <tr>
                    <td>Fax</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaFax"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaFax')
                        }"
                        mask="000-000-0000"
                        [patterns]="numberMaskPattern"
                        size="25"
                      />
                      <nfrp-inline-error
                        *ngIf="(tpAdminControl.tpaFax.touched || submitted) && tpAdminControl.tpaFax.errors?.maxLength"
                        errorId="tpa-admin-fax-valid-error"
                        [message]="getValidErrorMessage('Fax')"
                      >
                      </nfrp-inline-error>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaEmail"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaEmail')
                        }"
                        size="25"
                      />
                      <nfrp-inline-error
                        *ngIf="
                          (tpAdminControl.tpaEmail.touched || submitted) && tpAdminControl.tpaEmail.errors?.pattern
                        "
                        errorId="tpa-admin-email-valid-error"
                        [message]="getValidErrorMessage('Email')"
                      >
                      </nfrp-inline-error>
                    </td>
                  </tr>
                  <tr>
                    <td>Contact</td>
                    <td>
                      <input
                        type="text"
                        formControlName="tpaContact"
                        [ngClass]="{
                          'has-error': isControlInvalid('tpaContact')
                        }"
                        size="25"
                      />
                      <nfrp-inline-error
                        *ngIf="
                          (tpAdminControl.tpaContact.touched || submitted) && tpAdminControl.tpaContact.errors?.pattern
                        "
                        errorId="tpa-admin-contact-valid-error"
                        [message]="getValidErrorMessage('Contact')"
                      >
                      </nfrp-inline-error>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mb-10" *ngIf="!isToggleAdminNw">
            <div class="col-sm-6">
              <ul
                id="fixed-period-distribution-params"
                summary="fixed period distribution parameters"
                class="ul-li-view-edit table stripes no-margin border-ul"
              >
                <li *ngFor="let moneyManager of moneyManagerList; let i = index">
                  <div class="col-sm-6 padding-left">{{ moneyManager.moneyManagerLabel }}</div>
                  <div class="col-sm-3"></div>
                  <div class="col-sm-3">
                    <span
                      ><select
                        formControlName="mnyMgrFeePct"
                        (change)="selectedMMFees($event, moneyManager.moneyManagerId)"
                        id="input-{{ i }}"
                      >
                        <option *ngFor="let fee of tpaLimit" [value]="fee">{{ fee }}</option>
                      </select>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-sm-6"></div>
          </div>
          <div class="row">
            <div class="col-sm-1 no-padding">
              <input
                id="search-button"
                class="button-primary button hover-right margin-right"
                type="submit"
                size="40"
                value="Submit"
              />
            </div>
            <div class="col-sm-10 no-padding"></div>
            <div class="col-sm-1 no-padding">
              <input
                type="button"
                class="button button-secondary hover-left margin-left"
                (click)="cancel()"
                value="Cancel"
              />
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</section>
<nfrp-navigate-away-modal></nfrp-navigate-away-modal>
