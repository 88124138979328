import { Component, OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'mas-retail-private';
  currentPath: string;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
    constructor(private bnIdle: BnNgIdleService, private router: Router, private config: ConfigService) {
  }
  ngOnInit(): void {
    this.bnIdle.startWatching(1200).subscribe((res) => {
      if (res) {
        console.log('session expired');
        sessionStorage?.removeItem('loginUser');
        this.router.navigate(['/login']);
      }
    });
  }
}
