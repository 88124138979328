import { Injectable } from '@angular/core';
import { Store } from 'nfrp-shared-angular';
import { MatrixDocumentOptions } from './models/matrix-document-options.model';
import { MatrixAllProduct } from './models/matrix-all-product.model';
import { PlanType } from './models/plan-type.model';
import { MoneyManagerMgmt } from '../nfrp-mas-retail/model/mny-mgr-mgmt';
import { MoneyManagerProgramOption } from './models/money-manager-program-option.model';
import { MatrixDocumentType } from './models/matrix-document-types.model';
import { MatrixAllDocument } from './models/matrix-all-document.model';
import { MatrixDocument } from './models/matrix-document.model';

export class MatrixDocumentState {
  documentOptions: MatrixDocumentOptions[];
  allProducts: MatrixAllProduct[];
  planType: PlanType[];
  moneyManager: MoneyManagerMgmt[];
  moneyManagerProgramOption: MoneyManagerProgramOption[];
  matrixDocumentType: MatrixDocumentType[];
  allDocuments: MatrixAllDocument[];
  matrixDocument: MatrixDocument;
}

@Injectable({
  providedIn: 'root',
})
export class MatrixDocumentStore extends Store {
  constructor() {
    super(new MatrixDocumentState());
  }
}
