import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NfrpAlertMessage, NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { BinaryResponseEnum, FeatureTypeIDEnum, UserTypeIDEnum } from 'src/app/helpers/enum';
import { alertMessages } from 'src/app/helpers/common-constants';
import { CommunicationService } from 'src/app/services/communication.service';
import { GetPlanFees } from '../../../model/get-plan.model';
import { PlanDetailsService } from '../../../services/plan-details.service';

@Component({
  selector: 'lib-plan-fee',
  templateUrl: './plan-fee.component.html',
  styleUrls: ['./plan-fee.component.css'],
})
export class PlanFeeComponent extends NfrpDefaultGridComponent<GetPlanFees> implements OnInit, OnChanges, OnDestroy {
  @Output() editPlanFeeAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showEditOption: boolean;
  @Input() showArchiveOption: boolean;
  @Input() pendingStatus: boolean;
  @Input() status: boolean;
  planId: number;
  planFees: GetPlanFees = null;
  isViewPlanMode = true;
  isEditPlanMode = false;
  numberOfItems: number;
  showEditCorePlanOption = false;
  archivePlanStatus = false;
  private userEmail: string;
  private subscriptions = [];
  userTypeId: number;
  isPlanFeeVerified = false;
  editPlanFeeMessge: NfrpAlertMessage;
  verificationFees: GetPlanFees;
  isMnyRiaFeeVerified = false;
  userId: number;
  errorMessage: NfrpAlertMessage;

  constructor(
    private route: ActivatedRoute,
    private planDetailsService: PlanDetailsService,
    private communicationService: CommunicationService,
    nfrpGridService: NfrpGridService<GetPlanFees>
  ) {
    super(nfrpGridService);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : '';
    this.userId = JSON.parse(loginUser)?.userId;
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.getPlanFees();
    });
    //Archive plan event
    this.subscriptions.push(
      this.communicationService.archivePlanStatusEvent.subscribe((archivePlanStatus: boolean) => {
        this.archivePlanStatus = archivePlanStatus;
      })
    );
  }

  getPlanFees(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanFees(this.planId, this.userId, this.pendingStatus).subscribe((data: any) => {
        if(data?.planFeeScheduleId !== null) {
          this.planFees = data;
          this.getVerificationFees();
        }
        else {
          this.planFees = null;
        }
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  getVerificationFees(): void {
    this.subscriptions.push(
      this.planDetailsService.getVerificationFees(this.planId, this.pendingStatus).subscribe((data: GetPlanFees) => {
        this.verificationFees = data;
        if (this.verificationFees && this.planFees) {
          const planRiaFees = this.toValue(this.planFees.planFeeRias);
          const planMgrFees = this.toValue(this.planFees.planFeeManager);
          if (
            (this.verificationFees.riaFeeFlag === BinaryResponseEnum.NO) ||
            (this.verificationFees.mnyMgrFeeFlag === BinaryResponseEnum.NO)
          ) {
            this.isPlanFeeVerified = true;
          } else {
            this.isPlanFeeVerified = false;
          }
        }
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }
  toValue(value) {
    const val = +value.replace('%', '');
    return val;
  }
  editCorePlanDetails() {
    this.isViewPlanMode = false;
    this.isEditPlanMode = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.planId && changes.pendingStatus && (changes.pendingStatus.currentValue || changes.pendingStatus.previousValue)) {
      this.getPlanFees();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
