import { Component, Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-response';
import { LoginUser } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-user';
import { validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
@Injectable()
export class ForgotPasswordComponent implements OnDestroy {
  forgotPasswordForm: UntypedFormGroup;
  submitted = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  forgotPasswordResponse: LoginResponse;
  forgotPasswordRequest: LoginUser;
  emailPattern = validationPatterns.emailPattern;
  userEmail: string;
  private subscriptions = [];
  constructor(private fb: UntypedFormBuilder, private loginService: LoginService, private router: Router) {
    this.forgotPasswordForm = fb.group(
      {
        userName: ['', [Validators.required]],
      },
    );
  }
  get forgotPasswordControl() {
    return this.forgotPasswordForm.controls;
  }
  onClickSubmit(): void {
    this.submitted = true;
    if (this.forgotPasswordForm.valid) {
      this.forgotPasswordRequest = {
        userEmail: this.forgotPasswordForm.value.userName,
      };
      this.subscriptions.push(
        this.loginService.forgetPasswordData(this.forgotPasswordRequest).subscribe(
          (response: any) => {
            window.scroll(0, 0);
            this.successMessage = { message: response.status, details : [] };
            this.router.navigate(['/forgot-password-link']);
          },
          (error) => {
            window.scroll(0, 0);
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
  }
  ngOnDestroy(): void {
    for (const subscriptions$ of this.subscriptions) {
      subscriptions$.unsubscribe();
    }
  }
}
