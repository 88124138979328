<div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
  <nfrp-alert alertId="plan-trustee-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
</div>
<ul
  id="trustee-params"
  summary="trustee"
  class="ul-li-view-edit table stripes no-margin"
  style="border: 1px solid #9c9c9c"
>
  <li class="head" [ngClass]="{'pending-status-bg':pendingStatus}">
    <div class="col-sm-12 padding-left">
      <b>Trustee</b>
    </div>
  </li>
  <li>
    <div class="col-sm-6 padding-left" *ngIf="trusteeObj">
      <span id="trustee-value">
        {{ trusteeObj?.trusteeLastName | nfrpNormalizeValue : '' }},
        {{ trusteeObj?.trusteeFirstName | nfrpNormalizeValue : '' }}
      </span>
    </div>
  </li>
  <!-- *ngIf="showEditCorePlanOption||showArchiveOption" -->
  <li *ngIf="!archivePlanStatus && !isMoneyMgrUser">
    <div class="col-sm-12 padding-left">
      <b>
        <a href="javascript:void(0);" class="subLink" *ngIf="trusteeObj && status" routerLink="trustee/edit">Edit </a
        ><span *ngIf="trusteeObj && status">&nbsp; - &nbsp;</span>
        <a href="javascript:void(0);" class="subLink" *ngIf="trusteeObj && status" routerLink="trustee/remove">
          Remove
        </a>
        <a href="javascript:void(0);" class="subLink" *ngIf="!trusteeObj && status" routerLink="trustee/add"> Add Trustee</a></b
      >
    </div>
  </li>
</ul>
