<section class="container-fluid temp-color">
  <h1 id="main-title" class="page-title ng-binding">Booklet Documents</h1>
  <div class="row">
    <div class="col-sm-12">
      <div class="margin-bottom alert-container--error" *ngIf="errorMessage?.message?.length > 0">
        <div class="col-sm-12 no-padding">
          <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"></nfrp-alert>
        </div>
      </div>
      <ng-container>
        <div class="box compartment summary animation-fade-in">
          <form [formGroup]="ekpHomeForm" (ngSubmit)="onSubmit()">
            <div class="section__header">
              <h2 class="content-title">Enter a plan number to retrieve booklet documents:</h2>
            </div>
            <ul class="ul-li-view-edit no-margin padding-left">
              <li>
                <div class="col-sm-3 no-padding-top">
                  <input
                    class="form-input form-input--large"
                    type="text"
                    name="planNo"
                    size="50"
                    [ngClass]="{
                      'has-error': isControlInvalid('planNo')
                    }"
                    (keydown.space)="$event.preventDefault()"
                    formControlName="planNo"
                  />
                </div>
                <div>
                  <input
                    id="search-button"
                    class="button-primary button padding-right"
                    type="submit"
                    (click)="onSubmit()"
                    size="50"
                    value="Find Plan"
                  />
                </div>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (ekpHomeFormControl.planNo.touched || submitted) &&
                      (ekpHomeFormControl.planNo.errors?.pattern ||
                        ekpHomeFormControl.planNo.errors?.minlength ||
                        ekpHomeFormControl.planNo.errors?.maxlength)
                    "
                    errorId="planNo-valid-error"
                    [message]="getValidErrorMessage('Plan Number')"
                  >
                  </nfrp-inline-error>
                </div>
              </li>
            </ul>
          </form></div
      ></ng-container>

      <!-- *ngIf="createDoc && !planNoRecord" -->
      <ng-container *ngIf="createDoc">
        <div class="box compartment summary animation-fade-in">
          <div class="section__header" role="button">
            <h2 class="content-title"><b>Select Documents to Print for plan: </b></h2>
            <i id="ul-li-view-header-chevron"></i>
            <a>{{ ekpResponse?.planNumber }} : {{ ekpResponse?.planLabel }}</a>
          </div>

          <div class="content form-group">
            <div class="section__body">
              <div *ngFor="let ekp of planNumberView; let i = index">
                <label for="ekpDoc">
                  <input type="checkbox" [value]="ekp.documentId" (change)="onCheckboxChange($event, ekp)" />
                  <b>{{ ekp.documentName }} - </b>This document will be inserted into the back of the
                  {{ ekp.documentTypeName }}.
                </label>
              </div>
            </div>
          </div>

          <div class="col-sm-2 padding-bottom">
            <input type="button" value="Create Documents" (click)="createDocument()" class="button button-primary" />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="loading">
        <nfrp-spinner></nfrp-spinner>
      </ng-container>
    </div>
  </div>
</section>
