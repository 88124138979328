<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <div class="padding-sides" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="padding-sides" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container>
        <ul
          id="core-plan-params"
          summary="core plan"
          class="ul-li-view-edit table stripes no-margin border-common mt-10"
        >
          <li class="head">
            <div class="col-sm-12 padding-left font-size-15">
              <b>Add Approved Solicitors to Repository</b>
            </div>
          </li>
          <li>
            <div id="selectMnyMgr" class="margin-left">
              <div name="MassImport">
                <div class="unread-font-weight">
                  Select .csv file with approved solicitors to import. We will display a report once they are complete.
                </div>
                <br />
                <form
                  class="mt-10"
                  id="solicitor-form"
                  [formGroup]="solicitorForm"
                  (ngSubmit)="onSubmit()"
                  enctype="multipart/form-data"
                >
                  <div>
                    <div class="form-group no-margin">
                      <input
                        type="file"
                        accept=".csv"
                        id="add-select-file-input"
                        class="no-border-shadow no-padding-left"
                        name="file"
                        #fileInput
                        formControlName="file"
                        (change)="onChangeFile($event)"
                        (click)="updateFileStatus(fileInput)"
                        [ngClass]="{ 'has-error': isFileControlInvalid('file') }"
                      />
                      <div>
                        <nfrp-inline-error
                          *ngIf="fileSelectErrorMessage"
                          errorId="file-select-error"
                          [message]="fileSelectErrorMessage"
                        >
                        </nfrp-inline-error>
                      </div>
                    </div>
                    <br />
                    <div>
                      <input
                        type="submit"
                        name="AddMassSolicitors"
                        class="button-primary button"
                        value="Import Approved Solicitors"
                        [disabled]="isImportApprovedSolicitorsDisabled()"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <br />
              <div><b>OR</b></div>
              <br />

              <div>
                <form id="solicitor-form-name" [formGroup]="solicitorFormName">
                  <b>Approved Solicitor Name: </b
                  ><input type="text" formControlName="appSolicitorName" name="appSolicitorName" />
                  <input
                    type="button"
                    id="solicitor-button"
                    class="button-primary button"
                    name="AddMassSolicitors"
                    value="Add Approved Solicitor"
                    (click)="saveAppSolicitorName()"
                  />
                </form>
              </div>
              <div>
                <nfrp-inline-error
                  *ngIf="
                    (solicitorFormNameControl.appSolicitorName.touched || submitted) &&
                    solicitorFormNameControl.appSolicitorName.errors?.required
                  "
                  errorId="sol-name-valid-error"
                  [message]="getRequiredErrorMessage('Approved Solicitor Name')"
                >
                </nfrp-inline-error>
              </div>
            </div>
          </li>
          <div *ngIf="loading">
            <nfrp-spinner></nfrp-spinner>
          </div>
        </ul>
      </ng-container>
    </div>
  </div>
</section>
