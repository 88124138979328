import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NfrpAlertMessage, NavigationStore } from 'nfrp-shared-angular';
import { EditEmployeeSponsorResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/employee-sponsor.model';
import { GetPlans } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/get-plan.model';
import { FooterFeaturesService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/footer-features.service';
import { PlanDetailsService } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/services/plan-details.service';
import { Subscription } from 'rxjs';
import { statesList, modalMessage, actionText, validationPatterns, alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum, ActionTypeEnum, MoneyManagerIDEnum } from 'src/app/helpers/enum';
import { CommunicationService } from 'src/app/services/communication.service';
@Component({
  selector: 'edit-employee-sponsor',
  templateUrl: './edit-employee-sponsor.component.html',
  styleUrls: ['./edit-employee-sponsor.component.css'],
})
export class EditEmployeeSponsorComponent implements OnInit, OnDestroy {
  selectedAction: string;
  headerString: string;
  buttonString: string;
  submitted = false;
  loading = false;
  companyId: string;
  empSponsorForm: UntypedFormGroup;
  statesList = statesList;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  planId: number;
  userEmail: string;
  planDetails: GetPlans;
  formSubscription: Subscription;
  isPending: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public numberMaskPattern = { 0: { pattern: new RegExp('[0-9]') } };
  loggedInUserId: number;
  isPhoneRequired = false;

  modalOptions: any = {
    header: modalMessage.CONFIRMATION,
    message: modalMessage.REMOVE_EMPLOYEE_SPONSOR_MSG,
    showModal: false,
    noButtonValue: modalMessage.NO_TEXT,
    yesButtonValue: modalMessage.YES_TEXT,
  };

  buttonText: any = {
    save: actionText.SAVE,
    remove: actionText.REMOVE,
    add: actionText.ADD,
  };
  headerText: any = {
    save: actionText.UPDATE,
    remove: actionText.REMOVE,
    add: actionText.ADD,
  };
  private subscriptions = [];

  constructor(
    private fb: UntypedFormBuilder,
    private planDetailsService: PlanDetailsService,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private router: Router,
    private navigationStore: NavigationStore,
    private footerFeaturesService: FooterFeaturesService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.loggedInUserId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe(pending => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.selectedAction = params.action;
      this.getPlanDetails();
    });
    this.empSponsorForm = this.fb.group({
      planId: [null],
      companyLabel: [
        null,
        [Validators.required, Validators.maxLength(255), Validators.pattern(validationPatterns.cmpNamePattern)],
      ],
      companyAddrA: [
        null,
        [Validators.required, Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)],
      ],
      companyAddrB: [null, [Validators.maxLength(100), Validators.pattern(validationPatterns.addressPattern)]],
      companyCity: [
        null,
        [Validators.required, Validators.maxLength(100), Validators.pattern(validationPatterns.cityPattern)],
      ],
      companyState: [null, [Validators.required]],
      companyZip: [null, [Validators.required]],
      companyPhone: ['', [Validators.maxLength(30)]],
      companyFax: [null, [Validators.maxLength(30)]],
      companyEmail: [null, [Validators.maxLength(255), Validators.pattern(validationPatterns.emailPattern)]],
      lastUpdatedUserId: [this.loggedInUserId],
      isPlanSponsor: [null],
    });
    this.formSubscription = this.empSponsorForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });

    if (this.selectedAction) {
      this.setButtonText(this.selectedAction.toLowerCase());
    }
  }

  setButtonText(action) {
    if (action === ActionTypeEnum.EDIT) {
      this.buttonString = this.buttonText.save;
      this.headerString = this.headerText.save;
      this.getExistingData();
    } else if (action === ActionTypeEnum.REMOVE) {
      this.buttonString = this.buttonText.remove;
      this.headerString = this.headerText.remove;

      this.getExistingData();
    } else {
      this.buttonString = this.buttonText.add;
      this.headerString = this.headerText.add;
    }
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
        if (this.planDetails?.moneyManagerId === MoneyManagerIDEnum.STADION_MONEY_MGMT) {
          this.empSponsorForm.controls.companyPhone.setValidators([Validators.required]);
          this.empSponsorForm.controls.companyPhone.updateValueAndValidity();
          this.isPhoneRequired = true;
        }
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  getExistingData(): void {
    this.subscriptions.push(
      this.planDetailsService.getEmployeeSponsor(this.planId, this.isPending).subscribe((data: any) => {
        this.setExistingFormData(data.planEmployeeSponsorResponseList[0]);
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  setExistingFormData(data) {
    if (data) {
      this.companyId = data.companyId;
      this.empSponsorForm.patchValue({
        companyLabel: data.companyLabel,
        companyAddrA: data.companyAddrA,
        companyAddrB: data.companyAddrB,
        companyCity: data.companyCity,
        companyState: data.companyState,
        companyZip: data.companyZip,
        companyPhone: data.companyPhone,
        companyFax: data.companyFax,
        companyEmail: data.companyEmail,
        lastUpdatedUserId: this.loggedInUserId,
      });
    }
    this.navigationStore.set('formChanged', false);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.empSponsorForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }

  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  get empSponsorFormControl() {
    return this.empSponsorForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.empSponsorFormControl.planId.setValue(this.planId);
    // this.empSponsorFormControl.lastUpdatedUserId.setValue(this.userEmail);
    this.empSponsorFormControl.isPlanSponsor.setValue(1);

    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };

    if (this.selectedAction.toLowerCase() === ActionTypeEnum.EDIT) {
      this.editEmployeeSubmit();
    } else if (this.selectedAction.toLowerCase() === ActionTypeEnum.REMOVE) {
      this.modalOptions.showModal = true;
    } else {
      this.addEmployeeSubmit();
    }
  }
  noClicked() {
    this.modalOptions.showModal = false;
  }
  yesClicked() {
    this.modalOptions.showModal = false;
    this.removeEmployeeSubmit();
  }

  addEmployeeSubmit() {
    if (this.empSponsorForm.valid) {
      this.loading = true;
      this.subscriptions.push(
        this.planDetailsService.addEmployeeSponsor(this.planId, this.empSponsorForm.value, this.isPending).subscribe(
          (empSponsorResponse: EditEmployeeSponsorResponse) => {
            this.loading = false;
            window.scroll(0, 0);
            this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
            this.errorMessage = { message: '', details: [] };
            this.successMessage = { message: alertMessages.EMPLOYEE_SPONSOR_CREATED, details: [] };
          },
          (error: HttpErrorResponse) => {
            this.loading = false;
            window.scroll(0, 0);
            this.successMessage = { message: '', details: [] };
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  editEmployeeSubmit() {
    if (this.empSponsorForm.valid) {
      this.loading = true;
      const editEmployeeRequest = this.empSponsorForm.value;
      editEmployeeRequest.companyId = this.companyId;
      this.subscriptions.push(
        this.planDetailsService.updateEmployeeSponsor(this.planId, editEmployeeRequest).subscribe(
          (empSponsorResponse: EditEmployeeSponsorResponse) => {
            this.loading = false;
            window.scroll(0, 0);
            this.errorMessage = { message: '', details: [] };
            this.communicationService.getPlanStepsNavigation(this.planId, this.isPending);
            this.successMessage = { message: '', details: [] };
          },
          (error: HttpErrorResponse) => {
            this.loading = false;
            window.scroll(0, 0);
            this.successMessage = { message: '', details: [] };
            this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  removeEmployeeSubmit() {
    this.loading = true;
    const editEmployeeRequest = this.empSponsorForm.value;
    editEmployeeRequest.companyId = this.companyId;
    this.subscriptions.push(
      this.planDetailsService.removeEmployeeSponsor(this.planId, editEmployeeRequest.companyId, this.isPending).subscribe(
        (empSponsorResponse: EditEmployeeSponsorResponse) => {
          this.loading = false;
          window.scroll(0, 0);
          this.errorMessage = { message: '', details: [] };
          this.router.navigate(['/plan-summary', this.planId]);
          this.successMessage = { message: '', details: [] };
        },
        (error: HttpErrorResponse) => {
          window.scroll(0, 0);
          this.loading = false;
          this.successMessage = { message: '', details: [] };
          this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
        }
      )
    );
    this.navigationStore.set('formChanged', false);
  }

  onCancel() {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
    this.router.navigate(['/plan-summary', this.planId]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.formSubscription?.unsubscribe();
  }
}
