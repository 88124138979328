import { Injectable } from '@angular/core';
import { Store } from './store';

export class CCLandingState {}

@Injectable({
  providedIn: 'root',
})
export class CCLandingStore extends Store {
  constructor() {
    super(new CCLandingState());
  }
}
