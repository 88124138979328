import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { NfrpAlertMessage, NfrpDefaultGridComponent, NfrpGridService } from 'nfrp-shared-angular';
import { ActivatedRoute } from '@angular/router';
import { TrackPlanSpnserDocs } from '../../../model/get-plan.model';
import { PlanDetailsService } from '../../../services/plan-details.service';


@Component({
  selector: 'plan-sponsor-docs',
  templateUrl: './plan-sponsor-docs.component.html',
  styleUrls: ['./plan-sponsor-docs.component.css'],
})
export class PlanSponsorDocsComponent extends NfrpDefaultGridComponent<TrackPlanSpnserDocs>
  implements OnInit, OnDestroy {
  @Input() pendingStatus: boolean;
  planId: number;
  dataSource: TrackPlanSpnserDocs[];
  numberOfItems: number;
  private subscriptions = [];
  private userTypeId: number;
  isAdmin: boolean;
  errorMessage: NfrpAlertMessage;
  successMessage: NfrpAlertMessage;
  userId: number;
  constructor(
    private route: ActivatedRoute,
    private planDetailsService: PlanDetailsService,
    nfrpGridService: NfrpGridService<TrackPlanSpnserDocs>
  ) {
    super(nfrpGridService);
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : null;
    this.isAdmin = this.userTypeId === 1;
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.trackPlanSpnserDocs();
    });
  }

  trackPlanSpnserDocs() {
    this.subscriptions.push(
      this.planDetailsService
        .trackPlanSpnserDocs(this.planId)
        .pipe(
          tap((trackFormDocumentList) => {
            window.scroll(0, 0);
            this.dataSource = trackFormDocumentList?.trackFormDocumentList;
            this.numberOfItems = this.dataSource?.length;
            if (this.dataSource) {
              this.setupGrid();
            }
          })
        )
        .subscribe()
    );
  }

  markRecieved(formId:number): void {
    this.subscriptions.push(
      this.planDetailsService.markUserRecieved(this.userId,formId).subscribe((message: any) => {
        this.successMessage = { message: message.status, details: [] };
        this.errorMessage = { message: '', details: [] };
        this.trackPlanSpnserDocs();
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
        this.successMessage = { message: '', details: [] };
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
