<ng-container *ngIf="trackPlans">
  <div class="padding-top">
    <ul style="border: 1px solid #9c9c9c;" class="ul-li-view-edit">
      <li [ngClass]="{'pending-status-bg':pendingStatus}">
        <div class="col-sm-9 padding-left">
          <b>Plan Dates</b>
        </div>
      </li>
    </ul>
    <ul class="ul-li-view-edit border-common">
      <li>
        <div class="col-sm-6 padding-left">
          <span id="date-created-label">Created</span>
        </div>
        <div class="col-sm-6 padding-left">
          <span id="date-created-value">{{ trackPlans?.createdOn }} - {{ trackPlans?.createdBy }}</span>
        </div>
      </li>
      <li>
        <div class="col-sm-6 padding-left">
          <span id="last-updated-label">Last Updated</span>
        </div>
        <div class="col-sm-6 padding-left">
          <span id="last-updated-value">{{ trackPlans?.lastUpdatedOn }} - {{ trackPlans?.lastUpdatedBy }}</span>
        </div>
      </li>
      <li>
        <div class="col-sm-6 padding-left">
          <span id="activated-label">Activated</span>
        </div>
        <div class="col-sm-6 padding-left">
          <span id="activated-value">{{ trackPlans?.activatedOn }} - {{ trackPlans?.activatedBy }}</span>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
