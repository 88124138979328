<section class="container-fluid temp-color">
  <div class="row">
    <div class="col-sm-2">
      <security-navigation class="sticky-nav"> </security-navigation>
    </div>
    <div class="col-sm-10">
      <h1 id="main-title" class="page-title ng-binding">
        <span
          *ngIf="(planDetails?.planIsTMSActive === 1 || planDetails?.isTrackActive === 1) && planDetails?.planStatus === 1"
          ><i class="nw-icon-check f-green"></i
        ></span>
        {{ planDetails?.planLabel }} - ({{ planDetails?.planCaseNumber }})
        <span *ngIf="planDetails?.planArchived === 0"><i class="icon-a-symbol f-red"></i></span>
      </h1>
      <div class="margin-bottom" *ngIf="successMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-success" type="success" [message]="successMessage" [page]="false"> </nfrp-alert>
      </div>
      <div class="margin-bottom" *ngIf="errorMessage?.message?.length > 0">
        <nfrp-alert alertId="add-mm-error" [message]="errorMessage" type="error" [page]="false"> </nfrp-alert>
      </div>
      <ng-container *ngIf="!loading">
        <form [formGroup]="trusteeForm" (ngSubmit)="onSubmit()">
          <ul id="representative-params" summary="employee sponsor" class="ul-li-view-edit table stripes no-margin">
            <li class="head" [ngClass]="{'pending-status-bg':isPending}">
              <div class="col-sm-12 padding-left">
                <b>{{ headerString }} Plan Trustee Information</b>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Trustee First Name*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteeFirstName"
                  formControlName="trusteeFirstName"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteeFirstName')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeFirstName.touched || submitted) &&
                      trusteeFormControl.trusteeFirstName.errors?.required
                    "
                    errorId="trusteeFirstName-required"
                    [message]="getRequiredErrorMessage('Trustee First Name')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeFirstName.touched || submitted) &&
                      trusteeFormControl.trusteeFirstName.errors?.pattern
                    "
                    errorId="trusteeFirstName-valid-error"
                    [message]="getValidErrorMessage('Trustee First Name')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Trustee Last Name*</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteeLastName"
                  formControlName="trusteeLastName"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteeLastName')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeLastName.touched || submitted) &&
                      trusteeFormControl.trusteeLastName.errors?.required
                    "
                    errorId="trusteeLastName-required"
                    [message]="getRequiredErrorMessage('Trustee Last Name')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeLastName.touched || submitted) &&
                      trusteeFormControl.trusteeLastName.errors?.pattern
                    "
                    errorId="trusteeLastName-valid-error"
                    [message]="getValidErrorMessage('Trustee Last Name')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Address Line One</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteeAddrA"
                  formControlName="trusteeAddrA"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteeAddrA')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeAddrA.touched || submitted) &&
                      trusteeFormControl.trusteeAddrA.errors?.required
                    "
                    errorId="address-line-one-required"
                    [message]="getRequiredErrorMessage('Address Line One')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeAddrA.touched || submitted) &&
                      trusteeFormControl.trusteeAddrA.errors?.pattern
                    "
                    errorId="address-line-one-valid-error"
                    [message]="getValidErrorMessage('Address Line One')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Address Line Two</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteeAddrB"
                  formControlName="trusteeAddrB"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteeAddrB')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeAddrB.touched || submitted) &&
                      trusteeFormControl.trusteeAddrB.errors?.pattern
                    "
                    errorId="address-line-two-valid-error"
                    [message]="getValidErrorMessage('Address Line Two')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">City</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteeCity"
                  formControlName="trusteeCity"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteeCity')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeCity.touched || submitted) &&
                      trusteeFormControl.trusteeCity.errors?.required
                    "
                    errorId="trusteeCity-required"
                    [message]="getRequiredErrorMessage('City')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeCity.touched || submitted) &&
                      trusteeFormControl.trusteeCity.errors?.pattern
                    "
                    errorId="trusteeCity-valid-error"
                    [message]="getValidErrorMessage('City')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">State</div>
              <div class="col-sm-8 padding-left">
                <select
                  name="trusteeState"
                  id="trusteeState"
                  formControlName="trusteeState"
                  [ngClass]="{
                    'has-error margin-right-20': isControlInvalid('trusteeState')
                  }"
                >
                  <option [ngValue]="null">-- Choose from List --</option>
                  <option *ngFor="let state of statesList" [value]="state.value">{{ state.label }}</option>
                </select>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeState.touched || submitted) &&
                      trusteeFormControl.trusteeState.errors?.required
                    "
                    errorId="trusteeState-required"
                    [message]="getRequiredErrorMessage('State')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Zip</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteeZip"
                  formControlName="trusteeZip"
                  size="20"
                  mask="00000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteeZip')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeZip.touched || submitted) &&
                      trusteeFormControl.trusteeZip.errors?.required
                    "
                    errorId="trusteeZip-required"
                    [message]="getRequiredErrorMessage('Zip')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      ((trusteeFormControl.trusteeZip.touched || submitted) &&
                        trusteeFormControl.trusteeZip.errors?.maxLength) ||
                      trusteeFormControl.trusteeZip.errors?.pattern
                    "
                    errorId="trusteeZip-valid-error"
                    [message]="getValidErrorMessage('Zip')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Phone</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteePhone"
                  formControlName="trusteePhone"
                  size="25"
                  mask="(000)-000-0000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteePhone')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteePhone.touched || submitted) &&
                      trusteeFormControl.trusteePhone.errors?.required
                    "
                    errorId="trusteePhone-required"
                    [message]="getRequiredErrorMessage('Phone')"
                  >
                  </nfrp-inline-error>
                  <nfrp-inline-error
                    *ngIf="
                      ((trusteeFormControl.trusteePhone.touched || submitted) &&
                        trusteeFormControl.trusteePhone.errors?.maxLength) ||
                      trusteeFormControl.trusteePhone.errors?.pattern
                    "
                    errorId="trusteePhone-valid-error"
                    [message]="getValidErrorMessage('Phone')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">Fax</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteeFax"
                  formControlName="trusteeFax"
                  size="25"
                  mask="(000)-000-0000"
                  [patterns]="numberMaskPattern"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteeFax')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      ((trusteeFormControl.trusteeFax.touched || submitted) &&
                        trusteeFormControl.trusteeFax.errors?.maxLength) ||
                      trusteeFormControl.trusteeFax.errors?.pattern
                    "
                    errorId="trusteeFax-valid-error"
                    [message]="getValidErrorMessage('Fax')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <li>
              <div class="col-sm-2 padding-left">E-mail</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="text"
                  name="trusteeEmail"
                  formControlName="trusteeEmail"
                  size="38"
                  [ngClass]="{
                    'has-error': isControlInvalid('trusteeEmail')
                  }"
                />
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeEmail.touched || submitted) &&
                      trusteeFormControl.trusteeEmail.errors?.pattern
                    "
                    errorId="trusteeEmail-valid-error"
                    [message]="getValidErrorMessage('Email')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li>
            <!-- <li>
              <div class="col-sm-2 padding-left">This Trustee is</div>
              <div class="col-sm-8 padding-left">
                <input
                  type="radio"
                  formControlName="trusteeActive"
                  id="trusteeActiveActive"
                  [value]="1"
                  name="trusteeActive"
                />
                <label for="trusteeActiveActive">Active</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  formControlName="trusteeActive"
                  id="trusteeActiveInActive"
                  [value]="0"
                  name="trusteeActive"
                />
                <label for="trusteeActiveInActive">Inactive</label>
                <div>
                  <nfrp-inline-error
                    *ngIf="
                      (trusteeFormControl.trusteeActive.touched || submitted) &&
                      trusteeFormControl.trusteeActive.errors?.pattern
                    "
                    errorId="trusteeActive-valid-error"
                    [message]="getValidErrorMessage('This Trustee is')"
                  >
                  </nfrp-inline-error>
                </div>
              </div>
            </li> -->
          </ul>
          <div class="row margin-top">
            <div class="col-sm-1 no-padding">
              <input
                id="search-button"
                class="button-primary button hover-left margin-left"
                type="submit"
                size="40"
                value="{{ buttonString }}"
              />
            </div>
            <div class="col-sm-10 no-padding"></div>
            <div class="col-sm-1 no-padding">
              <input
                type="button"
                class="button button-secondary hover-right margin-right"
                (click)="onCancel()"
                value="Cancel"
              />
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="loading">
        <nfrp-spinner></nfrp-spinner>
      </ng-container>
    </div>
  </div>
</section>

<nfrp-navigate-away-modal></nfrp-navigate-away-modal>

<nfrp-default-modal
  [header]="modalOptions.header"
  [message]="modalOptions.message"
  [noButtonValue]="modalOptions.noButtonValue"
  [yesButtonValue]="modalOptions.yesButtonValue"
  [showModal]="modalOptions.showModal"
  (noClicked)="noClicked()"
  (yesClicked)="yesClicked()"
>
</nfrp-default-modal>
