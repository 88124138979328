import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EditAddRepComponent } from './plan-rep/edit/edit-add-rep.component';
import { PlanSummaryComponent } from './plan-summary.component';
import { EditEmployeeSponsorComponent } from './employee-sponsor/edit/edit-employee-sponsor.component';
import {
  UnsavedChangesGuard,
  NfrpNavigationModule,
  NfrpWidgetsModule,
  NfrpGridUtilsModule,
  NfrpPipesModule,
} from 'nfrp-shared-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from '@ag-grid-community/angular';
import { EditTrusteeComponent } from './trustee/edit/edit-trustee.component';
import { PlanFeeScheduleComponent } from './plan-fee-schedule/plan-fee-schedule.component';
import { PlanSolicitorComponent } from './plan-solicitor/plan-solicitor.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CorePlanComponent } from './core-plan/core-plan.component';
import { AddViewNotesComponent } from './plan-notes/add-view-notes/add-view-notes.component';
import { PlanDocsComponent } from './plan-docs/plan-docs.component';
import { EnrollDocComponent } from '../enroll-doc/enroll-doc.component';
import { PrintSponsorDocComponent } from './plan-docs/print-sponsor-doc/print-sponsor-doc.component';
import { PrintEnrollDocComponent } from '../enroll-doc/print-enroll-doc/print-enroll-doc.component';
import { PrintDocsComponent } from './print-docs/print-docs.component';
import { NfrpMasRetailPrivateAngularModule } from '../../nfrp-mas-retail-private-angular.module';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'plan-summary/:planId',
    component: PlanSummaryComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Plan Summary',
    }, 
  },
  {
    path: 'employee-sponsor/:action',
    component: EditEmployeeSponsorComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Employee Sponsor',
    },
  },
  {
    path: 'trustee/:action',
    component: EditTrusteeComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Trustee',
    },
  },
  {
    path: 'plan-fee-schedule/:action',
    component: PlanFeeScheduleComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Plan Fee Schedule',
    },
  },
  {
    path: 'representative/:action',
    component: EditAddRepComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Plan Representative',
    },
  },
  {
    path: 'representative/:action/:repId',
    component: EditAddRepComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Plan Representative',
    },
  },
  {
    path: 'plan-solicitor/:action',
    component: PlanSolicitorComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Plan Solicitor',
    },
  },
  {
    path: 'core-plan',
    component: CorePlanComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Create Plan',
    },
  },
  {
    path: 'print-sponsor-doc/:planId',
    component: PrintSponsorDocComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Print Sponsor Documents',
    },
  },
  {
    path: 'print-docs/:planId',
    component: PrintDocsComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Print Documents',
    },
  },
  {
    path: 'plan-docs/:planId',
    component: PlanDocsComponent,
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Plan Sponsor Documents',
    },
  },
  {
    path: 'print-enroll-doc/:planId',
    component: PrintEnrollDocComponent,
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Print Enrollment Docs',
    },
  },
  {
    path: 'enroll-doc/:planId',
    component: EnrollDocComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Enrollment Documents',
    },
  },
  {
    path: 'add-notes',
    component: AddViewNotesComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumb: 'Add Notes',
    },
  },
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AgGridModule,
    NfrpWidgetsModule,
    NfrpGridUtilsModule,
    NfrpPipesModule,
    ReactiveFormsModule,
    NfrpNavigationModule,
    NgxPaginationModule,
    RouterModule.forChild(routes),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlanSummaryModule {}
