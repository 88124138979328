import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationStore, NfrpAlertMessage } from 'nfrp-shared-angular';
import { Subscription } from 'rxjs';
import { alertMessages } from 'src/app/helpers/common-constants';
import { FeatureTypeIDEnum } from 'src/app/helpers/enum';
import { SearchPlanSponsorDoc, GetPlans } from '../../model/get-plan.model';
import { PlanDetailsService } from '../../services/plan-details.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { EkpDetail } from '../../model/ekp-detail';

@Component({
  selector: 'enroll-doc',
  templateUrl: './enroll-doc.component.html',
  styleUrls: ['./enroll-doc.component.css'],
})
export class EnrollDocComponent implements OnInit {
  userEmail: string;
  planId: number;
  createDoc = false;
  formSubscription: Subscription;
  searchDoc: SearchPlanSponsorDoc;
  createDocMsg = false;
  enrollmentDoc: EkpDetail[];
  submitted = false;
  loading = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  planNumber: string;
  userTypeId: any;
  userId: string;
  enrollDocForm: UntypedFormGroup;
  enrollDisabled: boolean;
  planLabel: string;
  showNav = true;
  planDetails: GetPlans;
  isPending: boolean;
  hasPendingChanges: boolean;
  private subscriptions = [];
  private planCaseNumber: string;
  private printFlag: any;
  documentsByNamesToHide = ['blank_Acknowledgement_and_Questionnaire', 'blank_Participant_Agreement']

  constructor(
    private route: ActivatedRoute,
    private planDetailsService: PlanDetailsService,
    private router: Router,
    private navigationStore: NavigationStore,
    private fb: UntypedFormBuilder,
    private communicationService: CommunicationService
  ) {}
  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userId = loginUser ? JSON.parse(loginUser)?.userId : 0;
    this.userTypeId = loginUser ? JSON.parse(loginUser)?.userTypeId : 0;
    this.planNumber = sessionStorage.getItem('planNumber');
    this.planLabel = sessionStorage.getItem('planLabel');
    this.userEmail = loginUser ? JSON.parse(loginUser)?.userEmail : '';
    this.subscriptions.push(
      this.communicationService.getPendingStatus().subscribe(pending => {
        this.isPending = pending;
      })
    );
    this.route.params.subscribe((params) => {
      this.planId = params.planId;
      this.getPlanDetails();
    });
    this.enrollDocForm = this.fb.group({
      planNumber: this.planNumber,
      userId: this.userId,
      documentDetails: this.fb.array([]),
      isSponsorDocument: 0,
    });
    this.formSubscription = this.enrollDocForm?.valueChanges.subscribe((form) => {
      this.navigationStore.set('formChanged', true);
    });
    this.enrollmentDocs();
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.enrollDocForm.get(controlName);
    return control.invalid && (control.touched || this.submitted);
  }
  getRequiredErrorMessage(preText: string): string {
    return `${preText} is required`;
  }
  getValidErrorMessage(preText: string): string {
    return `Enter a valid ${preText}`;
  }

  getPlanDetails(): void {
    this.subscriptions.push(
      this.planDetailsService.getPlanDetails(this.planId, this.userEmail, this.isPending).subscribe((data: GetPlans) => {
        this.planDetails = data.planOverviews;
        this.hasPendingChanges = data.planOverviews.hasPendingChanges;
      },
      (error) => {
        this.errorMessage = { message: error, details: [] };
      })
    );
  }

  onCheckboxChange($event, edp) {
    const documentDetails: UntypedFormArray = this.enrollDocForm.get('documentDetails') as UntypedFormArray;
    if ($event.target.checked) {
      const enrollRequest = {
        documentId: edp.documentId,
        docName: edp.documentName,
      };
      documentDetails.push(new UntypedFormControl(enrollRequest));
    } else {
      const index = documentDetails.controls.findIndex((x) => x.value === $event.target.value);
      documentDetails.removeAt(index);
    }
  }

  createDocument(): void {
    this.submitted = true;
    if (this.enrollDocForm.valid) {
      this.loading = true;
      const planSponsorRequest = this.enrollDocForm.value;
      this.subscriptions.push(
        this.planDetailsService.saveSponsorDocument(planSponsorRequest).subscribe(
          (res) => {
            window.scroll(0, 0);
            this.loading = false;
            this.errorMessage = { message: '', details: [] };
            this.successMessage = { message: res.status, details: [] };
            this.router.navigate(['/print-enroll-doc', this.planId]);
          },
          (error) => {
            window.scroll(0, 0);
            this.loading = false;
            this.successMessage = { message: '', details: [] };
            if (error) {
              this.errorMessage = { message: error, details: [] };            
            } else {
              this.errorMessage = { message: alertMessages.SYSTEM_UNAVAILABLE_MESSAGE, details: [] };
            }
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
    this.navigationStore.set('formChanged', false);
  }

  enrollmentDocs(): void {
    window.scroll(0, 0);
    if (this.enrollDocForm.valid) {
      this.loading = true;
      this.enrollDisabled = true;
      this.createDocMsg = false;
      this.subscriptions.push(
        this.planDetailsService.enrollmentDocs(this.planNumber, this.isPending, this.hasPendingChanges).subscribe(
          (res) => {
            this.enrollmentDoc = this.removeDocumentsFromDisplay(this.documentsByNamesToHide, res.searchDocumentResponses);
            if(this.enrollmentDoc.length > 0) {              
              this.enrollDisabled = false;
              this.printFlag = res.printFlag;
              this.createDoc = true;
              this.loading = false;
            }
            else {
              this.errorMessage = { message: alertMessages.NO_DOCUMENT_FOUND, details: [] };
            }
          },
          (error) => {
            this.createDoc = false;
            this.loading = false;
            window.scroll(0, 0);
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    }
  }

  removeDocumentsFromDisplay(documentNameList: string[], documentList: EkpDetail[]): EkpDetail[] {
    documentNameList.forEach((docName) => {
      documentList = documentList.filter((doc) => doc.documentName !== docName);
    })
    return documentList;
  }

  onClickCancel() {
    this.loading = false;
    if (this.userTypeId === 29) {
      this.router.navigate(['/ekp-home']);
    } else if (this.userTypeId === 69) {
      this.router.navigate(['/cc-home']);
    } else {
      this.router.navigate(['/plan-summary', this.planId]);
    }
    this.navigationStore.set('formChanged', false);
  }
  showLeftNav() {
    if (this.userTypeId === 69 || this.userTypeId === 29) {
      this.showNav = false;
    }
  }
}
