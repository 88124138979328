import { PasswordValidators, RESET_VALIDATION_PATTERNS } from '../../helpers/password-validators';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NfrpAlertMessage } from 'nfrp-shared-angular';
import { LoginResponse } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-response';
import { LoginUser } from 'projects/nfrp-mas-retail-private-angular/src/nfrp-mas-retail/model/login-user';
import { alertMessages } from 'src/app/helpers/common-constants';
import { CommunicationService } from 'src/app/services/communication.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetForm: UntypedFormGroup;
  submitted = false;
  successMessage: NfrpAlertMessage;
  errorMessage: NfrpAlertMessage;
  passwordPattern = RESET_VALIDATION_PATTERNS.resetPasswordPattern;
  resetPasswordResponse: LoginResponse;
  resetPasswordRequest: LoginUser;
  userEmail: string;
  private subscriptions = [];
  constructor(
    fb: UntypedFormBuilder,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private communicationService: CommunicationService
  ) {
    this.resetForm = fb.group(
      {
        newPassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: PasswordValidators.passwordsShouldMatch,
      }
    );
  }
  get newPassword() {
    return this.resetForm.get('newPassword');
  }
  get confirmPassword() {
    return this.resetForm.get('confirmPassword');
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const loginUser = sessionStorage.getItem('loginUser');
    this.userEmail = (loginUser && loginUser !== 'null') ? JSON.parse(loginUser)?.userEmail : this.communicationService.userEmail ? this.communicationService.userEmail:'';
  }

  onClickSubmit(): void {
    this.submitted = true;
    if (this.resetForm.valid) {
      this.resetPasswordRequest = {
        userEmail: this.userEmail,
        token: this.resetForm.value.confirmPassword,
      };
      this.subscriptions.push(
        this.loginService.changePasswordData(this.resetPasswordRequest).subscribe(
          (response: any) => {
            window.scroll(0, 0);
            this.successMessage = { message: response.status, details: []}
            this.communicationService.userLoggedInStatus(false);
            sessionStorage.removeItem('loginUser');
            this.router.navigate(['/reset-password-info']);           
          },
          (error) => {
            window.scroll(0, 0);
            this.errorMessage = { message: error, details: [] };
          }
        )
      );
    } else {
      this.errorMessage = { message: alertMessages.USER_ERROR_MESSAGE, details: [] };
    }
  }

  resetPasswordInfo(): void {
    this.errorMessage = { message: '', details: [] };
    this.successMessage = { message: '', details: [] };
  }
  ngOnDestroy(): void {
    for (const subscriptions$ of this.subscriptions) {
      subscriptions$.unsubscribe();
    }
  }
}
