import { Injectable } from '@angular/core';
import {Store} from '../../store';

export class PlanState {}

@Injectable({
  providedIn: 'root',
})
export class PlanStore extends Store {
  constructor() {
    super(new PlanState());
  }
}
