import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatrixDocumentStore } from '../matrix-document.store';
import { MatrixDocumentOptions, MatrixDynamicOptions, MatrixResponse } from '../models/matrix-document-options.model';
import { MatrixAllProduct } from '../models/matrix-all-product.model';
import { PlanType } from '../models/plan-type.model';
import { MoneyManagerMgmt } from '../../nfrp-mas-retail/model/mny-mgr-mgmt';
import { MoneyManagerProgramOption } from '../models/money-manager-program-option.model';
import { MatrixDocumentType, MatrixSearchResponse } from '../models/matrix-document-types.model';
import {
  DocumentMoneyManagerPayload,
  DocumentOptionPayload,
} from '../models/add-matrix-document-payload.model';
import { MatrixDocument } from '../models/matrix-document.model';
import { MatrixWithOrderResponse } from '../models/matrix-edit-document.model';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorUtilsService } from 'src/app/services/error-utils.service';
import { MoneyManager } from '../../nfrp-mas-retail/model/money-manager-data';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class MatrixDocumentService {
  documentOptions$: Observable<MatrixDocumentOptions[]> = this.matrixDocumentStore.select('documentOptions');
  allProducts$: Observable<MatrixAllProduct[]> = this.matrixDocumentStore.select('allProducts');
  planType$: Observable<PlanType[]> = this.matrixDocumentStore.select('planType');
  moneyManager$: Observable<MoneyManagerMgmt[]> = this.matrixDocumentStore.select('moneyManager');
  moneyManagerProgramOption$: Observable<MoneyManagerProgramOption[]> = this.matrixDocumentStore.select(
    'moneyManagerProgramOption'
  );
  matrixDocumentType$: Observable<MatrixDocumentType[]> = this.matrixDocumentStore.select('matrixDocumentType');
  matrixDocument$: Observable<MatrixDocument> = this.matrixDocumentStore.select('matrixDocument');
  moneyManagerProgramOptionMap = new Map();
  moneyMgrProgramOption = [];
  private headers: HttpHeaders;
  moneyManagerProgramOptionsList = [];
  baseUrl = this.config.config?.masRetailPrivateProcessing;

  constructor(
    private http: HttpClient,
    private matrixDocumentStore: MatrixDocumentStore,
    private formBuilder: UntypedFormBuilder,
    private errorUtilsService: ErrorUtilsService,
    private config: ConfigService
  ) {
    this.headers = new HttpHeaders({
      accessControlAllowOrigin: '*',
      print2Image: 'true',
      proxyAlternateURI: 'http://localhost:8080/',
    });
  }

  getAllDocumentOptions(): Observable<MatrixDocumentOptions[]> {
    const serviceURL = this.baseUrl +'/matrix/get-all-document-options';
    return this.http.get<MatrixDocumentOptions[]>(serviceURL).pipe(
      tap((documentOptions: MatrixDocumentOptions[]) => {
        this.matrixDocumentStore.set('documentOptions', documentOptions);
      })
    );
  }

  getAllProduct(): Observable<MatrixAllProduct[]> {
    const serviceURL = this.baseUrl +'/plan/get-all-product';
    return this.http.get<MatrixAllProduct[]>(serviceURL).pipe(
      tap((allProducts: MatrixAllProduct[]) => {
        this.matrixDocumentStore.set('allProducts', allProducts);
      })
    );
  }

  getAllPlanType(): Observable<PlanType[]> {
    const serviceURL = this.baseUrl +'/plan/get-all-plan-type';
    return this.http.get<PlanType[]>(serviceURL).pipe(
      tap((planType: PlanType[]) => {
        this.matrixDocumentStore.set('planType', planType);
      })
    );
  }

  getMoneyManagerMatrix(emailId: string): Observable<MoneyManager[]> {
    const params = new HttpParams()
    .set('user-email', emailId ? emailId : '')
    return this.http.get<MoneyManager[]>(this.baseUrl +`/manager/matrix`, { params }).pipe(
      map((moneyManagers: MoneyManager[]): MoneyManager[] =>
        moneyManagers?.filter((moneyManager: MoneyManager) => moneyManager.active === 1)
      ),
      tap((moneyManager: MoneyManager[]) => {
        moneyManager.sort((a, b) => this.sortMoneyManager(a, b));
        this.matrixDocumentStore.set('moneyManager', moneyManager);
      }),catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error)))
    );
  }

  getAllDocumentType(): Observable<MatrixDocumentType[]> {
    const serviceURL = this.baseUrl +'/matrix/get-all-document-type';
    return this.http.get<MatrixDocumentType[]>(serviceURL).pipe(
      tap((matrixDocumentType: MatrixDocumentType[]) => {
        this.matrixDocumentStore.set('matrixDocumentType', matrixDocumentType);
      })
    );
  }

  sortMoneyManager(mnyMgr1: MoneyManager, mnyMgr2: MoneyManager): number {
    if (mnyMgr1.wallOff === null) {
      return 1;
    } else if (mnyMgr2.wallOff === null) {
      return -1;
    } else {
      return mnyMgr1.wallOff - mnyMgr2.wallOff;
    }
  }

  getAllMoneyManagerProgramsOption(moneyManagerIdList: number[]): void {
    const requestPayload = {
      mnyMgrIds: moneyManagerIdList,
    };
    if (moneyManagerIdList.length) {
      const mnyMgrPgmOptionList = [];
      mnyMgrPgmOptionList.push(
        this.http.get<MoneyManagerProgramOption>(this.baseUrl +'/matrix/programs')
      );
      forkJoin(mnyMgrPgmOptionList)
        .pipe(
          map((results: any) => {
            this.moneyMgrProgramOption = results[0].mnyMgrPrograms;
          })
        )
        .subscribe();
    }
  }
  getMoneyManagerProgramsOption(moneyManagerIdList: number[]): MoneyManagerProgramOption[] {
    const moneyManagerProgramOptionsList: MoneyManagerProgramOption[] = [];    
    const mnyMgIds = this.moneyMgrProgramOption.map(item => item.mnyMgrId.mnyMgrId);
    if (moneyManagerIdList?.length) {
      moneyManagerIdList.forEach((mnyMgrId) => {
        this.moneyMgrProgramOption.forEach((oneItem) => {
          if (mnyMgrId === oneItem?.mnyMgrId?.mnyMgrId) {
            moneyManagerProgramOptionsList.push(oneItem);
          }
        });
        const checkMnyMgrInOptions = mnyMgIds.includes(mnyMgrId);
        const stdPrgmOption = {
          mnyMgrId: {
            mnyMgrId: mnyMgrId,
            mnyMgrProgramCode: 'std',
          },
          mnyMgrProgramLabel: 'Standard Program',
          walledOff: 0,
        }
        if(!checkMnyMgrInOptions) {
          moneyManagerProgramOptionsList.push(stdPrgmOption);
        }
      });
    }
    return moneyManagerProgramOptionsList;
  }
  createMatrixFromGroup(documentOptions: MatrixDocumentOptions[]): UntypedFormGroup {
    const matrixForm = this.formBuilder.group({
      file: [null, Validators.required],
      displayName: [null, Validators.required],
      filePath: [null, Validators.required],
      documentTypeId: [null, Validators.required],
      providerProductId: [null, Validators.required],
      planTypeId: [null, Validators.required],
      mnyMgrId: [null, Validators.required],
      mnyMgrProgramCode: [null, Validators.required],
      providerProductSelectAll: [null],
      planTypeSelectAll: [null],
      mnyMgrSelectAll: [null],
      mnyMgrProgramCodeSelectAll: [null],
    });

    documentOptions.forEach((documentOption: MatrixDocumentOptions) => {
      matrixForm.addControl(documentOption.optionFormName, this.formBuilder.control(null, [Validators.required]));
    });
    return matrixForm;
  }

  reverseMatrixFromGroup(documentOptions: MatrixDocumentOptions[]): UntypedFormGroup {
    const matrixForm = this.formBuilder.group({
      planStatus: [null, Validators.required],
      providerProductId: [null, Validators.required],
      planTypeId: [null, Validators.required],
      mnyMgrId: [null, Validators.required],
      mnyMgrProgramCode: [null, Validators.required],
      providerProductSelectAll: [null],
      planTypeSelectAll: [null],
      mnyMgrSelectAll: [null],
      mnyMgrProgramCodeSelectAll: [null],
    });

    documentOptions.forEach((documentOption: MatrixDocumentOptions) => {
      matrixForm.addControl(documentOption.optionFormName, this.formBuilder.control(null, [Validators.required]));
    });
    return matrixForm;
  }

  createEditMatrixFromGroup(
    documentOptions: MatrixDocumentOptions[],
    mode: string = null,
    matrixDocument: any = {}
  ): UntypedFormGroup {
    const replaceMode = mode === 'replace';
    const matrixForm = this.formBuilder.group({
      file: [
        replaceMode && matrixDocument?.document?.path ? matrixDocument.document.path.split('\\').pop() : null,
        Validators.required,
      ], // This option is disabled because service not accepting the file value. */
      displayName: [replaceMode ? matrixDocument?.document?.displayName : null, Validators.required],
      filePath: [
        replaceMode && matrixDocument?.document?.path ? matrixDocument.document.path.split('\\').pop() : null,
        Validators.required,
      ],
      documentTypeId: [
        replaceMode && matrixDocument?.document?.documentTypeId ? matrixDocument?.document.documentTypeId : null,
        Validators.required,
      ],
      providerProductId: [
        replaceMode && matrixDocument?.document?.providerproducts
          ? matrixDocument.document.providerproducts.split(',').map(Number)
          : null,
        Validators.required,
      ],
      planTypeId: [
        replaceMode && matrixDocument?.document?.planTypes
          ? matrixDocument.document.planTypes.split(',').map(Number)
          : null,
        Validators.required,
      ],
      mnyMgrId: [
        replaceMode && matrixDocument?.document?.moneyManagerId
          ? matrixDocument.document.moneyManagerId.split(',').map(Number)
          : null,
        Validators.required,
      ],
      mnyMgrProgramCode: [
        replaceMode && matrixDocument?.document?.moneyManagerProgramCode
          ? matrixDocument.document.moneyManagerProgramCode.split(',').map(String)
          : null,
        Validators.required,
      ],
      providerProductSelectAll: [null],
      planTypeSelectAll: [null],
      mnyMgrSelectAll: [null],
      mnyMgrProgramCodeSelectAll: [null],
    });

    documentOptions.forEach((documentOption: MatrixDocumentOptions) => {
      matrixForm.addControl(
        documentOption.optionFormName,
        this.formBuilder.control(
          this.getDocumentOptionFormCtrlValue(
            replaceMode,
            documentOption.documentOptionsId,
            matrixDocument?.documentAssignedOptions
          ),
          [Validators.required]
        )
      );
    });
    return matrixForm;
  }

  getDocumentOptionFormCtrlValue(mode: boolean, documentOptionsId: number, documentAssignedOptions): string {
    let documentOptionsValue = null;
    if (mode) {
      documentOptionsValue = documentAssignedOptions?.find(
        (documentOptions) => documentOptions?.documentAssignedOptionKey?.documentOptionsId === documentOptionsId
      );
    }
    return documentOptionsValue ? documentOptionsValue?.assignedOptionValue : null;
  }

  createAddMatrixPayload(
    form: UntypedFormGroup,
    managerProgramOptions: MoneyManagerProgramOption[],
    documentOptions: MatrixDocumentOptions[]
  ) {
    const formValue = form.getRawValue();
    return {
      documentDisplayName: formValue.displayName,
      documentPath: formValue.filePath,
      documentTypeId: formValue.documentTypeId,
      providerProducts: formValue.providerProductId,
      planTypes: formValue.planTypeId,
      moneyManagerIds: formValue.mnyMgrId,
      moneyManagerPrograms: formValue.mnyMgrProgramCode,
      documentOptions: this.createDocumentOptionsPayload(formValue, documentOptions),
    };
  }

  createDocumentOptionsPayload(formValue, documentOptions: MatrixDocumentOptions[]): DocumentOptionPayload[] {
    const documentOptionPayload = [];
    documentOptions?.forEach((documentOption) => {
      const optionDoc = [];
      optionDoc.push(JSON.stringify(documentOption.documentOptionsId));
      optionDoc.push(formValue[documentOption.optionFormName]);
      documentOptionPayload.push(optionDoc);
    });
    return documentOptionPayload;
  }

  getDocumentMoneyManager(
    mnyMgrProgramCode,
    managerProgramOptions: MoneyManagerProgramOption[]
  ): DocumentMoneyManagerPayload[] {
    const mnyMgrProgramCodePayload = [];
    mnyMgrProgramCode?.forEach((programCode) => {
      managerProgramOptions.forEach((programOption) => {
        if (programOption.mnyMgrId.mnyMgrProgramCode === programCode) {
          mnyMgrProgramCodePayload.push({
            moneyManager: programOption.mnyMgrId.mnyMgrId,
            program: programOption.mnyMgrId.mnyMgrProgramCode,
          });
        }
      });
    });
    return mnyMgrProgramCodePayload;
  }
  saveMatrixDocument(
    form,
    file: File,
    managerProgramOptions: MoneyManagerProgramOption[],
    documentOptions: MatrixDocumentOptions[]
  ) {
    const formData = new FormData();
    const addDocFormRequest = this.createAddMatrixPayload(form, managerProgramOptions, documentOptions);
    const fileName = form.value.filePath;
    const DTO_OBJECT = JSON.stringify(addDocFormRequest);
    formData.append('file', file, file.name);
    formData.append('document-save-request', DTO_OBJECT);
    return this.http.post<MatrixResponse>(this.baseUrl +'/matrix/save-matrix-document', formData).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  createDocumentsWithOrderPayload(form, arrayList) {
    const requestPayload = [];
    arrayList?.forEach((arrayCode) => {
      requestPayload.push({
        documentId: form[arrayCode].documentId,
        documentOrder: form[arrayCode].documentOrder,
      });
    });
    return requestPayload;
  }

  createReverseMatrixPayload(form, managerProgramOptions, documentOptions) {
    const formValue = form.getRawValue();
    return {
      planStatus: formValue.planStatus,
      providerProduct: formValue.providerProductId[0].toString(),
      planType: formValue.planTypeId[0].toString(),
      mnyMgrId: formValue.mnyMgrId[0],
      mnyMgrProgram: formValue.mnyMgrProgramCode[0],
      documentOptions: this.createDocumentOptionsPayloadForReverseSearch(
        formValue,
        documentOptions,
        managerProgramOptions
      ),
    };
  }

  reverseMatrixSearch(form, managerProgramOptions, documentOptions) {
    const requestObjectArray = this.createReverseMatrixPayload(form, managerProgramOptions, documentOptions);
    const serviceURL = this.baseUrl +'/matrix/document-search';
    return this.http.post<MatrixSearchResponse>(serviceURL, requestObjectArray);
  }

  getMatrixDocument(id: number) {
    const serviceURL = this.baseUrl +'/matrix/get-document/'+ id;
    return this.http.get<MatrixDocument>(serviceURL).pipe(
      tap((matrixDocument: MatrixDocument) => {
        this.matrixDocumentStore.set('matrixDocument', matrixDocument);
      })
    );
  }

  getAllDocumentsWithOrder(): Observable<MatrixWithOrderResponse[]> {
    const serviceURL = this.baseUrl +'/matrix/get-all-documents-with-order';
    return this.http.get<MatrixWithOrderResponse[]>(serviceURL).pipe(
      tap((documentOptions: MatrixWithOrderResponse[]) => {
        this.matrixDocumentStore.set('editdocumentOptions', documentOptions);
      })
    );
  }

  updateDocumentsWithOrder(form, arrayList) {
    const requestObjectArray = this.createDocumentsWithOrderPayload(form, arrayList);
    const serviceURL = this.baseUrl +'/matrix/update-document-order';
    return this.http.post<MatrixSearchResponse[]>(serviceURL, requestObjectArray);
  }
  savePopulateDynamicOption(requestPayload: MatrixDynamicOptions) {
    const serviceURL = this.baseUrl +'/matrix/options';
    return this.http.post<MatrixSearchResponse[]>(serviceURL, requestPayload);
  }

  updateMatrixDocument(
    form: UntypedFormGroup,
    managerProgramOptions: MoneyManagerProgramOption[],
    documentOptions: MatrixDocumentOptions[],
    matrixDocument: MatrixDocument = {}
  ) {
    return this.http.put<MatrixResponse>(
      this.baseUrl+ '/matrix/update-document',
      this.createUpdateMatrixPayload(form, managerProgramOptions, documentOptions, matrixDocument)
    ).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  createUpdateMatrixPayload(
    form: UntypedFormGroup,
    managerProgramOptions: MoneyManagerProgramOption[],
    documentOptions: MatrixDocumentOptions[],
    matrixDocument: MatrixDocument
  ) {
    const formValue = form.getRawValue();
    return {
      documentId: matrixDocument?.document?.id,
      documentDisplayName: formValue.displayName,
      documentPath: formValue.filePath,
      documentTypeId: formValue.documentTypeId,
      providerProducts: formValue.providerProductId,
      planTypes: formValue.planTypeId,
      moneyManagerIds: formValue.mnyMgrId,
      moneyManagerPrograms: formValue.mnyMgrProgramCode,
      documentOptions: this.createDocumentOptionsPayloadForUpdate(formValue, documentOptions, matrixDocument),
    };
  }

  createDocumentOptionsPayloadForUpdate(
    formValue: UntypedFormGroup,
    documentOptions: MatrixDocumentOptions[],
    matrixDocument: MatrixDocument
  ): DocumentOptionPayload[] {
    const documentOptionPayload = [];
    documentOptions?.forEach((documentOption) => {
      documentOptionPayload.push({
        assignedOptionId: this.getAssignedOptionId(documentOption.documentOptionsId, matrixDocument),
        documentOptionId: documentOption.documentOptionsId,
        assignedOptionValue: formValue[documentOption.optionFormName].toString(),
      });
    });
    return documentOptionPayload;
  }
  createDocumentOptionsPayloadForReverseSearch(
    formValue: UntypedFormGroup,
    documentOptions: MatrixDocumentOptions[],
    matrixDocument: MatrixDocument
  ): DocumentOptionPayload[] {
    const documentOptionPayload = [];
    documentOptions?.forEach((documentOption) => {
      documentOptionPayload.push({
        documentOptionsId: documentOption.documentOptionsId,
        assignedOptionValue: formValue[documentOption.optionFormName].toString(),
      });
    });
    return documentOptionPayload;
  }
  getAssignedOptionId(documentOptionsId: number, matrixDocument: MatrixDocument): number {
    const assignedOptionIdValue = matrixDocument?.documentAssignedOptions?.find(
      (documentOptions) => documentOptions?.documentAssignedOptionKey?.documentOptionsId === documentOptionsId
    );
    return assignedOptionIdValue ? assignedOptionIdValue?.assignedOptionsId : null;
  }
  deleteDocument(documentId: number) {
    const serviceURL = this.baseUrl +`/matrix/delete-document/${documentId}`;
    return this.http.delete<MatrixSearchResponse[]>(serviceURL);
  }

  replaceMatrixDocument(
    form,
    file: File,
    documentOptions: MatrixDocumentOptions[],
    matrixDocument: MatrixDocument
  ) {
    const formData = new FormData();
    const addDocFormRequest = this.createReplaceMatrixPayload(form, documentOptions, matrixDocument);
    const fileName = form.value.filePath;
    const DTO_OBJECT = JSON.stringify(addDocFormRequest);
    formData.append('file', file, file.name);
    formData.append('document-update-request', DTO_OBJECT);
    return this.http.post<MatrixResponse>(this.baseUrl +'/matrix/replace-document', formData).pipe(catchError((error: HttpErrorResponse) => throwError(this.errorUtilsService.getErrorMessage(error.error))));
  }

  createReplaceMatrixPayload(
    form: UntypedFormGroup,
    documentOptions: MatrixDocumentOptions[],
    matrixDocument?: MatrixDocument
  ) {
    const formValue = form.getRawValue();
    return {
      documentId: matrixDocument?.document?.id,
      documentDisplayName: formValue.displayName,
      documentPath: formValue.filePath,
      documentTypeId: formValue.documentTypeId,
      providerProducts: formValue.providerProductId,
      planTypes: formValue.planTypeId,
      moneyManagerIds: formValue.mnyMgrId,
      moneyManagerPrograms: formValue.mnyMgrProgramCode,
      documentOptions: this.createDocumentOptionsPayloadReplace(formValue, documentOptions),
    };
  }

  createDocumentOptionsPayloadReplace(formValue, documentOptions: MatrixDocumentOptions[]): DocumentOptionPayload[] {
    const documentOptionPayload = [];
    documentOptions?.forEach((documentOption) => {
      const optionDoc = [];
      documentOptionPayload.push({"documentOptionId":documentOption.documentOptionsId,"assignedOptionValue":formValue[documentOption.optionFormName]});
    });
    return documentOptionPayload;
  }
}
